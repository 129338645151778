import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export function Missing() {
    return (
        <Box
            width="fill-available"
            height="100%"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <LockOutlinedIcon sx={{ fontSize: '350px' }} />
            <Typography
                color="#707070"
                variant="h1"
                fontSize="30px"
                fontWeight="700"
            >
                This page is currently unavailable!
            </Typography>
            <Typography
                color="#707070"
                variant="h2"
                fontSize="30px"
            >
                Check back later for updates.
            </Typography>
        </Box>
    );
}
