import { ToastInterface, initialToastState } from './toastState';

const toastReducer = (
    state: Partial<ToastInterface> = initialToastState,
    action: any
) => {
    switch (action.type) {
        case 'SET_TOAST_CONTEXT':
            return {
                ...state,
                toastMessage: action.payload.toastMessage,
                toastType: action.payload.toastType,
            };
        case 'SET_TOAST_MESSAGE':
            return {
                ...state,
                toastMessage: action.payload.toastMessage,
            };
        case 'CLEAR_TOAST_CONTEXT':
            return initialToastState;
        default:
            return state;
    }
};

export default toastReducer;
