import { ResponsiveInterface, initialResponsiveState } from './responsiveState';

const responsiveReducer = (
    state: Partial<ResponsiveInterface> = initialResponsiveState,
    action: any
) => {
    switch (action.type) {
        case 'SET_RESPONSIVE_CONTEXT':
            return {
                ...state,
                isDesktop: action.payload.isDesktop,
                isWideScreen: action.payload.isWideScreen,
                isTablet: action.payload.isTablet,
                isMobile: action.payload.isMobile,
                isPortrait: action.payload.isPortrait,
                isRetina: action.payload.isRetina,
            };
        default:
            return state;
    }
};

export default responsiveReducer;
