import React, { useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { useAppDispatch, useAppSelector } from './redux/hooks';
import { clearToastContext } from './redux/reducers/toast/toastActions';
import { selectToastContext } from './redux/reducers/toast/toastSelectors';

export const AppToast = () => {
    const dispatch = useAppDispatch();
    const toastState = useAppSelector(selectToastContext);

    const [allowOpen, setAllowOpen] = useState(true);

    useEffect(() => {
        setAllowOpen(true);
    }, [toastState]);

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (reason === 'clickaway') {
            return;
        }

        setAllowOpen(false);
    };

    const handleExit = () => {
        dispatch(clearToastContext());
    };

    return (
        <Snackbar
            open={allowOpen && !!toastState?.toastMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            autoHideDuration={6000}
            onClose={handleClose}
            TransitionProps={{ onExited: handleExit }}
        >
            <Alert
                onClose={handleClose}
                severity={toastState?.toastType ? toastState.toastType : 'info'}
                sx={{ width: '100%' }}
            >
                {toastState?.toastMessage}
            </Alert>
        </Snackbar>
    );
};
