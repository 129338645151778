declare module '@mui/material/Paper' {
    interface PaperPropsVariantOverrides {
        prepListItemRow: true;
    }
}

const paperThemeOptions = {
    components: {
        MuiPaper: {
            variants: [
                {
                    props: { variant: 'prepListItemRow' },
                    style: ({ theme }: any) => ({
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        alignContent: 'space-between',
                        width: '100%',
                        marginTop: theme.spacing(0.5),
                        marginBottom: theme.spacing(0.5),
                        boxShadow: theme.shadows[2],
                    }),
                },
            ],
        },
    },
};

export default paperThemeOptions;
