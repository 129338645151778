/**
 * * Async JSON request
 * @param {user_id} string containing the new user's ID
 * @param {concept} string containing user's concept name
 * @param {pos_type} string containing pos type
 * @param {api_key} string containing user's Square API key
 */
export async function postVerifyPOS(
    user_id: string,
    concept: string,
    pos_type: string,
    api_key?: string
) {
    // API URL Endpoint Construction
    const url = `${process.env.REACT_APP_API_URL}/pos_verify`;

    let body = JSON.stringify({
        action: 'verify_pos',
        user_id: user_id,
        concept: concept,
        pos_type: pos_type.toLowerCase(),
        api_key: api_key,
    });

    // Perform API call
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(JSON.stringify(response.json()));
            }
            return response.json();
        })
        .catch((err) => {
            console.error(err);
            throw err;
        });
}
