const AWS_Offline_SignIn = {
    username: 'offline@clearcogs.com',
    pool: {
        userPoolId: 'us-east-2_3c6AscYjx',
        clientId: '5hfououonl1gq26kiud8o3mslq',
        client: {
            endpoint: 'https://cognito-idp.us-east-2.amazonaws.com/',
            fetchOptions: {},
        },
        advancedSecurityDataCollectionFlag: true,
        storage: {
            'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.deviceKey':
                'us-east-2_faf11cf9-0415-4346-8866-0ad0584aa4ff',
            'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.randomPasswordKey':
                'DNFTKjh5sFAyKbZyxARJpJ3GKRbcMmVy8Q2K61o9JcmimRPgMHp4Eg==',
            'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.deviceGroupKey':
                '-lgHFqDPr',
        },
    },
    Session:
        'AYABeGUaSJFpZiJ1fXLtbzZvQiYAHQABAAdTZXJ2aWNlABBDb2duaXRvVXNlclBvb2xzAAEAB2F3cy1rbXMAS2Fybjphd3M6a21zOnVzLWVhc3QtMjo0MTc1Njc5MDM0Njk6a2V5LzVjZDI0ZDRjLWVjNWItNGU4Ny05MGI2LTVkODdkOTZmY2RkMgC4AQIBAHjif3k0w30uAyP92ifoZ0jN6g50UW_KR0w9Vv2c_wlQAgForeVgJN40WcWB67Pb3U4uAAAAfjB8BgkqhkiG9w0BBwagbzBtAgEAMGgGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQM4yqmX8NO0hf7ksuwAgEQgDuUSYsOv8hbOG-WqEQ6ULAI0wGd47lMNRAt27mJH-wAxvCCmuY7qJPG4vo1gXRIsgBmZbnEJTFVYNraPgIAAAAADAAAEAAAAAAAAAAAAAAAAAA1e0IZH01TW70PzCgu1NsX_____wAAAAEAAAAAAAAAAAAAAAEAAAEHMDImyJAFdqfRnlMocj4W-Vcjq1gBGzp5djD7Y2lpjU3DwuwdREXUvSsWBNTkp2IGnr9Vhs619sa1wW81wfzPmaFIiP0d2iVxWzBEqDlOpoqiyjZx4aR5WiWo6S8ECbP46WxQwemtVgPE0sQvZ8LN3DlAXBk-Prpk8G-lzOLiDtrz5unSWdjn98Q0ER-CB9_2K7pSpDu5_RNzrzh2BG0r-Bfknar43uRKQFngVcdGlPOiXnnpbIeFVX05i5ViAxK5C2LzABk99ro8EIKDkyqOjjmtjGGhSJflVunIyCEi7Fk1qSAFN-Ay7n_0R3h3oLw7qZJtX02r0Ko_Ie5IIz4dlvtZi3dbyv4XIaTvJ1JlNz4y0dFUzTnd',
    client: {
        endpoint: 'https://cognito-idp.us-east-2.amazonaws.com/',
        fetchOptions: {},
    },
    signInUserSession: null,
    authenticationFlowType: 'CUSTOM_AUTH',
    storage: {
        'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.deviceKey':
            'us-east-2_faf11cf9-0415-4346-8866-0ad0584aa4ff',
        'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.randomPasswordKey':
            'DNFTKjh5sFAyKbZyxARJpJ3GKRbcMmVy8Q2K61o9JcmimRPgMHp4Eg==',
        'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.deviceGroupKey':
            '-lgHFqDPr',
    },
    keyPrefix: 'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq',
    userDataKey:
        'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.userData',
    challengeName: 'CUSTOM_CHALLENGE',
    challengeParam: {
        answer: '123456',
        USERNAME: '12345678-1234-5678-abcd-123456789abc',
    },
};

const AWS_Offline_User = {
    username: 'offline@clearcogs.com',
    pool: {
        userPoolId: 'us-east-2_3c6AscYjx',
        clientId: '5hfououonl1gq26kiud8o3mslq',
        client: {
            endpoint: 'https://cognito-idp.us-east-2.amazonaws.com/',
            fetchOptions: {},
        },
        advancedSecurityDataCollectionFlag: true,
        storage: {
            'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.userData':
                '{"UserAttributes":[{"Name":"sub","Value":"e9b0a9a2-2dab-4352-a6b6-140877274ae0"},{"Name":"email_verified","Value":"true"},{"Name":"email","Value":"offline@clearcogs.com"}],"Username":"e9b0a9a2-2dab-4352-a6b6-140877274ae0"}',
            'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.idToken':
                'eyJraWQiOiJRQTVIQVdDSEVNWTBkbDlsSDNid1JXa2Y5M3lib0QzODVncXJLd0toSE5BPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJlOWIwYTlhMi0yZGFiLTQzNTItYTZiNi0xNDA4NzcyNzRhZTAiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMi5hbWF6b25hd3MuY29tXC91cy1lYXN0LTJfM2M2QXNjWWp4IiwiY29nbml0bzp1c2VybmFtZSI6ImU5YjBhOWEyLTJkYWItNDM1Mi1hNmI2LTE0MDg3NzI3NGFlMCIsIm9yaWdpbl9qdGkiOiIwYzUwMjJkZS0yNjg5LTRhYWEtOWVmNC01ZjkyNGIzNzhiMTciLCJhdWQiOiI1aGZvdW91b25sMWdxMjZraXVkOG8zbXNscSIsImV2ZW50X2lkIjoiNjU0MTgwYWUtMmQxZS00NGQzLTliMmYtMGM5M2RmMTJlYTg0IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2ODk3MDIxMDEsImV4cCI6MTY4OTcyMzcwMSwiaWF0IjoxNjg5NzAyMTAxLCJqdGkiOiJmYzViNWE0Zi01NWFhLTQyNzUtYTVmNC04M2M1N2I2ZDc0MGMiLCJlbWFpbCI6InBhdHJpY2tAY2xlYXJjb2dzLmNvbSJ9.AAaCm19A6PSSL0qBFMGJij0Um51DQOADszUEcDBiIs3txn4JBsktZUb8y4stBElF6VvGuQAL27a6nFEEJyzMC-EkLqf2hgQHvAZGvNshGvdWC1aSYN2jOdMmR4wpkqOteu9ArT0NXrI6G2dykxc7hW29-YoOzmrd2yauRLjaaMiihLeNPnSuGvoX3Aku0S7ECNM8GiWARUy9jC8uxvt7wBo40gcb_NzLOK1RrhAh9Z1zplWA818MauvWFpfmu3z3P93zOmu2oz5gCkyjnSzLGoauwhMIVTBTsXqQu403zt2CUQmSSyIchp28LfdQowLkr0hBw8k1QNR6od_X7Fom9Q',
            'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.LastAuthUser':
                'offline@clearcogs.com',
            'amplify-signin-with-hostedUI': 'false',
            'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.refreshToken':
                'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.NwZuad07b6YqwYJdBLYawiUYbGda5Cmgwh6NOtb6qYhbiDd8TmrCVVw1S1BzASeROZQCej0JVeJlNsKo8mp2PvcNH9FdBJvn_CDCEe2YNySwpGrHcM2DmxjwA-RneoW7at4sSlbPwxu9-afbJKrvzLmYiLXltrf6vlhhOicTr_PVPZ5ZOsumq_zxGWofYGS2mAIHWi_deStG9TmBJl162OzP0JY_2AzQ2yITlsBMzuHepqo4z5-YlLSp1Ht4CqwjLz1e3qjBiUhwbw66ovJOxoxu6y9DP3rfsgb8cWkcHRFDcUTCCh43K_xLq2QSa6rlytpHFGbnW3hw32FHxu5Bcg.IwUuVzBeNP2eYm-u.LBpWU7fKBa4D92HxgIOy1oQAUXhcr7L8CI_-1esZ2hnJvwIpH23TlKPR2H8ZlkLf8lZafZGcmz_7ip9Lsrx037i3EqZrEdNlypex7wuiSoyKeVnFpxPcHS8G8p3UshX-jusiDXrjwWIKjikdZqJ_2y_Fsjqg6kdVUQjbIwUEL_8h7mEPmpwm053HXLzrg2axMHAmDijBhgHvPH9NtjhhSXr8KYvZe12NTS4gqGFNZNBuUU3pqogK-Ajy3Al4wjhLirP4ofddzvoqJa6xjlI6gufNdqq3KAOsfA0PYP3dW4BA5tfwCXiOZPkFCX6qm0_SpAhZndiSEzkNLz8c1tEc_TSBugQbIPydbG_8Hjp-V11QpemQjplufs1Vp7YSOsYkQGZX43ML7nc84lD5MdrcD6B6kn6iuzdDYbCzL4NwYLVjJiIOpi5a9IpeiDMCL1ICw0tj3Ltdq_UioEvQ8Cd9aVcG0C-_nK6GhzqF-wdhFqIlf19ZhK9encWurtUIPo5JxcT--3azGxLQFwGVKMlgFXyPjB_WolX7ZZQU5SR-I7jwFV0ZgxfRQ-pxWJODm0OXy67VIrVbJ17Bgctx0OCdaP4WjBYCC96lgIc1C_LnFwq33w_xOyTC4LXOYUssUOr1sj5S_y8ddYtaSMaXV6QK98mcxJZStJj7BwVsYp4BwrZzOCekqLvymU2lEHc0Bx59b7eQUUx0Pp24KJsxqO5_THoWzd539GhJhNUSw4vH7v8TAc_eQL7E9hM7tn-pH1BjLct0nTmy7XHjQ-Q7kpB3pLMXES1BfWXDhJlXBfjaNlIojeqMOexAt1AI9r-SNN547MKm-7lrWax7LgvUcDus6MSzjQ8kMmS9ISwM20P8d7RVYkRRUgiqn_D098pHM49PKvMdTqCkkEAM4myic-6QIHArBrj2Q1DO3pTh17OfuDwgTiqOGmG4uJNNtdbxbs3V0t65r8VLTBPE36MYqPPVddLBaZvXarKXM0lBeZijVWOYUKTYk1RHGy2VNkY-5cRFwPXXv529fzHaQh_9GBNcxBEJ0p6zxoLcW9g9HGCHjMOpIM0mvkFvxTG8X-bodYEqOq0HoG373gpn-2VFAM6rOIZP-4gt-HXtGl2GlbWkU0zHxR7Qee8MyM-OqHLmrG26igBDKXWnTsQinYfeWNO44Mt7uLIwaP37MPyE55Uyrpi8NGzA_z-kuiixbzdgAk-Z61TwmaFOyuM4m_LJmwmdv28mSlRQuk94QGLCVBfZmTjinMCxmhD8Ht6Zthc704offp_AUY604TUfUSyqUUEHAnpmRH6_F879Zs_8pvzLz4ZTRcJPu5ks9nSUu61QInSv1k5S72hfusKRj9-_Da2N7IexHmdsExZK_MseQtaEJKq4bcY1t9ynqfhkK80BDx5TTiNSRMYgSCZlTKiTOWBKNfF8qGyz0jpSv8A0Lr3E.RdkiGVzquVoTgb3q2YSK5Q',
            'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.accessToken':
                'eyJraWQiOiJ3Y3Vub3Q4Q3VoWVI0M2x4VzFqbjNcLzZFdGRIZXJFYlg4bDcrNXhQMjk5MD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJlOWIwYTlhMi0yZGFiLTQzNTItYTZiNi0xNDA4NzcyNzRhZTAiLCJkZXZpY2Vfa2V5IjoidXMtZWFzdC0yX2ZhZjExY2Y5LTA0MTUtNDM0Ni04ODY2LTBhZDA1ODRhYTRmZiIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0yXzNjNkFzY1lqeCIsImNsaWVudF9pZCI6IjVoZm91b3VvbmwxZ3EyNmtpdWQ4bzNtc2xxIiwib3JpZ2luX2p0aSI6IjBjNTAyMmRlLTI2ODktNGFhYS05ZWY0LTVmOTI0YjM3OGIxNyIsImV2ZW50X2lkIjoiNjU0MTgwYWUtMmQxZS00NGQzLTliMmYtMGM5M2RmMTJlYTg0IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTY4OTcwMjEwMSwiZXhwIjoxNjg5NzIzNzAxLCJpYXQiOjE2ODk3MDIxMDEsImp0aSI6Ijk2OTA5NThhLTNjNTYtNGM3Ny05MjU0LThlNzFlZmI5MWM0ZCIsInVzZXJuYW1lIjoiZTliMGE5YTItMmRhYi00MzUyLWE2YjYtMTQwODc3Mjc0YWUwIn0.OW13ktzjiCal_rB1msZrwO5sM6YjRv_tHHpI-c6cOafvj3qXCEfHf6U37zfJfGX4I_nVBYkXWJZLFYsCTBCGUJVAVKM9FKiUU7IfiqzWVfOg1Zl3VUo6H7E8m5QbXl95m1MT6NrKaJX-9fVpQaa3UlUtmZHd0C-ZNbRXNOFfuX-PBSNis_gQSZsE_dlU7I7K3CMPoP_v04MAmcps4a0cl_66u_EiVzWBl_Oi6hafpOp__QgA3EcHvtY6ho50UPVV9v7eblxJtygNFkzcJnE0uuNk6i1bZxnThe-URFxPuVSdn5C9qvWjNGZ8ixlnJ0ZdGLSoT-pJUKEmr3uNfwIJXQ',
            'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.deviceKey':
                'us-east-2_faf11cf9-0415-4346-8866-0ad0584aa4ff',
            'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.randomPasswordKey':
                'DNFTKjh5sFAyKbZyxARJpJ3GKRbcMmVy8Q2K61o9JcmimRPgMHp4Eg==',
            'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.deviceGroupKey':
                '-lgHFqDPr',
            'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.clockDrift':
                '-1',
        },
    },
    Session: null,
    client: {
        endpoint: 'https://cognito-idp.us-east-2.amazonaws.com/',
        fetchOptions: {},
    },
    signInUserSession: {
        idToken: {
            jwtToken:
                'eyJraWQiOiJRQTVIQVdDSEVNWTBkbDlsSDNid1JXa2Y5M3lib0QzODVncXJLd0toSE5BPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJlOWIwYTlhMi0yZGFiLTQzNTItYTZiNi0xNDA4NzcyNzRhZTAiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMi5hbWF6b25hd3MuY29tXC91cy1lYXN0LTJfM2M2QXNjWWp4IiwiY29nbml0bzp1c2VybmFtZSI6ImU5YjBhOWEyLTJkYWItNDM1Mi1hNmI2LTE0MDg3NzI3NGFlMCIsIm9yaWdpbl9qdGkiOiIwYzUwMjJkZS0yNjg5LTRhYWEtOWVmNC01ZjkyNGIzNzhiMTciLCJhdWQiOiI1aGZvdW91b25sMWdxMjZraXVkOG8zbXNscSIsImV2ZW50X2lkIjoiNjU0MTgwYWUtMmQxZS00NGQzLTliMmYtMGM5M2RmMTJlYTg0IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2ODk3MDIxMDEsImV4cCI6MTY4OTcyMzcwMSwiaWF0IjoxNjg5NzAyMTAxLCJqdGkiOiJmYzViNWE0Zi01NWFhLTQyNzUtYTVmNC04M2M1N2I2ZDc0MGMiLCJlbWFpbCI6InBhdHJpY2tAY2xlYXJjb2dzLmNvbSJ9.AAaCm19A6PSSL0qBFMGJij0Um51DQOADszUEcDBiIs3txn4JBsktZUb8y4stBElF6VvGuQAL27a6nFEEJyzMC-EkLqf2hgQHvAZGvNshGvdWC1aSYN2jOdMmR4wpkqOteu9ArT0NXrI6G2dykxc7hW29-YoOzmrd2yauRLjaaMiihLeNPnSuGvoX3Aku0S7ECNM8GiWARUy9jC8uxvt7wBo40gcb_NzLOK1RrhAh9Z1zplWA818MauvWFpfmu3z3P93zOmu2oz5gCkyjnSzLGoauwhMIVTBTsXqQu403zt2CUQmSSyIchp28LfdQowLkr0hBw8k1QNR6od_X7Fom9Q',
            payload: {
                sub: '12345678-1234-5678-abcd-123456789abc',
                email_verified: true,
                iss: 'https://cognito-idp.us-east-2.amazonaws.com/us-east-2_3c6AscYjx',
                'cognito:username': '12345678-1234-5678-abcd-123456789abc',
                origin_jti: '12345678-1234-5678-abcd-123456789abc',
                aud: '5hfououonl1gq26kiud8o3mslq',
                event_id: '12345678-1234-5678-abcd-123456789abc',
                token_use: 'id',
                auth_time: 1689702101,
                exp: 1689723701,
                iat: 1689702101,
                jti: '12345678-1234-5678-abcd-123456789abc',
                email: 'offline@clearcogs.com',
            },
        },
        refreshToken: {
            token: 'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.NwZuad07b6YqwYJdBLYawiUYbGda5Cmgwh6NOtb6qYhbiDd8TmrCVVw1S1BzASeROZQCej0JVeJlNsKo8mp2PvcNH9FdBJvn_CDCEe2YNySwpGrHcM2DmxjwA-RneoW7at4sSlbPwxu9-afbJKrvzLmYiLXltrf6vlhhOicTr_PVPZ5ZOsumq_zxGWofYGS2mAIHWi_deStG9TmBJl162OzP0JY_2AzQ2yITlsBMzuHepqo4z5-YlLSp1Ht4CqwjLz1e3qjBiUhwbw66ovJOxoxu6y9DP3rfsgb8cWkcHRFDcUTCCh43K_xLq2QSa6rlytpHFGbnW3hw32FHxu5Bcg.IwUuVzBeNP2eYm-u.LBpWU7fKBa4D92HxgIOy1oQAUXhcr7L8CI_-1esZ2hnJvwIpH23TlKPR2H8ZlkLf8lZafZGcmz_7ip9Lsrx037i3EqZrEdNlypex7wuiSoyKeVnFpxPcHS8G8p3UshX-jusiDXrjwWIKjikdZqJ_2y_Fsjqg6kdVUQjbIwUEL_8h7mEPmpwm053HXLzrg2axMHAmDijBhgHvPH9NtjhhSXr8KYvZe12NTS4gqGFNZNBuUU3pqogK-Ajy3Al4wjhLirP4ofddzvoqJa6xjlI6gufNdqq3KAOsfA0PYP3dW4BA5tfwCXiOZPkFCX6qm0_SpAhZndiSEzkNLz8c1tEc_TSBugQbIPydbG_8Hjp-V11QpemQjplufs1Vp7YSOsYkQGZX43ML7nc84lD5MdrcD6B6kn6iuzdDYbCzL4NwYLVjJiIOpi5a9IpeiDMCL1ICw0tj3Ltdq_UioEvQ8Cd9aVcG0C-_nK6GhzqF-wdhFqIlf19ZhK9encWurtUIPo5JxcT--3azGxLQFwGVKMlgFXyPjB_WolX7ZZQU5SR-I7jwFV0ZgxfRQ-pxWJODm0OXy67VIrVbJ17Bgctx0OCdaP4WjBYCC96lgIc1C_LnFwq33w_xOyTC4LXOYUssUOr1sj5S_y8ddYtaSMaXV6QK98mcxJZStJj7BwVsYp4BwrZzOCekqLvymU2lEHc0Bx59b7eQUUx0Pp24KJsxqO5_THoWzd539GhJhNUSw4vH7v8TAc_eQL7E9hM7tn-pH1BjLct0nTmy7XHjQ-Q7kpB3pLMXES1BfWXDhJlXBfjaNlIojeqMOexAt1AI9r-SNN547MKm-7lrWax7LgvUcDus6MSzjQ8kMmS9ISwM20P8d7RVYkRRUgiqn_D098pHM49PKvMdTqCkkEAM4myic-6QIHArBrj2Q1DO3pTh17OfuDwgTiqOGmG4uJNNtdbxbs3V0t65r8VLTBPE36MYqPPVddLBaZvXarKXM0lBeZijVWOYUKTYk1RHGy2VNkY-5cRFwPXXv529fzHaQh_9GBNcxBEJ0p6zxoLcW9g9HGCHjMOpIM0mvkFvxTG8X-bodYEqOq0HoG373gpn-2VFAM6rOIZP-4gt-HXtGl2GlbWkU0zHxR7Qee8MyM-OqHLmrG26igBDKXWnTsQinYfeWNO44Mt7uLIwaP37MPyE55Uyrpi8NGzA_z-kuiixbzdgAk-Z61TwmaFOyuM4m_LJmwmdv28mSlRQuk94QGLCVBfZmTjinMCxmhD8Ht6Zthc704offp_AUY604TUfUSyqUUEHAnpmRH6_F879Zs_8pvzLz4ZTRcJPu5ks9nSUu61QInSv1k5S72hfusKRj9-_Da2N7IexHmdsExZK_MseQtaEJKq4bcY1t9ynqfhkK80BDx5TTiNSRMYgSCZlTKiTOWBKNfF8qGyz0jpSv8A0Lr3E.RdkiGVzquVoTgb3q2YSK5Q',
        },
        accessToken: {
            jwtToken:
                'eyJraWQiOiJ3Y3Vub3Q4Q3VoWVI0M2x4VzFqbjNcLzZFdGRIZXJFYlg4bDcrNXhQMjk5MD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJlOWIwYTlhMi0yZGFiLTQzNTItYTZiNi0xNDA4NzcyNzRhZTAiLCJkZXZpY2Vfa2V5IjoidXMtZWFzdC0yX2ZhZjExY2Y5LTA0MTUtNDM0Ni04ODY2LTBhZDA1ODRhYTRmZiIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0yXzNjNkFzY1lqeCIsImNsaWVudF9pZCI6IjVoZm91b3VvbmwxZ3EyNmtpdWQ4bzNtc2xxIiwib3JpZ2luX2p0aSI6IjBjNTAyMmRlLTI2ODktNGFhYS05ZWY0LTVmOTI0YjM3OGIxNyIsImV2ZW50X2lkIjoiNjU0MTgwYWUtMmQxZS00NGQzLTliMmYtMGM5M2RmMTJlYTg0IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTY4OTcwMjEwMSwiZXhwIjoxNjg5NzIzNzAxLCJpYXQiOjE2ODk3MDIxMDEsImp0aSI6Ijk2OTA5NThhLTNjNTYtNGM3Ny05MjU0LThlNzFlZmI5MWM0ZCIsInVzZXJuYW1lIjoiZTliMGE5YTItMmRhYi00MzUyLWE2YjYtMTQwODc3Mjc0YWUwIn0.OW13ktzjiCal_rB1msZrwO5sM6YjRv_tHHpI-c6cOafvj3qXCEfHf6U37zfJfGX4I_nVBYkXWJZLFYsCTBCGUJVAVKM9FKiUU7IfiqzWVfOg1Zl3VUo6H7E8m5QbXl95m1MT6NrKaJX-9fVpQaa3UlUtmZHd0C-ZNbRXNOFfuX-PBSNis_gQSZsE_dlU7I7K3CMPoP_v04MAmcps4a0cl_66u_EiVzWBl_Oi6hafpOp__QgA3EcHvtY6ho50UPVV9v7eblxJtygNFkzcJnE0uuNk6i1bZxnThe-URFxPuVSdn5C9qvWjNGZ8ixlnJ0ZdGLSoT-pJUKEmr3uNfwIJXQ',
            payload: {
                sub: '12345678-1234-5678-abcd-123456789abc',
                device_key: 'us-east-2_faf11cf9-0415-4346-8866-0ad0584aa4ff',
                iss: 'https://cognito-idp.us-east-2.amazonaws.com/us-east-2_3c6AscYjx',
                client_id: '5hfououonl1gq26kiud8o3mslq',
                origin_jti: '12345678-1234-5678-abcd-123456789abc',
                event_id: '12345678-1234-5678-abcd-123456789abc',
                token_use: 'access',
                scope: 'aws.cognito.signin.user.admin',
                auth_time: 1689702101,
                exp: 1689723701,
                iat: 1689702101,
                jti: '12345678-1234-5678-abcd-123456789abc',
                username: '12345678-1234-5678-abcd-123456789abc',
            },
        },
        clockDrift: -1,
    },
    authenticationFlowType: 'USER_SRP_AUTH',
    storage: {
        'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.userData':
            '{"UserAttributes":[{"Name":"sub","Value":"e9b0a9a2-2dab-4352-a6b6-140877274ae0"},{"Name":"email_verified","Value":"true"},{"Name":"email","Value":"offline@clearcogs.com"}],"Username":"e9b0a9a2-2dab-4352-a6b6-140877274ae0"}',
        'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.idToken':
            'eyJraWQiOiJRQTVIQVdDSEVNWTBkbDlsSDNid1JXa2Y5M3lib0QzODVncXJLd0toSE5BPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJlOWIwYTlhMi0yZGFiLTQzNTItYTZiNi0xNDA4NzcyNzRhZTAiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMi5hbWF6b25hd3MuY29tXC91cy1lYXN0LTJfM2M2QXNjWWp4IiwiY29nbml0bzp1c2VybmFtZSI6ImU5YjBhOWEyLTJkYWItNDM1Mi1hNmI2LTE0MDg3NzI3NGFlMCIsIm9yaWdpbl9qdGkiOiIwYzUwMjJkZS0yNjg5LTRhYWEtOWVmNC01ZjkyNGIzNzhiMTciLCJhdWQiOiI1aGZvdW91b25sMWdxMjZraXVkOG8zbXNscSIsImV2ZW50X2lkIjoiNjU0MTgwYWUtMmQxZS00NGQzLTliMmYtMGM5M2RmMTJlYTg0IiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2ODk3MDIxMDEsImV4cCI6MTY4OTcyMzcwMSwiaWF0IjoxNjg5NzAyMTAxLCJqdGkiOiJmYzViNWE0Zi01NWFhLTQyNzUtYTVmNC04M2M1N2I2ZDc0MGMiLCJlbWFpbCI6InBhdHJpY2tAY2xlYXJjb2dzLmNvbSJ9.AAaCm19A6PSSL0qBFMGJij0Um51DQOADszUEcDBiIs3txn4JBsktZUb8y4stBElF6VvGuQAL27a6nFEEJyzMC-EkLqf2hgQHvAZGvNshGvdWC1aSYN2jOdMmR4wpkqOteu9ArT0NXrI6G2dykxc7hW29-YoOzmrd2yauRLjaaMiihLeNPnSuGvoX3Aku0S7ECNM8GiWARUy9jC8uxvt7wBo40gcb_NzLOK1RrhAh9Z1zplWA818MauvWFpfmu3z3P93zOmu2oz5gCkyjnSzLGoauwhMIVTBTsXqQu403zt2CUQmSSyIchp28LfdQowLkr0hBw8k1QNR6od_X7Fom9Q',
        'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.LastAuthUser':
            'offline@clearcogs.com',
        'amplify-signin-with-hostedUI': 'false',
        'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.refreshToken':
            'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.NwZuad07b6YqwYJdBLYawiUYbGda5Cmgwh6NOtb6qYhbiDd8TmrCVVw1S1BzASeROZQCej0JVeJlNsKo8mp2PvcNH9FdBJvn_CDCEe2YNySwpGrHcM2DmxjwA-RneoW7at4sSlbPwxu9-afbJKrvzLmYiLXltrf6vlhhOicTr_PVPZ5ZOsumq_zxGWofYGS2mAIHWi_deStG9TmBJl162OzP0JY_2AzQ2yITlsBMzuHepqo4z5-YlLSp1Ht4CqwjLz1e3qjBiUhwbw66ovJOxoxu6y9DP3rfsgb8cWkcHRFDcUTCCh43K_xLq2QSa6rlytpHFGbnW3hw32FHxu5Bcg.IwUuVzBeNP2eYm-u.LBpWU7fKBa4D92HxgIOy1oQAUXhcr7L8CI_-1esZ2hnJvwIpH23TlKPR2H8ZlkLf8lZafZGcmz_7ip9Lsrx037i3EqZrEdNlypex7wuiSoyKeVnFpxPcHS8G8p3UshX-jusiDXrjwWIKjikdZqJ_2y_Fsjqg6kdVUQjbIwUEL_8h7mEPmpwm053HXLzrg2axMHAmDijBhgHvPH9NtjhhSXr8KYvZe12NTS4gqGFNZNBuUU3pqogK-Ajy3Al4wjhLirP4ofddzvoqJa6xjlI6gufNdqq3KAOsfA0PYP3dW4BA5tfwCXiOZPkFCX6qm0_SpAhZndiSEzkNLz8c1tEc_TSBugQbIPydbG_8Hjp-V11QpemQjplufs1Vp7YSOsYkQGZX43ML7nc84lD5MdrcD6B6kn6iuzdDYbCzL4NwYLVjJiIOpi5a9IpeiDMCL1ICw0tj3Ltdq_UioEvQ8Cd9aVcG0C-_nK6GhzqF-wdhFqIlf19ZhK9encWurtUIPo5JxcT--3azGxLQFwGVKMlgFXyPjB_WolX7ZZQU5SR-I7jwFV0ZgxfRQ-pxWJODm0OXy67VIrVbJ17Bgctx0OCdaP4WjBYCC96lgIc1C_LnFwq33w_xOyTC4LXOYUssUOr1sj5S_y8ddYtaSMaXV6QK98mcxJZStJj7BwVsYp4BwrZzOCekqLvymU2lEHc0Bx59b7eQUUx0Pp24KJsxqO5_THoWzd539GhJhNUSw4vH7v8TAc_eQL7E9hM7tn-pH1BjLct0nTmy7XHjQ-Q7kpB3pLMXES1BfWXDhJlXBfjaNlIojeqMOexAt1AI9r-SNN547MKm-7lrWax7LgvUcDus6MSzjQ8kMmS9ISwM20P8d7RVYkRRUgiqn_D098pHM49PKvMdTqCkkEAM4myic-6QIHArBrj2Q1DO3pTh17OfuDwgTiqOGmG4uJNNtdbxbs3V0t65r8VLTBPE36MYqPPVddLBaZvXarKXM0lBeZijVWOYUKTYk1RHGy2VNkY-5cRFwPXXv529fzHaQh_9GBNcxBEJ0p6zxoLcW9g9HGCHjMOpIM0mvkFvxTG8X-bodYEqOq0HoG373gpn-2VFAM6rOIZP-4gt-HXtGl2GlbWkU0zHxR7Qee8MyM-OqHLmrG26igBDKXWnTsQinYfeWNO44Mt7uLIwaP37MPyE55Uyrpi8NGzA_z-kuiixbzdgAk-Z61TwmaFOyuM4m_LJmwmdv28mSlRQuk94QGLCVBfZmTjinMCxmhD8Ht6Zthc704offp_AUY604TUfUSyqUUEHAnpmRH6_F879Zs_8pvzLz4ZTRcJPu5ks9nSUu61QInSv1k5S72hfusKRj9-_Da2N7IexHmdsExZK_MseQtaEJKq4bcY1t9ynqfhkK80BDx5TTiNSRMYgSCZlTKiTOWBKNfF8qGyz0jpSv8A0Lr3E.RdkiGVzquVoTgb3q2YSK5Q',
        'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.accessToken':
            'eyJraWQiOiJ3Y3Vub3Q4Q3VoWVI0M2x4VzFqbjNcLzZFdGRIZXJFYlg4bDcrNXhQMjk5MD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJlOWIwYTlhMi0yZGFiLTQzNTItYTZiNi0xNDA4NzcyNzRhZTAiLCJkZXZpY2Vfa2V5IjoidXMtZWFzdC0yX2ZhZjExY2Y5LTA0MTUtNDM0Ni04ODY2LTBhZDA1ODRhYTRmZiIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTIuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0yXzNjNkFzY1lqeCIsImNsaWVudF9pZCI6IjVoZm91b3VvbmwxZ3EyNmtpdWQ4bzNtc2xxIiwib3JpZ2luX2p0aSI6IjBjNTAyMmRlLTI2ODktNGFhYS05ZWY0LTVmOTI0YjM3OGIxNyIsImV2ZW50X2lkIjoiNjU0MTgwYWUtMmQxZS00NGQzLTliMmYtMGM5M2RmMTJlYTg0IiwidG9rZW5fdXNlIjoiYWNjZXNzIiwic2NvcGUiOiJhd3MuY29nbml0by5zaWduaW4udXNlci5hZG1pbiIsImF1dGhfdGltZSI6MTY4OTcwMjEwMSwiZXhwIjoxNjg5NzIzNzAxLCJpYXQiOjE2ODk3MDIxMDEsImp0aSI6Ijk2OTA5NThhLTNjNTYtNGM3Ny05MjU0LThlNzFlZmI5MWM0ZCIsInVzZXJuYW1lIjoiZTliMGE5YTItMmRhYi00MzUyLWE2YjYtMTQwODc3Mjc0YWUwIn0.OW13ktzjiCal_rB1msZrwO5sM6YjRv_tHHpI-c6cOafvj3qXCEfHf6U37zfJfGX4I_nVBYkXWJZLFYsCTBCGUJVAVKM9FKiUU7IfiqzWVfOg1Zl3VUo6H7E8m5QbXl95m1MT6NrKaJX-9fVpQaa3UlUtmZHd0C-ZNbRXNOFfuX-PBSNis_gQSZsE_dlU7I7K3CMPoP_v04MAmcps4a0cl_66u_EiVzWBl_Oi6hafpOp__QgA3EcHvtY6ho50UPVV9v7eblxJtygNFkzcJnE0uuNk6i1bZxnThe-URFxPuVSdn5C9qvWjNGZ8ixlnJ0ZdGLSoT-pJUKEmr3uNfwIJXQ',
        'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.deviceKey':
            'us-east-2_faf11cf9-0415-4346-8866-0ad0584aa4ff',
        'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.randomPasswordKey':
            'DNFTKjh5sFAyKbZyxARJpJ3GKRbcMmVy8Q2K61o9JcmimRPgMHp4Eg==',
        'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.deviceGroupKey':
            '-lgHFqDPr',
        'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.clockDrift':
            '-1',
    },
    keyPrefix: 'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq',
    userDataKey:
        'CognitoIdentityServiceProvider.5hfououonl1gq26kiud8o3mslq.offline@clearcogs.com.userData',
    attributes: {
        sub: '12345678-1234-5678-abcd-123456789abc',
        email_verified: true,
        email: 'offline@clearcogs.com',
    },
    preferredMFA: 'NOMFA',
};

export { AWS_Offline_SignIn, AWS_Offline_User };
