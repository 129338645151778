/**
 * * Async JSON request
 * @param {concept} string containing concept's UUID
 * @returns {object} object containing all user and store data
 */
export async function updateIngredients({ concept, ingredients }: any) {
    const url = `${process.env.REACT_APP_API_URL}/menu_mapper`;

    let body = JSON.stringify({
        action: 'DIFF_INGREDIENT',
        id: concept,
        data: ingredients,
    });

    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(JSON.stringify(response.json()));
            }
            return response.json();
        })
        .catch((err) => {
            console.error(err);
            throw err;
        });
}
