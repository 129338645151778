import { AlertColor } from '@mui/material';

import { RootState } from '../../store';
import { ToastInterface } from './toastState';

export const selectToastContext = (
    state: RootState
): Partial<ToastInterface> => {
    return state.toast;
};

export const selectToastMessage = (state: RootState): string => {
    return state.toast.toastMessage;
};

export const selectToastType = (state: RootState): AlertColor | undefined => {
    return state.toast.toastType;
};
