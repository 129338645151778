import { RootState } from '../../store';
import { FeatureSwitchInterface } from './featureSwitchState';

export const selectFeatureSwitch = (
    state: RootState
): Partial<FeatureSwitchInterface> => {
    return state.featureSwitch;
};

export const selectDebug = (state: RootState): boolean => {
    return state.featureSwitch.debug ?? false;
};

export const selectOfflineMode = (state: RootState): boolean => {
    return state.featureSwitch.offlineMode ?? false;
};

export const selectUnitsOfTimeSelector = (state: RootState): boolean => {
    return state.featureSwitch.unitsOfTimeSelector ?? false;
};

export const selectNewDataRelationship = (state: RootState): boolean => {
    return state.featureSwitch.newDataRelationship ?? false;
};

export const selectNewDataFormat = (state: RootState): boolean => {
    return state.featureSwitch.newDataFormat ?? false;
};

export const selectWasteStockoutSlider = (state: RootState): boolean => {
    return state.featureSwitch.wasteStockoutSlider ?? false;
};

export const selectEnforceLicenseLimit = (state: RootState): boolean => {
    return state.featureSwitch.enforceLicenseLimit ?? false;
};

export const selectQtyDisplayedAsTooltip = (state: RootState): boolean => {
    return state.featureSwitch.qtyDisplayedAsTooltip ?? false;
};

export const selectModifierGroups = (state: RootState): boolean => {
    return state.featureSwitch.modifierGroups ?? false;
};
