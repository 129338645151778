import React from 'react';

import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ProductionQuantityLimitsRoundedIcon from '@mui/icons-material/ProductionQuantityLimitsRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { updateMenuItems } from '../../../api/functions/updateMenuItems';
import { postMenu } from '../../../api/index';
import { colors } from '../../../constants/recipe_constants';
import { ToastInterface } from '../../../redux/reducers/toast/toastState';
import { ingredientsInterface, menuItemsInterface } from './Recipes';

let new_data_format = process.env.REACT_APP_FEATURE_NEW_DATA_FORMAT === 'true';

let new_data_relationship =
    process.env.REACT_APP_FEATURE_NEW_DATA_RELATIONSHIP === 'true';

export function RecipeTab({
    tabValue,
    menuItems,
    menuItemsEgress,
    activeItem,
    handleOuterRecipeAccordianChange,
    activeItemModifier,
    activeItemModifierOption,
    ingredients,
    handleMiddleRecipeAccordianChange,
    handleInnerRecipeAccordianChange,
    setCopyRegIng,
    setCopyRegIngUuid,
    setCopyRegQty,
    copyRegIng,
    copyRegIngUuid,
    copyRegQty,
    handleRecipeCopy,
    handleSelectChange,
    handleQtyChange,
    handleIngredientRemoveFromItem,
    setActiveItem,
    setMenuItems,
    setMenuItemsEgress,
    idToIdx,
    categories,
    prepLists,
    filter,
    setFilter,
    filteredMenuItems,
    setToastState,
    searchBarRef,
    setPageLock,
}: {
    tabValue: number;
    menuItems: menuItemsInterface;
    menuItemsEgress: menuItemsInterface;
    activeItem: string;
    handleOuterRecipeAccordianChange: (
        panel: string
    ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    activeItemModifier: string;
    activeItemModifierOption: string;
    ingredients: ingredientsInterface;
    handleMiddleRecipeAccordianChange: (
        panel: string
    ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    handleInnerRecipeAccordianChange: (
        panel: string
    ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    setCopyRegIng: React.Dispatch<React.SetStateAction<string[]>>;
    setCopyRegIngUuid: React.Dispatch<React.SetStateAction<string[]>>;
    setCopyRegQty: React.Dispatch<React.SetStateAction<string[]>>;
    copyRegIng: string[];
    copyRegIngUuid: string[];
    copyRegQty: string[];
    handleRecipeCopy: () => void;
    handleSelectChange: (
        event: SelectChangeEvent,
        index: number,
        newValue?: any
    ) => void;
    handleQtyChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number
    ) => void;
    handleIngredientRemoveFromItem: (index: number) => void;
    setActiveItem: React.Dispatch<React.SetStateAction<string>>;
    setMenuItems: React.Dispatch<React.SetStateAction<menuItemsInterface>>;
    setMenuItemsEgress: React.Dispatch<
        React.SetStateAction<menuItemsInterface>
    >;
    idToIdx: string;
    categories: string[];
    prepLists: any;
    filter: string;
    setFilter: React.Dispatch<React.SetStateAction<string>>;
    filteredMenuItems: menuItemsInterface;
    setToastState: (toast: ToastInterface) => void;
    searchBarRef: any;
    setPageLock: (pageLock: boolean) => void;
}) {
    return (
        <Box
            width="100%"
            display="flex"
            sx={{
                flexDirection: 'row',
                alignItems: 'stretch',
            }}
        >
            <Box
                width="400px"
                maxHeight="calc(100% - 49px)"
                sx={{
                    borderRight: 1,
                    overflowY: 'scroll',
                    borderColor: 'rgba(0, 0, 0, 0.12)',
                }}
            >
                <Paper
                    variant="outlined"
                    sx={{
                        p: '2px 4px',
                        display: 'flex',
                        alignItems: 'center',
                        width: 'fill',
                    }}
                >
                    <IconButton
                        onClick={() => {
                            searchBarRef?.current?.focus();
                        }}
                        sx={{ p: '10px' }}
                        aria-label="search"
                    >
                        <SearchRoundedIcon />
                    </IconButton>
                    <InputBase
                        id="outlined-basic"
                        inputRef={searchBarRef}
                        placeholder="Search Items"
                        value={filter}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            setFilter(event.target.value);
                        }}
                        fullWidth
                        sx={{ backgroundColor: 'white', ml: 1, flex: 1 }}
                    />
                    <IconButton
                        sx={{ p: '10px' }}
                        aria-label="clear search"
                        onClick={() => {
                            setFilter('');
                            searchBarRef?.current?.focus();
                        }}
                    >
                        <ClearRoundedIcon />
                    </IconButton>
                </Paper>
                {Object.keys(filteredMenuItems).map((item, idx_outside) => {
                    return (
                        <Accordion
                            expanded={activeItem === item}
                            onChange={handleOuterRecipeAccordianChange(item)}
                            key={idx_outside}
                            sx={{
                                '&.Mui-expanded': {
                                    marginTop: '1px',
                                    marginBottom: '1px',
                                },
                            }}
                        >
                            <AccordionSummary
                                // expandIcon={
                                //     menuItemsEgress[item].level_2 &&
                                //     Object.keys(
                                //         menuItemsEgress[item].level_2
                                //     ).length !== 0 && <ExpandMoreIcon />
                                // }
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    borderLeft: `4px solid ${
                                        colors[idx_outside % colors.length]
                                    }`,
                                    backgroundColor:
                                        activeItem === item &&
                                        !!!activeItemModifier &&
                                        !!!activeItemModifierOption
                                            ? '#D8D8D8'
                                            : '#FFF',
                                }}
                            >
                                {new_data_relationship
                                    ? !!menuItemsEgress[item]
                                          .ingredients_uuid &&
                                      (!menuItemsEgress[
                                          item
                                      ].ingredients_uuid.every(
                                          (val: any) =>
                                              !!Object.keys(ingredients).find(
                                                  (key) =>
                                                      ingredients[key].uuid ===
                                                      val
                                              )
                                      ) ||
                                          menuItemsEgress[item].ingredients_uuid
                                              .length !==
                                              menuItemsEgress[item].qty
                                                  .length) && (
                                          <Tooltip
                                              arrow
                                              placement="top"
                                              title={
                                                  !menuItemsEgress[
                                                      item
                                                  ].ingredients_uuid.every(
                                                      (val: any) =>
                                                          !!Object.keys(
                                                              ingredients
                                                          ).find(
                                                              (key) =>
                                                                  ingredients[
                                                                      key
                                                                  ].uuid === val
                                                          )
                                                  )
                                                      ? 'A forecastable item in this recipe has been lost!'
                                                      : 'Some quantity data has been lost!'
                                              }
                                          >
                                              <WarningRoundedIcon color="error" />
                                          </Tooltip>
                                      )
                                    : !!menuItemsEgress[item].ingredients &&
                                      (!menuItemsEgress[item].ingredients.every(
                                          (val: any) =>
                                              Object.keys(ingredients).includes(
                                                  val
                                              )
                                      ) ||
                                          menuItemsEgress[item].ingredients
                                              .length !==
                                              menuItemsEgress[item].qty
                                                  .length) && (
                                          <Tooltip
                                              arrow
                                              placement="top"
                                              title={
                                                  !menuItemsEgress[
                                                      item
                                                  ].ingredients.every(
                                                      (val: any) =>
                                                          Object.keys(
                                                              ingredients
                                                          ).includes(val)
                                                  )
                                                      ? 'A forecastable item in this recipe has been lost!'
                                                      : 'Some quantity data has been lost!'
                                              }
                                          >
                                              <WarningRoundedIcon color="error" />
                                          </Tooltip>
                                      )}
                                {new_data_relationship
                                    ? !!menuItemsEgress[item]
                                          .ingredients_uuid &&
                                      menuItemsEgress[item].ingredients_uuid
                                          .length === 0 && (
                                          <Tooltip
                                              arrow
                                              placement="top"
                                              title={
                                                  'This recipe has no forecastable items!'
                                              }
                                          >
                                              <ProductionQuantityLimitsRoundedIcon color="info" />
                                          </Tooltip>
                                      )
                                    : !!menuItemsEgress[item].ingredients &&
                                      menuItemsEgress[item].ingredients
                                          .length === 0 && (
                                          <Tooltip
                                              arrow
                                              placement="top"
                                              title={
                                                  'This recipe has no forecastable items!'
                                              }
                                          >
                                              <ProductionQuantityLimitsRoundedIcon color="info" />
                                          </Tooltip>
                                      )}
                                <Typography
                                    noWrap
                                    width={
                                        new_data_relationship
                                            ? !!menuItemsEgress[item]
                                                  .ingredients_uuid &&
                                              menuItemsEgress[item]
                                                  .ingredients_uuid.length ===
                                                  0 &&
                                              (!menuItemsEgress[
                                                  item
                                              ].ingredients_uuid.every(
                                                  (val: any) =>
                                                      !!Object.keys(
                                                          ingredients
                                                      ).find(
                                                          (key) =>
                                                              ingredients[key]
                                                                  .uuid === val
                                                      )
                                              ) ||
                                                  menuItemsEgress[item]
                                                      .ingredients_uuid
                                                      .length !==
                                                      menuItemsEgress[item].qty
                                                          .length)
                                                ? '250px'
                                                : !!menuItemsEgress[item]
                                                      .ingredients_uuid &&
                                                  (menuItemsEgress[item]
                                                      .ingredients_uuid
                                                      .length === 0 ||
                                                      !menuItemsEgress[
                                                          item
                                                      ].ingredients_uuid.every(
                                                          (val: any) =>
                                                              !!Object.keys(
                                                                  ingredients
                                                              ).find(
                                                                  (key) =>
                                                                      ingredients[
                                                                          key
                                                                      ].uuid ===
                                                                      val
                                                              )
                                                      ) ||
                                                      menuItemsEgress[item]
                                                          .ingredients_uuid
                                                          .length !==
                                                          menuItemsEgress[item]
                                                              .qty.length)
                                                ? '280px'
                                                : '310px'
                                            : !!menuItemsEgress[item]
                                                  .ingredients &&
                                              menuItemsEgress[item].ingredients
                                                  .length === 0 &&
                                              (!menuItemsEgress[
                                                  item
                                              ].ingredients.every((val: any) =>
                                                  Object.keys(
                                                      ingredients
                                                  ).includes(val)
                                              ) ||
                                                  menuItemsEgress[item]
                                                      .ingredients.length !==
                                                      menuItemsEgress[item].qty
                                                          .length)
                                            ? '250px'
                                            : !!menuItemsEgress[item]
                                                  .ingredients &&
                                              (menuItemsEgress[item].ingredients
                                                  .length === 0 ||
                                                  !menuItemsEgress[
                                                      item
                                                  ].ingredients.every(
                                                      (val: any) =>
                                                          Object.keys(
                                                              ingredients
                                                          ).includes(val)
                                                  ) ||
                                                  menuItemsEgress[item]
                                                      .ingredients.length !==
                                                      menuItemsEgress[item].qty
                                                          .length)
                                            ? '280px'
                                            : '310px'
                                    }
                                    sx={{
                                        flexShrink: 0,
                                        paddingLeft: '4px',
                                    }}
                                >
                                    {item}
                                </Typography>
                            </AccordionSummary>
                            {/* <Divider />
                                <AccordionDetails sx={{ padding: 0 }}>
                                    {activeItem === item && (
                                        <Box
                                            display="flex"
                                            sx={{
                                                flexDirection: 'column',
                                                marginLeft: 4,
                                            }}
                                        >
                                            {menuItemsEgress[item].level_2 &&
                                                Object.keys(
                                                    menuItemsEgress[item]
                                                        .level_2
                                                ).map(
                                                    (
                                                        modifier: any,
                                                        idx_m: any
                                                    ) => {
                                                        return (
                                                            <Accordion
                                                                expanded={
                                                                    activeItemModifier ===
                                                                    modifier
                                                                }
                                                                onChange={handleMiddleRecipeAccordianChange(
                                                                    modifier
                                                                )}
                                                                key={idx_m}
                                                                sx={{
                                                                    '&.Mui-expanded':
                                                                        {
                                                                            marginTop:
                                                                                '1px',
                                                                            marginBottom:
                                                                                '1px',
                                                                        },
                                                                }}
                                                            >
                                                                <AccordionSummary
                                                                    expandIcon={
                                                                        menuItemsEgress[
                                                                            item
                                                                        ]
                                                                            .level_2[
                                                                            modifier
                                                                        ]
                                                                            .level_3 &&
                                                                        Object.keys(
                                                                            menuItemsEgress[
                                                                                item
                                                                            ]
                                                                                .level_2[
                                                                                modifier
                                                                            ]
                                                                                .level_3
                                                                        )
                                                                            .length !==
                                                                            0 && (
                                                                            <ExpandMoreIcon />
                                                                        )
                                                                    }
                                                                    aria-controls="panel1bh-content"
                                                                    id="panel1bh-header"
                                                                    sx={{
                                                                        borderLeft: `4px solid ${
                                                                            colors[
                                                                                idx_outside %
                                                                                    colors.length
                                                                            ]
                                                                        }`,
                                                                        backgroundColor:
                                                                            activeItem ===
                                                                                item &&
                                                                            activeItemModifier ===
                                                                                modifier &&
                                                                            !!!activeItemModifierOption
                                                                                ? '#D8D8D8'
                                                                                : '#FFF',
                                                                    }}
                                                                >
                                                                    {new_data_relationship
                                                                        ? activeItemModifier &&
                                                                          menuItemsEgress[
                                                                              item
                                                                          ]
                                                                              .level_2[
                                                                              activeItemModifier
                                                                          ] &&
                                                                          menuItemsEgress[
                                                                              item
                                                                          ]
                                                                              .level_2[
                                                                              activeItemModifier
                                                                          ]
                                                                              .ingredients_uuid &&
                                                                          (!menuItemsEgress[
                                                                              item
                                                                          ].level_2[
                                                                              activeItemModifier
                                                                          ].ingredients_uuid.every(
                                                                              (
                                                                                  val: any
                                                                              ) =>
                                                                                  !!ingredients &&
                                                                                  !!Object.keys(
                                                                                      ingredients
                                                                                  ).find(
                                                                                      (
                                                                                          key
                                                                                      ) =>
                                                                                          ingredients[
                                                                                              key
                                                                                          ]
                                                                                              .uuid ===
                                                                                          val
                                                                                  )
                                                                          ) ||
                                                                              menuItemsEgress[
                                                                                  item
                                                                              ]
                                                                                  .level_2[
                                                                                  activeItemModifier
                                                                              ]
                                                                                  .ingredients_uuid
                                                                                  .length !==
                                                                                  menuItemsEgress[
                                                                                      item
                                                                                  ]
                                                                                      .level_2[
                                                                                      activeItemModifier
                                                                                  ]
                                                                                      .qty
                                                                                      .length) && (
                                                                              <Tooltip
                                                                                  arrow
                                                                                  placement="top"
                                                                                  title={
                                                                                      !menuItemsEgress[
                                                                                          item
                                                                                      ].level_2[
                                                                                          activeItemModifier
                                                                                      ].ingredients_uuid.every(
                                                                                          (
                                                                                              val: any
                                                                                          ) =>
                                                                                              !!ingredients &&
                                                                                              !!Object.keys(
                                                                                                  ingredients
                                                                                              ).find(
                                                                                                  (
                                                                                                      key
                                                                                                  ) =>
                                                                                                      ingredients[
                                                                                                          key
                                                                                                      ]
                                                                                                          .uuid ===
                                                                                                      val
                                                                                              )
                                                                                      )
                                                                                          ? 'A forecastable item in this recipe has been lost!'
                                                                                          : 'Some quantity data has been lost!'
                                                                                  }
                                                                              >
                                                                                  <WarningRoundedIcon color="error" />
                                                                              </Tooltip>
                                                                          )
                                                                        : activeItemModifier &&
                                                                          menuItemsEgress[
                                                                              item
                                                                          ]
                                                                              .level_2[
                                                                              activeItemModifier
                                                                          ] &&
                                                                          menuItemsEgress[
                                                                              item
                                                                          ]
                                                                              .level_2[
                                                                              activeItemModifier
                                                                          ]
                                                                              .ingredients &&
                                                                          (!menuItemsEgress[
                                                                              item
                                                                          ].level_2[
                                                                              activeItemModifier
                                                                          ].ingredients.every(
                                                                              (
                                                                                  val: any
                                                                              ) =>
                                                                                  !!ingredients &&
                                                                                  Object.keys(
                                                                                      ingredients
                                                                                  ).includes(
                                                                                      val
                                                                                  )
                                                                          ) ||
                                                                              menuItemsEgress[
                                                                                  item
                                                                              ]
                                                                                  .level_2[
                                                                                  activeItemModifier
                                                                              ]
                                                                                  .ingredients
                                                                                  .length !==
                                                                                  menuItemsEgress[
                                                                                      item
                                                                                  ]
                                                                                      .level_2[
                                                                                      activeItemModifier
                                                                                  ]
                                                                                      .qty
                                                                                      .length) && (
                                                                              <Tooltip
                                                                                  arrow
                                                                                  placement="top"
                                                                                  title={
                                                                                      !menuItemsEgress[
                                                                                          item
                                                                                      ].level_2[
                                                                                          activeItemModifier
                                                                                      ].ingredients.every(
                                                                                          (
                                                                                              val: any
                                                                                          ) =>
                                                                                              !!ingredients &&
                                                                                              Object.keys(
                                                                                                  ingredients
                                                                                              ).includes(
                                                                                                  val
                                                                                              )
                                                                                      )
                                                                                          ? 'A forecastable item in this recipe has been lost!'
                                                                                          : 'Some quantity data has been lost!'
                                                                                  }
                                                                              >
                                                                                  <WarningRoundedIcon color="error" />
                                                                              </Tooltip>
                                                                          )}
                                                                    <Typography
                                                                        sx={{
                                                                            flexShrink: 0,
                                                                            paddingLeft:
                                                                                '4px',
                                                                        }}
                                                                    >
                                                                        {
                                                                            modifier
                                                                        }
                                                                    </Typography>
                                                                </AccordionSummary>
                                                                <Divider />
                                                                <AccordionDetails
                                                                    sx={{
                                                                        padding: 0,
                                                                    }}
                                                                >
                                                                    {activeItem ===
                                                                        item &&
                                                                        activeItemModifier ===
                                                                            modifier && (
                                                                            <Box
                                                                                display="flex"
                                                                                sx={{
                                                                                    flexDirection:
                                                                                        'column',
                                                                                    marginLeft: 4,
                                                                                }}
                                                                            >
                                                                                {menuItemsEgress[
                                                                                    item
                                                                                ]
                                                                                    .level_2[
                                                                                    modifier
                                                                                ]
                                                                                    .level_3 &&
                                                                                    Object.keys(
                                                                                        menuItemsEgress[
                                                                                            item
                                                                                        ]
                                                                                            .level_2[
                                                                                            modifier
                                                                                        ]
                                                                                            .level_3
                                                                                    ).map(
                                                                                        (
                                                                                            option: any,
                                                                                            idx_o: number
                                                                                        ) => {
                                                                                            return (
                                                                                                <Accordion
                                                                                                    expanded={
                                                                                                        activeItemModifierOption ===
                                                                                                        option
                                                                                                    }
                                                                                                    onChange={handleInnerRecipeAccordianChange(
                                                                                                        option
                                                                                                    )}
                                                                                                    key={
                                                                                                        idx_o
                                                                                                    }
                                                                                                    sx={{
                                                                                                        '&.Mui-expanded':
                                                                                                            {
                                                                                                                marginTop:
                                                                                                                    '1px',
                                                                                                                marginBottom:
                                                                                                                    '1px',
                                                                                                            },
                                                                                                    }}
                                                                                                >
                                                                                                    <AccordionSummary
                                                                                                        aria-controls="panel1bh-content"
                                                                                                        id="panel1bh-header"
                                                                                                        sx={{
                                                                                                            borderLeft: `4px solid ${
                                                                                                                colors[
                                                                                                                    idx_outside %
                                                                                                                        colors.length
                                                                                                                ]
                                                                                                            }`,
                                                                                                            backgroundColor:
                                                                                                                activeItem ===
                                                                                                                    item &&
                                                                                                                activeItemModifier ===
                                                                                                                    modifier &&
                                                                                                                activeItemModifierOption ===
                                                                                                                    option
                                                                                                                    ? '#D8D8D8'
                                                                                                                    : '#FFF',
                                                                                                        }}
                                                                                                    >
                                                                                                        {new_data_relationship
                                                                                                            ? activeItemModifier &&
                                                                                                              activeItemModifierOption &&
                                                                                                              menuItemsEgress[
                                                                                                                  item
                                                                                                              ]
                                                                                                                  .level_2[
                                                                                                                  activeItemModifier
                                                                                                              ]
                                                                                                                  .level_3[
                                                                                                                  activeItemModifierOption
                                                                                                              ] &&
                                                                                                              menuItemsEgress[
                                                                                                                  item
                                                                                                              ]
                                                                                                                  .level_2[
                                                                                                                  activeItemModifier
                                                                                                              ]
                                                                                                                  .level_3[
                                                                                                                  activeItemModifierOption
                                                                                                              ]
                                                                                                                  .ingredients_uuid &&
                                                                                                              (!menuItemsEgress[
                                                                                                                  item
                                                                                                              ].level_2[
                                                                                                                  activeItemModifier
                                                                                                              ].level_3[
                                                                                                                  activeItemModifierOption
                                                                                                              ].ingredients_uuid.every(
                                                                                                                  (
                                                                                                                      val: any
                                                                                                                  ) =>
                                                                                                                      !!ingredients &&
                                                                                                                      !!Object.keys(
                                                                                                                          ingredients
                                                                                                                      ).find(
                                                                                                                          (
                                                                                                                              key
                                                                                                                          ) =>
                                                                                                                              ingredients[
                                                                                                                                  key
                                                                                                                              ]
                                                                                                                                  .uuid ===
                                                                                                                              val
                                                                                                                      )
                                                                                                              ) ||
                                                                                                                  menuItemsEgress[
                                                                                                                      item
                                                                                                                  ]
                                                                                                                      .level_2[
                                                                                                                      activeItemModifier
                                                                                                                  ]
                                                                                                                      .level_3[
                                                                                                                      activeItemModifierOption
                                                                                                                  ]
                                                                                                                      .ingredients_uuid
                                                                                                                      .length !==
                                                                                                                      menuItemsEgress[
                                                                                                                          item
                                                                                                                      ]
                                                                                                                          .level_2[
                                                                                                                          activeItemModifier
                                                                                                                      ]
                                                                                                                          .level_3[
                                                                                                                          activeItemModifierOption
                                                                                                                      ]
                                                                                                                          .qty
                                                                                                                          .length) && (
                                                                                                                  <Tooltip
                                                                                                                      arrow
                                                                                                                      placement="top"
                                                                                                                      title={
                                                                                                                          !menuItemsEgress[
                                                                                                                              item
                                                                                                                          ].level_2[
                                                                                                                              activeItemModifier
                                                                                                                          ].level_3[
                                                                                                                              activeItemModifierOption
                                                                                                                          ].ingredients_uuid.every(
                                                                                                                              (
                                                                                                                                  val: any
                                                                                                                              ) =>
                                                                                                                                  !!ingredients &&
                                                                                                                                  !!Object.keys(
                                                                                                                                      ingredients
                                                                                                                                  ).find(
                                                                                                                                      (
                                                                                                                                          key
                                                                                                                                      ) =>
                                                                                                                                          ingredients[
                                                                                                                                              key
                                                                                                                                          ]
                                                                                                                                              .uuid ===
                                                                                                                                          val
                                                                                                                                  )
                                                                                                                          )
                                                                                                                              ? 'A forecastable item in this recipe has been lost!'
                                                                                                                              : 'Some quantity data has been lost!'
                                                                                                                      }
                                                                                                                  >
                                                                                                                      <WarningRoundedIcon color="error" />
                                                                                                                  </Tooltip>
                                                                                                              )
                                                                                                            : activeItemModifier &&
                                                                                                              activeItemModifierOption &&
                                                                                                              menuItemsEgress[
                                                                                                                  item
                                                                                                              ]
                                                                                                                  .level_2[
                                                                                                                  activeItemModifier
                                                                                                              ]
                                                                                                                  .level_3[
                                                                                                                  activeItemModifierOption
                                                                                                              ] &&
                                                                                                              menuItemsEgress[
                                                                                                                  item
                                                                                                              ]
                                                                                                                  .level_2[
                                                                                                                  activeItemModifier
                                                                                                              ]
                                                                                                                  .level_3[
                                                                                                                  activeItemModifierOption
                                                                                                              ]
                                                                                                                  .ingredients &&
                                                                                                              (!menuItemsEgress[
                                                                                                                  item
                                                                                                              ].level_2[
                                                                                                                  activeItemModifier
                                                                                                              ].level_3[
                                                                                                                  activeItemModifierOption
                                                                                                              ].ingredients.every(
                                                                                                                  (
                                                                                                                      val: any
                                                                                                                  ) =>
                                                                                                                      !!ingredients &&
                                                                                                                      Object.keys(
                                                                                                                          ingredients
                                                                                                                      ).includes(
                                                                                                                          val
                                                                                                                      )
                                                                                                              ) ||
                                                                                                                  menuItemsEgress[
                                                                                                                      item
                                                                                                                  ]
                                                                                                                      .level_2[
                                                                                                                      activeItemModifier
                                                                                                                  ]
                                                                                                                      .level_3[
                                                                                                                      activeItemModifierOption
                                                                                                                  ]
                                                                                                                      .ingredients
                                                                                                                      .length !==
                                                                                                                      menuItemsEgress[
                                                                                                                          item
                                                                                                                      ]
                                                                                                                          .level_2[
                                                                                                                          activeItemModifier
                                                                                                                      ]
                                                                                                                          .level_3[
                                                                                                                          activeItemModifierOption
                                                                                                                      ]
                                                                                                                          .qty
                                                                                                                          .length) && (
                                                                                                                  <Tooltip
                                                                                                                      arrow
                                                                                                                      placement="top"
                                                                                                                      title={
                                                                                                                          !menuItemsEgress[
                                                                                                                              item
                                                                                                                          ].level_2[
                                                                                                                              activeItemModifier
                                                                                                                          ].level_3[
                                                                                                                              activeItemModifierOption
                                                                                                                          ].ingredients.every(
                                                                                                                              (
                                                                                                                                  val: any
                                                                                                                              ) =>
                                                                                                                                  !!ingredients &&
                                                                                                                                  Object.keys(
                                                                                                                                      ingredients
                                                                                                                                  ).includes(
                                                                                                                                      val
                                                                                                                                  )
                                                                                                                          )
                                                                                                                              ? 'A forecastable item in this recipe has been lost!'
                                                                                                                              : 'Some quantity data has been lost!'
                                                                                                                      }
                                                                                                                  >
                                                                                                                      <WarningRoundedIcon color="error" />
                                                                                                                  </Tooltip>
                                                                                                              )}
                                                                                                        <Typography
                                                                                                            sx={{
                                                                                                                flexShrink: 0,
                                                                                                                paddingLeft:
                                                                                                                    '4px',
                                                                                                            }}
                                                                                                        >
                                                                                                            {
                                                                                                                option
                                                                                                            }
                                                                                                        </Typography>
                                                                                                    </AccordionSummary>
                                                                                                    <Divider />
                                                                                                    <AccordionDetails
                                                                                                        sx={{
                                                                                                            padding: 0,
                                                                                                        }}
                                                                                                    ></AccordionDetails>
                                                                                                </Accordion>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                <Divider />
                                                                            </Box>
                                                                        )}
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        );
                                                    }
                                                )}
                                            <Divider />
                                        </Box>
                                    )}
                                </AccordionDetails> */}
                        </Accordion>
                    );
                })}
            </Box>
            <Box
                width="calc(100% - 400px)"
                maxHeight="calc(100% - 49px)"
                sx={{ overflowY: 'scroll' }}
            >
                {activeItem === '' || tabValue !== 1 ? ( //TODO: fix turnary into nullcase
                    <Typography
                        textAlign="center"
                        marginTop="40px"
                    >
                        Please select a POS Item.
                    </Typography>
                ) : (
                    <Box>
                        <Box
                            sx={{
                                minWidth: 120,
                                margin: 4,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginBottom: 3,
                                    gap: 2,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: '700',
                                    }}
                                >
                                    {!!activeItemModifierOption
                                        ? activeItemModifierOption
                                        : !!activeItemModifier
                                        ? activeItemModifier
                                        : activeItem}
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 1,
                                        alignItems: 'flex-end',
                                        m: 0.5,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '10px',
                                            fontWeight: '400',
                                            '&:hover': {
                                                color: '#f8643c',
                                                textDecoration:
                                                    'underline #f8643c',
                                                cursor: 'pointer',
                                            },
                                        }}
                                        onClick={() => {
                                            if (!!activeItemModifierOption) {
                                                setCopyRegIng(
                                                    new_data_relationship
                                                        ? menuItemsEgress[
                                                              activeItem
                                                          ].level_2[
                                                              activeItemModifier
                                                          ].level_3[
                                                              activeItemModifierOption
                                                          ].ingredients_uuid
                                                              .filter(
                                                                  (
                                                                      ingredient_uuid: string
                                                                  ) =>
                                                                      Object.entries(
                                                                          ingredients
                                                                      ).find(
                                                                          ([
                                                                              _,
                                                                              value,
                                                                          ]) =>
                                                                              value.uuid ===
                                                                              ingredient_uuid
                                                                      )![1]
                                                                          .category !==
                                                                      'Menu Items'
                                                              )
                                                              .map(
                                                                  (
                                                                      ingredient_uuid: any
                                                                  ) => {
                                                                      return Object.keys(
                                                                          ingredients
                                                                      ).find(
                                                                          (
                                                                              ingredient: any
                                                                          ) =>
                                                                              ingredients[
                                                                                  ingredient
                                                                              ]
                                                                                  .uuid ===
                                                                              ingredient_uuid
                                                                      );
                                                                  }
                                                              )
                                                        : menuItemsEgress[
                                                              activeItem
                                                          ].level_2[
                                                              activeItemModifier
                                                          ].level_3[
                                                              activeItemModifierOption
                                                          ].ingredients.filter(
                                                              (
                                                                  ingredient: any
                                                              ) =>
                                                                  ingredients[
                                                                      ingredient
                                                                  ].category !==
                                                                  'Menu Items'
                                                          )
                                                );
                                                setCopyRegIngUuid(
                                                    menuItemsEgress[
                                                        activeItem
                                                    ].level_2[
                                                        activeItemModifier
                                                    ].level_3[
                                                        activeItemModifierOption
                                                    ].ingredients_uuid.filter(
                                                        (
                                                            ingredient_uuid: string
                                                        ) =>
                                                            Object.entries(
                                                                ingredients
                                                            ).find(
                                                                ([_, value]) =>
                                                                    value.uuid ===
                                                                    ingredient_uuid
                                                            )![1].category !==
                                                            'Menu Items'
                                                    )
                                                );
                                                setCopyRegQty(
                                                    new_data_relationship
                                                        ? menuItemsEgress[
                                                              activeItem
                                                          ].level_2[
                                                              activeItemModifier
                                                          ].level_3[
                                                              activeItemModifierOption
                                                          ].qty.filter(
                                                              (
                                                                  _: any,
                                                                  idx: number
                                                              ) => {
                                                                  return menuItemsEgress[
                                                                      activeItem
                                                                  ].level_2[
                                                                      activeItemModifier
                                                                  ].level_3[
                                                                      activeItemModifierOption
                                                                  ].ingredients_uuid
                                                                      .map(
                                                                          (
                                                                              uuid: string,
                                                                              index: number
                                                                          ) => {
                                                                              return Object.entries(
                                                                                  ingredients
                                                                              ).find(
                                                                                  ([
                                                                                      _,
                                                                                      value,
                                                                                  ]) =>
                                                                                      value.uuid ===
                                                                                      uuid
                                                                              )![1]
                                                                                  .category !==
                                                                                  'Menu Items'
                                                                                  ? index
                                                                                  : undefined;
                                                                          }
                                                                      )
                                                                      .includes(
                                                                          idx
                                                                      );
                                                              }
                                                          )
                                                        : menuItemsEgress[
                                                              activeItem
                                                          ].level_2[
                                                              activeItemModifier
                                                          ].level_3[
                                                              activeItemModifierOption
                                                          ].qty.filter(
                                                              (
                                                                  _: any,
                                                                  idx: number
                                                              ) =>
                                                                  ingredients[
                                                                      menuItemsEgress[
                                                                          activeItem
                                                                      ].level_2[
                                                                          activeItemModifier
                                                                      ].level_3[
                                                                          activeItemModifierOption
                                                                      ]
                                                                          .ingredients[
                                                                          idx
                                                                      ]
                                                                  ].category !==
                                                                  'Menu Items'
                                                          )
                                                );
                                            } else if (!!activeItemModifier) {
                                                setCopyRegIng(
                                                    new_data_relationship
                                                        ? menuItemsEgress[
                                                              activeItem
                                                          ].level_2[
                                                              activeItemModifier
                                                          ].ingredients_uuid
                                                              .filter(
                                                                  (
                                                                      ingredient_uuid: string
                                                                  ) =>
                                                                      Object.entries(
                                                                          ingredients
                                                                      ).find(
                                                                          ([
                                                                              _,
                                                                              value,
                                                                          ]) =>
                                                                              value.uuid ===
                                                                              ingredient_uuid
                                                                      )![1]
                                                                          .category !==
                                                                      'Menu Items'
                                                              )
                                                              .map(
                                                                  (
                                                                      ingredient_uuid: any
                                                                  ) => {
                                                                      return Object.keys(
                                                                          ingredients
                                                                      ).find(
                                                                          (
                                                                              ingredient: any
                                                                          ) =>
                                                                              ingredients[
                                                                                  ingredient
                                                                              ]
                                                                                  .uuid ===
                                                                              ingredient_uuid
                                                                      );
                                                                  }
                                                              )
                                                        : menuItemsEgress[
                                                              activeItem
                                                          ].level_2[
                                                              activeItemModifier
                                                          ].ingredients.filter(
                                                              (
                                                                  ingredient: any
                                                              ) =>
                                                                  ingredients[
                                                                      ingredient
                                                                  ].category !==
                                                                  'Menu Items'
                                                          )
                                                );
                                                setCopyRegIngUuid(
                                                    menuItemsEgress[
                                                        activeItem
                                                    ].level_2[
                                                        activeItemModifier
                                                    ].ingredients_uuid.filter(
                                                        (
                                                            ingredient_uuid: string
                                                        ) =>
                                                            Object.entries(
                                                                ingredients
                                                            ).find(
                                                                ([_, value]) =>
                                                                    value.uuid ===
                                                                    ingredient_uuid
                                                            )![1].category !==
                                                            'Menu Items'
                                                    )
                                                );
                                                setCopyRegQty(
                                                    new_data_relationship
                                                        ? menuItemsEgress[
                                                              activeItem
                                                          ].level_2[
                                                              activeItemModifier
                                                          ].qty.filter(
                                                              (
                                                                  _: any,
                                                                  idx: number
                                                              ) => {
                                                                  return menuItemsEgress[
                                                                      activeItem
                                                                  ].level_2[
                                                                      activeItemModifier
                                                                  ].ingredients_uuid
                                                                      .map(
                                                                          (
                                                                              uuid: string,
                                                                              index: number
                                                                          ) => {
                                                                              return Object.entries(
                                                                                  ingredients
                                                                              ).find(
                                                                                  ([
                                                                                      _,
                                                                                      value,
                                                                                  ]) =>
                                                                                      value.uuid ===
                                                                                      uuid
                                                                              )![1]
                                                                                  .category !==
                                                                                  'Menu Items'
                                                                                  ? index
                                                                                  : undefined;
                                                                          }
                                                                      )
                                                                      .includes(
                                                                          idx
                                                                      );
                                                              }
                                                          )
                                                        : menuItemsEgress[
                                                              activeItem
                                                          ].level_2[
                                                              activeItemModifier
                                                          ].qty.filter(
                                                              (
                                                                  _: any,
                                                                  idx: number
                                                              ) =>
                                                                  ingredients[
                                                                      menuItemsEgress[
                                                                          activeItem
                                                                      ].level_2[
                                                                          activeItemModifier
                                                                      ]
                                                                          .ingredients[
                                                                          idx
                                                                      ]
                                                                  ].category !==
                                                                  'Menu Items'
                                                          )
                                                );
                                            } else {
                                                setCopyRegIng(
                                                    new_data_relationship
                                                        ? menuItemsEgress[
                                                              activeItem
                                                          ].ingredients_uuid
                                                              .filter(
                                                                  (
                                                                      ingredient_uuid: string
                                                                  ) =>
                                                                      Object.entries(
                                                                          ingredients
                                                                      ).find(
                                                                          ([
                                                                              _,
                                                                              value,
                                                                          ]) =>
                                                                              value.uuid ===
                                                                              ingredient_uuid
                                                                      )![1]
                                                                          .category !==
                                                                      'Menu Items'
                                                              )
                                                              .map(
                                                                  (
                                                                      ingredient_uuid: any
                                                                  ) => {
                                                                      return Object.keys(
                                                                          ingredients
                                                                      ).find(
                                                                          (
                                                                              ingredient: any
                                                                          ) =>
                                                                              ingredients[
                                                                                  ingredient
                                                                              ]
                                                                                  .uuid ===
                                                                              ingredient_uuid
                                                                      );
                                                                  }
                                                              )
                                                        : menuItemsEgress[
                                                              activeItem
                                                          ].ingredients.filter(
                                                              (
                                                                  ingredient: any
                                                              ) =>
                                                                  ingredients[
                                                                      ingredient
                                                                  ].category !==
                                                                  'Menu Items'
                                                          )
                                                );
                                                setCopyRegIngUuid(
                                                    menuItemsEgress[
                                                        activeItem
                                                    ].ingredients_uuid.filter(
                                                        (
                                                            ingredient_uuid: string
                                                        ) =>
                                                            Object.entries(
                                                                ingredients
                                                            ).find(
                                                                ([_, value]) =>
                                                                    value.uuid ===
                                                                    ingredient_uuid
                                                            )![1].category !==
                                                            'Menu Items'
                                                    )
                                                );
                                                setCopyRegQty(
                                                    new_data_relationship
                                                        ? menuItemsEgress[
                                                              activeItem
                                                          ].qty.filter(
                                                              (
                                                                  _: any,
                                                                  idx: number
                                                              ) => {
                                                                  return menuItemsEgress[
                                                                      activeItem
                                                                  ].ingredients_uuid
                                                                      .map(
                                                                          (
                                                                              uuid: string,
                                                                              index: number
                                                                          ) => {
                                                                              return Object.entries(
                                                                                  ingredients
                                                                              ).find(
                                                                                  ([
                                                                                      _,
                                                                                      value,
                                                                                  ]) =>
                                                                                      value.uuid ===
                                                                                      uuid
                                                                              )![1]
                                                                                  .category !==
                                                                                  'Menu Items'
                                                                                  ? index
                                                                                  : undefined;
                                                                          }
                                                                      )
                                                                      .includes(
                                                                          idx
                                                                      );
                                                              }
                                                          )
                                                        : menuItemsEgress[
                                                              activeItem
                                                          ].qty.filter(
                                                              (
                                                                  _: any,
                                                                  idx: number
                                                              ) =>
                                                                  ingredients[
                                                                      menuItemsEgress[
                                                                          activeItem
                                                                      ]
                                                                          .ingredients[
                                                                          idx
                                                                      ]
                                                                  ].category !==
                                                                  'Menu Items'
                                                          )
                                                );
                                            }
                                        }}
                                    >
                                        Copy
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '10px',
                                            fontWeight: '400',
                                            color:
                                                copyRegIng.length === 0 &&
                                                copyRegIngUuid.length === 0 &&
                                                copyRegQty.length === 0
                                                    ? '#555'
                                                    : null,
                                            '&:hover': {
                                                color:
                                                    copyRegIng.length === 0 &&
                                                    copyRegIngUuid.length ===
                                                        0 &&
                                                    copyRegQty.length === 0
                                                        ? '#555'
                                                        : '#f8643c',
                                                textDecoration:
                                                    copyRegIng.length === 0 &&
                                                    copyRegIngUuid.length ===
                                                        0 &&
                                                    copyRegQty.length === 0
                                                        ? null
                                                        : 'underline #f8643c',
                                                cursor: 'pointer',
                                            },
                                        }}
                                        onClick={() => {
                                            if (
                                                copyRegIng.length !== 0 ||
                                                copyRegIngUuid.length !== 0 ||
                                                copyRegQty.length !== 0
                                            ) {
                                                handleRecipeCopy();
                                            }
                                        }}
                                    >
                                        Paste
                                    </Typography>
                                </Box>
                            </Box>
                            {((activeItem &&
                                activeItemModifier &&
                                activeItemModifierOption &&
                                menuItemsEgress[activeItem].level_2[
                                    activeItemModifier
                                ].level_3[activeItemModifierOption]
                                    .statistics) ||
                                (activeItem &&
                                    activeItemModifier &&
                                    !activeItemModifierOption &&
                                    menuItemsEgress[activeItem].level_2[
                                        activeItemModifier
                                    ].statistics) ||
                                (activeItem &&
                                    !activeItemModifier &&
                                    !activeItemModifierOption &&
                                    menuItemsEgress[activeItem]
                                        .statistics)) && (
                                <Box
                                    sx={{
                                        width: 'fill',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        gap: 5,
                                        mb: 5,
                                    }}
                                >
                                    {activeItem &&
                                    activeItemModifier &&
                                    activeItemModifierOption &&
                                    menuItemsEgress[activeItem].level_2[
                                        activeItemModifier
                                    ].level_3[activeItemModifierOption]
                                        .statistics
                                        ? Object.keys(
                                              menuItemsEgress[activeItem]
                                                  .level_2[activeItemModifier]
                                                  .level_3[
                                                  activeItemModifierOption
                                              ].statistics
                                          ).map((stat, idx) => {
                                              return (
                                                  <Paper
                                                      key={idx}
                                                      elevation={6}
                                                      sx={{
                                                          display: 'flex',
                                                          flexDirection:
                                                              'column',
                                                          alignItems: 'center',
                                                          px: 3,
                                                          py: 1,
                                                      }}
                                                  >
                                                      <Typography
                                                          fontWeight={600}
                                                          fontSize="large"
                                                      >
                                                          {stat}
                                                      </Typography>
                                                      <Typography>
                                                          {
                                                              menuItemsEgress[
                                                                  activeItem
                                                              ].level_2[
                                                                  activeItemModifier
                                                              ].level_3[
                                                                  activeItemModifierOption
                                                              ].statistics[stat]
                                                          }
                                                      </Typography>
                                                  </Paper>
                                              );
                                          })
                                        : activeItem &&
                                          activeItemModifier &&
                                          !activeItemModifierOption &&
                                          menuItemsEgress[activeItem].level_2[
                                              activeItemModifier
                                          ].statistics
                                        ? Object.keys(
                                              menuItemsEgress[activeItem]
                                                  .level_2[activeItemModifier]
                                                  .statistics
                                          ).map((stat, idx) => {
                                              return (
                                                  <Paper
                                                      key={idx}
                                                      elevation={6}
                                                      sx={{
                                                          display: 'flex',
                                                          flexDirection:
                                                              'column',
                                                          alignItems: 'center',
                                                          px: 3,
                                                          py: 1,
                                                      }}
                                                  >
                                                      <Typography
                                                          fontWeight={600}
                                                          fontSize="large"
                                                      >
                                                          {stat}
                                                      </Typography>
                                                      <Typography>
                                                          {
                                                              menuItemsEgress[
                                                                  activeItem
                                                              ].level_2[
                                                                  activeItemModifier
                                                              ].statistics[stat]
                                                          }
                                                      </Typography>
                                                  </Paper>
                                              );
                                          })
                                        : activeItem &&
                                          !activeItemModifier &&
                                          !activeItemModifierOption &&
                                          menuItemsEgress[activeItem].statistics
                                        ? Object.keys(
                                              menuItemsEgress[activeItem]
                                                  .statistics
                                          ).map((stat, idx) => {
                                              return (
                                                  <Paper
                                                      key={idx}
                                                      elevation={6}
                                                      sx={{
                                                          display: 'flex',
                                                          flexDirection:
                                                              'column',
                                                          alignItems: 'center',
                                                          px: 3,
                                                          py: 1,
                                                      }}
                                                  >
                                                      <Typography
                                                          fontWeight={600}
                                                          fontSize="large"
                                                      >
                                                          {stat}
                                                      </Typography>
                                                      <Typography>
                                                          {
                                                              menuItemsEgress[
                                                                  activeItem
                                                              ].statistics[stat]
                                                          }
                                                      </Typography>
                                                  </Paper>
                                              );
                                          })
                                        : null}
                                </Box>
                            )}
                            {/* {TODO:// START HERE FOR FS CONTINUES} */}
                            {!new_data_relationship &&
                                !!!activeItemModifierOption &&
                                !!!activeItemModifier &&
                                menuItemsEgress[activeItem].ingredients
                                    .filter((val: any) =>
                                        Object.keys(ingredients).includes(val)
                                    )
                                    .map((ingredient: any, idx: any) => {
                                        let qty_idx = menuItemsEgress[
                                            activeItem
                                        ].ingredients.findIndex(
                                            (ing: string) => ing === ingredient
                                        );
                                        return (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                                key={idx}
                                            >
                                                <FormGroup
                                                    aria-label="position"
                                                    row
                                                >
                                                    <FormControl
                                                        sx={{
                                                            marginBottom: 2,
                                                            marginRight: 2,
                                                            width: '200px',
                                                        }}
                                                        disabled={
                                                            ingredients[
                                                                ingredient
                                                            ] &&
                                                            ingredients[
                                                                ingredient
                                                            ].category ===
                                                                'Menu Items'
                                                        }
                                                    >
                                                        <InputLabel id="demo-simple-select-label">
                                                            Forecastable Item
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={ingredient}
                                                            label="Forecastable Item"
                                                            onChange={(event) =>
                                                                handleSelectChange(
                                                                    event,
                                                                    idx
                                                                )
                                                            }
                                                        >
                                                            {Object.keys(
                                                                ingredients
                                                            )
                                                                .filter(
                                                                    function (
                                                                        item
                                                                    ) {
                                                                        return (
                                                                            menuItemsEgress[
                                                                                activeItem
                                                                            ].ingredients.indexOf(
                                                                                item
                                                                            ) ===
                                                                                -1 ||
                                                                            item ===
                                                                                ingredient
                                                                        );
                                                                    }
                                                                )
                                                                .map(
                                                                    (
                                                                        option,
                                                                        idx
                                                                    ) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    option
                                                                                }
                                                                                key={
                                                                                    idx
                                                                                }
                                                                            >
                                                                                {
                                                                                    option
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                        </Select>
                                                    </FormControl>

                                                    <FormControl
                                                        variant="standard"
                                                        sx={{
                                                            marginRight: 2,
                                                        }}
                                                        disabled={
                                                            ingredients[
                                                                ingredient
                                                            ] &&
                                                            ingredients[
                                                                ingredient
                                                            ].category ===
                                                                'Menu Items'
                                                        }
                                                    >
                                                        <InputLabel>
                                                            {
                                                                ingredients[
                                                                    ingredient
                                                                ].units
                                                            }
                                                        </InputLabel>
                                                        <Input
                                                            value={
                                                                menuItemsEgress[
                                                                    activeItem
                                                                ].qty[qty_idx]
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                handleQtyChange(
                                                                    event,
                                                                    idx
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                                <CloseRoundedIcon
                                                    sx={{
                                                        color:
                                                            ingredients[
                                                                ingredient
                                                            ] &&
                                                            ingredients[
                                                                ingredient
                                                            ].category ===
                                                                'Menu Items'
                                                                ? 'rgba(0, 0, 0, 0.38)'
                                                                : null,
                                                    }}
                                                    onClick={() => {
                                                        ingredients[
                                                            ingredient
                                                        ] &&
                                                            ingredients[
                                                                ingredient
                                                            ].category !==
                                                                'Menu Items' &&
                                                            handleIngredientRemoveFromItem(
                                                                idx
                                                            );
                                                    }}
                                                />
                                            </Box>
                                        );
                                    })}
                            {!new_data_relationship &&
                                !!!activeItemModifierOption &&
                                !!activeItemModifier &&
                                menuItemsEgress[activeItem].level_2[
                                    activeItemModifier
                                ].ingredients
                                    .filter((val: any) =>
                                        Object.keys(ingredients).includes(val)
                                    )
                                    .map((ingredient: any, idx: any) => {
                                        let qty_idx = menuItemsEgress[
                                            activeItem
                                        ].level_2[
                                            activeItemModifier
                                        ].ingredients.findIndex(
                                            (ing: string) => ing === ingredient
                                        );
                                        return (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                                key={idx}
                                            >
                                                <FormGroup
                                                    aria-label="position"
                                                    row
                                                >
                                                    <FormControl
                                                        sx={{
                                                            marginBottom: 2,
                                                            marginRight: 2,
                                                            width: '200px',
                                                        }}
                                                        disabled={
                                                            ingredients[
                                                                ingredient
                                                            ] &&
                                                            ingredients[
                                                                ingredient
                                                            ].category ===
                                                                'Menu Items'
                                                        }
                                                    >
                                                        <InputLabel id="demo-simple-select-label">
                                                            Forecastable Item
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={ingredient}
                                                            label="Ingredient"
                                                            onChange={(event) =>
                                                                handleSelectChange(
                                                                    event,
                                                                    idx
                                                                )
                                                            }
                                                        >
                                                            {Object.keys(
                                                                ingredients
                                                            )
                                                                .filter(
                                                                    function (
                                                                        item
                                                                    ) {
                                                                        return (
                                                                            menuItemsEgress[
                                                                                activeItem
                                                                            ].level_2[
                                                                                activeItemModifier
                                                                            ].ingredients.indexOf(
                                                                                item
                                                                            ) ===
                                                                                -1 ||
                                                                            item ===
                                                                                ingredient
                                                                        );
                                                                    }
                                                                )
                                                                .map(
                                                                    (
                                                                        option,
                                                                        idx
                                                                    ) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    option
                                                                                }
                                                                                key={
                                                                                    idx
                                                                                }
                                                                            >
                                                                                {
                                                                                    option
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                        </Select>
                                                    </FormControl>

                                                    <FormControl
                                                        variant="standard"
                                                        sx={{
                                                            marginRight: 2,
                                                        }}
                                                        disabled={
                                                            ingredients[
                                                                ingredient
                                                            ] &&
                                                            ingredients[
                                                                ingredient
                                                            ].category ===
                                                                'Menu Items'
                                                        }
                                                    >
                                                        <InputLabel>
                                                            {
                                                                ingredients[
                                                                    ingredient
                                                                ].units
                                                            }
                                                        </InputLabel>
                                                        <Input
                                                            value={
                                                                menuItemsEgress[
                                                                    activeItem
                                                                ].level_2[
                                                                    activeItemModifier
                                                                ].qty[qty_idx]
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                handleQtyChange(
                                                                    event,
                                                                    idx
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                                <CloseRoundedIcon
                                                    sx={{
                                                        color:
                                                            ingredients[
                                                                ingredient
                                                            ] &&
                                                            ingredients[
                                                                ingredient
                                                            ].category ===
                                                                'Menu Items'
                                                                ? 'rgba(0, 0, 0, 0.38)'
                                                                : null,
                                                    }}
                                                    onClick={() => {
                                                        ingredients[
                                                            ingredient
                                                        ] &&
                                                            ingredients[
                                                                ingredient
                                                            ].category !==
                                                                'Menu Items' &&
                                                            handleIngredientRemoveFromItem(
                                                                idx
                                                            );
                                                    }}
                                                />
                                            </Box>
                                        );
                                    })}
                            {!new_data_relationship &&
                                !!activeItemModifierOption &&
                                !!activeItemModifier &&
                                menuItemsEgress[activeItem].level_2[
                                    activeItemModifier
                                ].level_3[activeItemModifierOption].ingredients
                                    .filter((val: any) =>
                                        Object.keys(ingredients).includes(val)
                                    )
                                    .map((ingredient: any, idx: any) => {
                                        let qty_idx = menuItemsEgress[
                                            activeItem
                                        ].level_2[activeItemModifier].level_3[
                                            activeItemModifierOption
                                        ].ingredients.findIndex(
                                            (ing: string) => ing === ingredient
                                        );
                                        return (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                                key={idx}
                                            >
                                                <FormGroup
                                                    aria-label="position"
                                                    row
                                                >
                                                    <FormControl
                                                        sx={{
                                                            marginBottom: 2,
                                                            marginRight: 2,
                                                            width: '200px',
                                                        }}
                                                        disabled={
                                                            ingredients[
                                                                ingredient
                                                            ] &&
                                                            ingredients[
                                                                ingredient
                                                            ].category ===
                                                                'Menu Items'
                                                        }
                                                    >
                                                        <InputLabel id="demo-simple-select-label">
                                                            Forecastable Item
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={ingredient}
                                                            label="Forecastable Item"
                                                            onChange={(event) =>
                                                                handleSelectChange(
                                                                    event,
                                                                    idx
                                                                )
                                                            }
                                                        >
                                                            {Object.keys(
                                                                ingredients
                                                            )
                                                                .filter(
                                                                    function (
                                                                        item
                                                                    ) {
                                                                        return (
                                                                            menuItemsEgress[
                                                                                activeItem
                                                                            ].level_2[
                                                                                activeItemModifier
                                                                            ].level_3[
                                                                                activeItemModifierOption
                                                                            ].ingredients.indexOf(
                                                                                item
                                                                            ) ===
                                                                                -1 ||
                                                                            item ===
                                                                                ingredient
                                                                        );
                                                                    }
                                                                )
                                                                .map(
                                                                    (
                                                                        option,
                                                                        idx
                                                                    ) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    option
                                                                                }
                                                                                key={
                                                                                    idx
                                                                                }
                                                                            >
                                                                                {
                                                                                    option
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                        </Select>
                                                    </FormControl>

                                                    <FormControl
                                                        variant="standard"
                                                        sx={{
                                                            marginRight: 2,
                                                        }}
                                                        disabled={
                                                            ingredients[
                                                                ingredient
                                                            ] &&
                                                            ingredients[
                                                                ingredient
                                                            ].category ===
                                                                'Menu Items'
                                                        }
                                                    >
                                                        <InputLabel>
                                                            {
                                                                ingredients[
                                                                    ingredient
                                                                ].units
                                                            }
                                                        </InputLabel>
                                                        <Input
                                                            value={
                                                                menuItemsEgress[
                                                                    activeItem
                                                                ].level_2[
                                                                    activeItemModifier
                                                                ].level_3[
                                                                    activeItemModifierOption
                                                                ].qty[qty_idx]
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                handleQtyChange(
                                                                    event,
                                                                    idx
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                                <CloseRoundedIcon
                                                    sx={{
                                                        color:
                                                            ingredients[
                                                                ingredient
                                                            ] &&
                                                            ingredients[
                                                                ingredient
                                                            ].category ===
                                                                'Menu Items'
                                                                ? 'rgba(0, 0, 0, 0.38)'
                                                                : null,
                                                    }}
                                                    onClick={() => {
                                                        ingredients[
                                                            ingredient
                                                        ] &&
                                                            ingredients[
                                                                ingredient
                                                            ].category !==
                                                                'Menu Items' &&
                                                            handleIngredientRemoveFromItem(
                                                                idx
                                                            );
                                                    }}
                                                />
                                            </Box>
                                        );
                                    })}
                            {new_data_relationship &&
                                !!!activeItemModifierOption &&
                                !!!activeItemModifier &&
                                menuItemsEgress[activeItem].ingredients_uuid
                                    .filter((ingredient_uuid: string) =>
                                        Object.values(ingredients).find(
                                            (value) =>
                                                value.uuid === ingredient_uuid
                                        )
                                    )
                                    .map((ingredient_uuid: any, idx: any) => {
                                        let qty_idx = menuItemsEgress[
                                            activeItem
                                        ].ingredients_uuid.findIndex(
                                            (ing: string) =>
                                                ing === ingredient_uuid
                                        );
                                        return (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                                key={idx}
                                            >
                                                <FormGroup
                                                    aria-label="position"
                                                    row
                                                >
                                                    <FormControl
                                                        sx={{
                                                            marginBottom: 2,
                                                            marginRight: 2,
                                                            width: '200px',
                                                        }}
                                                        disabled={
                                                            Object.entries(
                                                                ingredients
                                                            ).find(
                                                                ([_, value]) =>
                                                                    value.uuid ===
                                                                    ingredient_uuid
                                                            )![1].category ===
                                                            'Menu Items'
                                                        }
                                                    >
                                                        <InputLabel id="demo-simple-select-label">
                                                            Forecastable Item
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={
                                                                Object.entries(
                                                                    ingredients
                                                                ).find(
                                                                    ([
                                                                        _,
                                                                        value,
                                                                    ]) =>
                                                                        value.uuid ===
                                                                        ingredient_uuid
                                                                )![0]
                                                            }
                                                            label="Forecastable Item"
                                                            onChange={(event) =>
                                                                handleSelectChange(
                                                                    event,
                                                                    idx
                                                                )
                                                            }
                                                        >
                                                            {Object.entries(
                                                                ingredients
                                                            )
                                                                .filter(
                                                                    function ([
                                                                        _,
                                                                        value,
                                                                    ]) {
                                                                        return (
                                                                            menuItemsEgress[
                                                                                activeItem
                                                                            ].ingredients_uuid.indexOf(
                                                                                value.uuid
                                                                            ) ===
                                                                                -1 ||
                                                                            value.uuid ===
                                                                                ingredient_uuid
                                                                        );
                                                                    }
                                                                )
                                                                .map(
                                                                    (
                                                                        [
                                                                            key,
                                                                            _,
                                                                        ],
                                                                        idx
                                                                    ) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    key
                                                                                }
                                                                                key={
                                                                                    idx
                                                                                }
                                                                            >
                                                                                {
                                                                                    key
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                        </Select>
                                                    </FormControl>

                                                    <FormControl
                                                        variant="standard"
                                                        sx={{
                                                            marginRight: 2,
                                                        }}
                                                        disabled={
                                                            Object.entries(
                                                                ingredients
                                                            ).find(
                                                                ([_, value]) =>
                                                                    value.uuid ===
                                                                    ingredient_uuid
                                                            )![1].category ===
                                                            'Menu Items'
                                                        }
                                                    >
                                                        <InputLabel>
                                                            {
                                                                Object.values(
                                                                    ingredients
                                                                ).find(
                                                                    (value) =>
                                                                        value.uuid ===
                                                                        ingredient_uuid
                                                                ).units
                                                            }
                                                        </InputLabel>
                                                        <Input
                                                            value={
                                                                menuItemsEgress[
                                                                    activeItem
                                                                ].qty[qty_idx]
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                handleQtyChange(
                                                                    event,
                                                                    idx
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                                <CloseRoundedIcon
                                                    sx={{
                                                        color:
                                                            Object.entries(
                                                                ingredients
                                                            ).find(
                                                                ([_, value]) =>
                                                                    value.uuid ===
                                                                    ingredient_uuid
                                                            )![1].category ===
                                                            'Menu Items'
                                                                ? 'rgba(0, 0, 0, 0.38)'
                                                                : null,
                                                    }}
                                                    onClick={() => {
                                                        Object.entries(
                                                            ingredients
                                                        ).find(
                                                            ([_, value]) =>
                                                                value.uuid ===
                                                                ingredient_uuid
                                                        )![1].category !==
                                                            'Menu Items' &&
                                                            handleIngredientRemoveFromItem(
                                                                idx
                                                            );
                                                    }}
                                                />
                                            </Box>
                                        );
                                    })}
                            {new_data_relationship &&
                                !!!activeItemModifierOption &&
                                !!activeItemModifier &&
                                menuItemsEgress[activeItem].level_2[
                                    activeItemModifier
                                ].ingredients_uuid
                                    .filter((ingredient_uuid: string) =>
                                        Object.entries(ingredients).find(
                                            ([_, value]) =>
                                                value.uuid === ingredient_uuid
                                        )
                                    )
                                    .map((ingredient_uuid: any, idx: any) => {
                                        let qty_idx = menuItemsEgress[
                                            activeItem
                                        ].level_2[
                                            activeItemModifier
                                        ].ingredients_uuid.findIndex(
                                            (ing: string) =>
                                                ing === ingredient_uuid
                                        );
                                        return (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                                key={idx}
                                            >
                                                <FormGroup
                                                    aria-label="position"
                                                    row
                                                >
                                                    <FormControl
                                                        sx={{
                                                            marginBottom: 2,
                                                            marginRight: 2,
                                                            width: '200px',
                                                        }}
                                                        disabled={
                                                            Object.entries(
                                                                ingredients
                                                            ).find(
                                                                ([_, value]) =>
                                                                    value.uuid ===
                                                                    ingredient_uuid
                                                            )![1].category ===
                                                            'Menu Items'
                                                        }
                                                    >
                                                        <InputLabel id="demo-simple-select-label">
                                                            Forecastable Item
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={
                                                                Object.entries(
                                                                    ingredients
                                                                ).find(
                                                                    ([
                                                                        _,
                                                                        value,
                                                                    ]) =>
                                                                        value.uuid ===
                                                                        ingredient_uuid
                                                                )![0]
                                                            }
                                                            label="Ingredient"
                                                            onChange={(event) =>
                                                                handleSelectChange(
                                                                    event,
                                                                    idx
                                                                )
                                                            }
                                                        >
                                                            {Object.entries(
                                                                ingredients
                                                            )
                                                                .filter(
                                                                    function ([
                                                                        _,
                                                                        value,
                                                                    ]) {
                                                                        return (
                                                                            menuItemsEgress[
                                                                                activeItem
                                                                            ].level_2[
                                                                                activeItemModifier
                                                                            ].ingredients_uuid.indexOf(
                                                                                value.uuid
                                                                            ) ===
                                                                                -1 ||
                                                                            value.uuid ===
                                                                                ingredient_uuid
                                                                        );
                                                                    }
                                                                )
                                                                .map(
                                                                    (
                                                                        [
                                                                            key,
                                                                            _,
                                                                        ],
                                                                        idx
                                                                    ) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    key
                                                                                }
                                                                                key={
                                                                                    idx
                                                                                }
                                                                            >
                                                                                {
                                                                                    key
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                        </Select>
                                                    </FormControl>

                                                    <FormControl
                                                        variant="standard"
                                                        sx={{
                                                            marginRight: 2,
                                                        }}
                                                        disabled={
                                                            Object.entries(
                                                                ingredients
                                                            ).find(
                                                                ([_, value]) =>
                                                                    value.uuid ===
                                                                    ingredient_uuid
                                                            )![1].category ===
                                                            'Menu Items'
                                                        }
                                                    >
                                                        <InputLabel>
                                                            {
                                                                Object.values(
                                                                    ingredients
                                                                ).find(
                                                                    (value) =>
                                                                        value.uuid ===
                                                                        ingredient_uuid
                                                                ).units
                                                            }
                                                        </InputLabel>
                                                        <Input
                                                            value={
                                                                menuItemsEgress[
                                                                    activeItem
                                                                ].level_2[
                                                                    activeItemModifier
                                                                ].qty[qty_idx]
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                handleQtyChange(
                                                                    event,
                                                                    idx
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                                <CloseRoundedIcon
                                                    sx={{
                                                        color:
                                                            Object.entries(
                                                                ingredients
                                                            ).find(
                                                                ([_, value]) =>
                                                                    value.uuid ===
                                                                    ingredient_uuid
                                                            )![1].category ===
                                                            'Menu Items'
                                                                ? 'rgba(0, 0, 0, 0.38)'
                                                                : null,
                                                    }}
                                                    onClick={() => {
                                                        Object.entries(
                                                            ingredients
                                                        ).find(
                                                            ([_, value]) =>
                                                                value.uuid ===
                                                                ingredient_uuid
                                                        )![1].category !==
                                                            'Menu Items' &&
                                                            handleIngredientRemoveFromItem(
                                                                idx
                                                            );
                                                    }}
                                                />
                                            </Box>
                                        );
                                    })}
                            {new_data_relationship &&
                                !!activeItemModifierOption &&
                                !!activeItemModifier &&
                                menuItemsEgress[activeItem].level_2[
                                    activeItemModifier
                                ].level_3[
                                    activeItemModifierOption
                                ].ingredients_uuid
                                    .filter((ingredient_uuid: string) =>
                                        Object.entries(ingredients).find(
                                            ([_, value]) =>
                                                value.uuid === ingredient_uuid
                                        )
                                    )
                                    .map((ingredient_uuid: any, idx: any) => {
                                        let qty_idx = menuItemsEgress[
                                            activeItem
                                        ].level_2[activeItemModifier].level_3[
                                            activeItemModifierOption
                                        ].ingredients_uuid.findIndex(
                                            (ing: string) =>
                                                ing === ingredient_uuid
                                        );
                                        return (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                                key={idx}
                                            >
                                                <FormGroup
                                                    aria-label="position"
                                                    row
                                                >
                                                    <FormControl
                                                        sx={{
                                                            marginBottom: 2,
                                                            marginRight: 2,
                                                            width: '200px',
                                                        }}
                                                        disabled={
                                                            Object.entries(
                                                                ingredients
                                                            ).find(
                                                                ([_, value]) =>
                                                                    value.uuid ===
                                                                    ingredient_uuid
                                                            )![1].category ===
                                                            'Menu Items'
                                                        }
                                                    >
                                                        <InputLabel id="demo-simple-select-label">
                                                            Forecastable Item
                                                        </InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={
                                                                Object.entries(
                                                                    ingredients
                                                                ).find(
                                                                    ([
                                                                        _,
                                                                        value,
                                                                    ]) =>
                                                                        value.uuid ===
                                                                        ingredient_uuid
                                                                )![0]
                                                            }
                                                            label="Forecastable Item"
                                                            onChange={(event) =>
                                                                handleSelectChange(
                                                                    event,
                                                                    idx
                                                                )
                                                            }
                                                        >
                                                            {Object.entries(
                                                                ingredients
                                                            )
                                                                .filter(
                                                                    function ([
                                                                        _,
                                                                        value,
                                                                    ]) {
                                                                        return (
                                                                            menuItemsEgress[
                                                                                activeItem
                                                                            ].level_2[
                                                                                activeItemModifier
                                                                            ].level_3[
                                                                                activeItemModifierOption
                                                                            ].ingredients_uuid.indexOf(
                                                                                value.uuid
                                                                            ) ===
                                                                                -1 ||
                                                                            value.uuid ===
                                                                                ingredient_uuid
                                                                        );
                                                                    }
                                                                )
                                                                .map(
                                                                    (
                                                                        [
                                                                            key,
                                                                            _,
                                                                        ],
                                                                        idx
                                                                    ) => {
                                                                        return (
                                                                            <MenuItem
                                                                                value={
                                                                                    key
                                                                                }
                                                                                key={
                                                                                    idx
                                                                                }
                                                                            >
                                                                                {
                                                                                    key
                                                                                }
                                                                            </MenuItem>
                                                                        );
                                                                    }
                                                                )}
                                                        </Select>
                                                    </FormControl>

                                                    <FormControl
                                                        variant="standard"
                                                        sx={{
                                                            marginRight: 2,
                                                        }}
                                                        disabled={
                                                            Object.entries(
                                                                ingredients
                                                            ).find(
                                                                ([_, value]) =>
                                                                    value.uuid ===
                                                                    ingredient_uuid
                                                            )![1].category ===
                                                            'Menu Items'
                                                        }
                                                    >
                                                        <InputLabel>
                                                            {
                                                                Object.values(
                                                                    ingredients
                                                                ).find(
                                                                    (value) =>
                                                                        value.uuid ===
                                                                        ingredient_uuid
                                                                ).units
                                                            }
                                                        </InputLabel>
                                                        <Input
                                                            value={
                                                                menuItemsEgress[
                                                                    activeItem
                                                                ].level_2[
                                                                    activeItemModifier
                                                                ].level_3[
                                                                    activeItemModifierOption
                                                                ].qty[qty_idx]
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                handleQtyChange(
                                                                    event,
                                                                    idx
                                                                );
                                                            }}
                                                        />
                                                    </FormControl>
                                                </FormGroup>
                                                <CloseRoundedIcon
                                                    sx={{
                                                        color:
                                                            Object.entries(
                                                                ingredients
                                                            ).find(
                                                                ([_, value]) =>
                                                                    value.uuid ===
                                                                    ingredient_uuid
                                                            )![1].category ===
                                                            'Menu Items'
                                                                ? 'rgba(0, 0, 0, 0.38)'
                                                                : null,
                                                    }}
                                                    onClick={() => {
                                                        Object.entries(
                                                            ingredients
                                                        ).find(
                                                            ([_, value]) =>
                                                                value.uuid ===
                                                                ingredient_uuid
                                                        )![1].category !==
                                                            'Menu Items' &&
                                                            handleIngredientRemoveFromItem(
                                                                idx
                                                            );
                                                    }}
                                                />
                                            </Box>
                                        );
                                    })}
                            <Autocomplete
                                blurOnSelect={true}
                                clearOnBlur={true}
                                value={{
                                    label: '',
                                    value: '',
                                }}
                                onChange={(
                                    event: any,
                                    newValue: {
                                        label: string;
                                        value: string;
                                    } | null
                                ) => {
                                    newValue !== null &&
                                        handleSelectChange(
                                            event,
                                            -1,
                                            newValue.value
                                        );
                                }}
                                options={
                                    !!!activeItemModifierOption &&
                                    !!!activeItemModifier
                                        ? Object.keys(ingredients)
                                              .filter(function (item) {
                                                  return new_data_relationship
                                                      ? menuItemsEgress[
                                                            activeItem
                                                        ].ingredients_uuid.indexOf(
                                                            ingredients[item]
                                                                .uuid
                                                        ) === -1
                                                      : menuItemsEgress[
                                                            activeItem
                                                        ].ingredients.indexOf(
                                                            item
                                                        ) === -1;
                                              })
                                              .filter((option) => {
                                                  return (
                                                      ingredients[option] &&
                                                      ingredients[option]
                                                          .category !==
                                                          'Menu Items'
                                                  );
                                              })
                                              .map((option) => {
                                                  return {
                                                      label: option,
                                                      value: option,
                                                  };
                                              })
                                        : !!!activeItemModifierOption &&
                                          !!activeItemModifier
                                        ? Object.keys(ingredients)
                                              .filter(function (item) {
                                                  return new_data_relationship
                                                      ? menuItemsEgress[
                                                            activeItem
                                                        ].level_2[
                                                            activeItemModifier
                                                        ].ingredients_uuid.indexOf(
                                                            ingredients[item]
                                                                .uuid
                                                        ) === -1
                                                      : menuItemsEgress[
                                                            activeItem
                                                        ].level_2[
                                                            activeItemModifier
                                                        ].ingredients.indexOf(
                                                            item
                                                        ) === -1;
                                              })
                                              .filter((option) => {
                                                  return (
                                                      ingredients[option] &&
                                                      ingredients[option]
                                                          .category !==
                                                          'Menu Items'
                                                  );
                                              })
                                              .map((option) => {
                                                  return {
                                                      label: option,
                                                      value: option,
                                                  };
                                              })
                                        : !!activeItemModifierOption &&
                                          !!activeItemModifier
                                        ? Object.keys(ingredients)
                                              .filter(function (item) {
                                                  return new_data_relationship
                                                      ? menuItemsEgress[
                                                            activeItem
                                                        ].level_2[
                                                            activeItemModifier
                                                        ].level_3[
                                                            activeItemModifierOption
                                                        ].ingredients_uuid.indexOf(
                                                            ingredients[item]
                                                                .uuid
                                                        ) === -1
                                                      : menuItemsEgress[
                                                            activeItem
                                                        ].level_2[
                                                            activeItemModifier
                                                        ].level_3[
                                                            activeItemModifierOption
                                                        ].ingredients.indexOf(
                                                            item
                                                        ) === -1;
                                              })
                                              .filter((option) => {
                                                  return (
                                                      ingredients[option] &&
                                                      ingredients[option]
                                                          .category !==
                                                          'Menu Items'
                                                  );
                                              })
                                              .map((option) => {
                                                  return {
                                                      label: option,
                                                      value: option,
                                                  };
                                              })
                                        : []
                                }
                                fullWidth
                                sx={{ mb: 2 }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Forecastable Item"
                                    />
                                )}
                            />
                            <Button
                                onClick={() => {
                                    setMenuItemsEgress(
                                        JSON.parse(JSON.stringify(menuItems))
                                    );
                                }}
                                disabled={
                                    JSON.stringify(menuItems) ===
                                    JSON.stringify(menuItemsEgress)
                                }
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    let tempMenuItems = JSON.parse(
                                        JSON.stringify(menuItemsEgress)
                                    );
                                    let tempMenuIngredients: any[] = [];
                                    let tempMenuIngredients_uuid: any[] = [];
                                    let tempMenuQty: any[] = [];
                                    if (
                                        !!activeItemModifierOption &&
                                        !!activeItemModifier
                                    ) {
                                        if (new_data_relationship) {
                                            tempMenuItems[activeItem].level_2[
                                                activeItemModifier
                                            ].level_3[
                                                activeItemModifierOption
                                            ].ingredients_uuid.forEach(
                                                (val: any, idx: number) => {
                                                    if (
                                                        Object.values(
                                                            ingredients
                                                        )
                                                            .map((obj) => {
                                                                return obj.uuid;
                                                            })
                                                            .includes(val)
                                                    ) {
                                                        tempMenuIngredients.push(
                                                            Object.keys(
                                                                ingredients
                                                            ).find(
                                                                (key) =>
                                                                    ingredients[
                                                                        key
                                                                    ].uuid ===
                                                                    val
                                                            )
                                                        );
                                                        tempMenuIngredients_uuid.push(
                                                            val
                                                        );
                                                        tempMenuQty.push(
                                                            tempMenuItems[
                                                                activeItem
                                                            ].level_2[
                                                                activeItemModifier
                                                            ].level_3[
                                                                activeItemModifierOption
                                                            ].qty[idx]
                                                        );
                                                    }
                                                }
                                            );
                                        } else {
                                            tempMenuItems[activeItem].level_2[
                                                activeItemModifier
                                            ].level_3[
                                                activeItemModifierOption
                                            ].ingredients.forEach(
                                                (val: any, idx: number) => {
                                                    if (
                                                        Object.keys(
                                                            ingredients
                                                        ).includes(val)
                                                    ) {
                                                        tempMenuIngredients.push(
                                                            val
                                                        );
                                                        tempMenuIngredients_uuid.push(
                                                            ingredients[val]
                                                                .uuid
                                                        );
                                                        tempMenuQty.push(
                                                            tempMenuItems[
                                                                activeItem
                                                            ].level_2[
                                                                activeItemModifier
                                                            ].level_3[
                                                                activeItemModifierOption
                                                            ].qty[idx]
                                                        );
                                                    }
                                                }
                                            );
                                        }
                                        tempMenuItems[activeItem].level_2[
                                            activeItemModifier
                                        ].level_3[
                                            activeItemModifierOption
                                        ].ingredients = tempMenuIngredients;
                                        tempMenuItems[activeItem].level_2[
                                            activeItemModifier
                                        ].level_3[
                                            activeItemModifierOption
                                        ].qty = tempMenuQty;
                                    } else if (
                                        !!!activeItemModifierOption &&
                                        !!activeItemModifier
                                    ) {
                                        if (new_data_relationship) {
                                            tempMenuItems[activeItem].level_2[
                                                activeItemModifier
                                            ].ingredients_uuid.forEach(
                                                (val: any, idx: number) => {
                                                    if (
                                                        Object.values(
                                                            ingredients
                                                        )
                                                            .map((obj) => {
                                                                return obj.uuid;
                                                            })
                                                            .includes(val)
                                                    ) {
                                                        tempMenuIngredients.push(
                                                            Object.keys(
                                                                ingredients
                                                            ).find(
                                                                (key) =>
                                                                    ingredients[
                                                                        key
                                                                    ].uuid ===
                                                                    val
                                                            )
                                                        );
                                                        tempMenuIngredients_uuid.push(
                                                            val
                                                        );
                                                        tempMenuQty.push(
                                                            tempMenuItems[
                                                                activeItem
                                                            ].level_2[
                                                                activeItemModifier
                                                            ].qty[idx]
                                                        );
                                                    }
                                                }
                                            );
                                        } else {
                                            tempMenuItems[activeItem].level_2[
                                                activeItemModifier
                                            ].ingredients.forEach(
                                                (val: any, idx: number) => {
                                                    if (
                                                        Object.keys(
                                                            ingredients
                                                        ).includes(val)
                                                    ) {
                                                        tempMenuIngredients.push(
                                                            val
                                                        );
                                                        tempMenuIngredients_uuid.push(
                                                            ingredients[val]
                                                                .uuid
                                                        );
                                                        tempMenuQty.push(
                                                            tempMenuItems[
                                                                activeItem
                                                            ].level_2[
                                                                activeItemModifier
                                                            ].qty[idx]
                                                        );
                                                    }
                                                }
                                            );
                                        }
                                        tempMenuItems[activeItem].level_2[
                                            activeItemModifier
                                        ].ingredients = tempMenuIngredients;
                                        tempMenuItems[activeItem].level_2[
                                            activeItemModifier
                                        ].qty = tempMenuQty;
                                    } else {
                                        if (new_data_relationship) {
                                            tempMenuItems[
                                                activeItem
                                            ].ingredients_uuid.forEach(
                                                (val: any, idx: number) => {
                                                    if (
                                                        Object.values(
                                                            ingredients
                                                        )
                                                            .map((obj) => {
                                                                return obj.uuid;
                                                            })
                                                            .includes(val)
                                                    ) {
                                                        tempMenuIngredients.push(
                                                            Object.keys(
                                                                ingredients
                                                            ).find(
                                                                (key) =>
                                                                    ingredients[
                                                                        key
                                                                    ].uuid ===
                                                                    val
                                                            )
                                                        );
                                                        tempMenuIngredients_uuid.push(
                                                            val
                                                        );
                                                        tempMenuQty.push(
                                                            tempMenuItems[
                                                                activeItem
                                                            ].qty[idx]
                                                        );
                                                    }
                                                }
                                            );
                                        } else {
                                            tempMenuItems[
                                                activeItem
                                            ].ingredients.forEach(
                                                (val: any, idx: number) => {
                                                    if (
                                                        Object.keys(
                                                            ingredients
                                                        ).includes(val)
                                                    ) {
                                                        tempMenuIngredients.push(
                                                            val
                                                        );
                                                        tempMenuIngredients_uuid.push(
                                                            ingredients[val]
                                                                .uuid
                                                        );
                                                        tempMenuQty.push(
                                                            tempMenuItems[
                                                                activeItem
                                                            ].qty[idx]
                                                        );
                                                    }
                                                }
                                            );
                                        }
                                        !new_data_format &&
                                            (tempMenuItems[
                                                activeItem
                                            ].ingredients =
                                                tempMenuIngredients);
                                        tempMenuItems[activeItem].qty =
                                            tempMenuQty;
                                        tempMenuItems[
                                            activeItem
                                        ].ingredients_uuid =
                                            tempMenuIngredients_uuid;
                                    }

                                    setToastState({
                                        toastMessage: 'Saving changes...',
                                        toastType: 'info',
                                    });

                                    // ! This will also fuck things up, but lets see how much and if it matters
                                    // setPageLock(true);

                                    //TODO: Test this for only sending the items edited
                                    let changedMenuItems: any = {};
                                    changedMenuItems[activeItem] =
                                        tempMenuItems[activeItem];

                                    //! GOGOGO!! This will be a point of error, I guarentee it, but fuck it, we ball.
                                    setMenuItems(
                                        JSON.parse(
                                            JSON.stringify(tempMenuItems)
                                        )
                                    );
                                    setMenuItemsEgress(
                                        JSON.parse(
                                            JSON.stringify(tempMenuItems)
                                        )
                                    );

                                    (new_data_format
                                        ? updateMenuItems({
                                              id: idToIdx,
                                              menuItems: JSON.parse(
                                                  JSON.stringify(
                                                      changedMenuItems
                                                  )
                                              ),
                                          })
                                        : postMenu({
                                              id: idToIdx,
                                              ingredients: ingredients,
                                              menuItems: JSON.parse(
                                                  JSON.stringify(tempMenuItems)
                                              ),
                                              categories: categories,
                                              prepLists: prepLists,
                                          })
                                    )
                                        .then(() => {
                                            // ! There is no undo functionality for failure!
                                            // setMenuItems(
                                            //     JSON.parse(
                                            //         JSON.stringify(
                                            //             tempMenuItems
                                            //         )
                                            //     )
                                            // );
                                            setToastState({
                                                toastMessage: 'Changes saved!',
                                                toastType: 'success',
                                            });
                                            // setPageLock(false);
                                        })
                                        .catch((err) => {
                                            // ! There is no undo functionality for failure!
                                            // TODO: Make fractional undo functionality given input.
                                            console.error(err);
                                            setToastState({
                                                toastMessage:
                                                    'Changes failed to save',
                                                toastType: 'error',
                                            });
                                            setPageLock(false);
                                            return;
                                        });
                                }}
                                autoFocus
                                disabled={
                                    JSON.stringify(menuItems) ===
                                    JSON.stringify(menuItemsEgress)
                                }
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
}
