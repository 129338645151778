export const enum logSeverity {
    Crash = 'Crash',
    Error = 'Error',
    Warning = 'Warning',
    Info = 'Info',
}

export const enum logType {
    Backend = 'Backend',
    API = 'API',
    Runtime = 'Runtime',
    Render = 'Render',
    Generic = 'Generic',
}

export const enum logAction {
    renderPath = 'RENDER_PATH',
}
