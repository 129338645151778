import Box from '@mui/material/Box';

interface AnimatedHamburgerProps {
    state: boolean;
    setState: Function;
    color?: string;
}

function AnimatedHamburger({
    state,
    setState,
    color = '#707070',
}: AnimatedHamburgerProps) {
    // ! This 0.1 is a magic number and only works for this project. I got tried of doing trig and will finish this later

    const translationX = () => {
        return (
            -document.getElementById('ResponsiveAnimatedHanburgerContainer')!
                .clientWidth * 0.1
        );
    };

    const translationY = () => {
        return (
            document.getElementById('ResponsiveAnimatedHanburgerContainer')!
                .clientWidth * 0.1
        );
    };

    return (
        <Box
            id="ResponsiveAnimatedHanburgerContainer"
            width="100%"
            paddingTop="100%"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
            }}
            onClick={() => {
                setState(!state);
            }}
        >
            <Box
                width="100%"
                height="6px"
                sx={{
                    position: 'absolute',
                    backgroundColor: color,
                    borderRadius: '5px',
                    top: '0%',
                    transformOrigin: '100% 50%',
                    transform: state
                        ? `translate(${translationX()}px, ${translationY()}px) rotate(-45deg)`
                        : 'translateY(100%) rotate(0deg)',
                    transition: 'transform 0.5s ease-in-out',
                }}
            />
            <Box
                width="100%"
                height="6px"
                sx={{
                    position: 'absolute',
                    backgroundColor: color,
                    borderRadius: '5px',
                    top: '46%',
                    transform: state ? 'scaleX(0)' : 'scaleX(1)',
                    transition: 'transform 0.6s ease-in-out',
                }}
            />
            <Box
                width="100%"
                height="6px"
                sx={{
                    position: 'absolute',
                    backgroundColor: color,
                    borderRadius: '5px',
                    top: '92%',
                    transformOrigin: '100% 50%',
                    transform: state
                        ? `translate(${translationX()}px, -${translationY()}px) rotate(45deg)`
                        : 'translateY(-100%) rotate(0deg)',
                    transition: 'transform 0.5s ease-in-out',
                }}
            />
        </Box>
    );
}

export { AnimatedHamburger };
