import { useEffect, useState } from 'react';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import './ActionMenu.css';

interface ActionMenuProps {
    textField: string;
    variant?: string;
    color?: string;
    setTextField: Function;
    newField?: boolean;
    newCancel?: any;
    generalCancel?: any;
    generalOpen?: any;
    controlledActive?: boolean;
}

export function ActionMenu({
    textField,
    variant = 'left',
    color = '#fff',
    setTextField,
    newField = false,
    newCancel = () => {},
    generalCancel = () => {},
    generalOpen = () => {},
    controlledActive = false,
}: ActionMenuProps) {
    const [active, setActive] = useState(false);
    const [edit, setEdit] = useState(false);
    const [text, setText] = useState(textField);
    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    useEffect(() => {
        setText(textField);
    }, [textField]);

    useEffect(() => {
        if (newField) {
            setEdit(true);
        }
    }, [newField]);

    useEffect(() => {
        setActive(controlledActive);
        if (!controlledActive) {
            setEdit(false);
        }
    }, [controlledActive]);

    return (
        <Box
            sx={{
                display: 'flex',
                direction: 'row',
                justifyContent: 'start',
                alignItems: 'center',
            }}
        >
            {variant !== 'right' &&
                (!edit ? (
                    <Typography
                        sx={{
                            height: '40px',
                            background: color,
                            borderRadius: '8px',
                            padding: '0 5px',
                            display: 'flex',
                            direction: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '17px',
                        }}
                    >
                        {textField}
                    </Typography>
                ) : (
                    <TextField
                        id="standard-basic"
                        label="Standard"
                        variant="standard"
                    />
                ))}
            <Box
                className={
                    active
                        ? edit
                            ? 'ActionMenu active edit'
                            : 'ActionMenu active'
                        : 'ActionMenu'
                }
                onClick={() => {
                    if (!active) {
                        setActive(true);
                        generalOpen();
                    }
                }}
                sx={{
                    background: color,
                    position: 'relative',
                    width: '15px',
                    height: '40px',
                    borderRadius: '10px',
                    cursor: active ? null : 'pointer',
                    transition: '0.5s',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&.active': {
                        width: '100px',
                    },
                }}
            >
                <span>
                    <Box
                        sx={{
                            opacity: active ? 1 : 0,
                            transition: '0.5s',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: active ? 'pointer' : null,
                        }}
                        onClick={() => {
                            if (active) {
                                if (edit) {
                                    setTextField(text);
                                }
                                setEdit(!edit);
                            }
                        }}
                    >
                        {!edit ? (
                            <EditRoundedIcon sx={{ color: '#fff' }} />
                        ) : (
                            <CheckRoundedIcon sx={{ color: '#fff' }} />
                        )}
                    </Box>
                </span>
                <span>
                    <Box
                        sx={{
                            opacity: active ? 1 : 0,
                            transition: '0.5s',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: active ? 'pointer' : null,
                        }}
                        onClick={() => {
                            if (active) {
                                setTextField('');
                                setActive(false);
                            }
                        }}
                    >
                        <DeleteRoundedIcon sx={{ color: '#fff' }} />
                    </Box>
                </span>
                <span>
                    <Box
                        sx={{
                            opacity: active ? 1 : 0,
                            transition: '0.5s',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: active ? 'pointer' : null,
                        }}
                        onClick={() => {
                            if (active) {
                                if (edit) {
                                    setEdit(false);
                                    if (newField) {
                                        newCancel();
                                    } else {
                                        setText(textField);
                                    }
                                } else {
                                    setActive(!active);
                                    generalCancel();
                                }
                            }
                        }}
                    >
                        <ClearRoundedIcon sx={{ color: '#fff' }} />
                    </Box>
                </span>
            </Box>
            {variant === 'right' &&
                (!edit ? (
                    <Typography
                        sx={{
                            height: '40px',
                            background: color,
                            borderRadius: '8px',
                            padding: '0 5px',
                            display: 'flex',
                            direction: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '17px',
                        }}
                    >
                        {textField}
                    </Typography>
                ) : (
                    <Box width="70%">
                        <TextField
                            id="standard-basic"
                            variant="standard"
                            value={text}
                            onChange={handleTextChange}
                            fullWidth
                        />
                    </Box>
                ))}
        </Box>
    );
}
