import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { ratio } from '../../../../constants/recipe_constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
    clearFocusedPrepItem,
    updateFocusedPrepItem,
} from '../../../../redux/reducers/recipes/recipesActions';
import {
    selectDialogState,
    selectFocusedPrepItem,
    selectOpenDialog,
} from '../../../../redux/reducers/recipes/recipesSelectors';
import { ingredientsInterface } from '../../components/Recipes';

interface EntryMapperProps {
    categories: string[];
    ingredients: ingredientsInterface;
}

export const EntryMapper: React.FC<EntryMapperProps> = ({
    categories,
    ingredients,
}) => {
    const dispatch = useAppDispatch();

    const focusedPrepItem = useAppSelector(selectFocusedPrepItem);
    const dialogState = useAppSelector(selectDialogState);
    const openDialog = useAppSelector(selectOpenDialog);

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            sx={{
                width: `${(1 - ratio) * 100}%`,
                marginLeft: `${ratio * 100}%`,
            }}
            open={openDialog && dialogState === 'EntryMapper'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => {
                dispatch(clearFocusedPrepItem());
            }}
            BackdropProps={{
                style: { backgroundColor: 'transparent' },
            }}
        >
            <DialogTitle id="alert-dialog-title">Usage</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span style={{ color: 'blue' }}>
                        {focusedPrepItem.itemName}
                    </span>{' '}
                    is based on the use of:
                </DialogContentText>
                <Autocomplete
                    value={{
                        label: focusedPrepItem.ingredientCategory,
                        value: focusedPrepItem.ingredientCategory,
                    }}
                    onChange={(
                        _,
                        newValue: { label: string; value: string } | null
                    ) => {
                        dispatch(
                            updateFocusedPrepItem({
                                ingredientCategory: newValue
                                    ? newValue.value
                                    : '',
                                ingredientName: '',
                                ingredientUUID: '',
                            })
                        );
                    }}
                    options={categories.map((option) => {
                        return {
                            label: option,
                            value: option,
                        };
                    })}
                    fullWidth
                    sx={{ my: 2 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Category"
                        />
                    )}
                />
                <Autocomplete
                    value={{
                        label: focusedPrepItem.ingredientName,
                        value: focusedPrepItem.ingredientName,
                    }}
                    onChange={(
                        event: any,
                        newValue: { label: string; value: string } | null
                    ) => {
                        dispatch(
                            updateFocusedPrepItem(
                                {
                                    ingredientName: newValue
                                        ? newValue.value
                                        : '',
                                    ingredientUUID: newValue
                                        ? ingredients[newValue.value].uuid
                                        : '',
                                },
                                'Frequency'
                            )
                        );
                    }}
                    options={Object.keys(ingredients)
                        .filter((item) => {
                            return (
                                ingredients[item].category ===
                                focusedPrepItem.ingredientCategory
                            );
                        })
                        .map((option) => {
                            return {
                                label: option,
                                value: option,
                            };
                        })}
                    fullWidth
                    sx={{ my: 2 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Item"
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        dispatch(
                            updateFocusedPrepItem(
                                {
                                    ingredientName: '',
                                    ingredientCategory: '',
                                    ingredientUUID: '',
                                },
                                'NameEntry'
                            )
                        );
                    }}
                >
                    Back
                </Button>
            </DialogActions>
        </Dialog>
    );
};
