import React from 'react';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { colors } from '../../../constants/recipe_constants';
import { ingredientsInterface, menuItemsInterface } from './Recipes';

let waste_stockout_slider =
    process.env.REACT_APP_FEATURE_WASTE_STOCKOUT_SLIDER === 'true';

let qty_as_tooltip = process.env.REACT_APP_QTY_DISPLAY_AS_TOOLTIP === 'true';

export function IngredientTab({
    tabValue,
    categories,
    expanded,
    handleAccordianChange,
    ingredients,
    menuItems,
    activeItem,
    setActiveItem,
    setActiveItemModifier,
    setActiveItemModifierOption,
    setTabValue,
    addNewIngredient,
    setEditName,
    setIngredientsEgress,
    setOpenDialog,
    setDialogState,
    store,
    usageDates,
}: {
    tabValue: number;
    categories: string[];
    expanded: {};
    handleAccordianChange: (
        panel: string
    ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
    ingredients: ingredientsInterface;
    menuItems: menuItemsInterface;
    activeItem: string;
    setActiveItem: React.Dispatch<React.SetStateAction<string>>;
    setActiveItemModifier: React.Dispatch<React.SetStateAction<string>>;
    setActiveItemModifierOption: React.Dispatch<React.SetStateAction<string>>;
    setTabValue: React.Dispatch<React.SetStateAction<number>>;
    addNewIngredient: (category: string) => void;
    setEditName: React.Dispatch<React.SetStateAction<string>>;
    setIngredientsEgress: React.Dispatch<
        React.SetStateAction<ingredientsInterface>
    >;
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setDialogState: React.Dispatch<React.SetStateAction<number>>;
    store: string;
    usageDates: any;
}) {
    const posNavChips =
        Object.keys(menuItems) && activeItem && tabValue === 0
            ? Object.keys(menuItems)
                  .map((item) => {
                      let arrToAdd = [];
                      if (
                          menuItems[item].ingredients_uuid.includes(
                              ingredients[activeItem].uuid
                          )
                      ) {
                          arrToAdd.push({
                              item: item,
                              subitem: undefined,
                              supersubitem: undefined,
                              qty: menuItems[item].qty[
                                  menuItems[item].ingredients_uuid.findIndex(
                                      (uuid: string) =>
                                          uuid === ingredients[activeItem].uuid
                                  )
                              ],
                          });
                      }

                      if (menuItems[item].level_2) {
                          Object.keys(menuItems[item].level_2).forEach(
                              (subitem) => {
                                  if (
                                      menuItems[item].level_2[
                                          subitem
                                      ].ingredients_uuid.includes(
                                          ingredients[activeItem].uuid
                                      )
                                  ) {
                                      arrToAdd.push({
                                          item: item,
                                          subitem: subitem,
                                          supersubitem: undefined,
                                          qty: menuItems[item].level_2[subitem]
                                              .qty[
                                              menuItems[item].level_2[
                                                  subitem
                                              ].ingredients_uuid.findIndex(
                                                  (uuid: string) =>
                                                      uuid ===
                                                      ingredients[activeItem]
                                                          .uuid
                                              )
                                          ],
                                      });
                                  }

                                  if (
                                      menuItems[item].level_2[subitem].level_3
                                  ) {
                                      Object.keys(
                                          menuItems[item].level_2[subitem]
                                              .level_3
                                      ).forEach((supersubitem) => {
                                          if (
                                              menuItems[item].level_2[
                                                  subitem
                                              ].level_3[
                                                  supersubitem
                                              ].ingredients_uuid.includes(
                                                  ingredients[activeItem].uuid
                                              )
                                          ) {
                                              arrToAdd.push({
                                                  item: item,
                                                  subitem: subitem,
                                                  supersubitem: supersubitem,
                                                  qty: menuItems[item].level_2[
                                                      subitem
                                                  ].level_3[supersubitem].qty[
                                                      menuItems[item].level_2[
                                                          subitem
                                                      ].level_3[
                                                          supersubitem
                                                      ].ingredients_uuid.findIndex(
                                                          (uuid: string) =>
                                                              uuid ===
                                                              ingredients[
                                                                  activeItem
                                                              ].uuid
                                                      )
                                                  ],
                                              });
                                          }
                                      });
                                  }
                              }
                          );
                      }

                      return arrToAdd;
                  })
                  .filter((subArr) => subArr.length)
                  .flat()
            : undefined;

    return (
        <Box
            width="100%"
            display="flex"
            sx={{
                flexDirection: 'row',
                alignItems: 'stretch',
            }}
        >
            <Box
                width="30%"
                maxHeight="calc(100% - 49px)"
                sx={{
                    borderRight: 1,
                    overflowY: 'scroll',
                    borderColor: 'rgba(0, 0, 0, 0.12)',
                }}
            >
                {categories.map((category, idx_outside) => {
                    return (
                        <Accordion
                            expanded={expanded === category}
                            onChange={handleAccordianChange(category)}
                            key={idx_outside}
                            sx={{
                                '&.Mui-expanded': {
                                    marginTop: '1px',
                                    marginBottom: '1px',
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    borderLeft: `4px solid ${
                                        colors[idx_outside % colors.length]
                                    }`,
                                }}
                            >
                                <Typography
                                    sx={{
                                        flexShrink: 0,
                                        paddingLeft: '4px',
                                    }}
                                >
                                    {category}
                                </Typography>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails sx={{ padding: 0 }}>
                                <Box
                                    display="flex"
                                    sx={{
                                        flexDirection: 'column',
                                        marginLeft: 4,
                                    }}
                                >
                                    {Object.keys(ingredients).map(
                                        (item, idx) => {
                                            return ingredients[item]
                                                .category === category ? (
                                                <Box
                                                    key={idx}
                                                    sx={{
                                                        backgroundColor:
                                                            activeItem === item
                                                                ? '#D8D8D8'
                                                                : null,
                                                        borderLeft: `4px solid ${
                                                            colors[
                                                                idx_outside %
                                                                    colors.length
                                                            ]
                                                        }`,
                                                    }}
                                                >
                                                    <Divider />
                                                    <Typography
                                                        sx={{
                                                            marginTop: 2,
                                                            marginBottom: 2,
                                                            paddingLeft: '4px',
                                                        }}
                                                        onClick={() => {
                                                            setActiveItem(item);
                                                        }}
                                                    >
                                                        {item}
                                                    </Typography>
                                                </Box>
                                            ) : null;
                                        }
                                    )}
                                    <Divider />
                                    {category === 'Forecastable Items' && (
                                        <>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    paddingTop: 2,
                                                    paddingBottom: 2,
                                                    borderLeft: `4px solid ${
                                                        colors[
                                                            idx_outside %
                                                                colors.length
                                                        ]
                                                    }`,
                                                }}
                                                onClick={() => {
                                                    addNewIngredient(category);
                                                }}
                                            >
                                                <AddRoundedIcon />{' '}
                                                <Typography>
                                                    Add Forecastable Item
                                                </Typography>
                                            </Box>
                                            <Divider />
                                        </>
                                    )}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>
            <Box
                width="70%"
                height="calc(100% - 49px)"
            >
                {activeItem === '' || tabValue !== 0 ? (
                    <Typography
                        textAlign="center"
                        marginTop="40px"
                    >
                        Please select a forecastable item.
                    </Typography>
                ) : (
                    <Box height="100%">
                        <Box
                            height="calc(100% - 64px)"
                            sx={{
                                minWidth: 120,
                                padding: 4,
                                overflowY: 'auto',
                            }}
                        >
                            <Box
                                display="flex"
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: 1,
                                    marginBottom: 3,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: '700',
                                    }}
                                >
                                    {activeItem +
                                        (!!ingredients[activeItem].units &&
                                            ' (' +
                                                ingredients[activeItem].units +
                                                ')')}
                                </Typography>
                                <Box
                                    onClick={() => {
                                        setEditName(activeItem);
                                        setIngredientsEgress(
                                            JSON.parse(
                                                JSON.stringify(ingredients)
                                            )
                                        );
                                        setDialogState(1);
                                        setOpenDialog(true);
                                    }}
                                >
                                    <EditRoundedIcon />
                                </Box>
                            </Box>
                            {usageDates &&
                            store in usageDates &&
                            activeItem in ingredients &&
                            ingredients[activeItem].usage &&
                            ingredients[activeItem].usage[store] ? (
                                <Box
                                    display="flex"
                                    sx={{
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        gap: 1,
                                        marginBottom: 3,
                                    }}
                                >
                                    <Typography
                                        color="#707070"
                                        sx={{
                                            fontSize: 16,
                                        }}
                                    >
                                        {'On ' +
                                            usageDates[store] +
                                            ', ' +
                                            ingredients[activeItem].usage[
                                                store
                                            ] +
                                            ' ' +
                                            ingredients[activeItem].units +
                                            ' of ' +
                                            activeItem +
                                            ' was used in ' +
                                            store +
                                            '.'}
                                    </Typography>
                                </Box>
                            ) : (
                                <></>
                            )}
                            {waste_stockout_slider && (
                                <Box
                                    width="450px"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Box
                                        width="100%"
                                        mb={2}
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-evenly',
                                        }}
                                    >
                                        <Box
                                            margin="0 25px"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                color="#707070"
                                                marginBottom="-4px"
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                            >
                                                Daily
                                            </Typography>
                                            <Typography
                                                color="#707070"
                                                marginBottom="-4px"
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                            >
                                                Waste
                                            </Typography>
                                        </Box>

                                        <Box
                                            width="170px"
                                            height="1px"
                                        />

                                        <Box
                                            margin={'0 20px'}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                color="#707070"
                                                marginBottom="-4px"
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                            >
                                                Stockout
                                            </Typography>
                                            <Typography
                                                color="#707070"
                                                marginBottom="-4px"
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                            >
                                                Risk
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            width="95%"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-evenly',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography
                                                align="center"
                                                width="60px"
                                                color="#707070"
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                            >
                                                {`$xx`}
                                            </Typography>
                                            <Slider
                                                track={false}
                                                aria-label="Priority"
                                                defaultValue={2}
                                                valueLabelDisplay="auto"
                                                sx={{
                                                    width: '200px',
                                                }}
                                                step={1}
                                                min={0}
                                                max={4}
                                                marks
                                            />
                                            <Typography
                                                align="center"
                                                width="60px"
                                                color="#707070"
                                                sx={{
                                                    fontSize: 16,
                                                }}
                                            >
                                                {`xx%`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                            <Box
                                width="fill"
                                mt={2}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography>
                                    {activeItem} is used in...
                                </Typography>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    useFlexGap
                                    flexWrap="wrap"
                                >
                                    {posNavChips &&
                                        posNavChips.map((posNavChip) => {
                                            let label = posNavChip.supersubitem
                                                ? posNavChip.item +
                                                  ', ' +
                                                  posNavChip.subitem +
                                                  ', ' +
                                                  posNavChip.supersubitem
                                                : posNavChip.subitem
                                                  ? posNavChip.item +
                                                    ', ' +
                                                    posNavChip.subitem
                                                  : posNavChip.item;
                                            return qty_as_tooltip ? (
                                                <Tooltip
                                                    arrow
                                                    title={
                                                        posNavChip.qty +
                                                        ' ' +
                                                        ingredients[activeItem]
                                                            .units
                                                    }
                                                >
                                                    <Chip
                                                        key={label}
                                                        label={label}
                                                        variant="outlined"
                                                        onClick={() => {
                                                            if (
                                                                posNavChip.subitem
                                                            ) {
                                                                setTabValue(3);
                                                            } else {
                                                                setActiveItem(
                                                                    posNavChip.item
                                                                );
                                                                setActiveItemModifier(
                                                                    posNavChip.subitem ??
                                                                        ''
                                                                );
                                                                setActiveItemModifierOption(
                                                                    posNavChip.supersubitem ??
                                                                        ''
                                                                );
                                                                setTabValue(1);
                                                            }
                                                        }}
                                                    />
                                                </Tooltip>
                                            ) : (
                                                <Chip
                                                    key={label}
                                                    label={
                                                        label +
                                                        ': ' +
                                                        posNavChip.qty +
                                                        ' ' +
                                                        ingredients[activeItem]
                                                            .units
                                                    }
                                                    variant="outlined"
                                                    onClick={() => {
                                                        setActiveItem(
                                                            posNavChip.item
                                                        );
                                                        setActiveItemModifier(
                                                            posNavChip.subitem ??
                                                                ''
                                                        );

                                                        setActiveItemModifierOption(
                                                            posNavChip.supersubitem ??
                                                                ''
                                                        );
                                                        setTabValue(1);
                                                    }}
                                                />
                                            );
                                        })}

                                    <Chip
                                        key={'Add'}
                                        label={'Add'}
                                        icon={<AddRoundedIcon />}
                                        variant="outlined"
                                        onClick={() => {
                                            setDialogState(2);
                                            setOpenDialog(true);
                                        }}
                                    />
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
}
