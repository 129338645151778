import { AlertColor } from '@mui/material';

import { emptyToastState } from '../../../constants/toast_context';

export interface ToastInterface {
    toastMessage: string;
    toastType: AlertColor | undefined;
}

export const initialToastState: ToastInterface = emptyToastState;
