export const activeColors = [
    '#ED0003',
    '#3500FF',
    '#01FE01',
    '#8C00FC',
    '#ffe700',
];
export const paths = [
    '/Dashboard',
    '/Email',
    '/Menu',
    '/Subscription',
    '/Settings',
];
export const iconNames = [
    'dashboardIcon',
    'emailIcon',
    'menuIcon',
    'subscriptionIcon',
    'settingsIcon',
];
