export const colors = [
    '#ff0000',
    '#ffa500',
    '#ffff00',
    '#008000',
    '#0000ff',
    '#4b0082',
    '#ee82ee',
];

export const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];

export const ratio = 40 / 100;

export const prepListColumns = [
    {
        key: (item: any) => (item?.item_name ?? 'header') + 'Prep Item',
        header: 'Prep Item',
        width: '320px',
        child: (item: any) => item.item_name,
    },
    {
        key: (item: any) => (item?.item_name ?? 'header') + 'Lead Time',
        header: 'Lead Time (Days)',
        width: '170px',
        child: (item: any) => item.lead_time,
    },
    {
        key: (item: any) => (item?.item_name ?? 'header') + 'Shelf Life',
        header: 'Shelf Life (Days)',
        width: '150px',
        child: (item: any) => item.shelf_life,
    },
    {
        key: (item: any) => (item?.item_name ?? 'header') + 'Units',
        header: 'Units',
        width: '160px',
        child: (item: any) => (item.new_unit ? item.new_unit : item.units),
    },
];
