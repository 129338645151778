import { featureSwitches } from '../../../constants/feature_switches';

export interface FeatureSwitchInterface {
    debug: boolean;
    offlineMode: boolean;
    unitsOfTimeSelector: boolean;
    newDataRelationship: boolean;
    newDataFormat: boolean;
    wasteStockoutSlider: boolean;
    enforceLicenseLimit: boolean;
    qtyDisplayedAsTooltip: boolean;
    modifierGroups: boolean;
}

export const initialFeatureSwitchState: FeatureSwitchInterface =
    featureSwitches;
