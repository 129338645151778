import { Box, Typography } from '@mui/material';

export function Error({ error, resetErrorBoundary }: any) {
    return (
        <Box
            width="fill-available"
            height="100%"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography
                color="#707070"
                variant="h1"
                fontSize="30px"
                fontWeight="700"
            >
                Looks like something went wrong! Please refresh the page.
            </Typography>
            <Typography
                color="#707070"
                variant="h2"
                fontSize="30px"
                textAlign="center"
            >
                Want to prevent errors like this in the future? Send the below
                to ClearCOGS support.
            </Typography>
            <Typography
                color="#707070"
                variant="body1"
                fontSize="10px"
                width="500px"
                sx={{
                    m: '50px',
                }}
            >
                {`${error.stack}`}
            </Typography>
        </Box>
    );
}
