import React from 'react';

import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import { Autocomplete, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { postMenu } from '../../../../api';
import { updateMenuItems } from '../../../../api/functions/updateMenuItems';
import {
    ingredientsInterface,
    menuItemsInterface,
} from '../../components/Recipes';

export function DialogAddToPOSItems(
    idToIdx: string,
    openDialog: boolean,
    dialogState: number,
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>,
    editName: string,
    handleNameChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void,
    ingredients: ingredientsInterface,
    ingredientsEgress: ingredientsInterface,
    activeItem: string,
    handleUnitChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ingredient: string
    ) => void,
    handleSelectCategoryChange: (
        event: SelectChangeEvent,
        ingredient: string
    ) => void,
    category: any,
    categories: string[],
    handleIngredientDeletion: () => void,
    handleEgressDemotion: () => void,
    handleEgressPromotion: () => void,
    duplicateError: boolean,
    setDuplicateError: (duplicateError: boolean) => void,
    menuItems: menuItemsInterface,
    modifiedMenuItems: any[],
    setModifiedMenuItems: React.Dispatch<React.SetStateAction<any[]>>,
    modifiedQtyValue: string,
    setModifiedQtyValue: React.Dispatch<React.SetStateAction<string>>,
    setMenuItems: React.Dispatch<React.SetStateAction<menuItemsInterface>>,
    setMenuItemsEgress: React.Dispatch<
        React.SetStateAction<menuItemsInterface>
    >,
    dialogAutocompleteInputValue: string,
    setDialogAutocompleteInputValue: React.Dispatch<
        React.SetStateAction<string>
    >
): React.ReactNode {
    let POSItemOptions = Object.keys(menuItems).length
        ? Object.keys(menuItems)
              .map((menuItem) => {
                  let arrToAdd: any[] = [
                      {
                          item: menuItem,
                          subItem: undefined,
                          superSubItem: undefined,
                      },
                  ];
                  if (
                      menuItems[menuItem].level_2 &&
                      Object.keys(menuItems[menuItem].level_2).length
                  ) {
                      Object.keys(menuItems[menuItem].level_2).forEach(
                          (subMenuItem) => {
                              arrToAdd.push({
                                  item: menuItem,
                                  subItem: subMenuItem,
                                  superSubItem: undefined,
                              });
                              if (
                                  menuItems[menuItem].level_2[subMenuItem]
                                      .level_3 &&
                                  Object.keys(
                                      menuItems[menuItem].level_2[subMenuItem]
                                          .level_3
                                  ).length
                              ) {
                                  Object.keys(
                                      menuItems[menuItem].level_2[subMenuItem]
                                          .level_3
                                  ).forEach((superSubMenuItem) => {
                                      arrToAdd.push({
                                          item: menuItem,
                                          subItem: subMenuItem,
                                          superSubItem: superSubMenuItem,
                                      });
                                  });
                              }
                          }
                      );
                  }
                  return arrToAdd;
              })
              .flat()
        : [];

    const onCloseStateCleanup = () => {
        setOpenDialog(false);
        setModifiedMenuItems([]);
        setModifiedQtyValue('');
    };

    return (
        <Dialog
            open={openDialog && dialogState === 2}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableEscapeKeyDown
            onClose={(event: object, reason: string) => {
                console.log(reason);
                if (reason === 'backdropClick') return;
                onCloseStateCleanup();
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {'Edit Forecastable Item'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    marginBottom={2}
                >
                    {'Select POS items to add ' +
                        activeItem +
                        ' to. Unit ammount will be aplied to all selected items.'}
                </DialogContentText>
                <Box
                    display="flex"
                    sx={{
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <FormControl
                        variant="standard"
                        sx={{
                            marginTop: 2,
                            marginBottom: 2,
                            width: '50%',
                        }}
                        error={duplicateError}
                    >
                        <Autocomplete
                            multiple
                            limitTags={2}
                            id="multiple-limit-tags"
                            disableCloseOnSelect
                            inputValue={dialogAutocompleteInputValue}
                            onBlur={() => {
                                setDialogAutocompleteInputValue('');
                            }}
                            options={POSItemOptions}
                            getOptionLabel={(option) => {
                                return option.superSubItem
                                    ? option.item +
                                          ', ' +
                                          option.subItem +
                                          ', ' +
                                          option.superSubItem
                                    : option.subItem
                                    ? option.item + ', ' + option.subItem
                                    : option.item;
                            }}
                            isOptionEqualToValue={(option, value) =>
                                JSON.stringify(option) === JSON.stringify(value)
                            }
                            renderOption={(props: any, option: any) => {
                                let currentQty: string | null = null;

                                let ingIdx: number = option.superSubItem
                                    ? menuItems[option.item].level_2[
                                          option.subItem
                                      ].level_3[
                                          option.superSubItem
                                      ].ingredients_uuid.indexOf(
                                          ingredients[activeItem].uuid
                                      )
                                    : option.subItem
                                    ? menuItems[option.item].level_2[
                                          option.subItem
                                      ].ingredients_uuid.indexOf(
                                          ingredients[activeItem].uuid
                                      )
                                    : menuItems[
                                          option.item
                                      ].ingredients_uuid.indexOf(
                                          ingredients[activeItem].uuid
                                      );

                                if (ingIdx !== -1) {
                                    currentQty = option.superSubItem
                                        ? menuItems[option.item].level_2[
                                              option.subItem
                                          ].level_3[option.superSubItem].qty[
                                              ingIdx
                                          ]
                                        : option.subItem
                                        ? menuItems[option.item].level_2[
                                              option.subItem
                                          ].qty[ingIdx]
                                        : menuItems[option.item].qty[ingIdx];
                                }
                                return (
                                    <li {...props}>
                                        <Typography>
                                            {option.superSubItem
                                                ? option.item +
                                                  ', ' +
                                                  option.subItem +
                                                  ', ' +
                                                  option.superSubItem
                                                : option.subItem
                                                ? option.item +
                                                  ', ' +
                                                  option.subItem
                                                : option.item}
                                        </Typography>
                                        {currentQty && (
                                            <Typography
                                                sx={{
                                                    marginTop: '2px',
                                                    marginLeft: 1,
                                                    fontSize: '12px',
                                                    color: 'gray',
                                                }}
                                            >
                                                {'(' +
                                                    currentQty +
                                                    ' ' +
                                                    ingredientsEgress[
                                                        activeItem
                                                    ].units +
                                                    ')'}
                                            </Typography>
                                        )}
                                    </li>
                                );
                            }}
                            filterOptions={(options, state) => {
                                let searchTerm = state.inputValue
                                    .toLowerCase()
                                    .trim();

                                if (searchTerm === '') return options;

                                let regexTerms = searchTerm
                                    .replace(/,/g, '')
                                    .split(' ')
                                    .map((word) => `(?=.*${word})`);

                                let regex = new RegExp(
                                    `^${regexTerms.join('')}.*$`,
                                    'i'
                                );

                                let filteredArr = options.filter((item) =>
                                    regex.test(
                                        item.item +
                                            (item.subItem ?? '') +
                                            (item.superSubItem ?? '')
                                    )
                                );

                                return filteredArr;
                            }}
                            value={modifiedMenuItems}
                            onChange={(event: any, newValue: any[]) => {
                                setModifiedMenuItems(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    onChange={(e) =>
                                        setDialogAutocompleteInputValue(
                                            e.target.value
                                        )
                                    }
                                    label="POS Items"
                                    placeholder="Type here..."
                                />
                            )}
                            renderTags={(value, getTagProps, ownerState) => {
                                const numTags = value.length;
                                const limitTags = ownerState.focused ? 4 : 2;

                                console.log('ownerState', ownerState);

                                return (
                                    <>
                                        {value
                                            .slice(
                                                0,
                                                !ownerState.focused ||
                                                    limitTags >= numTags
                                                    ? limitTags
                                                    : limitTags - 1
                                            )
                                            .map((option, index) => (
                                                <Chip
                                                    {...getTagProps({ index })}
                                                    key={index}
                                                    label={
                                                        option.superSubItem
                                                            ? option.item +
                                                              ', ..., ' +
                                                              option.superSubItem
                                                            : option.subItem
                                                            ? option.item +
                                                              ', ' +
                                                              option.subItem
                                                            : option.item
                                                    }
                                                />
                                            ))}

                                        {/* {ownerState.focused &&
                                            limitTags < numTags && (
                                                <Chip
                                                    key={'see_more'}
                                                    label={
                                                        <MoreHorizRoundedIcon />
                                                    }
                                                />
                                            )} */}

                                        {numTags > limitTags && (
                                            <Chip
                                                key={'see_more'}
                                                label={` +${
                                                    numTags - limitTags
                                                }`}
                                            />
                                        )}

                                        {ownerState.focused &&
                                            limitTags < numTags &&
                                            value
                                                .slice(-1)
                                                .map((option, index) => (
                                                    <Chip
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                        key={index}
                                                        label={
                                                            option.superSubItem
                                                                ? option.item +
                                                                  ', ..., ' +
                                                                  option.superSubItem
                                                                : option.subItem
                                                                ? option.item +
                                                                  ', ' +
                                                                  option.subItem
                                                                : option.item
                                                        }
                                                    />
                                                ))}

                                        {!ownerState.focused &&
                                            numTags > limitTags &&
                                            ` +${numTags - limitTags}`}
                                    </>
                                );
                            }}
                            sx={{ width: '500px' }}
                        />
                    </FormControl>

                    <FormControl
                        variant="standard"
                        disabled={category !== 'Forecastable Items'}
                        sx={{
                            marginTop: 2,
                            marginBottom: 2,
                            width: '40%',
                        }}
                    >
                        <InputLabel>
                            {ingredientsEgress[activeItem].units}
                        </InputLabel>
                        <Input
                            value={modifiedQtyValue}
                            onChange={(event) => {
                                setModifiedQtyValue(event.target.value);
                            }}
                        />
                        {category !== 'Forecastable Items' && (
                            <FormHelperText id="component-error-text">
                                Editing units is unavailable for Menu Items.
                            </FormHelperText>
                        )}
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onCloseStateCleanup();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        let copyMenuItems = JSON.parse(
                            JSON.stringify(menuItems)
                        );
                        let changedMenuItems = modifiedMenuItems.reduce(
                            (acc, menuItemDetails) => {
                                if (
                                    copyMenuItems.hasOwnProperty(
                                        menuItemDetails.item
                                    )
                                ) {
                                    if (
                                        !acc.hasOwnProperty(
                                            menuItemDetails.item
                                        )
                                    ) {
                                        acc[menuItemDetails.item] =
                                            copyMenuItems[menuItemDetails.item];
                                    }

                                    if (menuItemDetails.subItem) {
                                        if (menuItemDetails.superSubItem) {
                                            let idxToUpdate = acc[
                                                menuItemDetails.item
                                            ].level_2[
                                                menuItemDetails.subItem
                                            ].level_3[
                                                menuItemDetails.superSubItem
                                            ].ingredients_uuid.indexOf(
                                                ingredients[activeItem].uuid
                                            );
                                            if (idxToUpdate === -1) {
                                                acc[
                                                    menuItemDetails.item
                                                ].level_2[
                                                    menuItemDetails.subItem
                                                ].level_3[
                                                    menuItemDetails.superSubItem
                                                ].ingredients_uuid.push(
                                                    ingredients[activeItem].uuid
                                                );
                                                acc[
                                                    menuItemDetails.item
                                                ].level_2[
                                                    menuItemDetails.subItem
                                                ].level_3[
                                                    menuItemDetails.superSubItem
                                                ].qty.push(modifiedQtyValue);
                                            } else {
                                                acc[
                                                    menuItemDetails.item
                                                ].level_2[
                                                    menuItemDetails.subItem
                                                ].level_3[
                                                    menuItemDetails.superSubItem
                                                ].qty[idxToUpdate] =
                                                    modifiedQtyValue;
                                            }
                                        } else {
                                            let idxToUpdate = acc[
                                                menuItemDetails.item
                                            ].level_2[
                                                menuItemDetails.subItem
                                            ].ingredients_uuid.indexOf(
                                                ingredients[activeItem].uuid
                                            );
                                            if (idxToUpdate === -1) {
                                                acc[
                                                    menuItemDetails.item
                                                ].level_2[
                                                    menuItemDetails.subItem
                                                ].ingredients_uuid.push(
                                                    ingredients[activeItem].uuid
                                                );
                                                acc[
                                                    menuItemDetails.item
                                                ].level_2[
                                                    menuItemDetails.subItem
                                                ].qty.push(modifiedQtyValue);
                                            } else {
                                                acc[
                                                    menuItemDetails.item
                                                ].level_2[
                                                    menuItemDetails.subItem
                                                ].qty[idxToUpdate] =
                                                    modifiedQtyValue;
                                            }
                                        }
                                    } else {
                                        let idxToUpdate = acc[
                                            menuItemDetails.item
                                        ].ingredients_uuid.indexOf(
                                            ingredients[activeItem].uuid
                                        );
                                        if (idxToUpdate === -1) {
                                            acc[
                                                menuItemDetails.item
                                            ].ingredients_uuid.push(
                                                ingredients[activeItem].uuid
                                            );
                                            acc[menuItemDetails.item].qty.push(
                                                modifiedQtyValue
                                            );
                                        } else {
                                            acc[menuItemDetails.item].qty[
                                                idxToUpdate
                                            ] = modifiedQtyValue;
                                        }
                                    }
                                }
                                return acc;
                            },
                            {}
                        );
                        console.log('Changed Items: ', changedMenuItems);
                        console.log('Selected Items: ', modifiedMenuItems);
                        setMenuItems(
                            JSON.parse(
                                JSON.stringify(
                                    Object.assign(
                                        {},
                                        copyMenuItems,
                                        changedMenuItems
                                    )
                                )
                            )
                        );
                        setMenuItemsEgress(
                            JSON.parse(
                                JSON.stringify(
                                    Object.assign(
                                        {},
                                        copyMenuItems,
                                        changedMenuItems
                                    )
                                )
                            )
                        );
                        updateMenuItems({
                            id: idToIdx,
                            menuItems: JSON.parse(
                                JSON.stringify(changedMenuItems)
                            ),
                        });
                        onCloseStateCleanup();
                    }}
                    disabled={
                        modifiedQtyValue === '' ||
                        isNaN(+modifiedQtyValue) ||
                        !modifiedMenuItems.length
                    }
                    autoFocus
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
