import { Paper, Typography } from '@mui/material';

import { prepListColumns } from '../../../../constants/recipe_constants';
import { useAppDispatch } from '../../../../redux/hooks';
import { prepItem } from '../../../../redux/reducers/recipes/recipesState';
import { PrepListItemRowProps } from './IPrepListTab';

/**
 * Represents a row in the Prep List item list.
 * @param item - The prep list item.
 * @param section - The section of the prep list item.
 */
export const PrepListItemRow: React.FC<PrepListItemRowProps> = ({
    item,
    section,
}) => {
    const dispatch = useAppDispatch();

    const handlePrepItemSelection = () => {
        const formattedItem: prepItem = {
            ...item,
            itemName: item.item_name,
            ingredientCategory: item.ingredient_category,
            ingredientName: item.ingredient_name,
            ingredientUUID: item.ingredient_uuid,
            scheduleType: item.schedule_type,
            leadTime: item.lead_time,
            leadTimeUnits: item.lead_time_units,
            shelfLife: item.shelf_life,
            shelfLifeUnits: item.shelf_life_units,
            prepSchedule: item.prep_schedule,
            prepDays: item.prep_days,
            newUnit: item.new_unit,
            newUnitConversion: item.new_unit_conversion,
            section: section,
        };
        dispatch({
            type: 'SET_FOCUSED_PREP_ITEM',
            payload: { focusedPrepItem: formattedItem },
        });
    };

    return (
        <Paper
            variant="prepListItemRow"
            onClick={handlePrepItemSelection}
        >
            {prepListColumns.map((column: any) => {
                return (
                    <Typography
                        variant="centerText"
                        width={column.width}
                        key={column.key(item)}
                    >
                        {column.child(item)}
                    </Typography>
                );
            })}
        </Paper>
    );
};
