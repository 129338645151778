import React from 'react';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';

import { ratio } from '../../../../constants/recipe_constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
    clearFocusedPrepItem,
    updateFocusedPrepItem,
} from '../../../../redux/reducers/recipes/recipesActions';
import {
    selectDialogState,
    selectFocusedPrepItem,
    selectOpenDialog,
} from '../../../../redux/reducers/recipes/recipesSelectors';

interface FrequencyProps {}

export const Frequency: React.FC<FrequencyProps> = () => {
    const dispatch = useAppDispatch();

    const focusedPrepItem = useAppSelector(selectFocusedPrepItem);
    const dialogState = useAppSelector(selectDialogState);
    const openDialog = useAppSelector(selectOpenDialog);

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            sx={{
                width: `${(1 - ratio) * 100}%`,
                marginLeft: `${ratio * 100}%`,
            }}
            open={openDialog && dialogState === 'Frequency'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => {
                dispatch(clearFocusedPrepItem());
            }}
            BackdropProps={{
                style: { backgroundColor: 'transparent' },
            }}
        >
            <DialogTitle id="alert-dialog-title">Frequency of Prep</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    I make this:
                </DialogContentText>
                <FormControl
                    variant="standard"
                    sx={{
                        marginTop: 1,
                        marginBottom: 1,
                    }}
                >
                    <ButtonGroup size="large">
                        <Button
                            key="As Needed"
                            variant={
                                focusedPrepItem.scheduleType === 'As Needed'
                                    ? 'contained'
                                    : undefined
                            }
                            onClick={() => {
                                dispatch(
                                    updateFocusedPrepItem(
                                        { scheduleType: 'As Needed' },
                                        'PrepTime'
                                    )
                                );
                            }}
                        >
                            Every Day
                        </Button>
                        <Button
                            key="Batches"
                            variant={
                                focusedPrepItem.scheduleType === 'Batches'
                                    ? 'contained'
                                    : undefined
                            }
                            onClick={() => {
                                dispatch(
                                    updateFocusedPrepItem(
                                        { scheduleType: 'Batches' },
                                        'PrepSchedule'
                                    )
                                );
                            }}
                        >
                            Not Every Day
                        </Button>
                    </ButtonGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        dispatch(
                            updateFocusedPrepItem(
                                { scheduleType: '' },
                                'EntryMapper'
                            )
                        );
                    }}
                >
                    Back
                </Button>
            </DialogActions>
        </Dialog>
    );
};
