export const RoutesURL = {
    index: '/',
    auth: '/Auth',
    login: '/Login',
    dashboard: '/Dashboard',
    email: '/Email',
    recipes: '/Menu',
    settings: '/Settings',
    verification: '/Verify',
    subsription: '/Subscription',
    debug: '/Debug',
    notFound: '/*',
    fourofour: '/404',
    mobile: '/Mobile',
};
