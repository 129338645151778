import { Collapse } from '@mui/material';

interface ICollapsibleWrapperProps {
    navStatus: boolean;
    children: JSX.Element;
}

export const CollapsibleWrapper = ({
    navStatus,
    children,
}: ICollapsibleWrapperProps) => {
    return (
        <Collapse
            orientation="horizontal"
            in={navStatus}
            collapsedSize={90}
        >
            {children}
        </Collapse>
    );
};
