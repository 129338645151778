import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Typography } from '@mui/material';

import { BoxCenteredRow } from '../../../../components/StyledMUI/StyledBox';
import { useAppDispatch } from '../../../../redux/hooks';
import { setFocusedPrepSection } from '../../../../redux/reducers/recipes/recipesActions';
import { PrepListAddSectionProps } from './IPrepListTab';

/**
 * Component for adding a section to the prep list.
 * @returns React component.
 */
export const PrepListAddSection: React.FC<PrepListAddSectionProps> = () => {
    const dispatch = useAppDispatch();

    return (
        <BoxCenteredRow
            onClick={() => {
                dispatch(setFocusedPrepSection('', 'NewSection'));
            }}
        >
            <AddRoundedIcon />
            <Typography variant="addSection">Add Section</Typography>
        </BoxCenteredRow>
    );
};
