import React from 'react';

// This is a placeholder file for the log in component
import { Amplify } from 'aws-amplify';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import background from '../../../assets/background1.jpg';
import logo from '../../../assets/logo.png';
import awsconfig from '../../../constants/aws-exports';

Amplify.configure(awsconfig);

const CssTextField = styled(TextField)({
    input: {
        color: 'white',
    },
    '& label': {
        color: 'white',
    },
    '& label.Mui-focused': {
        color: 'white',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'white',
        },
        '&:hover fieldset': {
            borderColor: 'white',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'white',
        },
    },
    '& .MuiSvgIcon-root': {
        color: 'white',
    },
});

interface LogInInterface {
    message: string;
    session: any;
    otp: string;
    setOtp: any;
    verifyOtp: any;
    setEmail: any;
    signIn: any;
}

export function LogIn({
    message,
    session,
    otp,
    setOtp,
    verifyOtp,
    setEmail,
    signIn,
}: LogInInterface) {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    return (
        <Box
            sx={{
                backgroundImage: `url(${background})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                height: 'fill-available',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Paper
                className="App"
                sx={{
                    backgroundColor: 'rgba(0,0,0,.7)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'start',
                    padding: '20px 0 50px 0',
                }}
            >
                <Box
                    className="App-header"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: '10px 0',
                    }}
                >
                    <Box
                        sx={{
                            height: '55px',
                            width: '300px',
                            backgroundImage: `url(${logo})`,
                            backgroundSize: '300px',
                            marginBottom: 3,
                        }}
                    />
                    <Typography color="#FFF">{message}</Typography>
                </Box>
                {
                    /*!user &&*/ !session && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 2,
                                width: '450px',
                            }}
                        >
                            <CssTextField
                                label="Email"
                                variant="outlined"
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                                color="secondary"
                                sx={{ width: '300px' }}
                            />
                            {!(message === 'VERIFYING') ? (
                                <Button
                                    variant="contained"
                                    onClick={signIn}
                                    sx={{ width: '300px' }}
                                >
                                    Get OTP
                                </Button>
                            ) : (
                                <Box sx={{ display: 'flex' }}>
                                    <CircularProgress />
                                </Box>
                            )}
                        </Box>
                    )
                }
                {
                    /*!user &&*/ session && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 2,
                                width: '450px',
                            }}
                        >
                            <CssTextField
                                label="Your OTP"
                                onChange={(event) => setOtp(event.target.value)}
                                value={otp}
                                color={'primary'}
                                sx={{
                                    width: '300px',
                                }}
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                onMouseDown={
                                                    handleMouseDownPassword
                                                }
                                                edge="end"
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Button
                                variant="contained"
                                onClick={verifyOtp}
                                sx={{ width: '300px' }}
                            >
                                Confirm
                            </Button>
                        </Box>
                    )
                }
            </Paper>
        </Box>
    );
}
