//! --------------
//! SEE WARNING.MD
//! --------------
//! Fuck a context, use redux, please, for the love of god.
// //
import { ReactNode, useEffect } from 'react';

import { useResponsiveState } from '../constants/responsive_context';
import { useAppDispatch } from '../redux/hooks';
import { setResponsiveContext } from '../redux/reducers/responsive/responsiveActions';

interface ReduxContextProps {
    children: ReactNode;
}

const ReduxContext: React.FC<ReduxContextProps> = ({ children }) => {
    const dispatch = useAppDispatch();
    const newResponsiveContext = useResponsiveState();

    // Sets the Responsive Context to the redux store on
    useEffect(() => {
        dispatch(setResponsiveContext(newResponsiveContext));
    }, [newResponsiveContext]);

    return <>{children}</>;
};

export default ReduxContext;
