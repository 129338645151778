import { ToastInterface } from './toastState';

export const setToastContext = (data: ToastInterface) => ({
    type: 'SET_TOAST_CONTEXT',
    payload: data,
});

export const setToastMessage = (toastMessage: string) => ({
    type: 'SET_TOAST_MESSAGE',
    payload: { toastMessage: toastMessage },
});

export const clearToastContext = () => ({
    type: 'CLEAR_TOAST_CONTEXT',
});
