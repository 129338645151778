import { Box } from '@mui/material';

import styled from '@emotion/styled';

const BoxCenteredRow = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
}));

const BoxCenteredHeaderRow = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'space-between',
}));

const BoxPrepListSection = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
}));

const BoxTabPage = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    flexGrow: 1,
}));

const BoxTabMainCanvas = styled(Box)(() => ({
    width: '70%',
    height: '100%',
}));

const BoxWorkspaceWrapper = styled(Box)(({ theme }: any) => ({
    height: '100%',
    overflow: 'scroll',
}));

const BoxWorkspace = styled(Box)(({ theme }: any) => ({
    minWidth: '120px',
    margin: theme.spacing(4),
}));

const BoxPrepList = styled(Box)(({ theme }: any) => ({
    display: 'flex',
    flexDirection: 'row',
    ustifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3),
}));

const BoxPrepListSideBar = styled(Box)(({ theme }: any) => ({
    width: '30%',
    height: '100%',
    borderRight: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.12)',
    display: 'flex',
    flexDirection: 'column',
}));

const BoxPrepListSelctor = styled(Box)(({ theme, idx, selected }: any) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: selected ? '#D8D8D8' : idx !== 0 ? '#808080' : '#FFF',
}));

export {
    BoxCenteredRow,
    BoxCenteredHeaderRow,
    BoxPrepListSection,
    BoxTabPage,
    BoxTabMainCanvas,
    BoxWorkspaceWrapper,
    BoxWorkspace,
    BoxPrepList,
    BoxPrepListSideBar,
    BoxPrepListSelctor,
};
