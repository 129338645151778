import { LogIn } from './../../../components';

interface LogInInterface {
    message: string;
    session: any;
    otp: string;
    setOtp: any;
    verifyOtp: any;
    setEmail: any;
    signIn: any;
}
export const Auth = ({
    message,
    session,
    otp,
    setOtp,
    verifyOtp,
    setEmail,
    signIn,
}: LogInInterface) => {
    return (
        <LogIn
            message={message}
            session={session}
            otp={otp}
            setOtp={setOtp}
            verifyOtp={verifyOtp}
            setEmail={setEmail}
            signIn={signIn}
        />
    );
};
