// React
import React from 'react';
// Standard React object control and caching tools
import { createRoot } from 'react-dom/client';

// Imports post-auth application
import App from './App';
// Imports Styling
import './css/styles.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';

// gets the container in which the app will be rendered
const container = document.getElementById('root')!;
const root = createRoot(container);

// Establishes a Object Model & Router
// Renders the application within an auth component

// The Following is for Debugging!!!
//root.render(<React.StrictMode>{<App />}</React.StrictMode>);

// The Following is for Deployment!!!
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint.
reportWebVitals();
