/**
 * * Async JSON request
 * @param {null}
 * @returns {object} object containing all user and store data
 */
export async function getUsage(
    concept: string,
    store: string,
    startDate: string,
    endDate: string
) {
    const url = `${process.env.REACT_APP_API_URL}/ingredient_usage?concept=${concept}&store=${store}&startDate=${startDate}&endDate=${endDate}`;

    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(JSON.stringify(response.json()));
            }
            return response.json();
        })
        .catch((err) => {
            console.error(err);
            throw err;
        });
}
