import { RoutesURL } from '../constants/routes';

export function authenticationChecks(initialCheck: boolean, user: any) {
    const sendFromAuth =
        initialCheck &&
        user != null &&
        (window.location.pathname === RoutesURL.auth ||
            window.location.pathname === '/auth');

    const sendToAuth =
        initialCheck &&
        user == null &&
        window.location.pathname !== RoutesURL.auth;
    return { sendToAuth, sendFromAuth };
}
