import { Typography } from '@mui/material';

import {
    BoxPrepList,
    BoxWorkspace,
    BoxWorkspaceWrapper,
} from '../../../../components/StyledMUI/StyledBox';
import { useAppSelector } from '../../../../redux/hooks';
import {
    selectPrepLists,
    selectSelectedPrepList,
} from '../../../../redux/reducers/recipes/recipesSelectors';
import { PrepListWorkspaceProps } from './IPrepListTab';
import { PrepListAddSection } from './PrepListAddSection';
import { PrepListSection } from './PrepListSection';

/**
 * PrepListWorkspace component displays the workspace for a prep list.
 * It renders the prep list header, prep list sections, and a section for adding new sections.
 */
export const PrepListWorkspace: React.FC<PrepListWorkspaceProps> = () => {
    const prepLists = useAppSelector(selectPrepLists);
    const selectedPrepList = useAppSelector(selectSelectedPrepList);

    return (
        <BoxWorkspaceWrapper>
            <BoxWorkspace>
                <BoxPrepList>
                    <Typography variant="prepListHeader">
                        {selectedPrepList}
                    </Typography>
                </BoxPrepList>
                {Object.keys(prepLists[selectedPrepList]).map(
                    (section, idx) => {
                        return (
                            <PrepListSection
                                key={idx}
                                section={section}
                                prepList={prepLists[selectedPrepList]}
                            />
                        );
                    }
                )}
                <PrepListAddSection />
            </BoxWorkspace>
        </BoxWorkspaceWrapper>
    );
};
