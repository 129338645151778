import { RootState } from '../../store';
import { ResponsiveInterface } from './responsiveState';

export const selectResponsiveContext = (
    state: RootState
): Partial<ResponsiveInterface> => {
    return state.responsive;
};

export const selectIsWideScreen = (state: RootState): boolean => {
    return state.responsive.isWideScreen;
};

export const selectIsDesktop = (state: RootState): boolean => {
    return state.responsive.isDesktop;
};

export const selectITablet = (state: RootState): boolean => {
    return state.responsive.isTablet;
};

export const selectIsMobile = (state: RootState): boolean => {
    return state.responsive.isMobile;
};

export const selectIsPortrait = (state: RootState): boolean => {
    return state.responsive.isPortrait;
};

export const selectIsRetina = (state: RootState): boolean => {
    return state.responsive.isRetina;
};
