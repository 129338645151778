import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Box, Paper, Typography } from '@mui/material';

import { AnimatedHamburger } from '../../../components';
import { activeColors, iconNames, paths } from '../../../constants/navigation';
import { useAppSelector } from '../../../redux/hooks';
import { selectIsMobile } from '../../../redux/reducers/responsive/responsiveSelectors';

interface INavigationBarProps {
    navStatus: boolean;
    setNavStatus: React.Dispatch<React.SetStateAction<boolean>>;
    path: string;
    signOut: any;
    setConceptStorePair: any;
    concept: string | null;
    store: string | null;
}

export const NavigationBar = ({
    navStatus,
    setNavStatus,
    path,
    signOut,
    setConceptStorePair,
    concept,
    store,
}: INavigationBarProps) => {
    const [loaded, setLoaded] = useState<boolean>(false);
    const [currentPath, setCurrentPath] = useState<string>(path);

    const isMobile: boolean = useAppSelector(selectIsMobile);

    let navigator = useNavigate();

    useLayoutEffect(() => {
        if (currentPath === '/') {
            setCurrentPath('/Email');
        }
        setLoaded(true);
    }, [currentPath]);

    return (
        <Box
            height={isMobile ? 'auto' : '100vh'}
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column-reverse' : 'column',
                justifyContent: 'space-between',
                background: '#FFFFFF',
            }}
        >
            <Paper
                elevation={isMobile ? 0 : 4}
                square
                sx={{
                    width: isMobile ? '100%' : '340px',
                    marginBottom: isMobile ? 'default' : '45px',
                    height: isMobile ? '55px' : 'default',
                    display: 'flex',
                    justifyContent: isMobile ? 'center' : 'space-between',
                    alignItems: 'center',
                }}
            >
                {!isMobile && (
                    <Box
                        width="min(50%, 45px)"
                        height="45px"
                        marginLeft="22.5px"
                        sx={{
                            paddingTop: '22.5px',
                            paddingBottom: '22.5px',
                        }}
                    >
                        <AnimatedHamburger
                            state={navStatus}
                            setState={setNavStatus}
                        />
                    </Box>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: isMobile ? 'center' : 'end',
                        flexDirection: 'column',
                        maxHeight: '90px',
                        mx: 2,
                    }}
                    onClick={setConceptStorePair}
                >
                    <Typography
                        noWrap
                        height="auto"
                        sx={{
                            color: '#707070',
                            maxWidth: '230px',
                            fontSize: '27px',
                            fontWeight: '500',
                            marginBottom: store ? -1 : null,
                        }}
                    >
                        {concept}
                    </Typography>
                    <Typography
                        noWrap
                        sx={{
                            color: '#707070',
                            maxWidth: '230px',
                            fontSize: '20px',
                            fontWeight: '400',
                            marginTop: store ? -1 : null,
                        }}
                    >
                        {store}
                    </Typography>
                </Box>
            </Paper>
            {!isMobile && (
                <Box
                    height="1px"
                    width="300px"
                    sx={{ transform: 'translateX(-210px)' }}
                />
            )}
            <Box
                width={isMobile ? '100%' : '340px'}
                height={isMobile ? 'default' : '100%'}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    width={isMobile ? '100%' : '90px'}
                    height={isMobile ? 'auto' : 'calc(100% - 40px)'}
                    marginTop={isMobile ? 'default' : '40px'}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        boxSizing: 'border-box',
                    }}
                >
                    <Box
                        width={isMobile ? 'calc(100% - 40px)' : '90px'}
                        height="100%"
                        maxHeight="400px"
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'row' : 'column',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            boxSizing: 'border-box',
                        }}
                    >
                        {process.env.REACT_APP_DEPLOYMENT !== 'production' && (
                            <DashboardRoundedIcon
                                id={iconNames[0]}
                                sx={{
                                    fontSize: '50px',
                                    position: 'relative',
                                    zIndex: 5,
                                    transform:
                                        currentPath === '/Dashboard' &&
                                        !isMobile
                                            ? 'translateX(20px)'
                                            : null,
                                    transition: 'transform 0.5s ease-in-out',
                                }}
                                onClick={() => {
                                    navigator('/Dashboard');
                                    setCurrentPath('/Dashboard');
                                }}
                            />
                        )}
                        <MailOutlineRoundedIcon
                            id={iconNames[1]}
                            sx={{
                                fontSize: '50px',
                                position: 'relative',
                                zIndex: 5,
                                transform:
                                    currentPath === '/Email' && !isMobile
                                        ? 'translateX(20px)'
                                        : null,
                                transition: 'transform 0.5s ease-in-out',
                            }}
                            onClick={() => {
                                navigator('/Email');
                                setCurrentPath('/Email');
                            }}
                        />
                        <MenuBookRoundedIcon
                            id={iconNames[2]}
                            sx={{
                                fontSize: '50px',
                                position: 'relative',
                                zIndex: 5,
                                transform:
                                    currentPath === '/Menu' && !isMobile
                                        ? 'translateX(20px)'
                                        : null,
                                transition: 'transform 0.5s ease-in-out',
                            }}
                            onClick={() => {
                                navigator('/Menu');
                                setCurrentPath('/Menu');
                            }}
                        />
                        {process.env.REACT_APP_DEPLOYMENT === 'development' && (
                            <CreditCardRoundedIcon
                                id={iconNames[3]}
                                sx={{
                                    fontSize: '50px',
                                    position: 'relative',
                                    zIndex: 5,
                                    transform:
                                        currentPath === '/Subscription' &&
                                        !isMobile
                                            ? 'translateX(20px)'
                                            : null,
                                    transition: 'transform 0.5s ease-in-out',
                                }}
                                onClick={() => {
                                    navigator('/Subscription');
                                    setCurrentPath('/Subscription');
                                }}
                            />
                        )}
                        {process.env.REACT_APP_DEPLOYMENT === 'development' && (
                            <SettingsRoundedIcon
                                id={iconNames[4]}
                                sx={{
                                    fontSize: '50px',
                                    position: 'relative',
                                    zIndex: 5,
                                    transform:
                                        currentPath === '/Settings' && !isMobile
                                            ? 'translateX(20px)'
                                            : null,
                                    transition: 'transform 0.5s ease-in-out',
                                }}
                                onClick={() => {
                                    navigator('/Settings');
                                    setCurrentPath('/Settings');
                                }}
                            />
                        )}
                        {process.env.REACT_APP_DEPLOYMENT === 'production' && (
                            <Box />
                        )}
                        {process.env.REACT_APP_DEPLOYMENT !== 'development' && (
                            <Box />
                        )}
                    </Box>

                    {!isMobile && (
                        <LogoutRoundedIcon
                            onClick={() => signOut()}
                            sx={{
                                fontSize: '50px',
                                marginTop: '90px',
                                marginBottom: '22.5px',
                            }}
                        />
                    )}
                </Box>
                {!isMobile && (
                    <Box
                        width="250px"
                        height="calc(100% - 40px)"
                        marginTop="40px"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'space-between',
                            boxSizing: 'border-box',
                        }}
                    >
                        <Box
                            width="250px"
                            height="100%"
                            maxHeight="400px"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'start',
                                justifyContent: 'space-between',
                                boxSizing: 'border-box',
                            }}
                        >
                            {process.env.REACT_APP_DEPLOYMENT !==
                                'production' && (
                                <Box
                                    height="50px"
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '27px',
                                            fontWeight: '500',
                                            zIndex: 5,
                                            transform:
                                                currentPath === '/Dashboard'
                                                    ? 'translateX(20px)'
                                                    : null,
                                            transition:
                                                'transform 0.5s ease-in-out',
                                        }}
                                        onClick={() => {
                                            navigator('/Dashboard');
                                            setCurrentPath('/Dashboard');
                                        }}
                                    >
                                        Dashboard
                                    </Typography>
                                </Box>
                            )}
                            <Box
                                height="50px"
                                display="flex"
                                alignItems="center"
                            >
                                <Typography
                                    sx={{
                                        fontSize: '27px',
                                        fontWeight: '500',
                                        zIndex: 5,
                                        transform:
                                            currentPath === '/Email'
                                                ? 'translateX(20px)'
                                                : null,
                                        transition:
                                            'transform 0.5s ease-in-out',
                                    }}
                                    onClick={() => {
                                        navigator('/Email');
                                        setCurrentPath('/Email');
                                    }}
                                >
                                    Email Service
                                </Typography>
                            </Box>
                            <Box
                                height="50px"
                                display="flex"
                                alignItems="center"
                            >
                                <Typography
                                    sx={{
                                        fontSize: '27px',
                                        fontWeight: '500',
                                        zIndex: 5,
                                        transform:
                                            currentPath === '/Menu'
                                                ? 'translateX(20px)'
                                                : null,
                                        transition:
                                            'transform 0.5s ease-in-out',
                                    }}
                                    onClick={() => {
                                        navigator('/Menu');
                                        setCurrentPath('/Menu');
                                    }}
                                >
                                    Prep Sheets
                                </Typography>
                            </Box>
                            {process.env.REACT_APP_DEPLOYMENT ===
                                'development' && (
                                <Box
                                    height="50px"
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '27px',
                                            fontWeight: '500',
                                            zIndex: 5,
                                            transform:
                                                currentPath === '/Subscription'
                                                    ? 'translateX(20px)'
                                                    : null,
                                            transition:
                                                'transform 0.5s ease-in-out',
                                        }}
                                        onClick={() => {
                                            navigator('/Subscription');
                                            setCurrentPath('/Subscription');
                                        }}
                                    >
                                        Subscription
                                    </Typography>
                                </Box>
                            )}
                            {process.env.REACT_APP_DEPLOYMENT ===
                                'development' && (
                                <Box
                                    height="50px"
                                    display="flex"
                                    alignItems="center"
                                >
                                    <Typography
                                        sx={{
                                            fontSize: '27px',
                                            fontWeight: '500',
                                            zIndex: 5,
                                            transform:
                                                currentPath === '/Settings'
                                                    ? 'translateX(20px)'
                                                    : null,
                                            transition:
                                                'transform 0.5s ease-in-out',
                                        }}
                                        onClick={() => {
                                            navigator('/Settings');
                                            setCurrentPath('/Settings');
                                        }}
                                    >
                                        Settings
                                    </Typography>
                                </Box>
                            )}
                            {process.env.REACT_APP_DEPLOYMENT ===
                                'production' && <Box />}
                            {process.env.REACT_APP_DEPLOYMENT !==
                                'development' && <Box />}
                        </Box>
                        <Box
                            height="50px"
                            marginTop="90px"
                            marginBottom="22.5px"
                            display="flex"
                            alignItems="center"
                        >
                            <Typography
                                sx={{
                                    fontSize: '27px',
                                    fontWeight: '500',
                                    zIndex: 5,
                                }}
                                onClick={() => {
                                    navigator('/Dashboard');
                                    setCurrentPath('/Dashboard');
                                }}
                            >
                                Log Out
                            </Typography>
                        </Box>
                    </Box>
                )}
            </Box>
            <Box
                width="0"
                height="0"
                position="absolute"
                top="0"
                left="29px"
            >
                {activeColors.map((color, index) => {
                    return (
                        <Box
                            key={index}
                            width={isMobile ? '60px' : '70px'}
                            height={isMobile ? '60px' : '70px'}
                            position="relative"
                            sx={{
                                zIndex: 4,
                                backgroundColor: color,
                                borderRadius: isMobile ? '16px' : '20px',
                                boxShadow: isMobile
                                    ? '0 0 16px 0 ' + color
                                    : '0 0 20px 0 ' + color,
                                opacity:
                                    loaded && paths[index] === currentPath
                                        ? 1
                                        : 0,
                                transform:
                                    loaded && paths.includes(currentPath)
                                        ? isMobile
                                            ? `translateX(${
                                                  document
                                                      .getElementById(
                                                          iconNames[
                                                              paths.indexOf(
                                                                  currentPath
                                                              )
                                                          ]
                                                      )!
                                                      .getBoundingClientRect()
                                                      .x - 34
                                              }px) translateY(${
                                                  document
                                                      .getElementById(
                                                          iconNames[
                                                              paths.indexOf(
                                                                  currentPath
                                                              )
                                                          ]
                                                      )!
                                                      .getBoundingClientRect()
                                                      .y -
                                                  5 -
                                                  60 * index
                                              }px)`
                                            : `translateY(${
                                                  document
                                                      .getElementById(
                                                          iconNames[
                                                              paths.indexOf(
                                                                  currentPath
                                                              )
                                                          ]
                                                      )!
                                                      .getBoundingClientRect()
                                                      .y -
                                                  10 -
                                                  70 * index
                                              }px)`
                                        : `translateY(${180 - 70 * index}px)`,
                                transition:
                                    'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
                            }}
                        />
                    );
                })}
                <Box
                    width={isMobile ? '60px' : '70px'}
                    height={isMobile ? '60px' : '70px'}
                    position="relative"
                    sx={{
                        zIndex: 3,
                        backgroundColor: '#FFFFFF',
                        borderRadius: isMobile ? '16px' : '20px',
                        opacity: loaded && paths.includes(currentPath) ? 1 : 0,
                        transform:
                            loaded && paths.includes(currentPath)
                                ? isMobile
                                    ? `translateX(${
                                          document
                                              .getElementById(
                                                  iconNames[
                                                      paths.indexOf(currentPath)
                                                  ]
                                              )!
                                              .getBoundingClientRect().x - 34
                                      }px) translateY(${
                                          document
                                              .getElementById(
                                                  iconNames[
                                                      paths.indexOf(currentPath)
                                                  ]
                                              )!
                                              .getBoundingClientRect().y -
                                          5 -
                                          60 * paths.length
                                      }px)`
                                    : `translateY(${
                                          document
                                              .getElementById(
                                                  iconNames[
                                                      paths.indexOf(currentPath)
                                                  ]
                                              )!
                                              .getBoundingClientRect().y -
                                          10 -
                                          70 * paths.length
                                      }px)`
                                : `translateY(${180 - 70 * paths.length}px)`,
                        transition:
                            'transform 0.5s ease-in-out, opacity 0.5s ease',
                    }}
                />
            </Box>
        </Box>
    );
};
