import { prepItem } from '../redux/reducers/recipes/recipesState';

const empty_prep_item: prepItem = {
    id: '',
    itemName: '',
    ingredientCategory: '',
    ingredientName: '',
    ingredientUUID: '',
    scheduleType: '',
    units: '',
    leadTime: '0',
    leadTimeUnits: 'Days',
    shelfLife: '1',
    shelfLifeUnits: 'Days',
    prepSchedule: '',
    prepDays: Array(7).fill(false),
    newUnit: null,
    newUnitConversion: undefined,
    section: '',
};

export const recipe_state = {
    prepLists: {},
    focusedPrepItem: empty_prep_item,
    focusedPrepSection: '',
    selectedPrepSection: '',
    dialogState: 'NoDialogs',
    openDialog: false,
    activeItem: '', // TODO: REMOVE THIS
    selectedPrepList: '', // activeItem in prepListTab
    prepLicense: null,
};
