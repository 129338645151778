/**
 * * Async JSON request
 * @param {null}
 * @returns {object} object containing all user and store data
 */
export async function postMenu({
    id,
    ingredients,
    menuItems,
    categories,
    prepLists,
}: any) {
    // API URL Endpoint Construction
    const url = `${process.env.REACT_APP_API_URL}/menu_mapper`;

    let body = JSON.stringify({
        action: 'UPDATE',
        id: id,
        data: {
            id: id,
            ingredients: ingredients,
            menuItems: menuItems,
            categories: categories,
            prepLists: prepLists,
        },
    });

    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(JSON.stringify(response.json()));
            }
            return response.json();
        })
        .catch((err) => {
            console.error(err);
            throw err;
        });
}
