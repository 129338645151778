import { combineReducers } from 'redux';

import featureSwitchReducer from './featureSwitch/featureSwitchReducer';
import recipesReducer from './recipes/recipesReducer';
import responsiveReducer from './responsive/responsiveReducer';
import toastReducer from './toast/toastReducer';

const rootReducer = combineReducers({
    recipes: recipesReducer,
    featureSwitch: featureSwitchReducer,
    responsive: responsiveReducer,
    toast: toastReducer,
});

export default rootReducer;
