import { FeatureSwitchInterface } from '../redux/reducers/featureSwitch/featureSwitchState';

function parseBoolean(value: any): boolean {
    switch (value) {
        case true:
        case 'true':
        case 1:
        case '1':
        case 'on':
        case 'yes':
            return true;
        default:
            return false;
    }
}

export const featureSwitches: FeatureSwitchInterface = {
    debug: parseBoolean(process.env.REACT_APP_DEBUG) ?? false,
    offlineMode: parseBoolean(process.env.REACT_APP_OFFLINE_MODE) ?? false,
    unitsOfTimeSelector:
        parseBoolean(process.env.REACT_APP_FEATURE_UNITS_OF_TIME_SELECTOR) ??
        false,
    newDataRelationship:
        parseBoolean(process.env.REACT_APP_FEATURE_NEW_DATA_RELATIONSHIP) ??
        false,
    newDataFormat: parseBoolean(process.env.REACT_APP_FEATURE_NEW_DATA_FORMAT),
    wasteStockoutSlider: parseBoolean(
        process.env.REACT_APP_FEATURE_WASTE_STOCKOUT_SLIDER
    ),
    enforceLicenseLimit: parseBoolean(
        process.env.REACT_APP_FEATURE_ENFORCE_LICENSE_LIMIT
    ),
    qtyDisplayedAsTooltip:
        parseBoolean(process.env.REACT_APP_QTY_DISPLAY_AS_TOOLTIP) ?? false,
    modifierGroups:
        parseBoolean(
            process.env.REACT_APP_PATRICKS_SUPER_SECRET_FUCK_THIS_SHIT_FEATURES
        ) ?? false,
};
