import React, { useEffect, useState } from 'react';

import {
    CheckRounded as CheckRoundedIcon,
    ClearRounded as ClearRoundedIcon,
} from '@mui/icons-material';
import DevicesOtherRoundedIcon from '@mui/icons-material/DevicesOtherRounded';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import SvgIcon from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {
    getDataVerification,
    getStoreVerification,
    postDataVerification,
    postStoreVerification,
    postVerifyPOS,
} from '../../../api';
import Step1 from '../../../assets/Step-1.png';
import Step2 from '../../../assets/Step-2.png';
import Step3 from '../../../assets/Step-3.png';
import Step4 from '../../../assets/Step-4.png';
import Step5 from '../../../assets/Step-5.png';
import Step6 from '../../../assets/Step-6.png';
import Step7 from '../../../assets/Step-7.png';
import { ReactComponent as CloverLogo } from '../../../assets/companies/CloverLogo.svg';
import { ReactComponent as SquareLogo } from '../../../assets/companies/SquareLogo.svg';
import { ReactComponent as ToastLogo } from '../../../assets/companies/ToastLogo.svg';

interface onboardingInterface {
    user: string;
    concept: string;
    stores: any[];
    refreshUserData: any;
    user_details: any;
}

export function Onboarding({
    user,
    concept,
    stores,
    refreshUserData,
    user_details,
}: onboardingInterface) {
    console.log("User's details: ", user_details);

    console.log(stores);

    const userState: () => string = () => {
        switch (user_details.state) {
            case 'undeployed':
                return 'undeployed';
            case 'wait_deploy':
                return 'wait_deploy';
            case 'storeless':
                return 'storeless';
            case 'wait_verify':
                return 'wait_verify';
            case 'verify':
                return 'verify';
            case 'wait_review':
                return 'wait_review';
            case 'wait_walkthrough':
                return 'wait_walkthrough';
            case 'wait_manual_deploy':
                return 'wait_manual_deploy';
            case 'production':
                return 'production';
            default:
                return 'unknown';
        }
    };

    const [open, setOpen] = useState(false);

    const [demoState, setDemoState] = useState(userState());

    const [posType, setPosType] = useState('');

    const [verificationData, setVerificationData] = useState<any>({});
    const [verificationLoading, setVerificationLoading] =
        useState<boolean>(true);

    process.env.REACT_APP_DEBUG === 'true' &&
        console.log("User's stores: ", stores);

    const [onboardingStores, setOnboardingStores] = useState<any[]>(
        stores.filter((store) => {
            return store.verification === 1 || store.verification === 2;
        })
    );

    const [modifiedItem, setModifiedItem] = useState<string>('');
    const [modifiedQty, setModifiedQty] = useState<string>('');

    // const [selectedStores, setSelectedStores] = useState<string[]>([]);

    // const [selectedStoreIdx, setSelectedStoreIdx] = useState<number>(0);
    const selectedStoreIdx = 0;

    const [verificationStatus, setVerificationStatus] = useState<string[]>([]);

    const [errorMessage, setErrorMessage] = useState<string>('');

    const [apiEntry, setApiEntry] = useState<string>('');

    const [availableStores, setAvailableStores] = useState<any>(null);

    const [progress, setProgress] = useState<number>(0);

    const theLoop: () => void = () => {
        const timer = setInterval(() => {
            refreshUserData().then(() => {
                if (userState() === demoState || demoState.includes('wait')) {
                    setProgress((oldProgress) => {
                        if (oldProgress < 70) {
                            return oldProgress + (Math.random() * 5 + 5);
                        } else {
                            return (oldProgress + 100) / 2;
                        }
                    });
                    userState() !== demoState && setDemoState(userState());
                } else {
                    setProgress(100);
                    clearInterval(timer);
                    setTimeout(() => {
                        setDemoState(userState());
                        setProgress(0);
                    }, 3000);
                }
            });
        }, Math.floor((Math.random() * 10 + 13) * 1000));
    };

    useEffect(() => {
        if (demoState.includes('wait')) {
            theLoop();
        }
    }, []);

    useEffect(() => {
        if (!!!onboardingStores || !onboardingStores.length) {
            setVerificationLoading(true);
        }
    }, [stores, onboardingStores]);

    useEffect(() => {
        let currentState = userState();
        if (!verificationLoading) {
            return;
        }
        if (currentState !== demoState) {
            setDemoState(userState());
        }
        if (stores.length === 0) {
            return;
        }
        setVerificationData({});
        setVerificationStatus([]);
        let newOnboardingStores = stores.filter((store) => {
            return store.verification === 1 || store.verification === 2;
        });
        setOnboardingStores(newOnboardingStores);
        currentState === 'verify' &&
            !!newOnboardingStores.length &&
            getDataVerification(
                user,
                newOnboardingStores[selectedStoreIdx].concept,
                newOnboardingStores[selectedStoreIdx].id
            )
                .then((response) => {
                    if (typeof response === 'string') {
                        setErrorMessage(response);
                        return;
                    }
                    setVerificationData(response);
                    setVerificationStatus(
                        Array(
                            newOnboardingStores[selectedStoreIdx]
                                .verification === 1
                                ? response.sales_verification_data.length
                                : newOnboardingStores[selectedStoreIdx]
                                      .verification === 2
                                ? response.item_verification_data.length
                                : 0
                        ).fill('')
                    );
                    setVerificationLoading(false);
                })
                .catch((err) => {
                    console.error(err);
                });
    }, [user, stores, selectedStoreIdx, verificationLoading, user_details]);

    useEffect(() => {
        if (demoState === 'storeless' && availableStores === null) {
            getStoreVerification(user, concept)
                .then((results) => {
                    setAvailableStores(results);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [demoState]);

    const handleClickOpen = (name: string) => {
        let modifiedArray =
            onboardingStores[selectedStoreIdx].verification === 1
                ? verificationData.sales_verification_data
                : onboardingStores[selectedStoreIdx].verification === 2
                ? verificationData.item_verification_data
                : [];
        let modifiedIdx = modifiedArray.findIndex(
            (datum: any) => datum.item === name
        );

        setModifiedQty(
            modifiedArray[modifiedIdx]?.CorrectedValue ??
                modifiedArray[modifiedIdx].qty
        );
        setOpen(true);
    };

    const handleClose = (save: boolean) => {
        setOpen(false);
        let modifiedArray =
            onboardingStores[selectedStoreIdx].verification === 1
                ? verificationData.sales_verification_data
                : onboardingStores[selectedStoreIdx].verification === 2
                ? verificationData.item_verification_data
                : [];
        let modifiedIdx = modifiedArray.findIndex(
            (datum: any) => datum.item === modifiedItem
        );
        if (save) {
            if (onboardingStores[selectedStoreIdx].verification === 1) {
                let copyOfData = verificationData;
                copyOfData.sales_verification_data[modifiedIdx].CorrectedValue =
                    modifiedQty;
                setVerificationData(JSON.parse(JSON.stringify(copyOfData)));
            } else if (onboardingStores[selectedStoreIdx].verification === 2) {
                let copyOfData = verificationData;
                copyOfData.item_verification_data[modifiedIdx].CorrectedValue =
                    modifiedQty;
                setVerificationData(JSON.parse(JSON.stringify(copyOfData)));
            }
        } else {
            let verificationStatusCopy = verificationStatus;
            verificationStatusCopy[modifiedIdx] = '';
            setVerificationStatus(verificationStatusCopy);
        }

        setModifiedItem('');
        setModifiedQty('');
    };

    const disabledQtyChange = (qty: any) => {
        if (!!!modifiedItem) {
            return true;
        }
        let modifiedArray =
            onboardingStores[selectedStoreIdx].verification === 1
                ? verificationData.sales_verification_data
                : onboardingStores[selectedStoreIdx].verification === 2
                ? verificationData.item_verification_data
                : [];
        let modifiedIdx = modifiedArray.findIndex(
            (datum: any) => datum.item === modifiedItem
        );
        return modifiedArray[modifiedIdx].qty === qty;
    };

    return (
        <React.Fragment>
            <Dialog
                open={true}
                onClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Dialog
                    open={open}
                    onClose={() => {}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {'Item Correction'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            What is the correct quantity sold of {modifiedItem}?
                        </DialogContentText>

                        <TextField
                            id="outlined-basic"
                            label="Quantity Sold"
                            variant="outlined"
                            type="number"
                            value={modifiedQty}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                                setModifiedQty(event.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose(false)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleClose(true)}
                            disabled={disabledQtyChange(modifiedQty)}
                            autoFocus
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
                {demoState === 'undeployed' && !posType ? (
                    <Paper
                        sx={{
                            backgroundColor: '#FFF',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '350px',
                            gap: 4,
                            p: 4,
                        }}
                    >
                        <Typography
                            sx={{
                                height: '32px',
                                fontSize: '24px',
                                fontWeight: '500',
                            }}
                        >
                            Select your POS vendor.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 2,
                                }}
                            >
                                <Paper
                                    sx={{
                                        width: '150px',
                                        height: '150px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 0,
                                    }}
                                    elevation={6}
                                    onClick={() => {
                                        setPosType('Toast');
                                        postVerifyPOS(
                                            user,
                                            concept,
                                            'Toast',
                                            apiEntry
                                        );
                                    }}
                                >
                                    <SvgIcon
                                        component={ToastLogo}
                                        sx={{ fontSize: '80px' }}
                                        inheritViewBox={true}
                                    />
                                    <Typography
                                        fontWeight={500}
                                        fontSize="32px"
                                    >
                                        Toast
                                    </Typography>
                                </Paper>
                                <Paper
                                    sx={{
                                        width: '150px',
                                        height: '150px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 0,
                                    }}
                                    elevation={6}
                                    onClick={() => {
                                        setPosType('Square');
                                    }}
                                >
                                    <SvgIcon
                                        component={SquareLogo}
                                        sx={{ fontSize: '80px' }}
                                        inheritViewBox={true}
                                    />
                                    <Typography
                                        fontWeight={500}
                                        fontSize="32px"
                                    >
                                        Square
                                    </Typography>
                                </Paper>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 2,
                                }}
                            >
                                <Paper
                                    sx={{
                                        width: '150px',
                                        height: '150px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 0,
                                        opacity: 0.5,
                                        backgroundColor: '#aaa',
                                    }}
                                    elevation={6}
                                    // onClick={() => setDemoState(2)}
                                >
                                    <SvgIcon
                                        component={CloverLogo}
                                        sx={{ fontSize: '80px' }}
                                        inheritViewBox={true}
                                    />
                                    <Typography
                                        fontWeight={500}
                                        fontSize="32px"
                                    >
                                        Clover
                                    </Typography>
                                </Paper>
                                <Paper
                                    sx={{
                                        width: '150px',
                                        height: '150px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 0,
                                        opacity: 0.5,
                                        backgroundColor: '#aaa',
                                    }}
                                    elevation={6}
                                    // onClick={() => setDemoState(2)}
                                >
                                    <DevicesOtherRoundedIcon
                                        sx={{ fontSize: '80px' }}
                                    />
                                    <Typography
                                        fontWeight={500}
                                        fontSize="32px"
                                    >
                                        Other
                                    </Typography>
                                </Paper>
                            </Box>
                        </Box>
                    </Paper>
                ) : demoState === 'undeployed' && !!posType ? (
                    <Paper
                        sx={{
                            backgroundColor: '#FFF',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '480px',
                            gap: 4,
                            p: 4,
                        }}
                    >
                        <Typography
                            sx={{
                                height: '32px',
                                fontSize: '24px',
                                fontWeight: '500',
                            }}
                        >
                            {posType}
                        </Typography>
                        {posType === 'Square' ? (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 0.5,
                                    }}
                                >
                                    <Typography>
                                        1. Log in to your square account
                                    </Typography>
                                    <Box
                                        component="img"
                                        sx={{
                                            width: 500,
                                            maxWidth: 500,
                                        }}
                                        src={Step1}
                                    />
                                    <Typography>
                                        2. Go to the Square developer landing
                                        page:
                                        https://developer.squareup.com/apps
                                    </Typography>
                                    <Box
                                        component="img"
                                        sx={{
                                            width: 500,
                                            maxWidth: 500,
                                        }}
                                        src={Step2}
                                    />
                                    <Typography>
                                        {
                                            '3. Click the + button to create a new app'
                                        }
                                    </Typography>
                                    <Box
                                        component="img"
                                        sx={{
                                            width: 500,
                                            maxWidth: 500,
                                        }}
                                        src={Step3}
                                    />
                                    <Typography>
                                        {
                                            '4. Name your app (feel free to name it ClearCOGS)'
                                        }
                                    </Typography>
                                    <Box
                                        component="img"
                                        sx={{
                                            width: 500,
                                            maxWidth: 500,
                                        }}
                                        src={Step4}
                                    />
                                    <Typography>
                                        {'5. Skip the rest of these steps'}
                                    </Typography>
                                    <Box
                                        component="img"
                                        sx={{
                                            width: 500,
                                            maxWidth: 500,
                                        }}
                                        src={Step5}
                                    />
                                    <Typography>
                                        6. Open the app and click on
                                        "Production" in the top middle tabs
                                    </Typography>
                                    <Box
                                        component="img"
                                        sx={{
                                            width: 500,
                                            maxWidth: 500,
                                        }}
                                        src={Step6}
                                    />
                                    <Typography>
                                        7. Copy the value in Production Access
                                        Token and paste below
                                    </Typography>
                                    <Box
                                        component="img"
                                        sx={{
                                            width: 500,
                                            maxWidth: 500,
                                        }}
                                        src={Step7}
                                    />
                                </Box>
                                <TextField
                                    id="outlined-basic"
                                    label="API Key"
                                    variant="outlined"
                                    value={apiEntry}
                                    error={!!errorMessage}
                                    helperText={errorMessage && errorMessage}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                        setApiEntry(event.target.value);
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setDemoState('wait_deploy');
                                        postVerifyPOS(
                                            user,
                                            concept,
                                            posType,
                                            apiEntry
                                        )
                                            .then((response) => {
                                                setErrorMessage('');
                                                setTimeout(() => {
                                                    refreshUserData().then(
                                                        () => {
                                                            setVerificationLoading(
                                                                true
                                                            );
                                                        }
                                                    );
                                                    theLoop();
                                                }, 2500);
                                            })
                                            .catch((err) => {
                                                console.error(
                                                    err.errors[0].detail
                                                );
                                                setErrorMessage(
                                                    err.errors[0].detail
                                                );
                                                setTimeout(() => {
                                                    refreshUserData().then(
                                                        () => {
                                                            setVerificationLoading(
                                                                true
                                                            );
                                                        }
                                                    );
                                                }, 2500);
                                            });
                                        console.log(user);
                                    }}
                                >
                                    Verify
                                </Button>
                            </>
                        ) : posType === 'Toast' ? (
                            <>
                                <Typography>
                                    Please give{' '}
                                    <a href="mailto:data@clearcogs.com">
                                        data@clearcogs.com
                                    </a>{' '}
                                    Admin role access to your Toast account so
                                    we can start pulling your daily transaction
                                    data
                                </Typography>
                            </>
                        ) : posType === 'Clover' ? (
                            <>
                                <TextField
                                    id="outlined-basic"
                                    label="API Key"
                                    variant="outlined"
                                />
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        // setDemoState(3);
                                        // setTimeout(() => {
                                        //     setDemoState(4);
                                        // }, 10000);
                                    }}
                                >
                                    Submit
                                </Button>
                            </>
                        ) : (
                            <>
                                <TextField
                                    id="outlined-basic"
                                    label="Vendor Name"
                                    variant="outlined"
                                />
                                <TextField
                                    id="outlined-basic"
                                    label="API Key"
                                    variant="outlined"
                                />
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        // setDemoState(3);
                                        // setTimeout(() => {
                                        //     setDemoState(4);
                                        // }, 5000);
                                    }}
                                >
                                    Submit
                                </Button>
                            </>
                        )}
                    </Paper>
                ) : demoState === 'wait_deploy' ||
                  demoState === 'wait_verify' ? (
                    <Paper
                        sx={{
                            backgroundColor: '#FFF',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '350px',
                            gap: 4,
                            p: 4,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '24px',
                                fontWeight: '500',
                            }}
                        >
                            Loading...
                        </Typography>
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress
                                variant="determinate"
                                value={progress}
                            />
                        </Box>
                        <Typography
                            sx={{
                                fontSize: '12px',
                                fontWeight: '400',
                            }}
                        >
                            If this takes longer than 15 minutes, please email
                            help@clearcogs.com.
                        </Typography>
                    </Paper>
                ) : demoState === 'wait_review' ? (
                    <Paper
                        sx={{
                            backgroundColor: '#FFF',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '350px',
                            gap: 4,
                            p: 4,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '24px',
                                fontWeight: '500',
                            }}
                        >
                            Please send a daily item-level report with the
                            correct sales to{' '}
                            <a href="mailto:help@clearcogs.com">
                                help@clearcogs.com
                            </a>{' '}
                            so we can take the next steps in verification.
                        </Typography>
                    </Paper>
                ) : demoState === 'wait_manual_deploy' ? (
                    <Paper
                        sx={{
                            backgroundColor: '#FFF',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '350px',
                            gap: 4,
                            p: 4,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '24px',
                                fontWeight: '500',
                            }}
                        >
                            Your system is currently in deployment. Please keep
                            an eye on your email for updates.
                        </Typography>
                    </Paper>
                ) : demoState === 'wait_walkthrough' ? (
                    <Paper
                        sx={{
                            backgroundColor: '#FFF',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '350px',
                            gap: 4,
                            p: 4,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: '24px',
                                fontWeight: '500',
                            }}
                        >
                            Congratulations, you should start receiving sample
                            prep sheets containing some of your top selling
                            items, but to complete and perfect your custom prep
                            sheets we need to talk to you again. We should be
                            reaching out soon to schedule some time to go over
                            the customization. In the meantime, feel free to
                            email{' '}
                            <a href="mailto:success@clearcogs.com">
                                success@clearcogs.com
                            </a>{' '}
                            with any questions.
                        </Typography>
                    </Paper>
                ) : demoState === 'storeless' ? (
                    <Paper
                        sx={{
                            backgroundColor: '#FFF',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '350px',
                            p: 4,
                            gap: 1,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                sx={{
                                    height: '32px',
                                    fontSize: '24px',
                                    fontWeight: '500',
                                }}
                            >
                                Select Your Stores
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: '18px',
                                    fontWeight: '400',
                                    mt: 1,
                                    mb: 2,
                                    textAlign: 'center',
                                }}
                            >
                                {
                                    'Which store(s) would you like to receive forecasts for'
                                }
                            </Typography>
                            {availableStores && (
                                <Typography
                                    color={
                                        availableStores.licenses <
                                        availableStores.stores.reduce(
                                            (total: number, store: any) =>
                                                total + (store.active ? 1 : 0),
                                            0
                                        )
                                            ? 'error'
                                            : availableStores.licenses ===
                                              availableStores.stores.reduce(
                                                  (total: number, store: any) =>
                                                      total +
                                                      (store.active ? 1 : 0),
                                                  0
                                              )
                                            ? 'success'
                                            : 'gray'
                                    }
                                    sx={{
                                        fontSize: '12px',
                                        fontWeight: '400',
                                        mt: 1,
                                        mb: 2,
                                        textAlign: 'center',
                                    }}
                                >
                                    {`Licenses available: ${
                                        availableStores.licenses -
                                        availableStores.stores.reduce(
                                            (total: number, store: any) =>
                                                total + (store.active ? 1 : 0),
                                            0
                                        )
                                    }`}
                                </Typography>
                            )}
                        </Box>
                        {availableStores &&
                            availableStores.stores.map(
                                (store: any, idx: number) => {
                                    return (
                                        <Paper
                                            key={idx}
                                            elevation={2}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                px: 2,
                                                width: '80%',
                                            }}
                                            onClick={() => {
                                                let newStoreList = {
                                                    ...availableStores,
                                                };
                                                newStoreList.stores[
                                                    idx
                                                ].active =
                                                    !newStoreList.stores[idx]
                                                        .active;
                                                setAvailableStores(
                                                    newStoreList
                                                );
                                            }}
                                        >
                                            <Typography>
                                                {store.store_name}
                                            </Typography>
                                            <Checkbox checked={store.active} />
                                        </Paper>
                                    );
                                }
                            )}

                        <Button
                            variant="contained"
                            onClick={() => {
                                setDemoState('wait_verify');
                                postStoreVerification(
                                    user,
                                    concept,
                                    availableStores.stores
                                )
                                    .then((response) => {
                                        setErrorMessage('');
                                        setTimeout(() => {
                                            theLoop();
                                            setVerificationLoading(true);
                                        }, 2500);
                                    })
                                    .catch((err) => {
                                        console.error(err.errors[0].detail);
                                        setErrorMessage(err.errors[0].detail);
                                        setTimeout(() => {
                                            refreshUserData().then(() => {
                                                setVerificationLoading(true);
                                            });
                                        }, 2500);
                                    });
                                // setDemoState(3);
                                // setTimeout(() => {
                                //     setDemoState(5);
                                // }, 2000);
                            }}
                            disabled={
                                availableStores
                                    ? !(
                                          availableStores.licenses >=
                                          availableStores.stores.reduce(
                                              (total: number, store: any) =>
                                                  total +
                                                  (store.active ? 1 : 0),
                                              0
                                          )
                                      ) ||
                                      !availableStores.stores.reduce(
                                          (total: number, store: any) =>
                                              total + (store.active ? 1 : 0),
                                          0
                                      )
                                    : true
                            }
                        >
                            Submit
                        </Button>
                    </Paper>
                ) : demoState === 'verify' ? (
                    <Paper
                        sx={{
                            backgroundColor: '#FFF',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '350px',
                            gap: 4,
                            p: 4,
                        }}
                    >
                        <Typography
                            sx={{
                                height: '32px',
                                fontSize: '24px',
                                fontWeight: '500',
                            }}
                        >
                            {onboardingStores[selectedStoreIdx].verification ===
                            1
                                ? 'Sales Verification'
                                : onboardingStores[selectedStoreIdx]
                                      .verification === 2
                                ? 'Item Verificiation'
                                : null}
                        </Typography>
                        <Typography>
                            Verify the data below using the icon buttons. You
                            can adjust the values, if incorrect, by clicking on
                            the 'x' icon. This is a prerequisite to recieving
                            reports.
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 'fill-available',
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    color: '#000',
                                }}
                            >
                                {onboardingStores[selectedStoreIdx].name}
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    color: '#000',
                                }}
                            >
                                {!verificationLoading && verificationData.date}
                            </Typography>
                            {errorMessage ? (
                                <Typography
                                    sx={{
                                        fontWeight: 500,
                                        fontSize: '14px',
                                        color: '#888',
                                        textAlign: 'center',
                                    }}
                                >
                                    {errorMessage}
                                </Typography>
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-around',
                                        width: 'fill-available',
                                    }}
                                >
                                    <Typography
                                        width="150px"
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '12px',
                                            color: '#808080',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Item
                                    </Typography>
                                    <Typography
                                        width="100px"
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '12px',
                                            color: '#808080',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Qty Sold
                                    </Typography>
                                    <Typography
                                        width="100px"
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '12px',
                                            color: '#808080',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Verification
                                    </Typography>
                                </Box>
                            )}
                            {!verificationLoading &&
                                (onboardingStores[selectedStoreIdx]
                                    .verification === 1
                                    ? verificationData.sales_verification_data
                                    : onboardingStores[selectedStoreIdx]
                                          .verification === 2
                                    ? verificationData.item_verification_data
                                    : []
                                ).map((datum: any, idx: number) => {
                                    return (
                                        <Box
                                            key={idx}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-around',
                                                width: 'fill-available',
                                            }}
                                        >
                                            <Typography
                                                width="150px"
                                                textAlign="center"
                                            >
                                                {datum.item}
                                            </Typography>
                                            <Box
                                                width="100px"
                                                textAlign="center"
                                            >
                                                {datum.CorrectedValue && (
                                                    <Typography
                                                        sx={{
                                                            textDecoration:
                                                                'line-through',
                                                            color: '#999',
                                                        }}
                                                    >
                                                        {datum.qty_type ===
                                                        'price'
                                                            ? '$'
                                                            : null}
                                                        {datum.qty}
                                                    </Typography>
                                                )}
                                                <Typography textAlign="center">
                                                    {datum.qty_type === 'price'
                                                        ? '$'
                                                        : null}
                                                    {datum.CorrectedValue ??
                                                        datum.qty}
                                                </Typography>
                                            </Box>
                                            <Box
                                                width="100px"
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <ClearRoundedIcon
                                                    sx={{
                                                        background:
                                                            verificationStatus[
                                                                idx
                                                            ] === 'flag'
                                                                ? 'red'
                                                                : '#FFF',
                                                        color:
                                                            verificationStatus[
                                                                idx
                                                            ] === 'flag'
                                                                ? '#FFF'
                                                                : 'red',
                                                        borderStyle: 'solid',
                                                        borderWidth: '2px',
                                                        borderColor: 'red',
                                                        borderRadius: 5,
                                                        p: 0.3,
                                                        m: 0.2,
                                                    }}
                                                    onClick={() => {
                                                        if (
                                                            verificationStatus[
                                                                idx
                                                            ] !== 'flag'
                                                        ) {
                                                            let verificationStatusCopy =
                                                                verificationStatus;
                                                            verificationStatusCopy[
                                                                idx
                                                            ] = 'flag';
                                                            setModifiedItem(
                                                                datum.item
                                                            );
                                                            handleClickOpen(
                                                                datum.item
                                                            );
                                                            setVerificationStatus(
                                                                JSON.parse(
                                                                    JSON.stringify(
                                                                        verificationStatusCopy
                                                                    )
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                                <CheckRoundedIcon
                                                    sx={{
                                                        color:
                                                            verificationStatus[
                                                                idx
                                                            ] === 'check'
                                                                ? '#FFF'
                                                                : 'green',
                                                        background:
                                                            verificationStatus[
                                                                idx
                                                            ] === 'check'
                                                                ? 'green'
                                                                : '#FFF',
                                                        borderStyle: 'solid',
                                                        borderWidth: '2px',
                                                        borderColor: 'green',
                                                        borderRadius: 5,
                                                        p: 0.3,
                                                        m: 0.2,
                                                    }}
                                                    onClick={() => {
                                                        if (
                                                            verificationStatus[
                                                                idx
                                                            ] !== 'check'
                                                        ) {
                                                            let verificationStatusCopy =
                                                                verificationStatus;
                                                            verificationStatusCopy[
                                                                idx
                                                            ] = 'check';
                                                            setVerificationStatus(
                                                                JSON.parse(
                                                                    JSON.stringify(
                                                                        verificationStatusCopy
                                                                    )
                                                                )
                                                            );
                                                            let verificationDataCopy =
                                                                verificationData;
                                                            verificationDataCopy[
                                                                onboardingStores[
                                                                    selectedStoreIdx
                                                                ]
                                                                    .verification ===
                                                                1
                                                                    ? 'sales_verification_data'
                                                                    : 'item_verification_data'
                                                            ][
                                                                idx
                                                            ].CorrectedValue =
                                                                undefined;
                                                            setVerificationData(
                                                                JSON.parse(
                                                                    JSON.stringify(
                                                                        verificationDataCopy
                                                                    )
                                                                )
                                                            );
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    );
                                })}
                        </Box>
                        <Button
                            variant="contained"
                            onClick={() => {
                                postDataVerification(
                                    user,
                                    onboardingStores[selectedStoreIdx].concept,
                                    onboardingStores[selectedStoreIdx].id,
                                    onboardingStores[selectedStoreIdx]
                                        .verification,
                                    onboardingStores[selectedStoreIdx]
                                        .verification === 1
                                        ? verificationData.sales_verification_data // TODO: Stop sending correct data if it isnt
                                        : onboardingStores[selectedStoreIdx]
                                              .verification === 2
                                        ? verificationData.item_verification_data
                                        : []
                                )
                                    .then((response) => {
                                        setVerificationStatus([]);
                                        refreshUserData();
                                        setTimeout(() => {
                                            setVerificationLoading(true);
                                        }, 3000);
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                    });
                            }}
                            disabled={
                                !verificationStatus.reduce(
                                    (accumulator, currentValue) => {
                                        if (!currentValue) {
                                            return false;
                                        }
                                        return accumulator;
                                    },
                                    true
                                ) || !!errorMessage
                            }
                        >
                            Confirm
                        </Button>
                    </Paper>
                ) : demoState === 'production' ? (
                    <Paper
                        sx={{
                            backgroundColor: '#FFF',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '350px',
                            gap: 4,
                            p: 4,
                        }}
                    >
                        <Typography
                            sx={{
                                height: '32px',
                                fontSize: '24px',
                                fontWeight: '500',
                            }}
                        >
                            Success!
                        </Typography>
                        <Typography>
                            {
                                'Your account will be update with your new store(s) shortly!'
                            }
                        </Typography>
                    </Paper>
                ) : (
                    <Box />
                )}
            </Dialog>
        </React.Fragment>
    );
}
