import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export function AppFooter() {
    return (
        <Box
            position="fixed"
            display="flex"
            sx={{
                flexDirection: 'row',
                alignItems: 'baseline',
                alignSelf: 'center',
                fontSize: '24px',
                bottom: '0px',
            }}
        >
            <Typography
                sx={{
                    mr: 0.75,
                    fontSize: '20px',
                }}
            >
                Powered By
            </Typography>
            <Typography
                sx={{
                    fontFamily: 'futura-pt,sans-serif',
                    fontWeight: 600,
                    fontStyle: 'normal',
                    color: '#f8643c',
                    fontSize: '20px',
                }}
            >
                CLEAR
            </Typography>
            <Typography
                sx={{
                    fontFamily: 'futura-pt-bold,sans-serif',
                    fontWeight: 700,
                    fontStyle: 'normal',
                    color: '#f8643c',
                    fontSize: '20px',
                }}
            >
                COGS
            </Typography>
        </Box>
    );
}
