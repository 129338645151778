export function generate_lvl1_stats(json_obj: any) {
    const num_obj = Object.keys(json_obj).length;
    return Object.keys(json_obj).map((item, idx) => {
        const color = (360 / num_obj) * idx;
        return {
            id: item,
            label: item,
            converted: json_obj[item].converted,
            value_end_usage: Math.round(json_obj[item].end_usage * 100) / 100,
            value_end_units: json_obj[item].end_units,
            value_recipe_usage:
                Math.round(json_obj[item].recipe_usage * 100) / 100,
            value_recipe_units: json_obj[item].recipe_units,
            value_quantity_sold: json_obj[item].data.reduce(
                (aggregate: number, item: any) =>
                    aggregate + item.quantity_sold,
                0
            ),
            value_count: json_obj[item].data.length,
            color: `hsl(${color}, 70%, 50%)`,
        };
    });
}

export function generate_lvl2_stats(json_obj: any, ingredient: string) {
    const all_keys = json_obj[ingredient].data.map((ing: any) => {
        return ing.base_name;
    });
    const set_of_keys = new Set(all_keys);
    const array_of_keys = Array.from(set_of_keys);
    return array_of_keys.map((item, idx) => {
        const color = (360 / (set_of_keys.size + 1)) * idx;
        const arr_of_items = json_obj[ingredient].data.filter(
            (obj: any) => obj.base_name === item
        );
        return {
            id: item,
            label: item,
            converted: json_obj[ingredient].converted,
            value_end_usage: arr_of_items.reduce(
                (aggregate: number, item: any) =>
                    Math.round((aggregate + item.end_usage) * 100) / 100,
                0
            ),
            value_end_units: json_obj[ingredient].end_units,
            value_recipe_usage: arr_of_items.reduce(
                (aggregate: number, item: any) =>
                    Math.round((aggregate + item.recipe_usage) * 100) / 100,
                0
            ),
            value_recipe_units: json_obj[ingredient].recipe_units,
            value_quantity_sold: arr_of_items.reduce(
                (aggregate: number, item: any) =>
                    Math.round((aggregate + item.quantity_sold) * 100) / 100,
                0
            ),
            value_count: arr_of_items.length,
            color: `hsl(${color}, 70%, 50%)`,
        };
    });
}
