import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const ConceptNoAccess: React.FC = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignSelf: 'center',
                justifyContent: 'stretch',
            }}
        >
            <Typography>
                You do not own this concept, please select a concept with
                manager privliages.
            </Typography>
        </Box>
    );
};
