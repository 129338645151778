import isEqual from 'lodash.isequal';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import {
    FormControl,
    FormGroup,
    InputLabel,
    MenuItem,
    Paper,
    Select,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { updateMenuItems } from '../../../../api/functions/updateMenuItems';
import { colors } from '../../../../constants/recipe_constants';
import { useAppDispatch } from '../../../../redux/hooks';
import { setToastContext } from '../../../../redux/reducers/toast/toastActions';
import { ToastInterface } from '../../../../redux/reducers/toast/toastState';
import { RecipeModifierTabProps } from './IRecipeModifierTab';

export function RecipeModifierTab(props: RecipeModifierTabProps) {
    const {
        tabValue,
        ingredients,
        menuItems,
        setMenuItems,
        setMenuItemsEgress,
        activeItem,
        activeItemModifier,
        handleOuterRecipeAccordianChange,
        handleMiddleRecipeAccordianChange,
        modifierIngredients,
        modifierIngredientsOnLoad,
        modifierIngredientsArr,
        modifierIngredientsArrOnLoad,
        setModifierIngredients,
        setModifierIngredientsOnLoad,
        setModifierIngredientsArr,
        setModifierIngredientsArrOnLoad,
        setModifierIngredientsArrMiddleLoad,
        idToIdx,
        modifier_object,
        setActiveItem,
        setActiveItemModifier,
        setActiveItemModifierOption,
        setTabValue,
        setOpenDialog,
        selectedModIng,
        setSelectedModIng,
    } = props;

    console.log('modifier_object: ', modifier_object);
    console.log('modifierIngredients: ', modifierIngredients);
    console.log('modifierIngredientsOnLoad: ', modifierIngredientsOnLoad);
    console.log('modifierIngredientsArr: ', modifierIngredientsArr);

    const dispatch = useAppDispatch();
    const setToastState = (toastContext: ToastInterface) => {
        dispatch(setToastContext(toastContext));
    };

    const saveChanges = (newModifierItems: any, newModifierItemsArr: any) => {
        let menuItemsOriginal = JSON.parse(JSON.stringify(menuItems));
        let modifierIngredientsOriginal = JSON.parse(
            JSON.stringify(newModifierItems)
        );
        let modifierIngredientsArrOriginal = JSON.parse(
            JSON.stringify(newModifierItemsArr)
        );
        let menuItemsCopy = JSON.parse(JSON.stringify(menuItems));
        newModifierItemsArr.forEach((menuItemList: string[], idx: number) => {
            if (menuItemList.length === 0) {
                return;
            }
            menuItemList.forEach((menuItem: string) => {
                if (activeItemModifier !== '') {
                    menuItemsCopy[menuItem].level_2[activeItem].level_3[
                        activeItemModifier
                    ].ingredients_uuid = Object.keys(newModifierItems[idx]);
                    menuItemsCopy[menuItem].level_2[activeItem].level_3[
                        activeItemModifier
                    ].qty = Object.values(newModifierItems[idx]);
                } else {
                    menuItemsCopy[menuItem].level_2[
                        activeItem
                    ].ingredients_uuid = Object.keys(newModifierItems[idx]);
                    menuItemsCopy[menuItem].level_2[activeItem].qty =
                        Object.values(newModifierItems[idx]);
                }
            });
        });
        // TODO: Update to optimize for only impacted menu items instead of all
        setModifierIngredientsOnLoad(
            JSON.parse(JSON.stringify(newModifierItems))
        );
        setModifierIngredientsArrOnLoad(
            JSON.parse(JSON.stringify(newModifierItemsArr))
        );
        setMenuItems(JSON.parse(JSON.stringify(menuItemsCopy)));
        setMenuItemsEgress(JSON.parse(JSON.stringify(menuItemsCopy)));
        updateMenuItems({
            id: idToIdx,
            menuItems: JSON.parse(JSON.stringify(menuItemsCopy)),
        })
            .then(() => {
                setToastState({
                    toastMessage: 'Updated modifier group!',
                    toastType: 'success',
                });
            })
            .catch((err) => {
                console.error(err);

                setModifierIngredientsOnLoad(
                    JSON.parse(JSON.stringify(modifierIngredientsOriginal))
                );
                setModifierIngredientsArrOnLoad(
                    JSON.parse(JSON.stringify(modifierIngredientsArrOriginal))
                );
                setMenuItems(JSON.parse(JSON.stringify(menuItemsOriginal)));
                setMenuItemsEgress(
                    JSON.parse(JSON.stringify(menuItemsOriginal))
                );
                setToastState({
                    toastMessage: 'Failed to update modifier group.',
                    toastType: 'error',
                });
                return;
            });
    };

    return (
        <Box
            width="100%"
            display="flex"
            sx={{
                flexDirection: 'row',
                alignItems: 'stretch',
            }}
        >
            <Box
                width="400px"
                maxHeight="calc(100% - 49px)"
                sx={{
                    borderRight: 1,
                    overflowY: 'scroll',
                    borderColor: 'rgba(0, 0, 0, 0.12)',
                }}
            >
                {Object.keys(modifier_object).map((modifier_group, idx) => {
                    return (
                        <Accordion
                            expanded={activeItem === modifier_group}
                            onChange={handleOuterRecipeAccordianChange(
                                modifier_group
                            )}
                            key={idx}
                            sx={{
                                '&.Mui-expanded': {
                                    marginTop: '1px',
                                    marginBottom: '1px',
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                sx={{
                                    borderLeft: `4px solid ${
                                        colors[idx % colors.length]
                                    }`,
                                    backgroundColor:
                                        activeItem === modifier_group &&
                                        !!!activeItemModifier
                                            ? '#D8D8D8'
                                            : '#FFF',
                                }}
                            >
                                <Typography
                                    sx={{
                                        flexShrink: 0,
                                        paddingLeft: '4px',
                                    }}
                                >
                                    {modifier_group}
                                </Typography>
                            </AccordionSummary>
                            <Divider />
                            <AccordionDetails sx={{ padding: 0 }}>
                                {Object.keys(
                                    modifier_object[modifier_group].Options
                                ).length > 0 && (
                                    <Box
                                        display="flex"
                                        sx={{
                                            flexDirection: 'column',
                                            marginLeft: 4,
                                        }}
                                    >
                                        {Object.keys(
                                            modifier_object[modifier_group]
                                                .Options
                                        ).map(
                                            (
                                                modifier_option: any,
                                                idx2: number
                                            ) => {
                                                return (
                                                    <Accordion
                                                        expanded={
                                                            activeItemModifier ===
                                                            modifier_option
                                                        }
                                                        onChange={handleMiddleRecipeAccordianChange(
                                                            modifier_option
                                                        )}
                                                        key={idx2}
                                                        sx={{
                                                            '&.Mui-expanded': {
                                                                marginTop:
                                                                    '1px',
                                                                marginBottom:
                                                                    '1px',
                                                            },
                                                        }}
                                                    >
                                                        <AccordionSummary
                                                            aria-controls="panel1bh-content"
                                                            id="panel1bh-header"
                                                            sx={{
                                                                borderLeft: `4px solid ${
                                                                    colors[
                                                                        idx %
                                                                            colors.length
                                                                    ]
                                                                }`,
                                                                backgroundColor:
                                                                    activeItem ===
                                                                        modifier_group &&
                                                                    activeItemModifier ===
                                                                        modifier_option
                                                                        ? '#D8D8D8'
                                                                        : '#FFF',
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    flexShrink: 0,
                                                                    paddingLeft:
                                                                        '4px',
                                                                }}
                                                            >
                                                                {
                                                                    modifier_option
                                                                }
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <Divider />
                                                        <AccordionDetails
                                                            sx={{
                                                                padding: 0,
                                                            }}
                                                        ></AccordionDetails>
                                                    </Accordion>
                                                );
                                            }
                                        )}
                                    </Box>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </Box>

            <Box
                width="calc(100% - 400px)"
                maxHeight="calc(100% - 49px)"
                sx={{ overflowY: 'scroll' }}
            >
                {activeItem === '' || tabValue !== 3 ? (
                    <Typography
                        textAlign="center"
                        marginTop="40px"
                    >
                        Please select a POS Item Modifier.
                    </Typography>
                ) : (
                    <Box>
                        <Box
                            height="calc(100% - 64px)"
                            sx={{
                                minWidth: 120,
                                padding: 4,
                                overflowY: 'auto',
                            }}
                        >
                            <Box
                                display="flex"
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    gap: 1,
                                    marginBottom: 3,
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: '24px',
                                        fontWeight: '700',
                                    }}
                                >
                                    {!!activeItemModifier
                                        ? activeItemModifier
                                        : activeItem}
                                </Typography>
                                <EditRoundedIcon
                                    onClick={() => {
                                        setModifierIngredientsOnLoad(
                                            modifierIngredients
                                        );
                                        setModifierIngredientsArrMiddleLoad(
                                            modifierIngredientsArr
                                        );
                                        setOpenDialog(true);
                                    }}
                                />
                            </Box>
                            {modifierIngredients.map(
                                (
                                    subModifierIngredients: any,
                                    idx_list: number
                                ) => {
                                    if (
                                        modifierIngredientsArr[idx_list]
                                            .length === 0
                                    ) {
                                        return null;
                                    }
                                    return idx_list === 0 ? (
                                        <Paper
                                            elevation={idx_list === 0 ? 5 : 0}
                                            sx={{ padding: 1 }}
                                        >
                                            {idx_list !== 0 &&
                                                !(
                                                    idx_list === 1 &&
                                                    modifierIngredientsArr[0]
                                                        .length === 0
                                                ) && (
                                                    <Divider
                                                        sx={{
                                                            borderBottomWidth: 2,
                                                            mb: 2,
                                                        }}
                                                    />
                                                )}

                                            <Typography
                                                sx={{
                                                    fontSize: '18px',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                {idx_list === 0
                                                    ? 'Add to list'
                                                    : `List ${idx_list}`}
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                useFlexGap
                                                flexWrap="wrap"
                                                sx={{
                                                    paddingY: 1,
                                                }}
                                            >
                                                {idx_list === 0 && (
                                                    <Chip
                                                        key={'all'}
                                                        label={
                                                            modifierIngredientsArr[
                                                                idx_list
                                                            ].length ===
                                                            selectedModIng.length
                                                                ? 'Deselect All'
                                                                : 'Select All'
                                                        }
                                                        variant="outlined"
                                                        icon={
                                                            modifierIngredientsArr[
                                                                idx_list
                                                            ].length ===
                                                            selectedModIng.length ? (
                                                                <CheckCircleRoundedIcon />
                                                            ) : (
                                                                <RadioButtonUncheckedRoundedIcon />
                                                            )
                                                        }
                                                        onClick={() => {
                                                            setSelectedModIng(
                                                                modifierIngredientsArr[
                                                                    idx_list
                                                                ].length ===
                                                                    selectedModIng.length
                                                                    ? []
                                                                    : JSON.parse(
                                                                          JSON.stringify(
                                                                              modifierIngredientsArr[
                                                                                  idx_list
                                                                              ]
                                                                          )
                                                                      )
                                                            );
                                                        }}
                                                        sx={{
                                                            borderRadius: '4px',
                                                        }}
                                                    />
                                                )}
                                                {modifierIngredientsArr[
                                                    idx_list
                                                ].map((posNavChip) => {
                                                    let label = posNavChip;
                                                    return (
                                                        <Chip
                                                            key={label}
                                                            label={label}
                                                            variant="outlined"
                                                            icon={
                                                                idx_list ===
                                                                0 ? (
                                                                    selectedModIng.includes(
                                                                        label
                                                                    ) ? (
                                                                        <CheckCircleRoundedIcon />
                                                                    ) : (
                                                                        <RadioButtonUncheckedRoundedIcon />
                                                                    )
                                                                ) : undefined
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    idx_list ===
                                                                    0
                                                                ) {
                                                                    let selectedModIngCopy =
                                                                        JSON.parse(
                                                                            JSON.stringify(
                                                                                selectedModIng
                                                                            )
                                                                        );
                                                                    if (
                                                                        selectedModIngCopy.includes(
                                                                            label
                                                                        )
                                                                    ) {
                                                                        selectedModIngCopy =
                                                                            selectedModIngCopy.filter(
                                                                                (
                                                                                    ing: string
                                                                                ) =>
                                                                                    ing !==
                                                                                    label
                                                                            );
                                                                    } else {
                                                                        selectedModIngCopy.push(
                                                                            label
                                                                        );
                                                                    }
                                                                    setSelectedModIng(
                                                                        selectedModIngCopy
                                                                    );
                                                                } else {
                                                                    setActiveItem(
                                                                        posNavChip
                                                                    );
                                                                    setActiveItemModifier(
                                                                        activeItem
                                                                    );
                                                                    if (
                                                                        activeItemModifier !==
                                                                        ''
                                                                    ) {
                                                                        setActiveItemModifierOption(
                                                                            activeItemModifier
                                                                        );
                                                                    }
                                                                    setTabValue(
                                                                        1
                                                                    );
                                                                }
                                                            }}
                                                            onDelete={
                                                                idx_list === 0
                                                                    ? undefined
                                                                    : () => {}
                                                            }
                                                        />
                                                    );
                                                })}
                                            </Stack>
                                            {subModifierIngredients &&
                                                Object.keys(
                                                    subModifierIngredients
                                                ).map(
                                                    (
                                                        ingredient_uuid: any,
                                                        idx: any
                                                    ) => {
                                                        console.log(
                                                            subModifierIngredients
                                                        );
                                                        return (
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    alignItems:
                                                                        'center',
                                                                }}
                                                                key={idx}
                                                            >
                                                                <FormGroup
                                                                    aria-label="position"
                                                                    row
                                                                >
                                                                    <FormControl
                                                                        sx={{
                                                                            marginBottom: 2,
                                                                            marginRight: 2,
                                                                            width: '200px',
                                                                        }}
                                                                        disabled={
                                                                            Object.entries(
                                                                                ingredients
                                                                            ).find(
                                                                                ([
                                                                                    _,
                                                                                    value,
                                                                                ]) =>
                                                                                    value.uuid ===
                                                                                    ingredient_uuid
                                                                            )![1]
                                                                                .category ===
                                                                            'Menu Items'
                                                                        }
                                                                    >
                                                                        <InputLabel id="demo-simple-select-label">
                                                                            Forecastable
                                                                            Item
                                                                        </InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            value={
                                                                                Object.entries(
                                                                                    ingredients
                                                                                ).find(
                                                                                    ([
                                                                                        _,
                                                                                        value,
                                                                                    ]) =>
                                                                                        value.uuid ===
                                                                                        ingredient_uuid
                                                                                )![0]
                                                                            }
                                                                            label="Forecastable Item"
                                                                            onChange={(
                                                                                event
                                                                            ) => {
                                                                                let newModifierItems =
                                                                                    JSON.parse(
                                                                                        JSON.stringify(
                                                                                            modifierIngredients
                                                                                        )
                                                                                    );

                                                                                delete newModifierItems[
                                                                                    idx_list
                                                                                ][
                                                                                    Object.keys(
                                                                                        subModifierIngredients
                                                                                    )[
                                                                                        idx
                                                                                    ]
                                                                                ];

                                                                                newModifierItems[
                                                                                    idx_list
                                                                                ][
                                                                                    ingredients[
                                                                                        event.target.value
                                                                                    ].uuid
                                                                                ] = 0;
                                                                                setModifierIngredients(
                                                                                    newModifierItems
                                                                                );
                                                                            }}
                                                                        >
                                                                            {Object.entries(
                                                                                ingredients
                                                                            )
                                                                                .filter(
                                                                                    function ([
                                                                                        key,
                                                                                        _,
                                                                                    ]) {
                                                                                        return (
                                                                                            ingredients[
                                                                                                key
                                                                                            ] &&
                                                                                            ingredients[
                                                                                                key
                                                                                            ]
                                                                                                .category !==
                                                                                                'Menu Items' &&
                                                                                            (!Object.keys(
                                                                                                subModifierIngredients
                                                                                            ).includes(
                                                                                                ingredients[
                                                                                                    key
                                                                                                ]
                                                                                                    .uuid
                                                                                            ) ||
                                                                                                ingredients[
                                                                                                    key
                                                                                                ]
                                                                                                    .uuid ===
                                                                                                    ingredient_uuid)
                                                                                        );
                                                                                    }
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        [
                                                                                            key,
                                                                                            _,
                                                                                        ],
                                                                                        idx
                                                                                    ) => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                value={
                                                                                                    key
                                                                                                }
                                                                                                key={
                                                                                                    idx
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    key
                                                                                                }
                                                                                            </MenuItem>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                        </Select>
                                                                    </FormControl>

                                                                    <FormControl
                                                                        variant="standard"
                                                                        sx={{
                                                                            marginRight: 2,
                                                                        }}
                                                                        disabled={
                                                                            Object.entries(
                                                                                ingredients
                                                                            ).find(
                                                                                ([
                                                                                    _,
                                                                                    value,
                                                                                ]) =>
                                                                                    value.uuid ===
                                                                                    ingredient_uuid
                                                                            )![1]
                                                                                .category ===
                                                                            'Menu Items'
                                                                        }
                                                                    >
                                                                        {/* <InputLabel>
                                                                                {
                                                                                    Object.values(
                                                                                        ingredients
                                                                                    ).find(
                                                                                        (
                                                                                            value
                                                                                        ) =>
                                                                                            value.uuid ===
                                                                                            ingredient_uuid
                                                                                    )
                                                                                        .units
                                                                                }
                                                                            </InputLabel> */}
                                                                        <TextField
                                                                            value={
                                                                                subModifierIngredients[
                                                                                    ingredient_uuid
                                                                                ]
                                                                            }
                                                                            key={
                                                                                idx_list +
                                                                                'UnitInput'
                                                                            }
                                                                            label={
                                                                                Object.values(
                                                                                    ingredients
                                                                                ).find(
                                                                                    (
                                                                                        value
                                                                                    ) =>
                                                                                        value.uuid ===
                                                                                        ingredient_uuid
                                                                                )
                                                                                    .units
                                                                            }
                                                                            type="number"
                                                                            variant="standard"
                                                                            onChange={(
                                                                                event
                                                                            ) => {
                                                                                let newModifierItems =
                                                                                    JSON.parse(
                                                                                        JSON.stringify(
                                                                                            modifierIngredients
                                                                                        )
                                                                                    );
                                                                                newModifierItems[
                                                                                    idx_list
                                                                                ][
                                                                                    ingredient_uuid
                                                                                ] =
                                                                                    event.target.value;
                                                                                setModifierIngredients(
                                                                                    newModifierItems
                                                                                );
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </FormGroup>
                                                                <CloseRoundedIcon
                                                                    sx={{
                                                                        color:
                                                                            Object.entries(
                                                                                ingredients
                                                                            ).find(
                                                                                ([
                                                                                    _,
                                                                                    value,
                                                                                ]) =>
                                                                                    value.uuid ===
                                                                                    ingredient_uuid
                                                                            )![1]
                                                                                .category ===
                                                                            'Menu Items'
                                                                                ? 'rgba(0, 0, 0, 0.38)'
                                                                                : null,
                                                                    }}
                                                                    onClick={() => {
                                                                        let newModifierItems =
                                                                            JSON.parse(
                                                                                JSON.stringify(
                                                                                    modifierIngredients
                                                                                )
                                                                            );
                                                                        delete newModifierItems[
                                                                            idx_list
                                                                        ][
                                                                            Object.keys(
                                                                                subModifierIngredients
                                                                            )[
                                                                                idx
                                                                            ]
                                                                        ];
                                                                        setModifierIngredients(
                                                                            newModifierItems
                                                                        );
                                                                    }}
                                                                />
                                                            </Box>
                                                        );
                                                    }
                                                )}
                                            <Autocomplete
                                                disabled={
                                                    selectedModIng.length === 0
                                                }
                                                blurOnSelect={true}
                                                clearOnBlur={true}
                                                value={{
                                                    label: '',
                                                    value: '',
                                                }}
                                                onChange={(
                                                    event: any,
                                                    newValue: {
                                                        label: string;
                                                        value: string;
                                                    } | null
                                                ) => {
                                                    if (!newValue) return;
                                                    let newModifierItems =
                                                        JSON.parse(
                                                            JSON.stringify(
                                                                modifierIngredients
                                                            )
                                                        );
                                                    let newModifierItemsArr =
                                                        JSON.parse(
                                                            JSON.stringify(
                                                                modifierIngredientsArr
                                                            )
                                                        );
                                                    let mod_length =
                                                        newModifierItems.length;
                                                    if (idx_list !== 0) {
                                                        newModifierItems[
                                                            idx_list
                                                        ][newValue!.value] = 0;
                                                    } else {
                                                        newModifierItemsArr[
                                                            idx_list
                                                        ] = newModifierItemsArr[
                                                            idx_list
                                                        ].filter(
                                                            (ing: string) =>
                                                                !selectedModIng.includes(
                                                                    ing
                                                                )
                                                        );
                                                        newModifierItemsArr[
                                                            mod_length
                                                        ] = JSON.parse(
                                                            JSON.stringify(
                                                                selectedModIng
                                                            )
                                                        );
                                                        newModifierItems[
                                                            mod_length
                                                        ] = {};
                                                        newModifierItems[
                                                            mod_length
                                                        ][newValue!.value] = 0;
                                                        console.log(
                                                            'FLAG::TEST',
                                                            newModifierItemsArr,
                                                            newModifierItems
                                                        );
                                                    }
                                                    setModifierIngredients(
                                                        newModifierItems
                                                    );
                                                    setModifierIngredientsArr(
                                                        newModifierItemsArr
                                                    );
                                                    setSelectedModIng([]);
                                                    // Uncomment for autosave
                                                    // saveChanges(
                                                    //     newModifierItems,
                                                    //     newModifierItemsArr
                                                    // );
                                                }}
                                                options={Object.keys(
                                                    ingredients
                                                )
                                                    .filter((option) => {
                                                        return (
                                                            ingredients[
                                                                option
                                                            ] &&
                                                            ingredients[option]
                                                                .category !==
                                                                'Menu Items' &&
                                                            !Object.keys(
                                                                subModifierIngredients
                                                            ).includes(
                                                                ingredients[
                                                                    option
                                                                ].uuid
                                                            )
                                                        );
                                                    })
                                                    .map((option) => {
                                                        return {
                                                            label: option,
                                                            value: ingredients[
                                                                option
                                                            ].uuid,
                                                        };
                                                    })}
                                                fullWidth
                                                sx={{ mb: 2 }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Forecastable Item"
                                                    />
                                                )}
                                            />
                                        </Paper>
                                    ) : (
                                        <Box sx={{ padding: 1 }}>
                                            {idx_list !== 0 &&
                                                !(
                                                    idx_list === 1 &&
                                                    modifierIngredientsArr[0]
                                                        .length === 0
                                                ) && (
                                                    <Divider
                                                        sx={{
                                                            borderBottomWidth: 2,
                                                            mb: 2,
                                                        }}
                                                    />
                                                )}

                                            <Typography
                                                sx={{
                                                    fontSize: '18px',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                {idx_list === 0
                                                    ? 'Add to list'
                                                    : `List ${idx_list}`}
                                            </Typography>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                useFlexGap
                                                flexWrap="wrap"
                                                sx={{
                                                    paddingY: 1,
                                                }}
                                            >
                                                {modifierIngredientsArr[
                                                    idx_list
                                                ].map((posNavChip) => {
                                                    let label = posNavChip;
                                                    return (
                                                        <Chip
                                                            key={label}
                                                            label={label}
                                                            variant="outlined"
                                                            icon={
                                                                idx_list ===
                                                                0 ? (
                                                                    selectedModIng.includes(
                                                                        label
                                                                    ) ? (
                                                                        <CheckCircleRoundedIcon />
                                                                    ) : (
                                                                        <RadioButtonUncheckedRoundedIcon />
                                                                    )
                                                                ) : undefined
                                                            }
                                                            onClick={() => {
                                                                if (
                                                                    idx_list ===
                                                                    0
                                                                ) {
                                                                    let selectedModIngCopy =
                                                                        JSON.parse(
                                                                            JSON.stringify(
                                                                                selectedModIng
                                                                            )
                                                                        );
                                                                    if (
                                                                        selectedModIngCopy.includes(
                                                                            label
                                                                        )
                                                                    ) {
                                                                        selectedModIngCopy =
                                                                            selectedModIngCopy.filter(
                                                                                (
                                                                                    ing: string
                                                                                ) =>
                                                                                    ing !==
                                                                                    label
                                                                            );
                                                                    } else {
                                                                        selectedModIngCopy.push(
                                                                            label
                                                                        );
                                                                    }
                                                                    setSelectedModIng(
                                                                        selectedModIngCopy
                                                                    );
                                                                } else {
                                                                    setActiveItem(
                                                                        posNavChip
                                                                    );
                                                                    setActiveItemModifier(
                                                                        activeItem
                                                                    );
                                                                    if (
                                                                        activeItemModifier !==
                                                                        ''
                                                                    ) {
                                                                        setActiveItemModifierOption(
                                                                            activeItemModifier
                                                                        );
                                                                    }
                                                                    setTabValue(
                                                                        1
                                                                    );
                                                                }
                                                            }}
                                                            onDelete={
                                                                idx_list === 0
                                                                    ? undefined
                                                                    : () => {
                                                                          let newModifierItemsArr =
                                                                              JSON.parse(
                                                                                  JSON.stringify(
                                                                                      modifierIngredientsArr
                                                                                  )
                                                                              );
                                                                          newModifierItemsArr[0].push(
                                                                              label
                                                                          );
                                                                          newModifierItemsArr[
                                                                              idx_list
                                                                          ] =
                                                                              newModifierItemsArr[
                                                                                  idx_list
                                                                              ].filter(
                                                                                  (
                                                                                      item: string
                                                                                  ) =>
                                                                                      item !==
                                                                                      label
                                                                              );
                                                                          setModifierIngredientsArr(
                                                                              newModifierItemsArr
                                                                          );
                                                                      }
                                                            }
                                                        />
                                                    );
                                                })}
                                            </Stack>
                                            {subModifierIngredients &&
                                                Object.keys(
                                                    subModifierIngredients
                                                ).map(
                                                    (
                                                        ingredient_uuid: any,
                                                        idx: any
                                                    ) => {
                                                        console.log(
                                                            subModifierIngredients
                                                        );
                                                        return (
                                                            <Box
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'row',
                                                                    alignItems:
                                                                        'center',
                                                                }}
                                                                key={idx}
                                                            >
                                                                <FormGroup
                                                                    aria-label="position"
                                                                    row
                                                                >
                                                                    <FormControl
                                                                        sx={{
                                                                            marginBottom: 2,
                                                                            marginRight: 2,
                                                                            width: '200px',
                                                                        }}
                                                                        disabled={
                                                                            Object.entries(
                                                                                ingredients
                                                                            ).find(
                                                                                ([
                                                                                    _,
                                                                                    value,
                                                                                ]) =>
                                                                                    value.uuid ===
                                                                                    ingredient_uuid
                                                                            )![1]
                                                                                .category ===
                                                                            'Menu Items'
                                                                        }
                                                                    >
                                                                        <InputLabel id="demo-simple-select-label">
                                                                            Forecastable
                                                                            Item
                                                                        </InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select"
                                                                            value={
                                                                                Object.entries(
                                                                                    ingredients
                                                                                ).find(
                                                                                    ([
                                                                                        _,
                                                                                        value,
                                                                                    ]) =>
                                                                                        value.uuid ===
                                                                                        ingredient_uuid
                                                                                )![0]
                                                                            }
                                                                            label="Forecastable Item"
                                                                            onChange={(
                                                                                event
                                                                            ) => {
                                                                                let newModifierItems =
                                                                                    JSON.parse(
                                                                                        JSON.stringify(
                                                                                            modifierIngredients
                                                                                        )
                                                                                    );

                                                                                delete newModifierItems[
                                                                                    idx_list
                                                                                ][
                                                                                    Object.keys(
                                                                                        subModifierIngredients
                                                                                    )[
                                                                                        idx
                                                                                    ]
                                                                                ];

                                                                                newModifierItems[
                                                                                    idx_list
                                                                                ][
                                                                                    ingredients[
                                                                                        event.target.value
                                                                                    ].uuid
                                                                                ] = 0;
                                                                                setModifierIngredients(
                                                                                    newModifierItems
                                                                                );
                                                                            }}
                                                                        >
                                                                            {Object.entries(
                                                                                ingredients
                                                                            )
                                                                                .filter(
                                                                                    function ([
                                                                                        key,
                                                                                        _,
                                                                                    ]) {
                                                                                        return (
                                                                                            ingredients[
                                                                                                key
                                                                                            ] &&
                                                                                            ingredients[
                                                                                                key
                                                                                            ]
                                                                                                .category !==
                                                                                                'Menu Items' &&
                                                                                            (!Object.keys(
                                                                                                subModifierIngredients
                                                                                            ).includes(
                                                                                                ingredients[
                                                                                                    key
                                                                                                ]
                                                                                                    .uuid
                                                                                            ) ||
                                                                                                ingredients[
                                                                                                    key
                                                                                                ]
                                                                                                    .uuid ===
                                                                                                    ingredient_uuid)
                                                                                        );
                                                                                    }
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        [
                                                                                            key,
                                                                                            _,
                                                                                        ],
                                                                                        idx
                                                                                    ) => {
                                                                                        return (
                                                                                            <MenuItem
                                                                                                value={
                                                                                                    key
                                                                                                }
                                                                                                key={
                                                                                                    idx
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    key
                                                                                                }
                                                                                            </MenuItem>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                        </Select>
                                                                    </FormControl>

                                                                    <FormControl
                                                                        variant="standard"
                                                                        sx={{
                                                                            marginRight: 2,
                                                                        }}
                                                                        disabled={
                                                                            Object.entries(
                                                                                ingredients
                                                                            ).find(
                                                                                ([
                                                                                    _,
                                                                                    value,
                                                                                ]) =>
                                                                                    value.uuid ===
                                                                                    ingredient_uuid
                                                                            )![1]
                                                                                .category ===
                                                                            'Menu Items'
                                                                        }
                                                                    >
                                                                        <TextField
                                                                            value={
                                                                                subModifierIngredients[
                                                                                    ingredient_uuid
                                                                                ]
                                                                            }
                                                                            key={
                                                                                idx_list +
                                                                                'UnitInput'
                                                                            }
                                                                            label={
                                                                                Object.values(
                                                                                    ingredients
                                                                                ).find(
                                                                                    (
                                                                                        value
                                                                                    ) =>
                                                                                        value.uuid ===
                                                                                        ingredient_uuid
                                                                                )
                                                                                    .units
                                                                            }
                                                                            type="number"
                                                                            variant="standard"
                                                                            onChange={(
                                                                                event
                                                                            ) => {
                                                                                let newModifierItems =
                                                                                    JSON.parse(
                                                                                        JSON.stringify(
                                                                                            modifierIngredients
                                                                                        )
                                                                                    );
                                                                                newModifierItems[
                                                                                    idx_list
                                                                                ][
                                                                                    ingredient_uuid
                                                                                ] =
                                                                                    event.target.value;
                                                                                setModifierIngredients(
                                                                                    newModifierItems
                                                                                );
                                                                            }}
                                                                        />
                                                                    </FormControl>
                                                                </FormGroup>
                                                                <CloseRoundedIcon
                                                                    sx={{
                                                                        color:
                                                                            Object.entries(
                                                                                ingredients
                                                                            ).find(
                                                                                ([
                                                                                    _,
                                                                                    value,
                                                                                ]) =>
                                                                                    value.uuid ===
                                                                                    ingredient_uuid
                                                                            )![1]
                                                                                .category ===
                                                                            'Menu Items'
                                                                                ? 'rgba(0, 0, 0, 0.38)'
                                                                                : null,
                                                                    }}
                                                                    onClick={() => {
                                                                        let newModifierItems =
                                                                            JSON.parse(
                                                                                JSON.stringify(
                                                                                    modifierIngredients
                                                                                )
                                                                            );
                                                                        delete newModifierItems[
                                                                            idx_list
                                                                        ][
                                                                            Object.keys(
                                                                                subModifierIngredients
                                                                            )[
                                                                                idx
                                                                            ]
                                                                        ];
                                                                        setModifierIngredients(
                                                                            newModifierItems
                                                                        );
                                                                    }}
                                                                />
                                                            </Box>
                                                        );
                                                    }
                                                )}
                                            <Autocomplete
                                                blurOnSelect={true}
                                                clearOnBlur={true}
                                                value={{
                                                    label: '',
                                                    value: '',
                                                }}
                                                onChange={(
                                                    event: any,
                                                    newValue: {
                                                        label: string;
                                                        value: string;
                                                    } | null
                                                ) => {
                                                    if (!newValue) return;
                                                    let newModifierItems =
                                                        JSON.parse(
                                                            JSON.stringify(
                                                                modifierIngredients
                                                            )
                                                        );
                                                    newModifierItems[idx_list][
                                                        newValue!.value
                                                    ] = 0;
                                                    setModifierIngredients(
                                                        newModifierItems
                                                    );
                                                    // Uncomment for autosave
                                                    // saveChanges(
                                                    //     newModifierItems,
                                                    //     modifierIngredientsArr
                                                    // );
                                                }}
                                                options={Object.keys(
                                                    ingredients
                                                )
                                                    .filter((option) => {
                                                        return (
                                                            ingredients[
                                                                option
                                                            ] &&
                                                            ingredients[option]
                                                                .category !==
                                                                'Menu Items' &&
                                                            !Object.keys(
                                                                subModifierIngredients
                                                            ).includes(
                                                                ingredients[
                                                                    option
                                                                ].uuid
                                                            )
                                                        );
                                                    })
                                                    .map((option) => {
                                                        return {
                                                            label: option,
                                                            value: ingredients[
                                                                option
                                                            ].uuid,
                                                        };
                                                    })}
                                                fullWidth
                                                sx={{ mb: 2 }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Forecastable Item"
                                                    />
                                                )}
                                            />
                                        </Box>
                                    );
                                }
                            )}
                            <Button
                                onClick={() => {
                                    saveChanges(
                                        modifierIngredients,
                                        modifierIngredientsArr
                                    );
                                }}
                                disabled={
                                    isEqual(
                                        modifierIngredientsOnLoad,
                                        modifierIngredients
                                    ) &&
                                    isEqual(
                                        modifierIngredientsArrOnLoad,
                                        modifierIngredientsArr
                                    )
                                }
                            >
                                Save
                            </Button>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
}
