import { EditEntryPopup } from './EditEntryPopup';
import { EditSection } from './EditSection';
import { EntryMapper } from './EntryMapper';
import { FinishEntry } from './FinishEntry';
import { Frequency } from './Frequency';
import { NameEntry } from './NameEntry';
import { NewSection } from './NewSection';
import { PrepSchedule } from './PrepSchedule';
import { PrepTime } from './PrepTime';
import { Scheduler } from './Scheduler';
import { ShelfLife } from './ShelfLife';
import { SideSummary } from './SideSummary';
import { UnitConversion } from './UnitConversion';
import { Units } from './Units';
import { Yield } from './Yield';

const preplistDialogs = {
    EditEntryPopup: EditEntryPopup,
    FinishEntry: FinishEntry,
    Frequency: Frequency,
    NameEntry: NameEntry,
    NewSection: NewSection,
    EditSection: EditSection,
    SideSummary: SideSummary,
    Units: Units,
    Scheduler: Scheduler,
    PrepSchedule: PrepSchedule,
    UnitConversion: UnitConversion,
    Yield: Yield,
    ShelfLife: ShelfLife,
    EntryMapper: EntryMapper,
    PrepTime: PrepTime,
};

export default preplistDialogs;
