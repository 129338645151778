import React from 'react';

import Typography from '@mui/material/Typography';

import {
    BoxTabMainCanvas,
    BoxTabPage,
} from '../../../../components/StyledMUI/StyledBox';
import { useAppSelector } from '../../../../redux/hooks';
import { selectSelectedPrepList } from '../../../../redux/reducers/recipes/recipesSelectors';
import { PrepListTabProps } from './IPrepListTab';
import { PrepListSideBar } from './PrepListSideBar';
import { PrepListWorkspace } from './PrepListWorkspace';

/**
 * PrepListTab component displays the preparation list tab in the Recipes view.
 * It renders the sidebar, main canvas, and either a message to select a sheet or the prep list workspace.
 */
export const PrepListTab: React.FC<PrepListTabProps> = () => {
    const selectedPrepList = useAppSelector(selectSelectedPrepList);
    const prepListSelected = selectedPrepList !== '';

    return (
        <BoxTabPage>
            <PrepListSideBar />
            <BoxTabMainCanvas>
                {!prepListSelected ? (
                    <Typography variant="emptyTab">
                        Please select a sheet.
                    </Typography>
                ) : (
                    <PrepListWorkspace />
                )}
            </BoxTabMainCanvas>
        </BoxTabPage>
    );
};
