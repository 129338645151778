import React from 'react';

import isEqual from 'lodash.isequal';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ingredientsInterface } from '../../components/Recipes';

function not(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly string[], b: readonly string[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function MultiTransferList({
    lists,
    setLists,
}: {
    lists: string[][];
    setLists: React.Dispatch<React.SetStateAction<string[][]>>;
}) {
    console.log(lists);
    const [checked, setChecked] = React.useState<readonly string[]>([]);
    const [left, setLeft] = React.useState<readonly string[]>(
        (lists[0].length ? lists[0] : lists[1]) ?? []
    );
    const [right, setRight] = React.useState<readonly string[]>(
        (lists[0].length ? lists[1] : lists[0]) ?? []
    );
    const [leftIdx, setLeftIdx] = React.useState<string>(
        lists[0].length ? '0' : '1'
    );
    const [rightIdx, setRightIdx] = React.useState<string>(
        lists[0].length ? '1' : '0'
    );

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value: string) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
        let tempLists = JSON.parse(JSON.stringify(lists));
        tempLists[Number(leftIdx)] = [];
        tempLists[Number(rightIdx)] = right.concat(left);
        setLists(tempLists);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
        let tempLists = JSON.parse(JSON.stringify(lists));
        tempLists[Number(leftIdx)] = not(left, leftChecked);
        tempLists[Number(rightIdx)] = right.concat(leftChecked);
        setLists(tempLists);
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
        let tempLists = JSON.parse(JSON.stringify(lists));
        tempLists[Number(leftIdx)] = left.concat(rightChecked);
        tempLists[Number(rightIdx)] = not(right, rightChecked);
        setLists(tempLists);
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
        let tempLists = JSON.parse(JSON.stringify(lists));
        tempLists[Number(leftIdx)] = left.concat(right);
        tempLists[Number(rightIdx)] = [];
        setLists(tempLists);
    };

    const customList = (items: readonly string[]) => (
        <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
            <List
                dense
                component="div"
                role="list"
            >
                {items.map((value: string) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={value}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );

    return (
        <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
        >
            <Grid item>
                <FormControl
                    fullWidth
                    sx={{ mb: 1 }}
                >
                    <InputLabel id="demo-simple-select-label">
                        List A
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="List A"
                        value={leftIdx}
                        onChange={(event: SelectChangeEvent) => {
                            setLeftIdx(event.target.value);
                            setLeft(lists[Number(event.target.value)] ?? []);
                        }}
                    >
                        {lists.map((_, idx) => {
                            let listName =
                                idx === 0 ? 'Unmodified' : `List ${idx}`;

                            return (
                                <MenuItem
                                    value={idx}
                                    disabled={Number(rightIdx) === idx}
                                >
                                    {listName}
                                </MenuItem>
                            );
                        })}
                        <MenuItem
                            value={lists.length}
                            disabled={Number(rightIdx) === lists.length}
                        >
                            New List
                        </MenuItem>
                    </Select>
                </FormControl>
                {customList(left)}
            </Grid>
            <Grid item>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                >
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <FormControl
                    fullWidth
                    sx={{ mb: 1 }}
                >
                    <InputLabel id="demo-simple-select-label">
                        List B
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="List B"
                        value={rightIdx}
                        onChange={(event: SelectChangeEvent) => {
                            setRightIdx(event.target.value);
                            setRight(lists[Number(event.target.value)] ?? []);
                        }}
                    >
                        {lists.map((_, idx) => {
                            let listName =
                                idx === 0 ? 'Unmodified' : `List ${idx}`;

                            return (
                                <MenuItem
                                    value={idx}
                                    disabled={Number(leftIdx) === idx}
                                >
                                    {listName}
                                </MenuItem>
                            );
                        })}
                        <MenuItem
                            value={lists.length}
                            disabled={Number(leftIdx) === lists.length}
                        >
                            New List
                        </MenuItem>
                    </Select>
                </FormControl>
                {customList(right)}
            </Grid>
        </Grid>
    );
}

export function DialogModifyModList(
    openDialog: boolean,
    dialogState: number,
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>,
    editName: string,
    handleNameChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void,
    ingredients: ingredientsInterface,
    ingredientsEgress: ingredientsInterface,
    activeItem: string,
    handleUnitChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ingredient: string
    ) => void,
    handleSelectCategoryChange: (
        event: SelectChangeEvent,
        ingredient: string
    ) => void,
    category: any,
    categories: string[],
    handleIngredientDeletion: () => void,
    handleEgressDemotion: () => void,
    handleEgressPromotion: () => void,
    duplicateError: boolean,
    setDuplicateError: (duplicateError: boolean) => void,
    modifierIngredientsArr: string[][],
    modifierIngredientsArrOnLoad: string[][],
    modifierIngredientsArrMiddleLoad: string[][],
    modifierIngredients: any | any[],
    setModifierIngredientsArr: React.Dispatch<React.SetStateAction<string[][]>>,
    setModifierIngredientsArrOnLoad: React.Dispatch<
        React.SetStateAction<string[][]>
    >,
    setModifierIngredientsArrMiddleLoad: React.Dispatch<
        React.SetStateAction<string[][]>
    >,
    setModifierIngredients: React.Dispatch<React.SetStateAction<any | any[]>>
): React.ReactNode {
    return (
        <Dialog
            open={openDialog && dialogState === 1}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick') return;
                setDuplicateError(false);
                setOpenDialog(false);
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {'Edit Forecastable Item'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    marginBottom={2}
                >
                    Adjust your modifier groupings.
                </DialogContentText>
                <MultiTransferList
                    lists={modifierIngredientsArr}
                    setLists={setModifierIngredientsArr}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setModifierIngredientsArr(
                            modifierIngredientsArrMiddleLoad
                        );
                        setOpenDialog(false);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        setModifierIngredientsArrMiddleLoad(
                            modifierIngredientsArr
                        );
                        if (
                            modifierIngredients.length <
                            modifierIngredientsArr.length
                        ) {
                            let newModIng = modifierIngredients.concat(
                                Array.apply(
                                    null,
                                    Array(
                                        modifierIngredientsArr.length -
                                            modifierIngredients.length
                                    )
                                ).map(() => {
                                    return {};
                                })
                            );
                            setModifierIngredients(newModIng);
                        }
                        setOpenDialog(false);
                    }}
                    disabled={isEqual(
                        modifierIngredientsArr,
                        modifierIngredientsArrOnLoad
                    )}
                    autoFocus
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
