import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { Dayjs, isDayjs } from 'dayjs';

import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import KeyboardReturnRoundedIcon from '@mui/icons-material/KeyboardReturnRounded';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { getUsage } from '../../../api';
import { default_data } from '../../../constants/graph_data';
import {
    generate_lvl1_stats,
    generate_lvl2_stats,
} from '../../../constants/pie_data';

import { ResponsivePie } from '@nivo/pie';

/*function getWeekNumber(d: any) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart: any = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
}
const CustomSymbol = ({ size, color, borderWidth, borderColor }: any) => (
    <g>
        <circle
            fill="#fff"
            r={size / 2}
            strokeWidth={borderWidth}
            stroke={borderColor}
        />
        <circle
            r={size / 5}
            strokeWidth={borderWidth}
            stroke={borderColor}
            fill={color}
            fillOpacity={0.35}
        />
    </g>
);*/

interface dashboardInterface {
    concept: string;
    store: string;
}

export function Dashboard({ concept, store }: dashboardInterface) {
    const currentDate = new Date('2021-09-23'); //remove
    //const currentDate = new Date();

    let startFilterDate = new Date(currentDate);
    startFilterDate.setMonth(startFilterDate.getMonth() - 1);

    const possibleItems = default_data.map((obj) => obj.id);

    const [filterDate, setFilterDate] = useState(startFilterDate);

    const [filter, setFilter] = useState(4);

    const [checkedItems, setCheckedItems] = useState<String[]>(possibleItems);

    //const [dashType /*, setDashType*/] = useState<String>('pie');

    const [selectedCategory, setSelectedCategory] = useState<String | false>(
        false
    );

    const [gatheredDays, setGatheredDays] = useState<String[]>([]);
    const [requestedDays, setRequestedDays] = useState<Number | null>(null);

    const todaysDate = dayjs();
    const yesterdaysDate = todaysDate.add(-1, 'day');

    const [startDate, setStartDate] = useState<Dayjs | null>(yesterdaysDate);
    const [endDate, setEndDate] = useState<Dayjs | null>(yesterdaysDate);

    const [startAngle1, setStartAngle1] = useState<number>(0);
    const [endAngle1, setEndAngle1] = useState<number>(360);
    const [startAngle2, setStartAngle2] = useState<number>(0);
    const [endAngle2, setEndAngle2] = useState<number>(360);

    const angleAttributes = ['value_quantity_sold', 'value_end_usage'];
    //const startAngles = [startAngle1, startAngle2];
    //const endstartAngles = [endAngle1, endAngle2];
    const setStartAngles = [setStartAngle1, setStartAngle2];
    const setEndstartAngles = [setEndAngle1, setEndAngle2];

    const [rawPieData, setRawPieData] = useState<any | null>(null);
    const [pieData, setPieData] = useState<any | null>(null);
    const [outtermostPieData, setOuttermostPieData] = useState<any | null>(
        null
    );

    const [graphData, setGraphData] = useState(
        default_data.map((obj, idx) => {
            return {
                id: obj.id,
                data: obj.data.filter((obj) => {
                    var objDate = new Date(obj.x);
                    return objDate > filterDate;
                }),
            };
        })
    );

    const IngredientsTooltip =
        'These are all the forecastable items we track usage of';
    const ItemTooltip =
        'These are the items containing the selected ingredient';
    const ItemsSoldTooltip =
        'The number of items sold containing this ingredient';
    const ItemsSoldTooltipAlt = 'Number of this items sold';
    const TheoreticalUsagePrepTooltip =
        'This is the calculated usage of this ingredient in the unit you prep in';
    const TheoreticalUsagePrepTooltipAlt =
        'This is the calculated usage of the selected ingredient for this item in the unit you prep in';
    const TheoreticalUsageInvTooltip =
        'This is the calculated usage of this ingredient in the unit you order/count inventory';
    const TheoreticalUsageInvTooltipAlt =
        'This is the calculated usage of the selected ingredient for this item in the unit you order/count inventory';
    const RecipeCountTooltip =
        'The number of recipes containing this ingredient/item';

    useEffect(() => {
        setRawPieData(null);
        setOuttermostPieData(null);
        if (isDayjs(startDate) && isDayjs(endDate) && concept && store) {
            getUsage(
                concept,
                store,
                startDate!.toISOString().split('T')[0],
                endDate!.toISOString().split('T')[0]
            ).then((results: any) => {
                console.log(results);
                setRawPieData(results.data);
                setOuttermostPieData(generate_lvl1_stats(results.data));
                if (results && results.dates && results.requested_date_length) {
                    setGatheredDays(results.dates);
                    setRequestedDays(results.requested_date_length);
                }
            });
        }
    }, [concept, store, startDate, endDate]);

    useEffect(() => {
        setPieData(rawPieData ? generate_lvl1_stats(rawPieData) : null);
    }, [rawPieData]);

    /*const changeFilter = (weeks: number) => {
        setFilter(weeks);
        var filter = new Date(currentDate);
        filter.setDate(currentDate.getDate() - weeks * 7);
        setFilterDate(filter);
        let newGraphData = default_data.map((obj) => {
            return {
                id: obj.id,
                data: obj.data.filter((obj) => {
                    var objDate = new Date(obj.x);
                    return objDate > filter;
                }),
            };
        });
        if (weeks >= 4) {
        }
        setGraphData(newGraphData);
    };*/

    const changeDateRange = (date: any, inputType: boolean) => {
        if (!inputType) {
            if (!endDate || endDate < date) {
                setEndDate(date);
            }
            setStartDate(date);
        } else {
            if (!startDate || startDate > date) {
                setStartDate(date);
            }
            setEndDate(date);
        }
    };

    /*const handleCheckbox = (
        event: React.ChangeEvent<HTMLInputElement>,
        item: String
    ) => {
        let tempCheckedItems = checkedItems;
        if (event.target.checked) {
            tempCheckedItems.push(item);
            setCheckedItems([...tempCheckedItems]);
        } else {
            tempCheckedItems.splice(tempCheckedItems.indexOf(item), 1);
            setCheckedItems([...tempCheckedItems]);
        }
    };*/

    const reducePieChart = (catagory: string) => {
        setSelectedCategory(catagory);
        const catIdx = pieData.findIndex((item: any) => item.id === catagory);
        const prefixSum = angleAttributes.map((attribute) => {
            return pieData
                .slice(0, catIdx)
                .reduce(
                    (partialSum: number, a: any) => partialSum + a[attribute],
                    0
                );
        });
        const totalSum = angleAttributes.map((attribute, idx) => {
            return (
                prefixSum[idx] +
                pieData
                    .slice(catIdx)
                    .reduce(
                        (partialSum: number, a: any) =>
                            partialSum + a[attribute],
                        0
                    )
            );
        });
        let newStartAngle = angleAttributes.map((_, idx) => {
            return (prefixSum[idx] / totalSum[idx]) * 360;
        });
        let newEndAngle = angleAttributes.map((attribute, idx) => {
            return (
                newStartAngle[idx] +
                (pieData[catIdx][attribute] / totalSum[idx]) * 360
            );
        });
        setStartAngles.map((setter, idx) => {
            setter(newStartAngle[idx]);
            return null;
        });
        setEndstartAngles.map((setter, idx) => {
            setter(newEndAngle[idx]);
            return null;
        });
        setPieData(pieData.slice(catIdx, catIdx + 1));
        setTimeout(() => {
            setStartAngles.map((setter) => {
                setter(0);
                return null;
            });
            setEndstartAngles.map((setter) => {
                setter(360);
                return null;
            });
            setTimeout(() => {
                setPieData(generate_lvl2_stats(rawPieData, catagory));
            }, 500);
        }, 500);
    };

    const rebuildPieChart = () => {
        if (!outtermostPieData) {
            setOuttermostPieData(generate_lvl1_stats(rawPieData));
        }
        const catIdx = outtermostPieData.findIndex(
            (item: any) => item.id === selectedCategory
        );
        setSelectedCategory(false);
        const prefixSum = angleAttributes.map((attribute) => {
            return outtermostPieData
                .slice(0, catIdx)
                .reduce(
                    (partialSum: number, a: any) => partialSum + a[attribute],
                    0
                );
        });
        const totalSum = angleAttributes.map((attribute, idx) => {
            return (
                prefixSum[idx] +
                outtermostPieData
                    .slice(catIdx)
                    .reduce(
                        (partialSum: number, a: any) =>
                            partialSum + a[attribute],
                        0
                    )
            );
        });
        let newStartAngle = angleAttributes.map((_, idx) => {
            return (prefixSum[idx] / totalSum[idx]) * 360;
        });
        let newEndAngle = angleAttributes.map((attribute, idx) => {
            return (
                newStartAngle[idx] +
                (outtermostPieData[catIdx][attribute] / totalSum[idx]) * 360
            );
        });
        setPieData(outtermostPieData.slice(catIdx, catIdx + 1));

        setTimeout(() => {
            setStartAngles.map((setter, idx) => {
                setter(newStartAngle[idx]);
                return null;
            });
            setEndstartAngles.map((setter, idx) => {
                setter(newEndAngle[idx]);
                return null;
            });
            setTimeout(() => {
                setStartAngles.map((setter) => {
                    setter(0);
                    return null;
                });
                setEndstartAngles.map((setter) => {
                    setter(360);
                    return null;
                });
                setPieData(outtermostPieData);
            }, 500);
        }, 800);
    };

    return (
        <Box
            width="fill-available"
            height="auto"
            paddingTop={4}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'stretch',
                overflowY: 'scroll',
            }}
        >
            <Typography variant="h3">Ingredient Usage</Typography>
            <Typography variant="subtitle1">
                Select a range of dates below to view your analytics.
            </Typography>
            {store ? (
                <Box
                    sx={{
                        mx: 3,
                        my: 5,
                        borderRadius: '20px',
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        justifyContent: 'center',
                        backgroundColor: '#FFF',
                    }}
                >
                    <Box
                        sx={{
                            mx: 2,
                            mt: 2,
                            mb: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                            backgroundColor: '#FFF',
                            alignSelf: 'start',
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={(newValue: any) => {
                                    if (newValue) {
                                        changeDateRange(newValue, false);
                                    }
                                }}
                                renderInput={(params: any) => (
                                    <TextField {...params} />
                                )}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(newValue: any) => {
                                    changeDateRange(newValue, true);
                                }}
                                renderInput={(params: any) => (
                                    <TextField {...params} />
                                )}
                            />
                        </LocalizationProvider>
                        {gatheredDays.length !== 0 && (
                            <Typography sx={{ alignSelf: 'flex-end' }}>
                                {`Found data for ${gatheredDays.length} of ${requestedDays} days`}
                            </Typography>
                        )}
                    </Box>

                    {startDate &&
                        (Array.isArray(pieData) && pieData.length !== 0 ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'stretch',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignSelf: 'center',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Typography>
                                        Items in{' '}
                                        <Typography
                                            component="span"
                                            color="error"
                                        >
                                            Red
                                        </Typography>{' '}
                                        are missing one or more conversions
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignSelf: 'center',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Typography variant="h4">
                                        Ingredient Usage List
                                    </Typography>
                                </Box>
                                <Box sx={{ mx: 2, height: 240 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: null,
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontWeight: 400,
                                                    fontSize: '20px',
                                                    mr: 0.7,
                                                }}
                                            >
                                                Viewing
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: '20px',
                                                }}
                                            >
                                                {selectedCategory
                                                    ? `${selectedCategory}`
                                                    : 'All Forecastable Items'}
                                            </Typography>
                                        </Box>
                                        {selectedCategory ? (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    direction: 'row',
                                                    alignItems: 'flex-end',
                                                    justifyContent: 'center',
                                                    gap: 1,
                                                }}
                                                onClick={() => {
                                                    rebuildPieChart();
                                                }}
                                            >
                                                <Typography>
                                                    Return to full ingredient
                                                    list
                                                </Typography>
                                                <KeyboardReturnRoundedIcon />
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    direction: 'row',
                                                    alignItems: 'flex-end',
                                                    justifyContent: 'center',
                                                    gap: 1,
                                                }}
                                            >
                                                <Typography>
                                                    Select an item to see a
                                                    detailed view
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                    <TableContainer
                                        component={Paper}
                                        elevation={5}
                                        sx={{ maxHeight: 200 }}
                                    >
                                        <Table
                                            stickyHeader
                                            sx={{ minWidth: 650 }}
                                            size="small"
                                            aria-label="simple table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        {selectedCategory
                                                            ? 'Item'
                                                            : 'Ingredient'}
                                                        <Tooltip
                                                            TransitionComponent={
                                                                Zoom
                                                            }
                                                            placement="top"
                                                            title={
                                                                selectedCategory
                                                                    ? ItemTooltip
                                                                    : IngredientsTooltip
                                                            }
                                                            arrow
                                                        >
                                                            <HelpRoundedIcon
                                                                sx={{
                                                                    fontSize:
                                                                        'small',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        Items Sold
                                                        <Tooltip
                                                            TransitionComponent={
                                                                Zoom
                                                            }
                                                            placement="top"
                                                            title={
                                                                selectedCategory
                                                                    ? ItemsSoldTooltipAlt
                                                                    : ItemsSoldTooltip
                                                            }
                                                            arrow
                                                        >
                                                            <HelpRoundedIcon
                                                                sx={{
                                                                    fontSize:
                                                                        'small',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        Theoretical Usage (prep)
                                                        <Tooltip
                                                            TransitionComponent={
                                                                Zoom
                                                            }
                                                            placement="top"
                                                            title={
                                                                selectedCategory
                                                                    ? TheoreticalUsagePrepTooltipAlt
                                                                    : TheoreticalUsagePrepTooltip
                                                            }
                                                            arrow
                                                        >
                                                            <HelpRoundedIcon
                                                                sx={{
                                                                    fontSize:
                                                                        'small',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        Theoretical Usage (inv)
                                                        <Tooltip
                                                            TransitionComponent={
                                                                Zoom
                                                            }
                                                            placement="top"
                                                            title={
                                                                selectedCategory
                                                                    ? TheoreticalUsageInvTooltipAlt
                                                                    : TheoreticalUsageInvTooltip
                                                            }
                                                            arrow
                                                        >
                                                            <HelpRoundedIcon
                                                                sx={{
                                                                    fontSize:
                                                                        'small',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        Recipe Count
                                                        <Tooltip
                                                            TransitionComponent={
                                                                Zoom
                                                            }
                                                            placement="top"
                                                            title={
                                                                RecipeCountTooltip
                                                            }
                                                            arrow
                                                        >
                                                            <HelpRoundedIcon
                                                                sx={{
                                                                    fontSize:
                                                                        'small',
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {pieData &&
                                                    pieData.map((row: any) => (
                                                        <TableRow
                                                            key={row.id}
                                                            sx={{
                                                                '&:last-child td, &:last-child th':
                                                                    {
                                                                        border: 0,
                                                                    },
                                                            }}
                                                            onClick={() => {
                                                                if (
                                                                    !selectedCategory
                                                                ) {
                                                                    reducePieChart(
                                                                        row.id
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                            >
                                                                {row.id}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {
                                                                    row.value_quantity_sold
                                                                }
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Typography
                                                                    color={
                                                                        !!!row.converted
                                                                            ? 'error'
                                                                            : 'default'
                                                                    }
                                                                    sx={{
                                                                        fontSize:
                                                                            '0.875rem',
                                                                        lineHeight:
                                                                            '1.43',
                                                                        letterSpacing:
                                                                            '0.01071em',
                                                                    }}
                                                                >{`${row.value_end_usage} ${row.value_end_units}`}</Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Typography
                                                                    color={
                                                                        'default'
                                                                    }
                                                                    sx={{
                                                                        fontSize:
                                                                            '0.875rem',
                                                                        lineHeight:
                                                                            '1.43',
                                                                        letterSpacing:
                                                                            '0.01071em',
                                                                    }}
                                                                >{`${row.value_recipe_usage} ${row.value_recipe_units}`}</Typography>
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {
                                                                    row.value_count
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignSelf: 'center',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Typography variant="h4">
                                        Usage Ratio
                                    </Typography>
                                </Box>
                                <Box
                                    height="250"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Box
                                        width="450px"
                                        height="275px"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography>Items Sold</Typography>
                                        {pieData && (
                                            <Box
                                                width="450px"
                                                height="250px"
                                            >
                                                <ResponsivePie
                                                    data={pieData.map(
                                                        (slice: any) => {
                                                            return {
                                                                id: slice.id,
                                                                label: slice.label,
                                                                value: slice.value_quantity_sold,
                                                                color: slice.color,
                                                            };
                                                        }
                                                    )}
                                                    margin={{
                                                        top: 30,
                                                        right: 80,
                                                        bottom: 30,
                                                        left: 80,
                                                    }}
                                                    innerRadius={0.5}
                                                    padAngle={0.7}
                                                    cornerRadius={3}
                                                    activeOuterRadiusOffset={8}
                                                    borderWidth={1}
                                                    borderColor={{
                                                        from: 'color',
                                                        modifiers: [
                                                            ['darker', 0.2],
                                                        ],
                                                    }}
                                                    arcLinkLabelsSkipAngle={10}
                                                    arcLinkLabelsTextColor="#333333"
                                                    arcLinkLabelsThickness={2}
                                                    arcLinkLabelsColor={{
                                                        from: 'color',
                                                    }}
                                                    arcLabelsSkipAngle={10}
                                                    arcLabelsTextColor={{
                                                        from: 'color',
                                                        modifiers: [
                                                            ['darker', 2],
                                                        ],
                                                    }}
                                                    onClick={(data: any) => {
                                                        if (!selectedCategory) {
                                                            reducePieChart(
                                                                data.data['id']
                                                            );
                                                        }
                                                    }}
                                                    startAngle={startAngle1}
                                                    endAngle={endAngle1}
                                                    fit={false}
                                                    defs={[
                                                        {
                                                            id: 'dots',
                                                            type: 'patternDots',
                                                            background:
                                                                'inherit',
                                                            color: 'rgba(255, 255, 255, 0.3)',
                                                            size: 4,
                                                            padding: 1,
                                                            stagger: true,
                                                        },
                                                        {
                                                            id: 'lines',
                                                            type: 'patternLines',
                                                            background:
                                                                'inherit',
                                                            color: 'rgba(255, 255, 255, 0.3)',
                                                            rotation: -45,
                                                            lineWidth: 6,
                                                            spacing: 10,
                                                        },
                                                    ]}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                    <Box
                                        width="450px"
                                        height="250px"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography>
                                            Theoretical Usage
                                        </Typography>
                                        {pieData && (
                                            <Box
                                                width="450px"
                                                height="250px"
                                            >
                                                <ResponsivePie
                                                    data={pieData.map(
                                                        (slice: any) => {
                                                            return {
                                                                id: slice.id,
                                                                label: slice.label,
                                                                value: slice.value_end_usage,
                                                                color: slice.color,
                                                            };
                                                        }
                                                    )}
                                                    margin={{
                                                        top: 30,
                                                        right: 80,
                                                        bottom: 30,
                                                        left: 80,
                                                    }}
                                                    innerRadius={0.5}
                                                    padAngle={0.7}
                                                    cornerRadius={3}
                                                    activeOuterRadiusOffset={8}
                                                    borderWidth={1}
                                                    borderColor={{
                                                        from: 'color',
                                                        modifiers: [
                                                            ['darker', 0.2],
                                                        ],
                                                    }}
                                                    arcLinkLabelsSkipAngle={10}
                                                    arcLinkLabelsTextColor="#333333"
                                                    arcLinkLabelsThickness={2}
                                                    arcLinkLabelsColor={{
                                                        from: 'color',
                                                    }}
                                                    arcLabelsSkipAngle={10}
                                                    arcLabelsTextColor={{
                                                        from: 'color',
                                                        modifiers: [
                                                            ['darker', 2],
                                                        ],
                                                    }}
                                                    onClick={(data: any) => {
                                                        if (!selectedCategory) {
                                                            reducePieChart(
                                                                data.data['id']
                                                            );
                                                        }
                                                    }}
                                                    startAngle={startAngle2}
                                                    endAngle={endAngle2}
                                                    fit={false}
                                                    defs={[
                                                        {
                                                            id: 'dots',
                                                            type: 'patternDots',
                                                            background:
                                                                'inherit',
                                                            color: 'rgba(255, 255, 255, 0.3)',
                                                            size: 4,
                                                            padding: 1,
                                                            stagger: true,
                                                        },
                                                        {
                                                            id: 'lines',
                                                            type: 'patternLines',
                                                            background:
                                                                'inherit',
                                                            color: 'rgba(255, 255, 255, 0.3)',
                                                            rotation: -45,
                                                            lineWidth: 6,
                                                            spacing: 10,
                                                        },
                                                    ]}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        ) : (
                            <Typography sx={{ alignSelf: 'center', mb: 2 }}>
                                There is no data available in this range!
                            </Typography>
                        ))}
                </Box>
            ) : (
                <Typography>
                    Please select a store to view it's dashboard.
                </Typography>
            )}
        </Box>
    );
}
