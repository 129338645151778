/**
 * * Async JSON request
 * @param {null}
 * @returns {object} object containing all user and store data
 */
export async function postDataVerification(
    user_id: string,
    concept_id: string,
    store_id: string,
    verified: number,
    verificationData: any
) {
    const url = `${process.env.REACT_APP_API_URL}/store_verification`;

    let body = JSON.stringify({
        action: 'return_data',
        user_id: user_id,
        store: {
            concept_id: concept_id,
            store_id: store_id,
            verified: verified,
        },
        verificationData: verificationData,
    });

    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(JSON.stringify(response.json()));
            }
            return response.json();
        })
        .catch((err) => {
            console.error(err);
            throw err;
        });
}
