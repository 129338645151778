import {
    FeatureSwitchInterface,
    initialFeatureSwitchState,
} from './featureSwitchState';

const featureSwitchReducer = (
    state: Partial<FeatureSwitchInterface> = initialFeatureSwitchState,
    action: any
) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default featureSwitchReducer;
