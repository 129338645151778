import { createTheme } from '@mui/material/styles';

import dividerThemeOptions from './Divider';
import paperThemeOptions from './Paper';
import typographyThemeOptions from './Typography';

const defaultTheme = createTheme();

const muiTheme = createTheme(
    defaultTheme,
    typographyThemeOptions,
    paperThemeOptions,
    dividerThemeOptions
);

export default muiTheme;
