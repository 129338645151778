import { hooklessUseResponsiveState } from '../../../constants/responsive_context';

export interface ResponsiveInterface {
    isDesktop: boolean;
    isWideScreen: boolean;
    isTablet: boolean;
    isMobile: boolean;
    isPortrait: boolean;
    isRetina: boolean;
}

export const initialResponsiveState: ResponsiveInterface =
    hooklessUseResponsiveState();
