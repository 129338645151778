import { prepItem } from './recipesState';

export const setPrepLists = (prepLists: any) => ({
    type: 'SET_PREP_LISTS',
    payload: { prepLists: prepLists },
});

export const setFocusedPrepSection = (
    focusedPrepSection: string,
    dialogState?: string
) => ({
    type: 'SET_FOCUSED_PREP_SECTION',
    payload: {
        focusedPrepSection: focusedPrepSection,
        dialogState: dialogState,
    },
});

export const updateFocusedPrepSection = (focusedPrepSection: string) => ({
    type: 'UPDATE_FOCUSED_PREP_SECTION',
    payload: {
        focusedPrepSection: focusedPrepSection,
    },
});

export const clearFocusedPrepSection = () => ({
    type: 'CLEAR_FOCUSED_PREP_SECTION',
});

export const setFocusedPrepItem = (focusedPrepItem: prepItem) => ({
    type: 'SET_FOCUSED_PREP_ITEM',
    payload: { focusedPrepItem: focusedPrepItem },
});

export const updateFocusedPrepItem = (
    focusedPrepItem: Partial<prepItem>,
    dialogState?: string | null,
    openDialog?: boolean
) => ({
    type: 'UPDATE_FOCUSED_PREP_ITEM',
    payload: {
        focusedPrepItem: focusedPrepItem,
        dialogState: dialogState,
        openDialog: openDialog,
    },
});

export const clearFocusedPrepItem = () => ({
    type: 'CLEAR_FOCUSED_PREP_ITEM',
});

export const newFocusedPrepItem = (section: string) => ({
    type: 'NEW_FOCUSED_PREP_ITEM',
    payload: { section: section },
});

export const setActiveItem = (activeItem: string) => ({
    type: 'SET_ACTIVE_ITEM',
    payload: { activeItem: activeItem },
});

export const setSelectedPrepList = (selectedPrepList: string) => ({
    type: 'SET_SELECTED_PREP_LIST',
    payload: { selectedPrepList: selectedPrepList },
});

export const setPrepLicense = (prepLicense: number | null) => ({
    type: 'SET_PREP_LICENSE',
    payload: { prepLicense: prepLicense },
});
