import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';

import { logAction, logSeverity, logType } from '../../constants/telemetry';

const currentDate = new Date();
const options = { timeZone: 'America/Chicago' };
const currentDateTimeCT = currentDate.toLocaleString('en-US', options);
const currentDateTimeUTC = currentDate.toUTCString();

interface ITelemetryLog {
    action: logAction;
    state?: string | any;
    message?: string;
    details?: any;
    type?: logType;
    severity?: logSeverity;
}

/**
 * * Async JSON request
 * @param {null}
 * @returns {object} object containing all user and store data
 */
export async function postTelemetryLog({
    action,
    state = undefined,
    message = undefined,
    details = undefined,
    type = logType.Generic,
    severity = logSeverity.Info,
}: ITelemetryLog) {
    // API URL Endpoint Construction
    const url = `${process.env.REACT_APP_API_URL}/file_fetcher`;
    const user: string | undefined = Cookies.get('user');
    const tenant: string | undefined = Cookies.get('tenant');
    const concept: string | undefined = Cookies.get('concept');
    const store: string | undefined = Cookies.get('store');
    const enviroment: string | undefined = process.env.REACT_APP_DEPLOYMENT;
    const pathname: string | undefined = window.location.pathname;

    const actionId = uuid();

    let body = JSON.stringify({
        action: 'log_telemetry',
        data: {
            concept: concept,
            store: store,
            datetime: currentDateTimeUTC,
            datetimeCT: currentDateTimeCT,
            actionId: actionId,
            activityId: undefined, // reserved for future use
            sessionId: undefined, // reserved for future use
            enviroment: enviroment,
            path: pathname,
            user: user,
            tenant: tenant,
            action: action,
            state: state,
            featureSwitches: undefined, // reserved for future use
            message: message,
            details: details,
            type: type,
            severity: severity,
        },
    });

    fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    });

    return;
}
