export const default_data = [
    {
        id: 'tomato',
        color: '#9b5fe0',
        data: [
            { x: '2019-01-01', y: 152 },
            { x: '2019-01-02', y: 256 },
            { x: '2019-01-03', y: 314 },
            { x: '2019-01-04', y: 304 },
            { x: '2019-01-05', y: 182 },
            { x: '2019-01-07', y: 237 },
            { x: '2019-01-08', y: 290 },
            { x: '2019-01-09', y: 338 },
            { x: '2019-01-10', y: 308 },
            { x: '2019-01-11', y: 307 },
            { x: '2019-01-12', y: 246 },
            { x: '2019-01-14', y: 291 },
            { x: '2019-01-15', y: 264 },
            { x: '2019-01-16', y: 280 },
            { x: '2019-01-17', y: 272 },
            { x: '2019-01-18', y: 317 },
            { x: '2019-01-19', y: 227 },
            { x: '2019-01-21', y: 278 },
            { x: '2019-01-22', y: 299 },
            { x: '2019-01-23', y: 271 },
            { x: '2019-01-24', y: 337 },
            { x: '2019-01-25', y: 387 },
            { x: '2019-01-26', y: 316 },
            { x: '2019-01-28', y: 287 },
            { x: '2019-01-29', y: 276 },
            { x: '2019-01-30', y: 328 },
            { x: '2019-01-31', y: 338 },
            { x: '2019-02-01', y: 388 },
            { x: '2019-02-02', y: 203 },
            { x: '2019-02-04', y: 294 },
            { x: '2019-02-05', y: 261 },
            { x: '2019-02-06', y: 270 },
            { x: '2019-02-07', y: 322 },
            { x: '2019-02-08', y: 360 },
            { x: '2019-02-09', y: 233 },
            { x: '2019-02-11', y: 288 },
            { x: '2019-02-12', y: 303 },
            { x: '2019-02-13', y: 309 },
            { x: '2019-02-14', y: 306 },
            { x: '2019-02-15', y: 381 },
            { x: '2019-02-16', y: 450 },
            { x: '2019-02-18', y: 349 },
            { x: '2019-02-19', y: 285 },
            { x: '2019-02-20', y: 274 },
            { x: '2019-02-21', y: 257 },
            { x: '2019-02-22', y: 323 },
            { x: '2019-02-23', y: 264 },
            { x: '2019-02-25', y: 295 },
            { x: '2019-02-26', y: 316 },
            { x: '2019-02-27', y: 391 },
            { x: '2019-02-28', y: 371 },
            { x: '2019-03-01', y: 394 },
            { x: '2019-03-02', y: 215 },
            { x: '2019-03-04', y: 344 },
            { x: '2019-03-05', y: 325 },
            { x: '2019-03-07', y: 398 },
            { x: '2019-03-08', y: 479 },
            { x: '2019-03-09', y: 353 },
            { x: '2019-03-11', y: 354 },
            { x: '2019-03-12', y: 242 },
            { x: '2019-03-13', y: 280 },
            { x: '2019-03-14', y: 351 },
            { x: '2019-03-15', y: 392 },
            { x: '2019-03-16', y: 335 },
            { x: '2019-03-18', y: 387 },
            { x: '2019-03-19', y: 362 },
            { x: '2019-03-20', y: 351 },
            { x: '2019-03-21', y: 308 },
            { x: '2019-03-22', y: 432 },
            { x: '2019-03-23', y: 478 },
            { x: '2019-03-25', y: 395 },
            { x: '2019-03-26', y: 391 },
            { x: '2019-03-27', y: 341 },
            { x: '2019-03-28', y: 359 },
            { x: '2019-03-29', y: 450 },
            { x: '2019-03-30', y: 369 },
            { x: '2019-04-01', y: 388 },
            { x: '2019-04-02', y: 382 },
            { x: '2019-04-03', y: 425 },
            { x: '2019-04-04', y: 434 },
            { x: '2019-04-05', y: 470 },
            { x: '2019-04-06', y: 322 },
            { x: '2019-04-08', y: 350 },
            { x: '2019-04-09', y: 355 },
            { x: '2019-04-10', y: 384 },
            { x: '2019-04-11', y: 348 },
            { x: '2019-04-12', y: 383 },
            { x: '2019-04-13', y: 361 },
            { x: '2019-04-15', y: 387 },
            { x: '2019-04-16', y: 259 },
            { x: '2019-04-17', y: 387 },
            { x: '2019-04-18', y: 478 },
            { x: '2019-04-19', y: 353 },
            { x: '2019-04-20', y: 291 },
            { x: '2019-04-22', y: 369 },
            { x: '2019-04-23', y: 362 },
            { x: '2019-04-24', y: 370 },
            { x: '2019-04-25', y: 369 },
            { x: '2019-04-26', y: 382 },
            { x: '2019-04-27', y: 271 },
            { x: '2019-04-29', y: 292 },
            { x: '2019-04-30', y: 296 },
            { x: '2019-05-01', y: 333 },
            { x: '2019-05-02', y: 380 },
            { x: '2019-05-03', y: 488 },
            { x: '2019-05-04', y: 386 },
            { x: '2019-05-06', y: 333 },
            { x: '2019-05-07', y: 289 },
            { x: '2019-05-08', y: 322 },
            { x: '2019-05-09', y: 308 },
            { x: '2019-05-10', y: 327 },
            { x: '2019-05-11', y: 296 },
            { x: '2019-05-13', y: 330 },
            { x: '2019-05-14', y: 350 },
            { x: '2019-05-15', y: 378 },
            { x: '2019-05-16', y: 316 },
            { x: '2019-05-17', y: 352 },
            { x: '2019-05-18', y: 237 },
            { x: '2019-05-20', y: 303 },
            { x: '2019-05-21', y: 357 },
            { x: '2019-05-22', y: 338 },
            { x: '2019-05-23', y: 278 },
            { x: '2019-05-24', y: 346 },
            { x: '2019-05-25', y: 368 },
            { x: '2019-05-27', y: 187 },
            { x: '2019-05-28', y: 305 },
            { x: '2019-05-29', y: 327 },
            { x: '2019-05-30', y: 330 },
            { x: '2019-05-31', y: 314 },
            { x: '2019-06-01', y: 255 },
            { x: '2019-06-03', y: 338 },
            { x: '2019-06-04', y: 387 },
            { x: '2019-06-05', y: 363 },
            { x: '2019-06-06', y: 379 },
            { x: '2019-06-07', y: 329 },
            { x: '2019-06-08', y: 247 },
            { x: '2019-06-10', y: 363 },
            { x: '2019-06-11', y: 312 },
            { x: '2019-06-12', y: 324 },
            { x: '2019-06-13', y: 353 },
            { x: '2019-06-15', y: 242 },
            { x: '2019-06-17', y: 349 },
            { x: '2019-06-18', y: 305 },
            { x: '2019-06-19', y: 325 },
            { x: '2019-06-20', y: 350 },
            { x: '2019-06-21', y: 323 },
            { x: '2019-06-22', y: 252 },
            { x: '2019-06-24', y: 349 },
            { x: '2019-06-25', y: 316 },
            { x: '2019-06-26', y: 335 },
            { x: '2019-06-27', y: 320 },
            { x: '2019-06-28', y: 299 },
            { x: '2019-06-29', y: 193 },
            { x: '2019-07-01', y: 268 },
            { x: '2019-07-02', y: 306 },
            { x: '2019-07-03', y: 352 },
            { x: '2019-07-04', y: 175 },
            { x: '2019-07-05', y: 241 },
            { x: '2019-07-06', y: 227 },
            { x: '2019-07-08', y: 314 },
            { x: '2019-07-09', y: 320 },
            { x: '2019-07-10', y: 315 },
            { x: '2019-07-11', y: 272 },
            { x: '2019-07-12', y: 312 },
            { x: '2019-07-13', y: 211 },
            { x: '2019-07-15', y: 313 },
            { x: '2019-07-16', y: 287 },
            { x: '2019-07-17', y: 314 },
            { x: '2019-07-18', y: 320 },
            { x: '2019-07-19', y: 344 },
            { x: '2019-07-20', y: 194 },
            { x: '2019-07-22', y: 298 },
            { x: '2019-07-23', y: 321 },
            { x: '2019-07-24', y: 210 },
            { x: '2019-07-25', y: 301 },
            { x: '2019-07-26', y: 262 },
            { x: '2019-07-27', y: 213 },
            { x: '2019-07-29', y: 338 },
            { x: '2019-07-30', y: 295 },
            { x: '2019-07-31', y: 283 },
            { x: '2019-08-01', y: 300 },
            { x: '2019-08-02', y: 353 },
            { x: '2019-08-03', y: 215 },
            { x: '2019-08-05', y: 319 },
            { x: '2019-08-06', y: 310 },
            { x: '2019-08-07', y: 312 },
            { x: '2019-08-08', y: 313 },
            { x: '2019-08-09', y: 304 },
            { x: '2019-08-10', y: 256 },
            { x: '2019-08-12', y: 342 },
            { x: '2019-08-13', y: 298 },
            { x: '2019-08-14', y: 346 },
            { x: '2019-08-15', y: 305 },
            { x: '2019-08-16', y: 359 },
            { x: '2019-08-17', y: 230 },
            { x: '2019-08-19', y: 292 },
            { x: '2019-08-20', y: 315 },
            { x: '2019-08-21', y: 327 },
            { x: '2019-08-22', y: 369 },
            { x: '2019-08-23', y: 371 },
            { x: '2019-08-24', y: 248 },
            { x: '2019-08-26', y: 332 },
            { x: '2019-08-27', y: 338 },
            { x: '2019-08-28', y: 325 },
            { x: '2019-08-29', y: 336 },
            { x: '2019-08-30', y: 329 },
            { x: '2019-08-31', y: 260 },
            { x: '2019-09-02', y: 210 },
            { x: '2019-09-03', y: 284 },
            { x: '2019-09-04', y: 297 },
            { x: '2019-09-05', y: 327 },
            { x: '2019-09-06', y: 362 },
            { x: '2019-09-07', y: 298 },
            { x: '2019-09-09', y: 306 },
            { x: '2019-09-10', y: 323 },
            { x: '2019-09-11', y: 330 },
            { x: '2019-09-12', y: 298 },
            { x: '2019-09-13', y: 345 },
            { x: '2019-09-14', y: 258 },
            { x: '2019-09-16', y: 289 },
            { x: '2019-09-17', y: 347 },
            { x: '2019-09-18', y: 335 },
            { x: '2019-09-19', y: 349 },
            { x: '2019-09-20', y: 320 },
            { x: '2019-09-21', y: 276 },
            { x: '2019-09-23', y: 315 },
            { x: '2019-09-24', y: 309 },
            { x: '2019-09-25', y: 296 },
            { x: '2019-09-26', y: 322 },
            { x: '2019-09-27', y: 335 },
            { x: '2019-09-28', y: 240 },
            { x: '2019-09-30', y: 314 },
            { x: '2019-10-01', y: 324 },
            { x: '2019-10-02', y: 317 },
            { x: '2019-10-03', y: 365 },
            { x: '2019-10-04', y: 380 },
            { x: '2019-10-05', y: 293 },
            { x: '2019-10-07', y: 372 },
            { x: '2019-10-08', y: 287 },
            { x: '2019-10-09', y: 357 },
            { x: '2019-10-10', y: 315 },
            { x: '2019-10-11', y: 307 },
            { x: '2019-10-12', y: 260 },
            { x: '2019-10-14', y: 334 },
            { x: '2019-10-15', y: 342 },
            { x: '2019-10-16', y: 348 },
            { x: '2019-10-17', y: 345 },
            { x: '2019-10-18', y: 370 },
            { x: '2019-10-19', y: 252 },
            { x: '2019-10-21', y: 317 },
            { x: '2019-10-22', y: 271 },
            { x: '2019-10-23', y: 336 },
            { x: '2019-10-24', y: 336 },
            { x: '2019-10-25', y: 297 },
            { x: '2019-10-26', y: 279 },
            { x: '2019-10-28', y: 267 },
            { x: '2019-10-29', y: 268 },
            { x: '2019-10-30', y: 307 },
            { x: '2019-10-31', y: 245 },
            { x: '2019-11-01', y: 339 },
            { x: '2019-11-02', y: 300 },
            { x: '2019-11-04', y: 278 },
            { x: '2019-11-05', y: 271 },
            { x: '2019-11-06', y: 284 },
            { x: '2019-11-07', y: 334 },
            { x: '2019-11-08', y: 381 },
            { x: '2019-11-09', y: 371 },
            { x: '2019-11-11', y: 289 },
            { x: '2019-11-12', y: 272 },
            { x: '2019-11-13', y: 319 },
            { x: '2019-11-14', y: 290 },
            { x: '2019-11-15', y: 316 },
            { x: '2019-11-16', y: 238 },
            { x: '2019-11-18', y: 307 },
            { x: '2019-11-19', y: 295 },
            { x: '2019-11-20', y: 257 },
            { x: '2019-11-21', y: 271 },
            { x: '2019-11-22', y: 324 },
            { x: '2019-11-23', y: 253 },
            { x: '2019-11-25', y: 279 },
            { x: '2019-11-26', y: 351 },
            { x: '2019-11-27', y: 254 },
            { x: '2019-11-29', y: 201 },
            { x: '2019-11-30', y: 180 },
            { x: '2019-12-02', y: 281 },
            { x: '2019-12-03', y: 257 },
            { x: '2019-12-04', y: 308 },
            { x: '2019-12-05', y: 307 },
            { x: '2019-12-06', y: 343 },
            { x: '2019-12-07', y: 219 },
            { x: '2019-12-09', y: 285 },
            { x: '2019-12-10', y: 254 },
            { x: '2019-12-11', y: 313 },
            { x: '2019-12-12', y: 296 },
            { x: '2019-12-13', y: 266 },
            { x: '2019-12-14', y: 195 },
            { x: '2019-12-16', y: 249 },
            { x: '2019-12-17', y: 258 },
            { x: '2019-12-18', y: 284 },
            { x: '2019-12-19', y: 286 },
            { x: '2019-12-20', y: 286 },
            { x: '2019-12-21', y: 224 },
            { x: '2019-12-23', y: 294 },
            { x: '2019-12-24', y: 120 },
            { x: '2019-12-26', y: 186 },
            { x: '2019-12-27', y: 273 },
            { x: '2019-12-28', y: 208 },
            { x: '2019-12-30', y: 275 },
            { x: '2020-01-01', y: 157 },
            { x: '2020-01-02', y: 292 },
            { x: '2020-01-03', y: 301 },
            { x: '2020-01-04', y: 217 },
            { x: '2020-01-06', y: 284 },
            { x: '2020-01-07', y: 319 },
            { x: '2020-01-08', y: 316 },
            { x: '2020-01-09', y: 290 },
            { x: '2020-01-10', y: 309 },
            { x: '2020-01-11', y: 216 },
            { x: '2020-01-13', y: 293 },
            { x: '2020-01-14', y: 289 },
            { x: '2020-01-15', y: 324 },
            { x: '2020-01-16', y: 307 },
            { x: '2020-01-17', y: 323 },
            { x: '2020-01-18', y: 282 },
            { x: '2020-01-20', y: 275 },
            { x: '2020-01-21', y: 287 },
            { x: '2020-01-22', y: 286 },
            { x: '2020-01-23', y: 309 },
            { x: '2020-01-24', y: 329 },
            { x: '2020-01-25', y: 282 },
            { x: '2020-01-27', y: 319 },
            { x: '2020-01-28', y: 295 },
            { x: '2020-01-29', y: 296 },
            { x: '2020-01-30', y: 290 },
            { x: '2020-01-31', y: 350 },
            { x: '2020-02-01', y: 268 },
            { x: '2020-02-03', y: 238 },
            { x: '2020-02-04', y: 291 },
            { x: '2020-02-05', y: 294 },
            { x: '2020-02-06', y: 315 },
            { x: '2020-02-07', y: 318 },
            { x: '2020-02-08', y: 259 },
            { x: '2020-02-10', y: 333 },
            { x: '2020-02-11', y: 300 },
            { x: '2020-02-12', y: 298 },
            { x: '2020-02-13', y: 351 },
            { x: '2020-02-14', y: 428 },
            { x: '2020-02-15', y: 458 },
            { x: '2020-02-17', y: 401 },
            { x: '2020-02-18', y: 306 },
            { x: '2020-02-19', y: 332 },
            { x: '2020-02-20', y: 383 },
            { x: '2020-02-21', y: 402 },
            { x: '2020-02-22', y: 265 },
            { x: '2020-02-24', y: 317 },
            { x: '2020-02-25', y: 304 },
            { x: '2020-02-26', y: 307 },
            { x: '2020-02-27', y: 263 },
            { x: '2020-02-28', y: 257 },
            { x: '2020-02-29', y: 243 },
            { x: '2020-03-02', y: 230 },
            { x: '2020-03-03', y: 260 },
            { x: '2020-03-04', y: 270 },
            { x: '2020-03-05', y: 321 },
            { x: '2020-03-06', y: 438 },
            { x: '2020-03-07', y: 287 },
            { x: '2020-03-09', y: 272 },
            { x: '2020-03-10', y: 258 },
            { x: '2020-03-11', y: 305 },
            { x: '2020-03-12', y: 328 },
            { x: '2020-03-13', y: 244 },
            { x: '2020-03-14', y: 271 },
            { x: '2020-03-16', y: 238 },
            { x: '2020-03-17', y: 235 },
            { x: '2020-03-18', y: 226 },
            { x: '2020-03-19', y: 224 },
            { x: '2020-03-20', y: 200 },
            { x: '2020-03-21', y: 160 },
            { x: '2020-03-23', y: 215 },
            { x: '2020-03-24', y: 300 },
            { x: '2020-03-25', y: 258 },
            { x: '2020-03-26', y: 281 },
            { x: '2020-03-27', y: 211 },
            { x: '2020-03-28', y: 207 },
            { x: '2020-03-30', y: 240 },
            { x: '2020-03-31', y: 225 },
            { x: '2020-04-01', y: 238 },
            { x: '2020-04-02', y: 289 },
            { x: '2020-04-03', y: 257 },
            { x: '2020-04-04', y: 199 },
            { x: '2020-04-06', y: 299 },
            { x: '2020-04-07', y: 266 },
            { x: '2020-04-08', y: 243 },
            { x: '2020-04-10', y: 215 },
            { x: '2020-04-11', y: 248 },
            { x: '2020-04-13', y: 313 },
            { x: '2020-04-14', y: 250 },
            { x: '2020-04-15', y: 304 },
            { x: '2020-04-16', y: 298 },
            { x: '2020-04-17', y: 361 },
            { x: '2020-04-18', y: 286 },
            { x: '2020-04-20', y: 390 },
            { x: '2020-04-21', y: 249 },
            { x: '2020-04-22', y: 365 },
            { x: '2020-04-23', y: 308 },
            { x: '2020-04-24', y: 423 },
            { x: '2020-04-25', y: 364 },
            { x: '2020-04-27', y: 420 },
            { x: '2020-04-28', y: 328 },
            { x: '2020-04-29', y: 339 },
            { x: '2020-04-30', y: 359 },
            { x: '2020-05-01', y: 398 },
            { x: '2020-05-02', y: 371 },
            { x: '2020-05-04', y: 374 },
            { x: '2020-05-05', y: 296 },
            { x: '2020-05-06', y: 367 },
            { x: '2020-05-07', y: 318 },
            { x: '2020-05-08', y: 380 },
            { x: '2020-05-09', y: 335 },
            { x: '2020-05-11', y: 340 },
            { x: '2020-05-12', y: 342 },
            { x: '2020-05-13', y: 353 },
            { x: '2020-05-14', y: 384 },
            { x: '2020-05-15', y: 390 },
            { x: '2020-05-16', y: 395 },
            { x: '2020-05-18', y: 363 },
            { x: '2020-05-19', y: 321 },
            { x: '2020-05-20', y: 392 },
            { x: '2020-05-21', y: 379 },
            { x: '2020-05-22', y: 403 },
            { x: '2020-05-23', y: 379 },
            { x: '2020-05-25', y: 311 },
            { x: '2020-05-26', y: 345 },
            { x: '2020-05-27', y: 355 },
            { x: '2020-05-28', y: 368 },
            { x: '2020-05-29', y: 421 },
            { x: '2020-05-30', y: 325 },
            { x: '2020-06-01', y: 361 },
            { x: '2020-06-02', y: 338 },
            { x: '2020-06-03', y: 357 },
            { x: '2020-06-04', y: 367 },
            { x: '2020-06-05', y: 424 },
            { x: '2020-06-06', y: 275 },
            { x: '2020-06-08', y: 367 },
            { x: '2020-06-09', y: 353 },
            { x: '2020-06-10', y: 350 },
            { x: '2020-06-11', y: 394 },
            { x: '2020-06-12', y: 377 },
            { x: '2020-06-13', y: 298 },
            { x: '2020-06-15', y: 410 },
            { x: '2020-06-16', y: 350 },
            { x: '2020-06-17', y: 364 },
            { x: '2020-06-18', y: 381 },
            { x: '2020-06-19', y: 420 },
            { x: '2020-06-20', y: 289 },
            { x: '2020-06-22', y: 347 },
            { x: '2020-06-23', y: 356 },
            { x: '2020-06-24', y: 367 },
            { x: '2020-06-25', y: 355 },
            { x: '2020-06-26', y: 427 },
            { x: '2020-06-27', y: 287 },
            { x: '2020-06-29', y: 432 },
            { x: '2020-06-30', y: 350 },
            { x: '2020-07-01', y: 400 },
            { x: '2020-07-02', y: 351 },
            { x: '2020-07-03', y: 323 },
            { x: '2020-07-04', y: 218 },
            { x: '2020-07-06', y: 336 },
            { x: '2020-07-07', y: 295 },
            { x: '2020-07-08', y: 355 },
            { x: '2020-07-09', y: 304 },
            { x: '2020-07-10', y: 295 },
            { x: '2020-07-11', y: 297 },
            { x: '2020-07-13', y: 397 },
            { x: '2020-07-14', y: 362 },
            { x: '2020-07-15', y: 349 },
            { x: '2020-07-16', y: 373 },
            { x: '2020-07-17', y: 374 },
            { x: '2020-07-20', y: 297 },
            { x: '2020-07-21', y: 329 },
            { x: '2020-07-22', y: 333 },
            { x: '2020-07-23', y: 335 },
            { x: '2020-07-24', y: 278 },
            { x: '2020-07-25', y: 278 },
            { x: '2020-07-27', y: 323 },
            { x: '2020-07-28', y: 361 },
            { x: '2020-07-29', y: 332 },
            { x: '2020-07-30', y: 378 },
            { x: '2020-07-31', y: 389 },
            { x: '2020-08-01', y: 257 },
            { x: '2020-08-03', y: 348 },
            { x: '2020-08-04', y: 309 },
            { x: '2020-08-05', y: 359 },
            { x: '2020-08-06', y: 341 },
            { x: '2020-08-07', y: 338 },
            { x: '2020-08-08', y: 272 },
            { x: '2020-08-10', y: 321 },
            { x: '2020-08-11', y: 338 },
            { x: '2020-08-12', y: 383 },
            { x: '2020-08-13', y: 359 },
            { x: '2020-08-14', y: 373 },
            { x: '2020-08-15', y: 140 },
            { x: '2020-08-17', y: 342 },
            { x: '2020-08-18', y: 314 },
            { x: '2020-08-19', y: 360 },
            { x: '2020-08-20', y: 321 },
            { x: '2020-08-21', y: 349 },
            { x: '2020-08-22', y: 247 },
            { x: '2020-08-24', y: 396 },
            { x: '2020-08-25', y: 347 },
            { x: '2020-08-26', y: 356 },
            { x: '2020-08-27', y: 327 },
            { x: '2020-08-28', y: 410 },
            { x: '2020-08-29', y: 330 },
            { x: '2020-08-31', y: 360 },
            { x: '2020-09-01', y: 340 },
            { x: '2020-09-02', y: 340 },
            { x: '2020-09-03', y: 361 },
            { x: '2020-09-04', y: 389 },
            { x: '2020-09-05', y: 310 },
            { x: '2020-09-07', y: 222 },
            { x: '2020-09-08', y: 352 },
            { x: '2020-09-09', y: 321 },
            { x: '2020-09-10', y: 387 },
            { x: '2020-09-11', y: 357 },
            { x: '2020-09-12', y: 336 },
            { x: '2020-09-14', y: 370 },
            { x: '2020-09-15', y: 350 },
            { x: '2020-09-16', y: 339 },
            { x: '2020-09-17', y: 398 },
            { x: '2020-09-18', y: 337 },
            { x: '2020-09-19', y: 344 },
            { x: '2020-09-21', y: 381 },
            { x: '2020-09-22', y: 350 },
            { x: '2020-09-23', y: 339 },
            { x: '2020-09-24', y: 379 },
            { x: '2020-09-25', y: 378 },
            { x: '2020-09-26', y: 281 },
            { x: '2020-09-28', y: 306 },
            { x: '2020-09-29', y: 354 },
            { x: '2020-09-30', y: 297 },
            { x: '2020-10-01', y: 351 },
            { x: '2020-10-02', y: 325 },
            { x: '2020-10-03', y: 239 },
            { x: '2020-10-05', y: 331 },
            { x: '2020-10-06', y: 313 },
            { x: '2020-10-07', y: 346 },
            { x: '2020-10-08', y: 359 },
            { x: '2020-10-09', y: 338 },
            { x: '2020-10-10', y: 313 },
            { x: '2020-10-12', y: 322 },
            { x: '2020-10-13', y: 321 },
            { x: '2020-10-14', y: 313 },
            { x: '2020-10-15', y: 343 },
            { x: '2020-10-16', y: 340 },
            { x: '2020-10-17', y: 289 },
            { x: '2020-10-19', y: 321 },
            { x: '2020-10-20', y: 321 },
            { x: '2020-10-21', y: 331 },
            { x: '2020-10-22', y: 342 },
            { x: '2020-10-23', y: 386 },
            { x: '2020-10-24', y: 343 },
            { x: '2020-10-27', y: 284 },
            { x: '2020-10-28', y: 309 },
            { x: '2020-10-29', y: 363 },
            { x: '2020-10-30', y: 395 },
            { x: '2020-10-31', y: 231 },
            { x: '2020-11-02', y: 286 },
            { x: '2020-11-03', y: 371 },
            { x: '2020-11-04', y: 320 },
            { x: '2020-11-05', y: 297 },
            { x: '2020-11-06', y: 314 },
            { x: '2020-11-07', y: 240 },
            { x: '2020-11-09', y: 290 },
            { x: '2020-11-10', y: 298 },
            { x: '2020-11-11', y: 260 },
            { x: '2020-11-12', y: 243 },
            { x: '2020-11-13', y: 307 },
            { x: '2020-11-14', y: 201 },
            { x: '2020-11-16', y: 282 },
            { x: '2020-11-17', y: 285 },
            { x: '2020-11-18', y: 335 },
            { x: '2020-11-19', y: 321 },
            { x: '2020-11-20', y: 268 },
            { x: '2020-11-21', y: 214 },
            { x: '2020-11-23', y: 311 },
            { x: '2020-11-24', y: 309 },
            { x: '2020-11-25', y: 314 },
            { x: '2020-11-27', y: 204 },
            { x: '2020-11-28', y: 226 },
            { x: '2020-11-30', y: 302 },
            { x: '2020-12-01', y: 286 },
            { x: '2020-12-02', y: 262 },
            { x: '2020-12-03', y: 300 },
            { x: '2020-12-04', y: 296 },
            { x: '2020-12-05', y: 222 },
            { x: '2020-12-07', y: 258 },
            { x: '2020-12-08', y: 283 },
            { x: '2020-12-09', y: 283 },
            { x: '2020-12-10', y: 311 },
            { x: '2020-12-11', y: 274 },
            { x: '2020-12-12', y: 184 },
            { x: '2020-12-14', y: 289 },
            { x: '2020-12-15', y: 314 },
            { x: '2020-12-16', y: 289 },
            { x: '2020-12-17', y: 273 },
            { x: '2020-12-18', y: 285 },
            { x: '2020-12-19', y: 233 },
            { x: '2020-12-21', y: 264 },
            { x: '2020-12-22', y: 269 },
            { x: '2020-12-23', y: 287 },
            { x: '2020-12-24', y: 122 },
            { x: '2020-12-26', y: 153 },
            { x: '2020-12-28', y: 251 },
            { x: '2020-12-29', y: 301 },
            { x: '2020-12-30', y: 310 },
            { x: '2020-12-31', y: 262 },
            { x: '2021-01-01', y: 164 },
            { x: '2021-01-02', y: 240 },
            { x: '2021-01-04', y: 294 },
            { x: '2021-01-05', y: 336 },
            { x: '2021-01-06', y: 379 },
            { x: '2021-01-07', y: 274 },
            { x: '2021-01-08', y: 321 },
            { x: '2021-01-09', y: 244 },
            { x: '2021-01-11', y: 324 },
            { x: '2021-01-12', y: 262 },
            { x: '2021-01-13', y: 340 },
            { x: '2021-01-14', y: 308 },
            { x: '2021-01-15', y: 301 },
            { x: '2021-01-16', y: 299 },
            { x: '2021-01-18', y: 357 },
            { x: '2021-01-19', y: 285 },
            { x: '2021-01-20', y: 353 },
            { x: '2021-01-21', y: 339 },
            { x: '2021-01-22', y: 345 },
            { x: '2021-01-23', y: 216 },
            { x: '2021-01-25', y: 257 },
            { x: '2021-01-26', y: 306 },
            { x: '2021-01-27', y: 291 },
            { x: '2021-01-28', y: 310 },
            { x: '2021-01-29', y: 288 },
            { x: '2021-01-30', y: 295 },
            { x: '2021-02-01', y: 196 },
            { x: '2021-02-02', y: 265 },
            { x: '2021-02-04', y: 301 },
            { x: '2021-02-05', y: 318 },
            { x: '2021-02-06', y: 255 },
            { x: '2021-02-08', y: 228 },
            { x: '2021-02-09', y: 231 },
            { x: '2021-02-10', y: 265 },
            { x: '2021-02-11', y: 233 },
            { x: '2021-02-12', y: 395 },
            { x: '2021-02-13', y: 326 },
            { x: '2021-02-15', y: 289 },
            { x: '2021-02-16', y: 206 },
            { x: '2021-02-17', y: 292 },
            { x: '2021-02-18', y: 267 },
            { x: '2021-02-19', y: 304 },
            { x: '2021-02-20', y: 274 },
            { x: '2021-02-22', y: 214 },
            { x: '2021-02-23', y: 258 },
            { x: '2021-02-24', y: 269 },
            { x: '2021-02-25', y: 303 },
            { x: '2021-02-26', y: 270 },
            { x: '2021-02-27', y: 241 },
            { x: '2021-03-01', y: 253 },
            { x: '2021-03-02', y: 281 },
            { x: '2021-03-03', y: 240 },
            { x: '2021-03-04', y: 266 },
            { x: '2021-03-05', y: 261 },
            { x: '2021-03-06', y: 237 },
            { x: '2021-03-08', y: 265 },
            { x: '2021-03-09', y: 232 },
            { x: '2021-03-10', y: 230 },
            { x: '2021-03-11', y: 279 },
            { x: '2021-03-12', y: 275 },
            { x: '2021-03-13', y: 140 },
            { x: '2021-03-15', y: 281 },
            { x: '2021-03-16', y: 221 },
            { x: '2021-03-17', y: 247 },
            { x: '2021-03-18', y: 308 },
            { x: '2021-03-19', y: 484 },
            { x: '2021-03-20', y: 429 },
            { x: '2021-03-22', y: 302 },
            { x: '2021-03-23', y: 272 },
            { x: '2021-03-24', y: 314 },
            { x: '2021-03-25', y: 301 },
            { x: '2021-03-26', y: 368 },
            { x: '2021-03-27', y: 402 },
            { x: '2021-03-29', y: 334 },
            { x: '2021-03-30', y: 288 },
            { x: '2021-03-31', y: 328 },
            { x: '2021-04-01', y: 395 },
            { x: '2021-04-02', y: 413 },
            { x: '2021-04-03', y: 349 },
            { x: '2021-04-05', y: 323 },
            { x: '2021-04-06', y: 315 },
            { x: '2021-04-07', y: 332 },
            { x: '2021-04-08', y: 360 },
            { x: '2021-04-09', y: 355 },
            { x: '2021-04-10', y: 356 },
            { x: '2021-04-12', y: 264 },
            { x: '2021-04-13', y: 292 },
            { x: '2021-04-14', y: 285 },
            { x: '2021-04-15', y: 308 },
            { x: '2021-04-16', y: 320 },
            { x: '2021-04-17', y: 294 },
            { x: '2021-04-19', y: 285 },
            { x: '2021-04-20', y: 314 },
            { x: '2021-04-21', y: 348 },
            { x: '2021-04-22', y: 278 },
            { x: '2021-04-23', y: 313 },
            { x: '2021-04-24', y: 210 },
            { x: '2021-04-26', y: 230 },
            { x: '2021-04-27', y: 235 },
            { x: '2021-04-28', y: 255 },
            { x: '2021-04-29', y: 343 },
            { x: '2021-04-30', y: 369 },
            { x: '2021-05-01', y: 261 },
            { x: '2021-05-03', y: 285 },
            { x: '2021-05-04', y: 271 },
            { x: '2021-05-05', y: 252 },
            { x: '2021-05-06', y: 262 },
            { x: '2021-05-07', y: 284 },
            { x: '2021-05-08', y: 278 },
            { x: '2021-05-10', y: 250 },
            { x: '2021-05-12', y: 265 },
            { x: '2021-05-13', y: 292 },
            { x: '2021-05-14', y: 334 },
            { x: '2021-05-15', y: 247 },
            { x: '2021-05-17', y: 223 },
            { x: '2021-05-18', y: 262 },
            { x: '2021-05-19', y: 292 },
            { x: '2021-05-20', y: 280 },
            { x: '2021-05-21', y: 300 },
            { x: '2021-05-22', y: 199 },
            { x: '2021-05-24', y: 275 },
            { x: '2021-05-25', y: 255 },
            { x: '2021-05-26', y: 273 },
            { x: '2021-05-27', y: 275 },
            { x: '2021-05-28', y: 282 },
            { x: '2021-05-29', y: 242 },
            { x: '2021-05-31', y: 244 },
            { x: '2021-06-01', y: 281 },
            { x: '2021-06-02', y: 257 },
            { x: '2021-06-03', y: 296 },
            { x: '2021-06-04', y: 286 },
            { x: '2021-06-05', y: 231 },
            { x: '2021-06-07', y: 269 },
            { x: '2021-06-08', y: 267 },
            { x: '2021-06-09', y: 269 },
            { x: '2021-06-10', y: 270 },
            { x: '2021-06-11', y: 278 },
            { x: '2021-06-12', y: 178 },
            { x: '2021-06-14', y: 255 },
            { x: '2021-06-15', y: 301 },
            { x: '2021-06-16', y: 289 },
            { x: '2021-06-17', y: 278 },
            { x: '2021-06-18', y: 283 },
            { x: '2021-06-19', y: 168 },
            { x: '2021-06-21', y: 329 },
            { x: '2021-06-22', y: 265 },
            { x: '2021-06-23', y: 261 },
            { x: '2021-06-24', y: 264 },
            { x: '2021-06-25', y: 305 },
            { x: '2021-06-26', y: 204 },
            { x: '2021-06-28', y: 243 },
            { x: '2021-06-29', y: 270 },
            { x: '2021-06-30', y: 276 },
            { x: '2021-07-01', y: 292 },
            { x: '2021-07-02', y: 251 },
            { x: '2021-07-03', y: 138 },
            { x: '2021-07-05', y: 208 },
            { x: '2021-07-06', y: 292 },
            { x: '2021-07-07', y: 238 },
            { x: '2021-07-08', y: 306 },
            { x: '2021-07-09', y: 235 },
            { x: '2021-07-10', y: 241 },
            { x: '2021-07-12', y: 232 },
            { x: '2021-07-13', y: 244 },
            { x: '2021-07-14', y: 247 },
            { x: '2021-07-15', y: 265 },
            { x: '2021-07-16', y: 237 },
            { x: '2021-07-17', y: 174 },
            { x: '2021-07-19', y: 272 },
            { x: '2021-07-20', y: 216 },
            { x: '2021-07-21', y: 248 },
            { x: '2021-07-22', y: 242 },
            { x: '2021-07-24', y: 140 },
            { x: '2021-07-26', y: 278 },
            { x: '2021-07-27', y: 218 },
            { x: '2021-07-28', y: 260 },
            { x: '2021-07-29', y: 228 },
            { x: '2021-07-30', y: 235 },
            { x: '2021-07-31', y: 178 },
            { x: '2021-08-02', y: 231 },
            { x: '2021-08-03', y: 251 },
            { x: '2021-08-04', y: 270 },
            { x: '2021-08-05', y: 257 },
            { x: '2021-08-06', y: 242 },
            { x: '2021-08-07', y: 161 },
            { x: '2021-08-09', y: 245 },
            { x: '2021-08-10', y: 241 },
            { x: '2021-08-11', y: 286 },
            { x: '2021-08-12', y: 230 },
            { x: '2021-08-13', y: 213 },
            { x: '2021-08-14', y: 173 },
            { x: '2021-08-16', y: 281 },
            { x: '2021-08-17', y: 241 },
            { x: '2021-08-18', y: 234 },
            { x: '2021-08-19', y: 253 },
            { x: '2021-08-20', y: 254 },
            { x: '2021-08-21', y: 186 },
            { x: '2021-08-23', y: 246 },
            { x: '2021-08-24', y: 241 },
            { x: '2021-08-25', y: 230 },
            { x: '2021-08-26', y: 248 },
            { x: '2021-08-27', y: 297 },
            { x: '2021-08-28', y: 240 },
            { x: '2021-08-30', y: 262 },
            { x: '2021-08-31', y: 239 },
            { x: '2021-09-01', y: 218 },
            { x: '2021-09-02', y: 289 },
            { x: '2021-09-03', y: 228 },
            { x: '2021-09-04', y: 216 },
            { x: '2021-09-06', y: 204 },
            { x: '2021-09-07', y: 269 },
            { x: '2021-09-08', y: 265 },
            { x: '2021-09-09', y: 255 },
            { x: '2021-09-10', y: 280 },
            { x: '2021-09-11', y: 219 },
            { x: '2021-09-13', y: 236 },
            { x: '2021-09-14', y: 291 },
            { x: '2021-09-15', y: 216 },
            { x: '2021-09-16', y: 284 },
            { x: '2021-09-17', y: 322 },
            { x: '2021-09-18', y: 181 },
            { x: '2021-09-20', y: 260 },
            { x: '2021-09-21', y: 268 },
            { x: '2021-09-22', y: 261 },
            { x: '2021-09-23', y: 106 },
        ],
    },
    {
        id: 'french',
        color: '#16a4d8',
        data: [
            { x: '2019-01-01', y: 152 },
            { x: '2019-01-02', y: 256 },
            { x: '2019-01-03', y: 314 },
            { x: '2019-01-04', y: 304 },
            { x: '2019-01-05', y: 182 },
            { x: '2019-01-07', y: 237 },
            { x: '2019-01-08', y: 290 },
            { x: '2019-01-09', y: 338 },
            { x: '2019-01-10', y: 308 },
            { x: '2019-01-11', y: 307 },
            { x: '2019-01-12', y: 246 },
            { x: '2019-01-14', y: 291 },
            { x: '2019-01-15', y: 264 },
            { x: '2019-01-16', y: 280 },
            { x: '2019-01-17', y: 272 },
            { x: '2019-01-18', y: 317 },
            { x: '2019-01-19', y: 227 },
            { x: '2019-01-21', y: 278 },
            { x: '2019-01-22', y: 299 },
            { x: '2019-01-23', y: 271 },
            { x: '2019-01-24', y: 337 },
            { x: '2019-01-25', y: 387 },
            { x: '2019-01-26', y: 316 },
            { x: '2019-01-28', y: 287 },
            { x: '2019-01-29', y: 276 },
            { x: '2019-01-30', y: 328 },
            { x: '2019-01-31', y: 338 },
            { x: '2019-02-01', y: 388 },
            { x: '2019-02-02', y: 203 },
            { x: '2019-02-04', y: 294 },
            { x: '2019-02-05', y: 261 },
            { x: '2019-02-06', y: 270 },
            { x: '2019-02-07', y: 322 },
            { x: '2019-02-08', y: 360 },
            { x: '2019-02-09', y: 233 },
            { x: '2019-02-11', y: 288 },
            { x: '2019-02-12', y: 303 },
            { x: '2019-02-13', y: 309 },
            { x: '2019-02-14', y: 306 },
            { x: '2019-02-15', y: 381 },
            { x: '2019-02-16', y: 450 },
            { x: '2019-02-18', y: 349 },
            { x: '2019-02-19', y: 285 },
            { x: '2019-02-20', y: 274 },
            { x: '2019-02-21', y: 257 },
            { x: '2019-02-22', y: 323 },
            { x: '2019-02-23', y: 264 },
            { x: '2019-02-25', y: 295 },
            { x: '2019-02-26', y: 316 },
            { x: '2019-02-27', y: 391 },
            { x: '2019-02-28', y: 371 },
            { x: '2019-03-01', y: 394 },
            { x: '2019-03-02', y: 215 },
            { x: '2019-03-04', y: 344 },
            { x: '2019-03-05', y: 325 },
            { x: '2019-03-07', y: 398 },
            { x: '2019-03-08', y: 479 },
            { x: '2019-03-09', y: 353 },
            { x: '2019-03-11', y: 354 },
            { x: '2019-03-12', y: 242 },
            { x: '2019-03-13', y: 280 },
            { x: '2019-03-14', y: 351 },
            { x: '2019-03-15', y: 392 },
            { x: '2019-03-16', y: 335 },
            { x: '2019-03-18', y: 387 },
            { x: '2019-03-19', y: 362 },
            { x: '2019-03-20', y: 351 },
            { x: '2019-03-21', y: 308 },
            { x: '2019-03-22', y: 432 },
            { x: '2019-03-23', y: 478 },
            { x: '2019-03-25', y: 395 },
            { x: '2019-03-26', y: 391 },
            { x: '2019-03-27', y: 341 },
            { x: '2019-03-28', y: 359 },
            { x: '2019-03-29', y: 450 },
            { x: '2019-03-30', y: 369 },
            { x: '2019-04-01', y: 388 },
            { x: '2019-04-02', y: 382 },
            { x: '2019-04-03', y: 425 },
            { x: '2019-04-04', y: 434 },
            { x: '2019-04-05', y: 470 },
            { x: '2019-04-06', y: 322 },
            { x: '2019-04-08', y: 350 },
            { x: '2019-04-09', y: 355 },
            { x: '2019-04-10', y: 384 },
            { x: '2019-04-11', y: 348 },
            { x: '2019-04-12', y: 383 },
            { x: '2019-04-13', y: 361 },
            { x: '2019-04-15', y: 387 },
            { x: '2019-04-16', y: 259 },
            { x: '2019-04-17', y: 387 },
            { x: '2019-04-18', y: 478 },
            { x: '2019-04-19', y: 353 },
            { x: '2019-04-20', y: 291 },
            { x: '2019-04-22', y: 369 },
            { x: '2019-04-23', y: 362 },
            { x: '2019-04-24', y: 370 },
            { x: '2019-04-25', y: 369 },
            { x: '2019-04-26', y: 382 },
            { x: '2019-04-27', y: 271 },
            { x: '2019-04-29', y: 292 },
            { x: '2019-04-30', y: 296 },
            { x: '2019-05-01', y: 333 },
            { x: '2019-05-02', y: 380 },
            { x: '2019-05-03', y: 488 },
            { x: '2019-05-04', y: 386 },
            { x: '2019-05-06', y: 333 },
            { x: '2019-05-07', y: 289 },
            { x: '2019-05-08', y: 322 },
            { x: '2019-05-09', y: 308 },
            { x: '2019-05-10', y: 327 },
            { x: '2019-05-11', y: 296 },
            { x: '2019-05-13', y: 330 },
            { x: '2019-05-14', y: 350 },
            { x: '2019-05-15', y: 378 },
            { x: '2019-05-16', y: 316 },
            { x: '2019-05-17', y: 352 },
            { x: '2019-05-18', y: 237 },
            { x: '2019-05-20', y: 303 },
            { x: '2019-05-21', y: 357 },
            { x: '2019-05-22', y: 338 },
            { x: '2019-05-23', y: 278 },
            { x: '2019-05-24', y: 346 },
            { x: '2019-05-25', y: 368 },
            { x: '2019-05-27', y: 187 },
            { x: '2019-05-28', y: 305 },
            { x: '2019-05-29', y: 327 },
            { x: '2019-05-30', y: 330 },
            { x: '2019-05-31', y: 314 },
            { x: '2019-06-01', y: 255 },
            { x: '2019-06-03', y: 338 },
            { x: '2019-06-04', y: 387 },
            { x: '2019-06-05', y: 363 },
            { x: '2019-06-06', y: 379 },
            { x: '2019-06-07', y: 329 },
            { x: '2019-06-08', y: 247 },
            { x: '2019-06-10', y: 363 },
            { x: '2019-06-11', y: 312 },
            { x: '2019-06-12', y: 324 },
            { x: '2019-06-13', y: 353 },
            { x: '2019-06-15', y: 242 },
            { x: '2019-06-17', y: 349 },
            { x: '2019-06-18', y: 305 },
            { x: '2019-06-19', y: 325 },
            { x: '2019-06-20', y: 350 },
            { x: '2019-06-21', y: 323 },
            { x: '2019-06-22', y: 252 },
            { x: '2019-06-24', y: 349 },
            { x: '2019-06-25', y: 316 },
            { x: '2019-06-26', y: 335 },
            { x: '2019-06-27', y: 320 },
            { x: '2019-06-28', y: 299 },
            { x: '2019-06-29', y: 193 },
            { x: '2019-07-01', y: 268 },
            { x: '2019-07-02', y: 306 },
            { x: '2019-07-03', y: 352 },
            { x: '2019-07-04', y: 175 },
            { x: '2019-07-05', y: 241 },
            { x: '2019-07-06', y: 227 },
            { x: '2019-07-08', y: 314 },
            { x: '2019-07-09', y: 320 },
            { x: '2019-07-10', y: 315 },
            { x: '2019-07-11', y: 272 },
            { x: '2019-07-12', y: 312 },
            { x: '2019-07-13', y: 211 },
            { x: '2019-07-15', y: 313 },
            { x: '2019-07-16', y: 287 },
            { x: '2019-07-17', y: 314 },
            { x: '2019-07-18', y: 320 },
            { x: '2019-07-19', y: 344 },
            { x: '2019-07-20', y: 194 },
            { x: '2019-07-22', y: 298 },
            { x: '2019-07-23', y: 321 },
            { x: '2019-07-24', y: 210 },
            { x: '2019-07-25', y: 301 },
            { x: '2019-07-26', y: 262 },
            { x: '2019-07-27', y: 213 },
            { x: '2019-07-29', y: 338 },
            { x: '2019-07-30', y: 295 },
            { x: '2019-07-31', y: 283 },
            { x: '2019-08-01', y: 300 },
            { x: '2019-08-02', y: 353 },
            { x: '2019-08-03', y: 215 },
            { x: '2019-08-05', y: 319 },
            { x: '2019-08-06', y: 310 },
            { x: '2019-08-07', y: 312 },
            { x: '2019-08-08', y: 313 },
            { x: '2019-08-09', y: 304 },
            { x: '2019-08-10', y: 256 },
            { x: '2019-08-12', y: 342 },
            { x: '2019-08-13', y: 298 },
            { x: '2019-08-14', y: 346 },
            { x: '2019-08-15', y: 305 },
            { x: '2019-08-16', y: 359 },
            { x: '2019-08-17', y: 230 },
            { x: '2019-08-19', y: 292 },
            { x: '2019-08-20', y: 315 },
            { x: '2019-08-21', y: 327 },
            { x: '2019-08-22', y: 369 },
            { x: '2019-08-23', y: 371 },
            { x: '2019-08-24', y: 248 },
            { x: '2019-08-26', y: 332 },
            { x: '2019-08-27', y: 338 },
            { x: '2019-08-28', y: 325 },
            { x: '2019-08-29', y: 336 },
            { x: '2019-08-30', y: 329 },
            { x: '2019-08-31', y: 260 },
            { x: '2019-09-02', y: 210 },
            { x: '2019-09-03', y: 284 },
            { x: '2019-09-04', y: 297 },
            { x: '2019-09-05', y: 327 },
            { x: '2019-09-06', y: 362 },
            { x: '2019-09-07', y: 298 },
            { x: '2019-09-09', y: 306 },
            { x: '2019-09-10', y: 323 },
            { x: '2019-09-11', y: 330 },
            { x: '2019-09-12', y: 298 },
            { x: '2019-09-13', y: 345 },
            { x: '2019-09-14', y: 258 },
            { x: '2019-09-16', y: 289 },
            { x: '2019-09-17', y: 347 },
            { x: '2019-09-18', y: 335 },
            { x: '2019-09-19', y: 349 },
            { x: '2019-09-20', y: 320 },
            { x: '2019-09-21', y: 276 },
            { x: '2019-09-23', y: 315 },
            { x: '2019-09-24', y: 309 },
            { x: '2019-09-25', y: 296 },
            { x: '2019-09-26', y: 322 },
            { x: '2019-09-27', y: 335 },
            { x: '2019-09-28', y: 240 },
            { x: '2019-09-30', y: 314 },
            { x: '2019-10-01', y: 324 },
            { x: '2019-10-02', y: 317 },
            { x: '2019-10-03', y: 365 },
            { x: '2019-10-04', y: 380 },
            { x: '2019-10-05', y: 293 },
            { x: '2019-10-07', y: 372 },
            { x: '2019-10-08', y: 287 },
            { x: '2019-10-09', y: 357 },
            { x: '2019-10-10', y: 315 },
            { x: '2019-10-11', y: 307 },
            { x: '2019-10-12', y: 260 },
            { x: '2019-10-14', y: 334 },
            { x: '2019-10-15', y: 342 },
            { x: '2019-10-16', y: 348 },
            { x: '2019-10-17', y: 345 },
            { x: '2019-10-18', y: 370 },
            { x: '2019-10-19', y: 252 },
            { x: '2019-10-21', y: 317 },
            { x: '2019-10-22', y: 271 },
            { x: '2019-10-23', y: 336 },
            { x: '2019-10-24', y: 336 },
            { x: '2019-10-25', y: 297 },
            { x: '2019-10-26', y: 279 },
            { x: '2019-10-28', y: 267 },
            { x: '2019-10-29', y: 268 },
            { x: '2019-10-30', y: 307 },
            { x: '2019-10-31', y: 245 },
            { x: '2019-11-01', y: 339 },
            { x: '2019-11-02', y: 300 },
            { x: '2019-11-04', y: 278 },
            { x: '2019-11-05', y: 271 },
            { x: '2019-11-06', y: 284 },
            { x: '2019-11-07', y: 334 },
            { x: '2019-11-08', y: 381 },
            { x: '2019-11-09', y: 371 },
            { x: '2019-11-11', y: 289 },
            { x: '2019-11-12', y: 272 },
            { x: '2019-11-13', y: 319 },
            { x: '2019-11-14', y: 290 },
            { x: '2019-11-15', y: 316 },
            { x: '2019-11-16', y: 238 },
            { x: '2019-11-18', y: 307 },
            { x: '2019-11-19', y: 295 },
            { x: '2019-11-20', y: 257 },
            { x: '2019-11-21', y: 271 },
            { x: '2019-11-22', y: 324 },
            { x: '2019-11-23', y: 253 },
            { x: '2019-11-25', y: 279 },
            { x: '2019-11-26', y: 351 },
            { x: '2019-11-27', y: 254 },
            { x: '2019-11-29', y: 201 },
            { x: '2019-11-30', y: 180 },
            { x: '2019-12-02', y: 281 },
            { x: '2019-12-03', y: 257 },
            { x: '2019-12-04', y: 308 },
            { x: '2019-12-05', y: 307 },
            { x: '2019-12-06', y: 343 },
            { x: '2019-12-07', y: 219 },
            { x: '2019-12-09', y: 285 },
            { x: '2019-12-10', y: 254 },
            { x: '2019-12-11', y: 313 },
            { x: '2019-12-12', y: 296 },
            { x: '2019-12-13', y: 266 },
            { x: '2019-12-14', y: 195 },
            { x: '2019-12-16', y: 249 },
            { x: '2019-12-17', y: 258 },
            { x: '2019-12-18', y: 284 },
            { x: '2019-12-19', y: 286 },
            { x: '2019-12-20', y: 286 },
            { x: '2019-12-21', y: 224 },
            { x: '2019-12-23', y: 294 },
            { x: '2019-12-24', y: 120 },
            { x: '2019-12-26', y: 186 },
            { x: '2019-12-27', y: 273 },
            { x: '2019-12-28', y: 208 },
            { x: '2019-12-30', y: 275 },
            { x: '2020-01-01', y: 157 },
            { x: '2020-01-02', y: 292 },
            { x: '2020-01-03', y: 301 },
            { x: '2020-01-04', y: 217 },
            { x: '2020-01-06', y: 284 },
            { x: '2020-01-07', y: 319 },
            { x: '2020-01-08', y: 316 },
            { x: '2020-01-09', y: 290 },
            { x: '2020-01-10', y: 309 },
            { x: '2020-01-11', y: 216 },
            { x: '2020-01-13', y: 293 },
            { x: '2020-01-14', y: 289 },
            { x: '2020-01-15', y: 324 },
            { x: '2020-01-16', y: 307 },
            { x: '2020-01-17', y: 323 },
            { x: '2020-01-18', y: 282 },
            { x: '2020-01-20', y: 275 },
            { x: '2020-01-21', y: 287 },
            { x: '2020-01-22', y: 286 },
            { x: '2020-01-23', y: 309 },
            { x: '2020-01-24', y: 329 },
            { x: '2020-01-25', y: 282 },
            { x: '2020-01-27', y: 319 },
            { x: '2020-01-28', y: 295 },
            { x: '2020-01-29', y: 296 },
            { x: '2020-01-30', y: 290 },
            { x: '2020-01-31', y: 350 },
            { x: '2020-02-01', y: 268 },
            { x: '2020-02-03', y: 238 },
            { x: '2020-02-04', y: 291 },
            { x: '2020-02-05', y: 294 },
            { x: '2020-02-06', y: 315 },
            { x: '2020-02-07', y: 318 },
            { x: '2020-02-08', y: 259 },
            { x: '2020-02-10', y: 333 },
            { x: '2020-02-11', y: 300 },
            { x: '2020-02-12', y: 298 },
            { x: '2020-02-13', y: 351 },
            { x: '2020-02-14', y: 428 },
            { x: '2020-02-15', y: 458 },
            { x: '2020-02-17', y: 401 },
            { x: '2020-02-18', y: 306 },
            { x: '2020-02-19', y: 332 },
            { x: '2020-02-20', y: 383 },
            { x: '2020-02-21', y: 402 },
            { x: '2020-02-22', y: 265 },
            { x: '2020-02-24', y: 317 },
            { x: '2020-02-25', y: 304 },
            { x: '2020-02-26', y: 307 },
            { x: '2020-02-27', y: 263 },
            { x: '2020-02-28', y: 257 },
            { x: '2020-02-29', y: 243 },
            { x: '2020-03-02', y: 230 },
            { x: '2020-03-03', y: 260 },
            { x: '2020-03-04', y: 270 },
            { x: '2020-03-05', y: 321 },
            { x: '2020-03-06', y: 438 },
            { x: '2020-03-07', y: 287 },
            { x: '2020-03-09', y: 272 },
            { x: '2020-03-10', y: 258 },
            { x: '2020-03-11', y: 305 },
            { x: '2020-03-12', y: 328 },
            { x: '2020-03-13', y: 244 },
            { x: '2020-03-14', y: 271 },
            { x: '2020-03-16', y: 238 },
            { x: '2020-03-17', y: 235 },
            { x: '2020-03-18', y: 226 },
            { x: '2020-03-19', y: 224 },
            { x: '2020-03-20', y: 200 },
            { x: '2020-03-21', y: 160 },
            { x: '2020-03-23', y: 215 },
            { x: '2020-03-24', y: 300 },
            { x: '2020-03-25', y: 258 },
            { x: '2020-03-26', y: 281 },
            { x: '2020-03-27', y: 211 },
            { x: '2020-03-28', y: 207 },
            { x: '2020-03-30', y: 240 },
            { x: '2020-03-31', y: 225 },
            { x: '2020-04-01', y: 238 },
            { x: '2020-04-02', y: 289 },
            { x: '2020-04-03', y: 257 },
            { x: '2020-04-04', y: 199 },
            { x: '2020-04-06', y: 299 },
            { x: '2020-04-07', y: 266 },
            { x: '2020-04-08', y: 243 },
            { x: '2020-04-10', y: 215 },
            { x: '2020-04-11', y: 248 },
            { x: '2020-04-13', y: 313 },
            { x: '2020-04-14', y: 250 },
            { x: '2020-04-15', y: 304 },
            { x: '2020-04-16', y: 298 },
            { x: '2020-04-17', y: 361 },
            { x: '2020-04-18', y: 286 },
            { x: '2020-04-20', y: 390 },
            { x: '2020-04-21', y: 249 },
            { x: '2020-04-22', y: 365 },
            { x: '2020-04-23', y: 308 },
            { x: '2020-04-24', y: 423 },
            { x: '2020-04-25', y: 364 },
            { x: '2020-04-27', y: 420 },
            { x: '2020-04-28', y: 328 },
            { x: '2020-04-29', y: 339 },
            { x: '2020-04-30', y: 359 },
            { x: '2020-05-01', y: 398 },
            { x: '2020-05-02', y: 371 },
            { x: '2020-05-04', y: 374 },
            { x: '2020-05-05', y: 296 },
            { x: '2020-05-06', y: 367 },
            { x: '2020-05-07', y: 318 },
            { x: '2020-05-08', y: 380 },
            { x: '2020-05-09', y: 335 },
            { x: '2020-05-11', y: 340 },
            { x: '2020-05-12', y: 342 },
            { x: '2020-05-13', y: 353 },
            { x: '2020-05-14', y: 384 },
            { x: '2020-05-15', y: 390 },
            { x: '2020-05-16', y: 395 },
            { x: '2020-05-18', y: 363 },
            { x: '2020-05-19', y: 321 },
            { x: '2020-05-20', y: 392 },
            { x: '2020-05-21', y: 379 },
            { x: '2020-05-22', y: 403 },
            { x: '2020-05-23', y: 379 },
            { x: '2020-05-25', y: 311 },
            { x: '2020-05-26', y: 345 },
            { x: '2020-05-27', y: 355 },
            { x: '2020-05-28', y: 368 },
            { x: '2020-05-29', y: 421 },
            { x: '2020-05-30', y: 325 },
            { x: '2020-06-01', y: 361 },
            { x: '2020-06-02', y: 338 },
            { x: '2020-06-03', y: 357 },
            { x: '2020-06-04', y: 367 },
            { x: '2020-06-05', y: 424 },
            { x: '2020-06-06', y: 275 },
            { x: '2020-06-08', y: 367 },
            { x: '2020-06-09', y: 353 },
            { x: '2020-06-10', y: 350 },
            { x: '2020-06-11', y: 394 },
            { x: '2020-06-12', y: 377 },
            { x: '2020-06-13', y: 298 },
            { x: '2020-06-15', y: 410 },
            { x: '2020-06-16', y: 350 },
            { x: '2020-06-17', y: 364 },
            { x: '2020-06-18', y: 381 },
            { x: '2020-06-19', y: 420 },
            { x: '2020-06-20', y: 289 },
            { x: '2020-06-22', y: 347 },
            { x: '2020-06-23', y: 356 },
            { x: '2020-06-24', y: 367 },
            { x: '2020-06-25', y: 355 },
            { x: '2020-06-26', y: 427 },
            { x: '2020-06-27', y: 287 },
            { x: '2020-06-29', y: 432 },
            { x: '2020-06-30', y: 350 },
            { x: '2020-07-01', y: 400 },
            { x: '2020-07-02', y: 351 },
            { x: '2020-07-03', y: 323 },
            { x: '2020-07-04', y: 218 },
            { x: '2020-07-06', y: 336 },
            { x: '2020-07-07', y: 295 },
            { x: '2020-07-08', y: 355 },
            { x: '2020-07-09', y: 304 },
            { x: '2020-07-10', y: 295 },
            { x: '2020-07-11', y: 297 },
            { x: '2020-07-13', y: 397 },
            { x: '2020-07-14', y: 362 },
            { x: '2020-07-15', y: 349 },
            { x: '2020-07-16', y: 373 },
            { x: '2020-07-17', y: 374 },
            { x: '2020-07-20', y: 297 },
            { x: '2020-07-21', y: 329 },
            { x: '2020-07-22', y: 333 },
            { x: '2020-07-23', y: 335 },
            { x: '2020-07-24', y: 278 },
            { x: '2020-07-25', y: 278 },
            { x: '2020-07-27', y: 323 },
            { x: '2020-07-28', y: 361 },
            { x: '2020-07-29', y: 332 },
            { x: '2020-07-30', y: 378 },
            { x: '2020-07-31', y: 389 },
            { x: '2020-08-01', y: 257 },
            { x: '2020-08-03', y: 348 },
            { x: '2020-08-04', y: 309 },
            { x: '2020-08-05', y: 359 },
            { x: '2020-08-06', y: 341 },
            { x: '2020-08-07', y: 338 },
            { x: '2020-08-08', y: 272 },
            { x: '2020-08-10', y: 321 },
            { x: '2020-08-11', y: 338 },
            { x: '2020-08-12', y: 383 },
            { x: '2020-08-13', y: 359 },
            { x: '2020-08-14', y: 373 },
            { x: '2020-08-15', y: 140 },
            { x: '2020-08-17', y: 342 },
            { x: '2020-08-18', y: 314 },
            { x: '2020-08-19', y: 360 },
            { x: '2020-08-20', y: 321 },
            { x: '2020-08-21', y: 349 },
            { x: '2020-08-22', y: 247 },
            { x: '2020-08-24', y: 396 },
            { x: '2020-08-25', y: 347 },
            { x: '2020-08-26', y: 356 },
            { x: '2020-08-27', y: 327 },
            { x: '2020-08-28', y: 410 },
            { x: '2020-08-29', y: 330 },
            { x: '2020-08-31', y: 360 },
            { x: '2020-09-01', y: 340 },
            { x: '2020-09-02', y: 340 },
            { x: '2020-09-03', y: 361 },
            { x: '2020-09-04', y: 389 },
            { x: '2020-09-05', y: 310 },
            { x: '2020-09-07', y: 222 },
            { x: '2020-09-08', y: 352 },
            { x: '2020-09-09', y: 321 },
            { x: '2020-09-10', y: 387 },
            { x: '2020-09-11', y: 357 },
            { x: '2020-09-12', y: 336 },
            { x: '2020-09-14', y: 370 },
            { x: '2020-09-15', y: 350 },
            { x: '2020-09-16', y: 339 },
            { x: '2020-09-17', y: 398 },
            { x: '2020-09-18', y: 337 },
            { x: '2020-09-19', y: 344 },
            { x: '2020-09-21', y: 381 },
            { x: '2020-09-22', y: 350 },
            { x: '2020-09-23', y: 339 },
            { x: '2020-09-24', y: 379 },
            { x: '2020-09-25', y: 378 },
            { x: '2020-09-26', y: 281 },
            { x: '2020-09-28', y: 306 },
            { x: '2020-09-29', y: 354 },
            { x: '2020-09-30', y: 297 },
            { x: '2020-10-01', y: 351 },
            { x: '2020-10-02', y: 325 },
            { x: '2020-10-03', y: 239 },
            { x: '2020-10-05', y: 331 },
            { x: '2020-10-06', y: 313 },
            { x: '2020-10-07', y: 346 },
            { x: '2020-10-08', y: 359 },
            { x: '2020-10-09', y: 338 },
            { x: '2020-10-10', y: 313 },
            { x: '2020-10-12', y: 322 },
            { x: '2020-10-13', y: 321 },
            { x: '2020-10-14', y: 313 },
            { x: '2020-10-15', y: 343 },
            { x: '2020-10-16', y: 340 },
            { x: '2020-10-17', y: 289 },
            { x: '2020-10-19', y: 321 },
            { x: '2020-10-20', y: 321 },
            { x: '2020-10-21', y: 331 },
            { x: '2020-10-22', y: 342 },
            { x: '2020-10-23', y: 386 },
            { x: '2020-10-24', y: 343 },
            { x: '2020-10-27', y: 284 },
            { x: '2020-10-28', y: 309 },
            { x: '2020-10-29', y: 363 },
            { x: '2020-10-30', y: 395 },
            { x: '2020-10-31', y: 231 },
            { x: '2020-11-02', y: 286 },
            { x: '2020-11-03', y: 371 },
            { x: '2020-11-04', y: 320 },
            { x: '2020-11-05', y: 297 },
            { x: '2020-11-06', y: 314 },
            { x: '2020-11-07', y: 240 },
            { x: '2020-11-09', y: 290 },
            { x: '2020-11-10', y: 298 },
            { x: '2020-11-11', y: 260 },
            { x: '2020-11-12', y: 243 },
            { x: '2020-11-13', y: 307 },
            { x: '2020-11-14', y: 201 },
            { x: '2020-11-16', y: 282 },
            { x: '2020-11-17', y: 285 },
            { x: '2020-11-18', y: 335 },
            { x: '2020-11-19', y: 321 },
            { x: '2020-11-20', y: 268 },
            { x: '2020-11-21', y: 214 },
            { x: '2020-11-23', y: 311 },
            { x: '2020-11-24', y: 309 },
            { x: '2020-11-25', y: 314 },
            { x: '2020-11-27', y: 204 },
            { x: '2020-11-28', y: 226 },
            { x: '2020-11-30', y: 302 },
            { x: '2020-12-01', y: 286 },
            { x: '2020-12-02', y: 262 },
            { x: '2020-12-03', y: 300 },
            { x: '2020-12-04', y: 296 },
            { x: '2020-12-05', y: 222 },
            { x: '2020-12-07', y: 258 },
            { x: '2020-12-08', y: 283 },
            { x: '2020-12-09', y: 283 },
            { x: '2020-12-10', y: 311 },
            { x: '2020-12-11', y: 274 },
            { x: '2020-12-12', y: 184 },
            { x: '2020-12-14', y: 289 },
            { x: '2020-12-15', y: 314 },
            { x: '2020-12-16', y: 289 },
            { x: '2020-12-17', y: 273 },
            { x: '2020-12-18', y: 285 },
            { x: '2020-12-19', y: 233 },
            { x: '2020-12-21', y: 264 },
            { x: '2020-12-22', y: 269 },
            { x: '2020-12-23', y: 287 },
            { x: '2020-12-24', y: 122 },
            { x: '2020-12-26', y: 153 },
            { x: '2020-12-28', y: 251 },
            { x: '2020-12-29', y: 301 },
            { x: '2020-12-30', y: 310 },
            { x: '2020-12-31', y: 262 },
            { x: '2021-01-01', y: 164 },
            { x: '2021-01-02', y: 240 },
            { x: '2021-01-04', y: 294 },
            { x: '2021-01-05', y: 336 },
            { x: '2021-01-06', y: 379 },
            { x: '2021-01-07', y: 274 },
            { x: '2021-01-08', y: 321 },
            { x: '2021-01-09', y: 244 },
            { x: '2021-01-11', y: 324 },
            { x: '2021-01-12', y: 262 },
            { x: '2021-01-13', y: 340 },
            { x: '2021-01-14', y: 308 },
            { x: '2021-01-15', y: 301 },
            { x: '2021-01-16', y: 299 },
            { x: '2021-01-18', y: 357 },
            { x: '2021-01-19', y: 285 },
            { x: '2021-01-20', y: 353 },
            { x: '2021-01-21', y: 339 },
            { x: '2021-01-22', y: 345 },
            { x: '2021-01-23', y: 216 },
            { x: '2021-01-25', y: 257 },
            { x: '2021-01-26', y: 306 },
            { x: '2021-01-27', y: 291 },
            { x: '2021-01-28', y: 310 },
            { x: '2021-01-29', y: 288 },
            { x: '2021-01-30', y: 295 },
            { x: '2021-02-01', y: 196 },
            { x: '2021-02-02', y: 265 },
            { x: '2021-02-04', y: 301 },
            { x: '2021-02-05', y: 318 },
            { x: '2021-02-06', y: 255 },
            { x: '2021-02-08', y: 228 },
            { x: '2021-02-09', y: 231 },
            { x: '2021-02-10', y: 265 },
            { x: '2021-02-11', y: 233 },
            { x: '2021-02-12', y: 395 },
            { x: '2021-02-13', y: 326 },
            { x: '2021-02-15', y: 289 },
            { x: '2021-02-16', y: 206 },
            { x: '2021-02-17', y: 292 },
            { x: '2021-02-18', y: 267 },
            { x: '2021-02-19', y: 304 },
            { x: '2021-02-20', y: 274 },
            { x: '2021-02-22', y: 214 },
            { x: '2021-02-23', y: 258 },
            { x: '2021-02-24', y: 269 },
            { x: '2021-02-25', y: 303 },
            { x: '2021-02-26', y: 270 },
            { x: '2021-02-27', y: 241 },
            { x: '2021-03-01', y: 253 },
            { x: '2021-03-02', y: 281 },
            { x: '2021-03-03', y: 240 },
            { x: '2021-03-04', y: 266 },
            { x: '2021-03-05', y: 261 },
            { x: '2021-03-06', y: 237 },
            { x: '2021-03-08', y: 265 },
            { x: '2021-03-09', y: 232 },
            { x: '2021-03-10', y: 230 },
            { x: '2021-03-11', y: 279 },
            { x: '2021-03-12', y: 275 },
            { x: '2021-03-13', y: 140 },
            { x: '2021-03-15', y: 281 },
            { x: '2021-03-16', y: 221 },
            { x: '2021-03-17', y: 247 },
            { x: '2021-03-18', y: 308 },
            { x: '2021-03-19', y: 484 },
            { x: '2021-03-20', y: 429 },
            { x: '2021-03-22', y: 302 },
            { x: '2021-03-23', y: 272 },
            { x: '2021-03-24', y: 314 },
            { x: '2021-03-25', y: 301 },
            { x: '2021-03-26', y: 368 },
            { x: '2021-03-27', y: 402 },
            { x: '2021-03-29', y: 334 },
            { x: '2021-03-30', y: 288 },
            { x: '2021-03-31', y: 328 },
            { x: '2021-04-01', y: 395 },
            { x: '2021-04-02', y: 413 },
            { x: '2021-04-03', y: 349 },
            { x: '2021-04-05', y: 323 },
            { x: '2021-04-06', y: 315 },
            { x: '2021-04-07', y: 332 },
            { x: '2021-04-08', y: 360 },
            { x: '2021-04-09', y: 355 },
            { x: '2021-04-10', y: 356 },
            { x: '2021-04-12', y: 264 },
            { x: '2021-04-13', y: 292 },
            { x: '2021-04-14', y: 285 },
            { x: '2021-04-15', y: 308 },
            { x: '2021-04-16', y: 320 },
            { x: '2021-04-17', y: 294 },
            { x: '2021-04-19', y: 285 },
            { x: '2021-04-20', y: 314 },
            { x: '2021-04-21', y: 348 },
            { x: '2021-04-22', y: 278 },
            { x: '2021-04-23', y: 313 },
            { x: '2021-04-24', y: 210 },
            { x: '2021-04-26', y: 230 },
            { x: '2021-04-27', y: 235 },
            { x: '2021-04-28', y: 255 },
            { x: '2021-04-29', y: 343 },
            { x: '2021-04-30', y: 369 },
            { x: '2021-05-01', y: 261 },
            { x: '2021-05-03', y: 285 },
            { x: '2021-05-04', y: 271 },
            { x: '2021-05-05', y: 252 },
            { x: '2021-05-06', y: 262 },
            { x: '2021-05-07', y: 284 },
            { x: '2021-05-08', y: 278 },
            { x: '2021-05-10', y: 250 },
            { x: '2021-05-12', y: 265 },
            { x: '2021-05-13', y: 292 },
            { x: '2021-05-14', y: 334 },
            { x: '2021-05-15', y: 247 },
            { x: '2021-05-17', y: 223 },
            { x: '2021-05-18', y: 262 },
            { x: '2021-05-19', y: 292 },
            { x: '2021-05-20', y: 280 },
            { x: '2021-05-21', y: 300 },
            { x: '2021-05-22', y: 199 },
            { x: '2021-05-24', y: 275 },
            { x: '2021-05-25', y: 255 },
            { x: '2021-05-26', y: 273 },
            { x: '2021-05-27', y: 275 },
            { x: '2021-05-28', y: 282 },
            { x: '2021-05-29', y: 242 },
            { x: '2021-05-31', y: 244 },
            { x: '2021-06-01', y: 281 },
            { x: '2021-06-02', y: 257 },
            { x: '2021-06-03', y: 296 },
            { x: '2021-06-04', y: 286 },
            { x: '2021-06-05', y: 231 },
            { x: '2021-06-07', y: 269 },
            { x: '2021-06-08', y: 267 },
            { x: '2021-06-09', y: 269 },
            { x: '2021-06-10', y: 270 },
            { x: '2021-06-11', y: 278 },
            { x: '2021-06-12', y: 178 },
            { x: '2021-06-14', y: 255 },
            { x: '2021-06-15', y: 301 },
            { x: '2021-06-16', y: 289 },
            { x: '2021-06-17', y: 278 },
            { x: '2021-06-18', y: 283 },
            { x: '2021-06-19', y: 168 },
            { x: '2021-06-21', y: 329 },
            { x: '2021-06-22', y: 265 },
            { x: '2021-06-23', y: 261 },
            { x: '2021-06-24', y: 264 },
            { x: '2021-06-25', y: 305 },
            { x: '2021-06-26', y: 204 },
            { x: '2021-06-28', y: 243 },
            { x: '2021-06-29', y: 270 },
            { x: '2021-06-30', y: 276 },
            { x: '2021-07-01', y: 292 },
            { x: '2021-07-02', y: 251 },
            { x: '2021-07-03', y: 138 },
            { x: '2021-07-05', y: 208 },
            { x: '2021-07-06', y: 292 },
            { x: '2021-07-07', y: 238 },
            { x: '2021-07-08', y: 306 },
            { x: '2021-07-09', y: 235 },
            { x: '2021-07-10', y: 241 },
            { x: '2021-07-12', y: 232 },
            { x: '2021-07-13', y: 244 },
            { x: '2021-07-14', y: 247 },
            { x: '2021-07-15', y: 265 },
            { x: '2021-07-16', y: 237 },
            { x: '2021-07-17', y: 174 },
            { x: '2021-07-19', y: 272 },
            { x: '2021-07-20', y: 216 },
            { x: '2021-07-21', y: 248 },
            { x: '2021-07-22', y: 242 },
            { x: '2021-07-24', y: 140 },
            { x: '2021-07-26', y: 278 },
            { x: '2021-07-27', y: 218 },
            { x: '2021-07-28', y: 260 },
            { x: '2021-07-29', y: 228 },
            { x: '2021-07-30', y: 235 },
            { x: '2021-07-31', y: 178 },
            { x: '2021-08-02', y: 231 },
            { x: '2021-08-03', y: 251 },
            { x: '2021-08-04', y: 270 },
            { x: '2021-08-05', y: 257 },
            { x: '2021-08-06', y: 242 },
            { x: '2021-08-07', y: 161 },
            { x: '2021-08-09', y: 245 },
            { x: '2021-08-10', y: 241 },
            { x: '2021-08-11', y: 286 },
            { x: '2021-08-12', y: 230 },
            { x: '2021-08-13', y: 213 },
            { x: '2021-08-14', y: 173 },
            { x: '2021-08-16', y: 281 },
            { x: '2021-08-17', y: 241 },
            { x: '2021-08-18', y: 234 },
            { x: '2021-08-19', y: 253 },
            { x: '2021-08-20', y: 254 },
            { x: '2021-08-21', y: 186 },
            { x: '2021-08-23', y: 246 },
            { x: '2021-08-24', y: 241 },
            { x: '2021-08-25', y: 230 },
            { x: '2021-08-26', y: 248 },
            { x: '2021-08-27', y: 297 },
            { x: '2021-08-28', y: 240 },
            { x: '2021-08-30', y: 262 },
            { x: '2021-08-31', y: 239 },
            { x: '2021-09-01', y: 218 },
            { x: '2021-09-02', y: 289 },
            { x: '2021-09-03', y: 228 },
            { x: '2021-09-04', y: 216 },
            { x: '2021-09-06', y: 204 },
            { x: '2021-09-07', y: 269 },
            { x: '2021-09-08', y: 265 },
            { x: '2021-09-09', y: 255 },
            { x: '2021-09-10', y: 280 },
            { x: '2021-09-11', y: 219 },
            { x: '2021-09-13', y: 236 },
            { x: '2021-09-14', y: 291 },
            { x: '2021-09-15', y: 216 },
            { x: '2021-09-16', y: 284 },
            { x: '2021-09-17', y: 322 },
            { x: '2021-09-18', y: 181 },
            { x: '2021-09-20', y: 260 },
            { x: '2021-09-21', y: 268 },
            { x: '2021-09-22', y: 261 },
            { x: '2021-09-23', y: 106 },
        ],
    },
    {
        id: 'cheese',
        color: '#60dbe8',
        data: [
            { x: '2019-01-01', y: 120 },
            { x: '2019-01-02', y: 216 },
            { x: '2019-01-03', y: 258 },
            { x: '2019-01-04', y: 276 },
            { x: '2019-01-05', y: 143 },
            { x: '2019-01-07', y: 206 },
            { x: '2019-01-08', y: 250 },
            { x: '2019-01-09', y: 281 },
            { x: '2019-01-10', y: 307 },
            { x: '2019-01-11', y: 293 },
            { x: '2019-01-12', y: 217 },
            { x: '2019-01-14', y: 249 },
            { x: '2019-01-15', y: 228 },
            { x: '2019-01-16', y: 248 },
            { x: '2019-01-17', y: 233 },
            { x: '2019-01-18', y: 272 },
            { x: '2019-01-19', y: 175 },
            { x: '2019-01-21', y: 250 },
            { x: '2019-01-22', y: 269 },
            { x: '2019-01-23', y: 247 },
            { x: '2019-01-24', y: 305 },
            { x: '2019-01-25', y: 330 },
            { x: '2019-01-26', y: 262 },
            { x: '2019-01-28', y: 235 },
            { x: '2019-01-29', y: 252 },
            { x: '2019-01-30', y: 280 },
            { x: '2019-01-31', y: 294 },
            { x: '2019-02-01', y: 332 },
            { x: '2019-02-02', y: 185 },
            { x: '2019-02-04', y: 264 },
            { x: '2019-02-05', y: 222 },
            { x: '2019-02-06', y: 218 },
            { x: '2019-02-07', y: 273 },
            { x: '2019-02-08', y: 297 },
            { x: '2019-02-09', y: 213 },
            { x: '2019-02-11', y: 242 },
            { x: '2019-02-12', y: 233 },
            { x: '2019-02-13', y: 280 },
            { x: '2019-02-14', y: 281 },
            { x: '2019-02-15', y: 321 },
            { x: '2019-02-16', y: 360 },
            { x: '2019-02-18', y: 321 },
            { x: '2019-02-19', y: 234 },
            { x: '2019-02-20', y: 218 },
            { x: '2019-02-21', y: 227 },
            { x: '2019-02-22', y: 244 },
            { x: '2019-02-23', y: 246 },
            { x: '2019-02-25', y: 262 },
            { x: '2019-02-26', y: 287 },
            { x: '2019-02-27', y: 334 },
            { x: '2019-02-28', y: 318 },
            { x: '2019-03-01', y: 337 },
            { x: '2019-03-02', y: 194 },
            { x: '2019-03-04', y: 321 },
            { x: '2019-03-05', y: 273 },
            { x: '2019-03-07', y: 334 },
            { x: '2019-03-08', y: 417 },
            { x: '2019-03-09', y: 284 },
            { x: '2019-03-11', y: 335 },
            { x: '2019-03-12', y: 240 },
            { x: '2019-03-13', y: 257 },
            { x: '2019-03-14', y: 324 },
            { x: '2019-03-15', y: 336 },
            { x: '2019-03-16', y: 278 },
            { x: '2019-03-18', y: 321 },
            { x: '2019-03-19', y: 321 },
            { x: '2019-03-20', y: 293 },
            { x: '2019-03-21', y: 235 },
            { x: '2019-03-22', y: 391 },
            { x: '2019-03-23', y: 403 },
            { x: '2019-03-25', y: 326 },
            { x: '2019-03-26', y: 352 },
            { x: '2019-03-27', y: 303 },
            { x: '2019-03-28', y: 309 },
            { x: '2019-03-29', y: 360 },
            { x: '2019-03-30', y: 337 },
            { x: '2019-04-01', y: 326 },
            { x: '2019-04-02', y: 336 },
            { x: '2019-04-03', y: 369 },
            { x: '2019-04-04', y: 373 },
            { x: '2019-04-05', y: 413 },
            { x: '2019-04-06', y: 285 },
            { x: '2019-04-08', y: 311 },
            { x: '2019-04-09', y: 296 },
            { x: '2019-04-10', y: 333 },
            { x: '2019-04-11', y: 317 },
            { x: '2019-04-12', y: 322 },
            { x: '2019-04-13', y: 275 },
            { x: '2019-04-15', y: 339 },
            { x: '2019-04-16', y: 230 },
            { x: '2019-04-17', y: 344 },
            { x: '2019-04-18', y: 405 },
            { x: '2019-04-19', y: 309 },
            { x: '2019-04-20', y: 269 },
            { x: '2019-04-22', y: 301 },
            { x: '2019-04-23', y: 331 },
            { x: '2019-04-24', y: 319 },
            { x: '2019-04-25', y: 334 },
            { x: '2019-04-26', y: 359 },
            { x: '2019-04-27', y: 222 },
            { x: '2019-04-29', y: 244 },
            { x: '2019-04-30', y: 237 },
            { x: '2019-05-01', y: 277 },
            { x: '2019-05-02', y: 340 },
            { x: '2019-05-03', y: 403 },
            { x: '2019-05-04', y: 287 },
            { x: '2019-05-06', y: 270 },
            { x: '2019-05-07', y: 279 },
            { x: '2019-05-08', y: 289 },
            { x: '2019-05-09', y: 263 },
            { x: '2019-05-10', y: 270 },
            { x: '2019-05-11', y: 267 },
            { x: '2019-05-13', y: 285 },
            { x: '2019-05-14', y: 319 },
            { x: '2019-05-15', y: 327 },
            { x: '2019-05-16', y: 293 },
            { x: '2019-05-17', y: 301 },
            { x: '2019-05-18', y: 209 },
            { x: '2019-05-20', y: 256 },
            { x: '2019-05-21', y: 285 },
            { x: '2019-05-22', y: 311 },
            { x: '2019-05-23', y: 227 },
            { x: '2019-05-24', y: 278 },
            { x: '2019-05-25', y: 334 },
            { x: '2019-05-27', y: 150 },
            { x: '2019-05-28', y: 263 },
            { x: '2019-05-29', y: 263 },
            { x: '2019-05-30', y: 236 },
            { x: '2019-05-31', y: 273 },
            { x: '2019-06-01', y: 217 },
            { x: '2019-06-03', y: 278 },
            { x: '2019-06-04', y: 323 },
            { x: '2019-06-05', y: 324 },
            { x: '2019-06-06', y: 327 },
            { x: '2019-06-07', y: 301 },
            { x: '2019-06-08', y: 222 },
            { x: '2019-06-10', y: 305 },
            { x: '2019-06-11', y: 276 },
            { x: '2019-06-12', y: 285 },
            { x: '2019-06-13', y: 306 },
            { x: '2019-06-15', y: 219 },
            { x: '2019-06-17', y: 304 },
            { x: '2019-06-18', y: 265 },
            { x: '2019-06-19', y: 282 },
            { x: '2019-06-20', y: 311 },
            { x: '2019-06-21', y: 268 },
            { x: '2019-06-22', y: 238 },
            { x: '2019-06-24', y: 312 },
            { x: '2019-06-25', y: 265 },
            { x: '2019-06-26', y: 276 },
            { x: '2019-06-27', y: 263 },
            { x: '2019-06-28', y: 263 },
            { x: '2019-06-29', y: 167 },
            { x: '2019-07-01', y: 223 },
            { x: '2019-07-02', y: 300 },
            { x: '2019-07-03', y: 288 },
            { x: '2019-07-04', y: 145 },
            { x: '2019-07-05', y: 216 },
            { x: '2019-07-06', y: 183 },
            { x: '2019-07-08', y: 274 },
            { x: '2019-07-09', y: 291 },
            { x: '2019-07-10', y: 252 },
            { x: '2019-07-11', y: 221 },
            { x: '2019-07-12', y: 292 },
            { x: '2019-07-13', y: 195 },
            { x: '2019-07-15', y: 252 },
            { x: '2019-07-16', y: 249 },
            { x: '2019-07-17', y: 264 },
            { x: '2019-07-18', y: 299 },
            { x: '2019-07-19', y: 297 },
            { x: '2019-07-20', y: 201 },
            { x: '2019-07-22', y: 258 },
            { x: '2019-07-23', y: 291 },
            { x: '2019-07-24', y: 169 },
            { x: '2019-07-25', y: 278 },
            { x: '2019-07-26', y: 235 },
            { x: '2019-07-27', y: 176 },
            { x: '2019-07-29', y: 322 },
            { x: '2019-07-30', y: 242 },
            { x: '2019-07-31', y: 235 },
            { x: '2019-08-01', y: 267 },
            { x: '2019-08-02', y: 290 },
            { x: '2019-08-03', y: 185 },
            { x: '2019-08-05', y: 279 },
            { x: '2019-08-06', y: 278 },
            { x: '2019-08-07', y: 284 },
            { x: '2019-08-08', y: 257 },
            { x: '2019-08-09', y: 254 },
            { x: '2019-08-10', y: 215 },
            { x: '2019-08-12', y: 302 },
            { x: '2019-08-13', y: 277 },
            { x: '2019-08-14', y: 289 },
            { x: '2019-08-15', y: 275 },
            { x: '2019-08-16', y: 324 },
            { x: '2019-08-17', y: 177 },
            { x: '2019-08-19', y: 236 },
            { x: '2019-08-20', y: 272 },
            { x: '2019-08-21', y: 291 },
            { x: '2019-08-22', y: 306 },
            { x: '2019-08-23', y: 319 },
            { x: '2019-08-24', y: 233 },
            { x: '2019-08-26', y: 294 },
            { x: '2019-08-27', y: 289 },
            { x: '2019-08-28', y: 266 },
            { x: '2019-08-29', y: 278 },
            { x: '2019-08-30', y: 305 },
            { x: '2019-08-31', y: 227 },
            { x: '2019-09-02', y: 202 },
            { x: '2019-09-03', y: 254 },
            { x: '2019-09-04', y: 252 },
            { x: '2019-09-05', y: 284 },
            { x: '2019-09-06', y: 301 },
            { x: '2019-09-07', y: 257 },
            { x: '2019-09-09', y: 240 },
            { x: '2019-09-10', y: 298 },
            { x: '2019-09-11', y: 282 },
            { x: '2019-09-12', y: 254 },
            { x: '2019-09-13', y: 276 },
            { x: '2019-09-14', y: 234 },
            { x: '2019-09-16', y: 252 },
            { x: '2019-09-17', y: 328 },
            { x: '2019-09-18', y: 317 },
            { x: '2019-09-19', y: 310 },
            { x: '2019-09-20', y: 256 },
            { x: '2019-09-21', y: 230 },
            { x: '2019-09-23', y: 277 },
            { x: '2019-09-24', y: 258 },
            { x: '2019-09-25', y: 239 },
            { x: '2019-09-26', y: 287 },
            { x: '2019-09-27', y: 285 },
            { x: '2019-09-28', y: 218 },
            { x: '2019-09-30', y: 287 },
            { x: '2019-10-01', y: 273 },
            { x: '2019-10-02', y: 273 },
            { x: '2019-10-03', y: 316 },
            { x: '2019-10-04', y: 358 },
            { x: '2019-10-05', y: 245 },
            { x: '2019-10-07', y: 329 },
            { x: '2019-10-08', y: 269 },
            { x: '2019-10-09', y: 326 },
            { x: '2019-10-10', y: 275 },
            { x: '2019-10-11', y: 271 },
            { x: '2019-10-12', y: 231 },
            { x: '2019-10-14', y: 303 },
            { x: '2019-10-15', y: 335 },
            { x: '2019-10-16', y: 320 },
            { x: '2019-10-17', y: 313 },
            { x: '2019-10-18', y: 343 },
            { x: '2019-10-19', y: 236 },
            { x: '2019-10-21', y: 254 },
            { x: '2019-10-22', y: 237 },
            { x: '2019-10-23', y: 297 },
            { x: '2019-10-24', y: 305 },
            { x: '2019-10-25', y: 270 },
            { x: '2019-10-26', y: 249 },
            { x: '2019-10-28', y: 252 },
            { x: '2019-10-29', y: 235 },
            { x: '2019-10-30', y: 305 },
            { x: '2019-10-31', y: 210 },
            { x: '2019-11-01', y: 308 },
            { x: '2019-11-02', y: 262 },
            { x: '2019-11-04', y: 270 },
            { x: '2019-11-05', y: 225 },
            { x: '2019-11-06', y: 256 },
            { x: '2019-11-07', y: 281 },
            { x: '2019-11-08', y: 303 },
            { x: '2019-11-09', y: 353 },
            { x: '2019-11-11', y: 272 },
            { x: '2019-11-12', y: 233 },
            { x: '2019-11-13', y: 294 },
            { x: '2019-11-14', y: 290 },
            { x: '2019-11-15', y: 290 },
            { x: '2019-11-16', y: 206 },
            { x: '2019-11-18', y: 270 },
            { x: '2019-11-19', y: 239 },
            { x: '2019-11-20', y: 240 },
            { x: '2019-11-21', y: 247 },
            { x: '2019-11-22', y: 300 },
            { x: '2019-11-23', y: 240 },
            { x: '2019-11-25', y: 228 },
            { x: '2019-11-26', y: 321 },
            { x: '2019-11-27', y: 197 },
            { x: '2019-11-29', y: 176 },
            { x: '2019-11-30', y: 169 },
            { x: '2019-12-02', y: 235 },
            { x: '2019-12-03', y: 237 },
            { x: '2019-12-04', y: 266 },
            { x: '2019-12-05', y: 275 },
            { x: '2019-12-06', y: 304 },
            { x: '2019-12-07', y: 186 },
            { x: '2019-12-09', y: 243 },
            { x: '2019-12-10', y: 240 },
            { x: '2019-12-11', y: 280 },
            { x: '2019-12-12', y: 261 },
            { x: '2019-12-13', y: 234 },
            { x: '2019-12-14', y: 172 },
            { x: '2019-12-16', y: 234 },
            { x: '2019-12-17', y: 230 },
            { x: '2019-12-18', y: 244 },
            { x: '2019-12-19', y: 257 },
            { x: '2019-12-20', y: 250 },
            { x: '2019-12-21', y: 187 },
            { x: '2019-12-23', y: 240 },
            { x: '2019-12-24', y: 97 },
            { x: '2019-12-26', y: 164 },
            { x: '2019-12-27', y: 223 },
            { x: '2019-12-28', y: 176 },
            { x: '2019-12-30', y: 247 },
            { x: '2020-01-01', y: 134 },
            { x: '2020-01-02', y: 276 },
            { x: '2020-01-03', y: 282 },
            { x: '2020-01-04', y: 192 },
            { x: '2020-01-06', y: 223 },
            { x: '2020-01-07', y: 285 },
            { x: '2020-01-08', y: 258 },
            { x: '2020-01-09', y: 249 },
            { x: '2020-01-10', y: 289 },
            { x: '2020-01-11', y: 189 },
            { x: '2020-01-13', y: 260 },
            { x: '2020-01-14', y: 269 },
            { x: '2020-01-15', y: 291 },
            { x: '2020-01-16', y: 256 },
            { x: '2020-01-17', y: 283 },
            { x: '2020-01-18', y: 255 },
            { x: '2020-01-20', y: 259 },
            { x: '2020-01-21', y: 236 },
            { x: '2020-01-22', y: 271 },
            { x: '2020-01-23', y: 260 },
            { x: '2020-01-24', y: 291 },
            { x: '2020-01-25', y: 266 },
            { x: '2020-01-27', y: 309 },
            { x: '2020-01-28', y: 255 },
            { x: '2020-01-29', y: 256 },
            { x: '2020-01-30', y: 230 },
            { x: '2020-01-31', y: 312 },
            { x: '2020-02-01', y: 225 },
            { x: '2020-02-03', y: 200 },
            { x: '2020-02-04', y: 251 },
            { x: '2020-02-05', y: 246 },
            { x: '2020-02-06', y: 264 },
            { x: '2020-02-07', y: 277 },
            { x: '2020-02-08', y: 217 },
            { x: '2020-02-10', y: 277 },
            { x: '2020-02-11', y: 256 },
            { x: '2020-02-12', y: 244 },
            { x: '2020-02-13', y: 328 },
            { x: '2020-02-14', y: 374 },
            { x: '2020-02-15', y: 438 },
            { x: '2020-02-17', y: 368 },
            { x: '2020-02-18', y: 273 },
            { x: '2020-02-19', y: 278 },
            { x: '2020-02-20', y: 351 },
            { x: '2020-02-21', y: 372 },
            { x: '2020-02-22', y: 235 },
            { x: '2020-02-24', y: 272 },
            { x: '2020-02-25', y: 248 },
            { x: '2020-02-26', y: 277 },
            { x: '2020-02-27', y: 241 },
            { x: '2020-02-28', y: 243 },
            { x: '2020-02-29', y: 208 },
            { x: '2020-03-02', y: 223 },
            { x: '2020-03-03', y: 235 },
            { x: '2020-03-04', y: 245 },
            { x: '2020-03-05', y: 268 },
            { x: '2020-03-06', y: 405 },
            { x: '2020-03-07', y: 256 },
            { x: '2020-03-09', y: 230 },
            { x: '2020-03-10', y: 204 },
            { x: '2020-03-11', y: 282 },
            { x: '2020-03-12', y: 287 },
            { x: '2020-03-13', y: 240 },
            { x: '2020-03-14', y: 239 },
            { x: '2020-03-16', y: 197 },
            { x: '2020-03-17', y: 187 },
            { x: '2020-03-18', y: 194 },
            { x: '2020-03-19', y: 198 },
            { x: '2020-03-20', y: 180 },
            { x: '2020-03-21', y: 131 },
            { x: '2020-03-23', y: 195 },
            { x: '2020-03-24', y: 269 },
            { x: '2020-03-25', y: 232 },
            { x: '2020-03-26', y: 251 },
            { x: '2020-03-27', y: 167 },
            { x: '2020-03-28', y: 189 },
            { x: '2020-03-30', y: 175 },
            { x: '2020-03-31', y: 193 },
            { x: '2020-04-01', y: 209 },
            { x: '2020-04-02', y: 271 },
            { x: '2020-04-03', y: 212 },
            { x: '2020-04-04', y: 179 },
            { x: '2020-04-06', y: 270 },
            { x: '2020-04-07', y: 234 },
            { x: '2020-04-08', y: 210 },
            { x: '2020-04-10', y: 198 },
            { x: '2020-04-11', y: 235 },
            { x: '2020-04-13', y: 267 },
            { x: '2020-04-14', y: 226 },
            { x: '2020-04-15', y: 270 },
            { x: '2020-04-16', y: 268 },
            { x: '2020-04-17', y: 352 },
            { x: '2020-04-18', y: 242 },
            { x: '2020-04-20', y: 324 },
            { x: '2020-04-21', y: 234 },
            { x: '2020-04-22', y: 321 },
            { x: '2020-04-23', y: 253 },
            { x: '2020-04-24', y: 398 },
            { x: '2020-04-25', y: 307 },
            { x: '2020-04-27', y: 331 },
            { x: '2020-04-28', y: 277 },
            { x: '2020-04-29', y: 315 },
            { x: '2020-04-30', y: 306 },
            { x: '2020-05-01', y: 390 },
            { x: '2020-05-02', y: 322 },
            { x: '2020-05-04', y: 311 },
            { x: '2020-05-05', y: 242 },
            { x: '2020-05-06', y: 330 },
            { x: '2020-05-07', y: 284 },
            { x: '2020-05-08', y: 325 },
            { x: '2020-05-09', y: 304 },
            { x: '2020-05-11', y: 288 },
            { x: '2020-05-12', y: 301 },
            { x: '2020-05-13', y: 325 },
            { x: '2020-05-14', y: 358 },
            { x: '2020-05-15', y: 342 },
            { x: '2020-05-16', y: 366 },
            { x: '2020-05-18', y: 361 },
            { x: '2020-05-19', y: 284 },
            { x: '2020-05-20', y: 334 },
            { x: '2020-05-21', y: 345 },
            { x: '2020-05-22', y: 370 },
            { x: '2020-05-23', y: 361 },
            { x: '2020-05-25', y: 280 },
            { x: '2020-05-26', y: 314 },
            { x: '2020-05-27', y: 345 },
            { x: '2020-05-28', y: 320 },
            { x: '2020-05-29', y: 413 },
            { x: '2020-05-30', y: 307 },
            { x: '2020-06-01', y: 317 },
            { x: '2020-06-02', y: 294 },
            { x: '2020-06-03', y: 313 },
            { x: '2020-06-04', y: 339 },
            { x: '2020-06-05', y: 381 },
            { x: '2020-06-06', y: 219 },
            { x: '2020-06-08', y: 328 },
            { x: '2020-06-09', y: 327 },
            { x: '2020-06-10', y: 334 },
            { x: '2020-06-11', y: 355 },
            { x: '2020-06-12', y: 336 },
            { x: '2020-06-13', y: 260 },
            { x: '2020-06-15', y: 391 },
            { x: '2020-06-16', y: 323 },
            { x: '2020-06-17', y: 339 },
            { x: '2020-06-18', y: 353 },
            { x: '2020-06-19', y: 386 },
            { x: '2020-06-20', y: 266 },
            { x: '2020-06-22', y: 317 },
            { x: '2020-06-23', y: 336 },
            { x: '2020-06-24', y: 348 },
            { x: '2020-06-25', y: 306 },
            { x: '2020-06-26', y: 347 },
            { x: '2020-06-27', y: 282 },
            { x: '2020-06-29', y: 386 },
            { x: '2020-06-30', y: 335 },
            { x: '2020-07-01', y: 348 },
            { x: '2020-07-02', y: 337 },
            { x: '2020-07-03', y: 311 },
            { x: '2020-07-04', y: 202 },
            { x: '2020-07-06', y: 285 },
            { x: '2020-07-07', y: 244 },
            { x: '2020-07-08', y: 306 },
            { x: '2020-07-09', y: 282 },
            { x: '2020-07-10', y: 267 },
            { x: '2020-07-11', y: 267 },
            { x: '2020-07-13', y: 375 },
            { x: '2020-07-14', y: 297 },
            { x: '2020-07-15', y: 321 },
            { x: '2020-07-16', y: 331 },
            { x: '2020-07-17', y: 343 },
            { x: '2020-07-20', y: 251 },
            { x: '2020-07-21', y: 273 },
            { x: '2020-07-22', y: 292 },
            { x: '2020-07-23', y: 329 },
            { x: '2020-07-24', y: 232 },
            { x: '2020-07-25', y: 250 },
            { x: '2020-07-27', y: 274 },
            { x: '2020-07-28', y: 334 },
            { x: '2020-07-29', y: 300 },
            { x: '2020-07-30', y: 328 },
            { x: '2020-07-31', y: 340 },
            { x: '2020-08-01', y: 218 },
            { x: '2020-08-03', y: 302 },
            { x: '2020-08-04', y: 257 },
            { x: '2020-08-05', y: 327 },
            { x: '2020-08-06', y: 289 },
            { x: '2020-08-07', y: 308 },
            { x: '2020-08-08', y: 250 },
            { x: '2020-08-10', y: 306 },
            { x: '2020-08-11', y: 279 },
            { x: '2020-08-12', y: 364 },
            { x: '2020-08-13', y: 344 },
            { x: '2020-08-14', y: 346 },
            { x: '2020-08-15', y: 131 },
            { x: '2020-08-17', y: 290 },
            { x: '2020-08-18', y: 268 },
            { x: '2020-08-19', y: 311 },
            { x: '2020-08-20', y: 266 },
            { x: '2020-08-21', y: 344 },
            { x: '2020-08-22', y: 237 },
            { x: '2020-08-24', y: 322 },
            { x: '2020-08-25', y: 292 },
            { x: '2020-08-26', y: 324 },
            { x: '2020-08-27', y: 305 },
            { x: '2020-08-28', y: 380 },
            { x: '2020-08-29', y: 313 },
            { x: '2020-08-31', y: 316 },
            { x: '2020-09-01', y: 288 },
            { x: '2020-09-02', y: 297 },
            { x: '2020-09-03', y: 302 },
            { x: '2020-09-04', y: 345 },
            { x: '2020-09-05', y: 280 },
            { x: '2020-09-07', y: 184 },
            { x: '2020-09-08', y: 321 },
            { x: '2020-09-09', y: 276 },
            { x: '2020-09-10', y: 334 },
            { x: '2020-09-11', y: 317 },
            { x: '2020-09-12', y: 317 },
            { x: '2020-09-14', y: 339 },
            { x: '2020-09-15', y: 300 },
            { x: '2020-09-16', y: 296 },
            { x: '2020-09-17', y: 356 },
            { x: '2020-09-18', y: 277 },
            { x: '2020-09-19', y: 296 },
            { x: '2020-09-21', y: 336 },
            { x: '2020-09-22', y: 320 },
            { x: '2020-09-23', y: 304 },
            { x: '2020-09-24', y: 364 },
            { x: '2020-09-25', y: 321 },
            { x: '2020-09-26', y: 228 },
            { x: '2020-09-28', y: 259 },
            { x: '2020-09-29', y: 285 },
            { x: '2020-09-30', y: 260 },
            { x: '2020-10-01', y: 290 },
            { x: '2020-10-02', y: 261 },
            { x: '2020-10-03', y: 212 },
            { x: '2020-10-05', y: 234 },
            { x: '2020-10-06', y: 265 },
            { x: '2020-10-07', y: 306 },
            { x: '2020-10-08', y: 292 },
            { x: '2020-10-09', y: 278 },
            { x: '2020-10-10', y: 252 },
            { x: '2020-10-12', y: 295 },
            { x: '2020-10-13', y: 287 },
            { x: '2020-10-14', y: 276 },
            { x: '2020-10-15', y: 317 },
            { x: '2020-10-16', y: 334 },
            { x: '2020-10-17', y: 275 },
            { x: '2020-10-19', y: 294 },
            { x: '2020-10-20', y: 264 },
            { x: '2020-10-21', y: 332 },
            { x: '2020-10-22', y: 281 },
            { x: '2020-10-23', y: 349 },
            { x: '2020-10-24', y: 302 },
            { x: '2020-10-27', y: 256 },
            { x: '2020-10-28', y: 278 },
            { x: '2020-10-29', y: 325 },
            { x: '2020-10-30', y: 328 },
            { x: '2020-10-31', y: 191 },
            { x: '2020-11-02', y: 241 },
            { x: '2020-11-03', y: 313 },
            { x: '2020-11-04', y: 291 },
            { x: '2020-11-05', y: 251 },
            { x: '2020-11-06', y: 277 },
            { x: '2020-11-07', y: 175 },
            { x: '2020-11-09', y: 257 },
            { x: '2020-11-10', y: 253 },
            { x: '2020-11-11', y: 224 },
            { x: '2020-11-12', y: 218 },
            { x: '2020-11-13', y: 281 },
            { x: '2020-11-14', y: 196 },
            { x: '2020-11-16', y: 249 },
            { x: '2020-11-17', y: 276 },
            { x: '2020-11-18', y: 309 },
            { x: '2020-11-19', y: 277 },
            { x: '2020-11-20', y: 262 },
            { x: '2020-11-21', y: 186 },
            { x: '2020-11-23', y: 274 },
            { x: '2020-11-24', y: 275 },
            { x: '2020-11-25', y: 258 },
            { x: '2020-11-27', y: 192 },
            { x: '2020-11-28', y: 206 },
            { x: '2020-11-30', y: 255 },
            { x: '2020-12-01', y: 260 },
            { x: '2020-12-02', y: 246 },
            { x: '2020-12-03', y: 249 },
            { x: '2020-12-04', y: 288 },
            { x: '2020-12-05', y: 189 },
            { x: '2020-12-07', y: 208 },
            { x: '2020-12-08', y: 222 },
            { x: '2020-12-09', y: 277 },
            { x: '2020-12-10', y: 305 },
            { x: '2020-12-11', y: 228 },
            { x: '2020-12-12', y: 163 },
            { x: '2020-12-14', y: 260 },
            { x: '2020-12-15', y: 272 },
            { x: '2020-12-16', y: 262 },
            { x: '2020-12-17', y: 221 },
            { x: '2020-12-18', y: 240 },
            { x: '2020-12-19', y: 217 },
            { x: '2020-12-21', y: 244 },
            { x: '2020-12-22', y: 224 },
            { x: '2020-12-23', y: 264 },
            { x: '2020-12-24', y: 119 },
            { x: '2020-12-26', y: 123 },
            { x: '2020-12-28', y: 212 },
            { x: '2020-12-29', y: 267 },
            { x: '2020-12-30', y: 280 },
            { x: '2020-12-31', y: 237 },
            { x: '2021-01-01', y: 154 },
            { x: '2021-01-02', y: 227 },
            { x: '2021-01-04', y: 240 },
            { x: '2021-01-05', y: 297 },
            { x: '2021-01-06', y: 306 },
            { x: '2021-01-07', y: 248 },
            { x: '2021-01-08', y: 294 },
            { x: '2021-01-09', y: 213 },
            { x: '2021-01-11', y: 266 },
            { x: '2021-01-12', y: 221 },
            { x: '2021-01-13', y: 293 },
            { x: '2021-01-14', y: 268 },
            { x: '2021-01-15', y: 264 },
            { x: '2021-01-16', y: 250 },
            { x: '2021-01-18', y: 278 },
            { x: '2021-01-19', y: 242 },
            { x: '2021-01-20', y: 326 },
            { x: '2021-01-21', y: 315 },
            { x: '2021-01-22', y: 309 },
            { x: '2021-01-23', y: 189 },
            { x: '2021-01-25', y: 217 },
            { x: '2021-01-26', y: 254 },
            { x: '2021-01-27', y: 259 },
            { x: '2021-01-28', y: 259 },
            { x: '2021-01-29', y: 238 },
            { x: '2021-01-30', y: 270 },
            { x: '2021-02-01', y: 154 },
            { x: '2021-02-02', y: 201 },
            { x: '2021-02-04', y: 269 },
            { x: '2021-02-05', y: 284 },
            { x: '2021-02-06', y: 209 },
            { x: '2021-02-08', y: 201 },
            { x: '2021-02-09', y: 193 },
            { x: '2021-02-10', y: 246 },
            { x: '2021-02-11', y: 219 },
            { x: '2021-02-12', y: 368 },
            { x: '2021-02-13', y: 265 },
            { x: '2021-02-15', y: 212 },
            { x: '2021-02-16', y: 186 },
            { x: '2021-02-17', y: 248 },
            { x: '2021-02-18', y: 241 },
            { x: '2021-02-19', y: 278 },
            { x: '2021-02-20', y: 235 },
            { x: '2021-02-22', y: 190 },
            { x: '2021-02-23', y: 238 },
            { x: '2021-02-24', y: 241 },
            { x: '2021-02-25', y: 286 },
            { x: '2021-02-26', y: 234 },
            { x: '2021-02-27', y: 210 },
            { x: '2021-03-01', y: 219 },
            { x: '2021-03-02', y: 254 },
            { x: '2021-03-03', y: 219 },
            { x: '2021-03-04', y: 227 },
            { x: '2021-03-05', y: 217 },
            { x: '2021-03-06', y: 228 },
            { x: '2021-03-08', y: 237 },
            { x: '2021-03-09', y: 222 },
            { x: '2021-03-10', y: 211 },
            { x: '2021-03-11', y: 243 },
            { x: '2021-03-12', y: 219 },
            { x: '2021-03-13', y: 127 },
            { x: '2021-03-15', y: 241 },
            { x: '2021-03-16', y: 198 },
            { x: '2021-03-17', y: 202 },
            { x: '2021-03-18', y: 256 },
            { x: '2021-03-19', y: 345 },
            { x: '2021-03-20', y: 339 },
            { x: '2021-03-22', y: 251 },
            { x: '2021-03-23', y: 227 },
            { x: '2021-03-24', y: 282 },
            { x: '2021-03-25', y: 275 },
            { x: '2021-03-26', y: 272 },
            { x: '2021-03-27', y: 349 },
            { x: '2021-03-29', y: 281 },
            { x: '2021-03-30', y: 261 },
            { x: '2021-03-31', y: 314 },
            { x: '2021-04-01', y: 343 },
            { x: '2021-04-02', y: 348 },
            { x: '2021-04-03', y: 328 },
            { x: '2021-04-05', y: 272 },
            { x: '2021-04-06', y: 267 },
            { x: '2021-04-07', y: 278 },
            { x: '2021-04-08', y: 324 },
            { x: '2021-04-09', y: 341 },
            { x: '2021-04-10', y: 321 },
            { x: '2021-04-12', y: 236 },
            { x: '2021-04-13', y: 270 },
            { x: '2021-04-14', y: 271 },
            { x: '2021-04-15', y: 269 },
            { x: '2021-04-16', y: 283 },
            { x: '2021-04-17', y: 260 },
            { x: '2021-04-19', y: 267 },
            { x: '2021-04-20', y: 263 },
            { x: '2021-04-21', y: 288 },
            { x: '2021-04-22', y: 246 },
            { x: '2021-04-23', y: 282 },
            { x: '2021-04-24', y: 211 },
            { x: '2021-04-26', y: 211 },
            { x: '2021-04-27', y: 184 },
            { x: '2021-04-28', y: 229 },
            { x: '2021-04-29', y: 288 },
            { x: '2021-04-30', y: 355 },
            { x: '2021-05-01', y: 231 },
            { x: '2021-05-03', y: 250 },
            { x: '2021-05-04', y: 230 },
            { x: '2021-05-05', y: 235 },
            { x: '2021-05-06', y: 217 },
            { x: '2021-05-07', y: 251 },
            { x: '2021-05-08', y: 237 },
            { x: '2021-05-10', y: 228 },
            { x: '2021-05-12', y: 230 },
            { x: '2021-05-13', y: 256 },
            { x: '2021-05-14', y: 310 },
            { x: '2021-05-15', y: 203 },
            { x: '2021-05-17', y: 227 },
            { x: '2021-05-18', y: 216 },
            { x: '2021-05-19', y: 255 },
            { x: '2021-05-20', y: 238 },
            { x: '2021-05-21', y: 284 },
            { x: '2021-05-22', y: 174 },
            { x: '2021-05-24', y: 240 },
            { x: '2021-05-25', y: 204 },
            { x: '2021-05-26', y: 246 },
            { x: '2021-05-27', y: 234 },
            { x: '2021-05-28', y: 255 },
            { x: '2021-05-29', y: 217 },
            { x: '2021-05-31', y: 208 },
            { x: '2021-06-01', y: 247 },
            { x: '2021-06-02', y: 203 },
            { x: '2021-06-03', y: 266 },
            { x: '2021-06-04', y: 280 },
            { x: '2021-06-05', y: 214 },
            { x: '2021-06-07', y: 229 },
            { x: '2021-06-08', y: 231 },
            { x: '2021-06-09', y: 240 },
            { x: '2021-06-10', y: 213 },
            { x: '2021-06-11', y: 245 },
            { x: '2021-06-12', y: 147 },
            { x: '2021-06-14', y: 231 },
            { x: '2021-06-15', y: 264 },
            { x: '2021-06-16', y: 271 },
            { x: '2021-06-17', y: 253 },
            { x: '2021-06-18', y: 246 },
            { x: '2021-06-19', y: 154 },
            { x: '2021-06-21', y: 291 },
            { x: '2021-06-22', y: 203 },
            { x: '2021-06-23', y: 211 },
            { x: '2021-06-24', y: 231 },
            { x: '2021-06-25', y: 299 },
            { x: '2021-06-26', y: 190 },
            { x: '2021-06-28', y: 196 },
            { x: '2021-06-29', y: 250 },
            { x: '2021-06-30', y: 231 },
            { x: '2021-07-01', y: 259 },
            { x: '2021-07-02', y: 217 },
            { x: '2021-07-03', y: 109 },
            { x: '2021-07-05', y: 177 },
            { x: '2021-07-06', y: 249 },
            { x: '2021-07-07', y: 196 },
            { x: '2021-07-08', y: 276 },
            { x: '2021-07-09', y: 196 },
            { x: '2021-07-10', y: 208 },
            { x: '2021-07-12', y: 189 },
            { x: '2021-07-13', y: 199 },
            { x: '2021-07-14', y: 211 },
            { x: '2021-07-15', y: 238 },
            { x: '2021-07-16', y: 211 },
            { x: '2021-07-17', y: 142 },
            { x: '2021-07-19', y: 254 },
            { x: '2021-07-20', y: 182 },
            { x: '2021-07-21', y: 241 },
            { x: '2021-07-22', y: 208 },
            { x: '2021-07-24', y: 119 },
            { x: '2021-07-26', y: 233 },
            { x: '2021-07-27', y: 180 },
            { x: '2021-07-28', y: 214 },
            { x: '2021-07-29', y: 192 },
            { x: '2021-07-30', y: 187 },
            { x: '2021-07-31', y: 151 },
            { x: '2021-08-02', y: 209 },
            { x: '2021-08-03', y: 232 },
            { x: '2021-08-04', y: 232 },
            { x: '2021-08-05', y: 237 },
            { x: '2021-08-06', y: 195 },
            { x: '2021-08-07', y: 137 },
            { x: '2021-08-09', y: 203 },
            { x: '2021-08-10', y: 210 },
            { x: '2021-08-11', y: 232 },
            { x: '2021-08-12', y: 216 },
            { x: '2021-08-13', y: 208 },
            { x: '2021-08-14', y: 139 },
            { x: '2021-08-16', y: 259 },
            { x: '2021-08-17', y: 204 },
            { x: '2021-08-18', y: 195 },
            { x: '2021-08-19', y: 223 },
            { x: '2021-08-20', y: 205 },
            { x: '2021-08-21', y: 172 },
            { x: '2021-08-23', y: 199 },
            { x: '2021-08-24', y: 210 },
            { x: '2021-08-25', y: 187 },
            { x: '2021-08-26', y: 211 },
            { x: '2021-08-27', y: 224 },
            { x: '2021-08-28', y: 213 },
            { x: '2021-08-30', y: 234 },
            { x: '2021-08-31', y: 207 },
            { x: '2021-09-01', y: 197 },
            { x: '2021-09-02', y: 248 },
            { x: '2021-09-03', y: 195 },
            { x: '2021-09-04', y: 175 },
            { x: '2021-09-06', y: 168 },
            { x: '2021-09-07', y: 255 },
            { x: '2021-09-08', y: 225 },
            { x: '2021-09-09', y: 223 },
            { x: '2021-09-10', y: 229 },
            { x: '2021-09-11', y: 170 },
            { x: '2021-09-13', y: 200 },
            { x: '2021-09-14', y: 252 },
            { x: '2021-09-15', y: 207 },
            { x: '2021-09-16', y: 242 },
            { x: '2021-09-17', y: 287 },
            { x: '2021-09-18', y: 161 },
            { x: '2021-09-20', y: 235 },
            { x: '2021-09-21', y: 224 },
            { x: '2021-09-22', y: 224 },
            { x: '2021-09-23', y: 82 },
        ],
    },
    {
        id: 'ham',
        color: '#8bd346',
        data: [
            { x: '2019-01-01', y: 56 },
            { x: '2019-01-02', y: 92 },
            { x: '2019-01-03', y: 86 },
            { x: '2019-01-04', y: 106 },
            { x: '2019-01-05', y: 73 },
            { x: '2019-01-07', y: 78 },
            { x: '2019-01-08', y: 108 },
            { x: '2019-01-09', y: 117 },
            { x: '2019-01-10', y: 113 },
            { x: '2019-01-11', y: 103 },
            { x: '2019-01-12', y: 113 },
            { x: '2019-01-14', y: 121 },
            { x: '2019-01-15', y: 98 },
            { x: '2019-01-16', y: 96 },
            { x: '2019-01-17', y: 99 },
            { x: '2019-01-18', y: 96 },
            { x: '2019-01-19', y: 69 },
            { x: '2019-01-21', y: 108 },
            { x: '2019-01-22', y: 95 },
            { x: '2019-01-23', y: 99 },
            { x: '2019-01-24', y: 139 },
            { x: '2019-01-25', y: 120 },
            { x: '2019-01-26', y: 106 },
            { x: '2019-01-28', y: 107 },
            { x: '2019-01-29', y: 86 },
            { x: '2019-01-30', y: 124 },
            { x: '2019-01-31', y: 114 },
            { x: '2019-02-01', y: 150 },
            { x: '2019-02-02', y: 87 },
            { x: '2019-02-04', y: 104 },
            { x: '2019-02-05', y: 100 },
            { x: '2019-02-06', y: 86 },
            { x: '2019-02-07', y: 105 },
            { x: '2019-02-08', y: 151 },
            { x: '2019-02-09', y: 103 },
            { x: '2019-02-11', y: 98 },
            { x: '2019-02-12', y: 113 },
            { x: '2019-02-13', y: 116 },
            { x: '2019-02-14', y: 107 },
            { x: '2019-02-15', y: 123 },
            { x: '2019-02-16', y: 160 },
            { x: '2019-02-18', y: 131 },
            { x: '2019-02-19', y: 92 },
            { x: '2019-02-20', y: 100 },
            { x: '2019-02-21', y: 87 },
            { x: '2019-02-22', y: 128 },
            { x: '2019-02-23', y: 108 },
            { x: '2019-02-25', y: 92 },
            { x: '2019-02-26', y: 107 },
            { x: '2019-02-27', y: 136 },
            { x: '2019-02-28', y: 130 },
            { x: '2019-03-01', y: 133 },
            { x: '2019-03-02', y: 108 },
            { x: '2019-03-04', y: 111 },
            { x: '2019-03-05', y: 105 },
            { x: '2019-03-07', y: 150 },
            { x: '2019-03-08', y: 159 },
            { x: '2019-03-09', y: 130 },
            { x: '2019-03-11', y: 125 },
            { x: '2019-03-12', y: 104 },
            { x: '2019-03-13', y: 101 },
            { x: '2019-03-14', y: 124 },
            { x: '2019-03-15', y: 160 },
            { x: '2019-03-16', y: 118 },
            { x: '2019-03-18', y: 127 },
            { x: '2019-03-19', y: 115 },
            { x: '2019-03-20', y: 133 },
            { x: '2019-03-21', y: 113 },
            { x: '2019-03-22', y: 167 },
            { x: '2019-03-23', y: 161 },
            { x: '2019-03-25', y: 152 },
            { x: '2019-03-26', y: 136 },
            { x: '2019-03-27', y: 117 },
            { x: '2019-03-28', y: 133 },
            { x: '2019-03-29', y: 136 },
            { x: '2019-03-30', y: 137 },
            { x: '2019-04-01', y: 154 },
            { x: '2019-04-02', y: 142 },
            { x: '2019-04-03', y: 151 },
            { x: '2019-04-04', y: 159 },
            { x: '2019-04-05', y: 171 },
            { x: '2019-04-06', y: 111 },
            { x: '2019-04-08', y: 121 },
            { x: '2019-04-09', y: 148 },
            { x: '2019-04-10', y: 139 },
            { x: '2019-04-11', y: 135 },
            { x: '2019-04-12', y: 134 },
            { x: '2019-04-13', y: 103 },
            { x: '2019-04-15', y: 137 },
            { x: '2019-04-16', y: 94 },
            { x: '2019-04-17', y: 138 },
            { x: '2019-04-18', y: 165 },
            { x: '2019-04-19', y: 131 },
            { x: '2019-04-20', y: 105 },
            { x: '2019-04-22', y: 135 },
            { x: '2019-04-23', y: 115 },
            { x: '2019-04-24', y: 133 },
            { x: '2019-04-25', y: 144 },
            { x: '2019-04-26', y: 125 },
            { x: '2019-04-27', y: 98 },
            { x: '2019-04-29', y: 90 },
            { x: '2019-04-30', y: 99 },
            { x: '2019-05-01', y: 123 },
            { x: '2019-05-02', y: 126 },
            { x: '2019-05-03', y: 151 },
            { x: '2019-05-04', y: 123 },
            { x: '2019-05-06', y: 114 },
            { x: '2019-05-07', y: 111 },
            { x: '2019-05-08', y: 117 },
            { x: '2019-05-09', y: 97 },
            { x: '2019-05-10', y: 120 },
            { x: '2019-05-11', y: 111 },
            { x: '2019-05-13', y: 111 },
            { x: '2019-05-14', y: 111 },
            { x: '2019-05-15', y: 147 },
            { x: '2019-05-16', y: 99 },
            { x: '2019-05-17', y: 129 },
            { x: '2019-05-18', y: 97 },
            { x: '2019-05-20', y: 102 },
            { x: '2019-05-21', y: 119 },
            { x: '2019-05-22', y: 131 },
            { x: '2019-05-23', y: 97 },
            { x: '2019-05-24', y: 118 },
            { x: '2019-05-25', y: 130 },
            { x: '2019-05-27', y: 66 },
            { x: '2019-05-28', y: 115 },
            { x: '2019-05-29', y: 133 },
            { x: '2019-05-30', y: 108 },
            { x: '2019-05-31', y: 111 },
            { x: '2019-06-01', y: 105 },
            { x: '2019-06-03', y: 124 },
            { x: '2019-06-04', y: 129 },
            { x: '2019-06-05', y: 134 },
            { x: '2019-06-06', y: 119 },
            { x: '2019-06-07', y: 109 },
            { x: '2019-06-08', y: 96 },
            { x: '2019-06-10', y: 131 },
            { x: '2019-06-11', y: 100 },
            { x: '2019-06-12', y: 107 },
            { x: '2019-06-13', y: 122 },
            { x: '2019-06-15', y: 91 },
            { x: '2019-06-17', y: 142 },
            { x: '2019-06-18', y: 113 },
            { x: '2019-06-19', y: 116 },
            { x: '2019-06-20', y: 121 },
            { x: '2019-06-21', y: 124 },
            { x: '2019-06-22', y: 102 },
            { x: '2019-06-24', y: 126 },
            { x: '2019-06-25', y: 111 },
            { x: '2019-06-26', y: 110 },
            { x: '2019-06-27', y: 107 },
            { x: '2019-06-28', y: 109 },
            { x: '2019-06-29', y: 61 },
            { x: '2019-07-01', y: 101 },
            { x: '2019-07-02', y: 114 },
            { x: '2019-07-03', y: 126 },
            { x: '2019-07-04', y: 59 },
            { x: '2019-07-05', y: 86 },
            { x: '2019-07-06', y: 89 },
            { x: '2019-07-08', y: 118 },
            { x: '2019-07-09', y: 131 },
            { x: '2019-07-10', y: 130 },
            { x: '2019-07-11', y: 89 },
            { x: '2019-07-12', y: 122 },
            { x: '2019-07-13', y: 73 },
            { x: '2019-07-15', y: 108 },
            { x: '2019-07-16', y: 103 },
            { x: '2019-07-17', y: 120 },
            { x: '2019-07-18', y: 117 },
            { x: '2019-07-19', y: 105 },
            { x: '2019-07-20', y: 83 },
            { x: '2019-07-22', y: 118 },
            { x: '2019-07-23', y: 119 },
            { x: '2019-07-24', y: 73 },
            { x: '2019-07-25', y: 114 },
            { x: '2019-07-26', y: 93 },
            { x: '2019-07-27', y: 68 },
            { x: '2019-07-29', y: 94 },
            { x: '2019-07-30', y: 112 },
            { x: '2019-07-31', y: 97 },
            { x: '2019-08-01', y: 109 },
            { x: '2019-08-02', y: 130 },
            { x: '2019-08-03', y: 91 },
            { x: '2019-08-05', y: 137 },
            { x: '2019-08-06', y: 114 },
            { x: '2019-08-07', y: 130 },
            { x: '2019-08-08', y: 123 },
            { x: '2019-08-09', y: 108 },
            { x: '2019-08-10', y: 75 },
            { x: '2019-08-12', y: 110 },
            { x: '2019-08-13', y: 113 },
            { x: '2019-08-14', y: 127 },
            { x: '2019-08-15', y: 107 },
            { x: '2019-08-16', y: 132 },
            { x: '2019-08-17', y: 87 },
            { x: '2019-08-19', y: 110 },
            { x: '2019-08-20', y: 116 },
            { x: '2019-08-21', y: 113 },
            { x: '2019-08-22', y: 136 },
            { x: '2019-08-23', y: 129 },
            { x: '2019-08-24', y: 93 },
            { x: '2019-08-26', y: 116 },
            { x: '2019-08-27', y: 111 },
            { x: '2019-08-28', y: 112 },
            { x: '2019-08-29', y: 132 },
            { x: '2019-08-30', y: 113 },
            { x: '2019-08-31', y: 101 },
            { x: '2019-09-02', y: 68 },
            { x: '2019-09-03', y: 104 },
            { x: '2019-09-04', y: 112 },
            { x: '2019-09-05', y: 124 },
            { x: '2019-09-06', y: 115 },
            { x: '2019-09-07', y: 115 },
            { x: '2019-09-09', y: 106 },
            { x: '2019-09-10', y: 124 },
            { x: '2019-09-11', y: 106 },
            { x: '2019-09-12', y: 106 },
            { x: '2019-09-13', y: 110 },
            { x: '2019-09-14', y: 82 },
            { x: '2019-09-16', y: 106 },
            { x: '2019-09-17', y: 106 },
            { x: '2019-09-18', y: 117 },
            { x: '2019-09-19', y: 124 },
            { x: '2019-09-20', y: 108 },
            { x: '2019-09-21', y: 94 },
            { x: '2019-09-23', y: 99 },
            { x: '2019-09-24', y: 110 },
            { x: '2019-09-25', y: 133 },
            { x: '2019-09-26', y: 117 },
            { x: '2019-09-27', y: 121 },
            { x: '2019-09-28', y: 84 },
            { x: '2019-09-30', y: 117 },
            { x: '2019-10-01', y: 107 },
            { x: '2019-10-02', y: 125 },
            { x: '2019-10-03', y: 132 },
            { x: '2019-10-04', y: 142 },
            { x: '2019-10-05', y: 105 },
            { x: '2019-10-07', y: 159 },
            { x: '2019-10-08', y: 111 },
            { x: '2019-10-09', y: 124 },
            { x: '2019-10-10', y: 99 },
            { x: '2019-10-11', y: 111 },
            { x: '2019-10-12', y: 79 },
            { x: '2019-10-14', y: 133 },
            { x: '2019-10-15', y: 133 },
            { x: '2019-10-16', y: 128 },
            { x: '2019-10-17', y: 129 },
            { x: '2019-10-18', y: 123 },
            { x: '2019-10-19', y: 88 },
            { x: '2019-10-21', y: 112 },
            { x: '2019-10-22', y: 95 },
            { x: '2019-10-23', y: 139 },
            { x: '2019-10-24', y: 137 },
            { x: '2019-10-25', y: 114 },
            { x: '2019-10-26', y: 91 },
            { x: '2019-10-28', y: 118 },
            { x: '2019-10-29', y: 99 },
            { x: '2019-10-30', y: 117 },
            { x: '2019-10-31', y: 84 },
            { x: '2019-11-01', y: 124 },
            { x: '2019-11-02', y: 96 },
            { x: '2019-11-04', y: 106 },
            { x: '2019-11-05', y: 101 },
            { x: '2019-11-06', y: 102 },
            { x: '2019-11-07', y: 135 },
            { x: '2019-11-08', y: 143 },
            { x: '2019-11-09', y: 133 },
            { x: '2019-11-11', y: 84 },
            { x: '2019-11-12', y: 105 },
            { x: '2019-11-13', y: 118 },
            { x: '2019-11-14', y: 102 },
            { x: '2019-11-15', y: 122 },
            { x: '2019-11-16', y: 90 },
            { x: '2019-11-18', y: 108 },
            { x: '2019-11-19', y: 117 },
            { x: '2019-11-20', y: 100 },
            { x: '2019-11-21', y: 93 },
            { x: '2019-11-22', y: 126 },
            { x: '2019-11-23', y: 88 },
            { x: '2019-11-25', y: 102 },
            { x: '2019-11-26', y: 145 },
            { x: '2019-11-27', y: 83 },
            { x: '2019-11-29', y: 82 },
            { x: '2019-11-30', y: 73 },
            { x: '2019-12-02', y: 105 },
            { x: '2019-12-03', y: 99 },
            { x: '2019-12-04', y: 108 },
            { x: '2019-12-05', y: 105 },
            { x: '2019-12-06', y: 138 },
            { x: '2019-12-07', y: 76 },
            { x: '2019-12-09', y: 91 },
            { x: '2019-12-10', y: 96 },
            { x: '2019-12-11', y: 110 },
            { x: '2019-12-12', y: 107 },
            { x: '2019-12-13', y: 90 },
            { x: '2019-12-14', y: 70 },
            { x: '2019-12-16', y: 102 },
            { x: '2019-12-17', y: 94 },
            { x: '2019-12-18', y: 116 },
            { x: '2019-12-19', y: 103 },
            { x: '2019-12-20', y: 114 },
            { x: '2019-12-21', y: 85 },
            { x: '2019-12-23', y: 102 },
            { x: '2019-12-24', y: 51 },
            { x: '2019-12-26', y: 70 },
            { x: '2019-12-27', y: 107 },
            { x: '2019-12-28', y: 82 },
            { x: '2019-12-30', y: 95 },
            { x: '2020-01-01', y: 62 },
            { x: '2020-01-02', y: 100 },
            { x: '2020-01-03', y: 108 },
            { x: '2020-01-04', y: 70 },
            { x: '2020-01-06', y: 117 },
            { x: '2020-01-07', y: 137 },
            { x: '2020-01-08', y: 120 },
            { x: '2020-01-09', y: 103 },
            { x: '2020-01-10', y: 123 },
            { x: '2020-01-11', y: 75 },
            { x: '2020-01-13', y: 108 },
            { x: '2020-01-14', y: 105 },
            { x: '2020-01-15', y: 109 },
            { x: '2020-01-16', y: 102 },
            { x: '2020-01-17', y: 121 },
            { x: '2020-01-18', y: 91 },
            { x: '2020-01-20', y: 95 },
            { x: '2020-01-21', y: 110 },
            { x: '2020-01-22', y: 113 },
            { x: '2020-01-23', y: 102 },
            { x: '2020-01-24', y: 119 },
            { x: '2020-01-25', y: 108 },
            { x: '2020-01-27', y: 123 },
            { x: '2020-01-28', y: 107 },
            { x: '2020-01-29', y: 118 },
            { x: '2020-01-30', y: 110 },
            { x: '2020-01-31', y: 132 },
            { x: '2020-02-01', y: 105 },
            { x: '2020-02-03', y: 96 },
            { x: '2020-02-04', y: 99 },
            { x: '2020-02-05', y: 108 },
            { x: '2020-02-06', y: 110 },
            { x: '2020-02-07', y: 123 },
            { x: '2020-02-08', y: 97 },
            { x: '2020-02-10', y: 113 },
            { x: '2020-02-11', y: 110 },
            { x: '2020-02-12', y: 112 },
            { x: '2020-02-13', y: 106 },
            { x: '2020-02-14', y: 154 },
            { x: '2020-02-15', y: 146 },
            { x: '2020-02-17', y: 142 },
            { x: '2020-02-18', y: 113 },
            { x: '2020-02-19', y: 100 },
            { x: '2020-02-20', y: 125 },
            { x: '2020-02-21', y: 140 },
            { x: '2020-02-22', y: 95 },
            { x: '2020-02-24', y: 126 },
            { x: '2020-02-25', y: 110 },
            { x: '2020-02-26', y: 117 },
            { x: '2020-02-27', y: 103 },
            { x: '2020-02-28', y: 91 },
            { x: '2020-02-29', y: 98 },
            { x: '2020-03-02', y: 91 },
            { x: '2020-03-03', y: 99 },
            { x: '2020-03-04', y: 101 },
            { x: '2020-03-05', y: 112 },
            { x: '2020-03-06', y: 145 },
            { x: '2020-03-07', y: 106 },
            { x: '2020-03-09', y: 98 },
            { x: '2020-03-10', y: 102 },
            { x: '2020-03-11', y: 114 },
            { x: '2020-03-12', y: 131 },
            { x: '2020-03-13', y: 88 },
            { x: '2020-03-14', y: 105 },
            { x: '2020-03-16', y: 87 },
            { x: '2020-03-17', y: 101 },
            { x: '2020-03-18', y: 96 },
            { x: '2020-03-19', y: 90 },
            { x: '2020-03-20', y: 86 },
            { x: '2020-03-21', y: 67 },
            { x: '2020-03-23', y: 95 },
            { x: '2020-03-24', y: 127 },
            { x: '2020-03-25', y: 102 },
            { x: '2020-03-26', y: 115 },
            { x: '2020-03-27', y: 89 },
            { x: '2020-03-28', y: 83 },
            { x: '2020-03-30', y: 85 },
            { x: '2020-03-31', y: 97 },
            { x: '2020-04-01', y: 85 },
            { x: '2020-04-02', y: 109 },
            { x: '2020-04-03', y: 104 },
            { x: '2020-04-04', y: 83 },
            { x: '2020-04-06', y: 114 },
            { x: '2020-04-07', y: 108 },
            { x: '2020-04-08', y: 102 },
            { x: '2020-04-10', y: 84 },
            { x: '2020-04-11', y: 103 },
            { x: '2020-04-13', y: 115 },
            { x: '2020-04-14', y: 106 },
            { x: '2020-04-15', y: 126 },
            { x: '2020-04-16', y: 124 },
            { x: '2020-04-17', y: 126 },
            { x: '2020-04-18', y: 110 },
            { x: '2020-04-20', y: 130 },
            { x: '2020-04-21', y: 102 },
            { x: '2020-04-22', y: 149 },
            { x: '2020-04-23', y: 123 },
            { x: '2020-04-24', y: 164 },
            { x: '2020-04-25', y: 139 },
            { x: '2020-04-27', y: 141 },
            { x: '2020-04-28', y: 119 },
            { x: '2020-04-29', y: 131 },
            { x: '2020-04-30', y: 128 },
            { x: '2020-05-01', y: 168 },
            { x: '2020-05-02', y: 150 },
            { x: '2020-05-04', y: 135 },
            { x: '2020-05-05', y: 100 },
            { x: '2020-05-06', y: 118 },
            { x: '2020-05-07', y: 108 },
            { x: '2020-05-08', y: 135 },
            { x: '2020-05-09', y: 124 },
            { x: '2020-05-11', y: 132 },
            { x: '2020-05-12', y: 133 },
            { x: '2020-05-13', y: 143 },
            { x: '2020-05-14', y: 138 },
            { x: '2020-05-15', y: 152 },
            { x: '2020-05-16', y: 146 },
            { x: '2020-05-18', y: 143 },
            { x: '2020-05-19', y: 120 },
            { x: '2020-05-20', y: 152 },
            { x: '2020-05-21', y: 143 },
            { x: '2020-05-22', y: 144 },
            { x: '2020-05-23', y: 121 },
            { x: '2020-05-25', y: 118 },
            { x: '2020-05-26', y: 140 },
            { x: '2020-05-27', y: 119 },
            { x: '2020-05-28', y: 132 },
            { x: '2020-05-29', y: 159 },
            { x: '2020-05-30', y: 119 },
            { x: '2020-06-01', y: 133 },
            { x: '2020-06-02', y: 128 },
            { x: '2020-06-03', y: 143 },
            { x: '2020-06-04', y: 137 },
            { x: '2020-06-05', y: 153 },
            { x: '2020-06-06', y: 97 },
            { x: '2020-06-08', y: 114 },
            { x: '2020-06-09', y: 131 },
            { x: '2020-06-10', y: 136 },
            { x: '2020-06-11', y: 143 },
            { x: '2020-06-12', y: 142 },
            { x: '2020-06-13', y: 104 },
            { x: '2020-06-15', y: 153 },
            { x: '2020-06-16', y: 135 },
            { x: '2020-06-17', y: 151 },
            { x: '2020-06-18', y: 151 },
            { x: '2020-06-19', y: 164 },
            { x: '2020-06-20', y: 94 },
            { x: '2020-06-22', y: 131 },
            { x: '2020-06-23', y: 124 },
            { x: '2020-06-24', y: 138 },
            { x: '2020-06-25', y: 136 },
            { x: '2020-06-26', y: 149 },
            { x: '2020-06-27', y: 128 },
            { x: '2020-06-29', y: 178 },
            { x: '2020-06-30', y: 131 },
            { x: '2020-07-01', y: 150 },
            { x: '2020-07-02', y: 133 },
            { x: '2020-07-03', y: 123 },
            { x: '2020-07-04', y: 68 },
            { x: '2020-07-06', y: 115 },
            { x: '2020-07-07', y: 98 },
            { x: '2020-07-08', y: 136 },
            { x: '2020-07-09', y: 116 },
            { x: '2020-07-10', y: 127 },
            { x: '2020-07-11', y: 95 },
            { x: '2020-07-13', y: 145 },
            { x: '2020-07-14', y: 121 },
            { x: '2020-07-15', y: 131 },
            { x: '2020-07-16', y: 139 },
            { x: '2020-07-17', y: 151 },
            { x: '2020-07-20', y: 107 },
            { x: '2020-07-21', y: 143 },
            { x: '2020-07-22', y: 132 },
            { x: '2020-07-23', y: 127 },
            { x: '2020-07-24', y: 94 },
            { x: '2020-07-25', y: 100 },
            { x: '2020-07-27', y: 134 },
            { x: '2020-07-28', y: 144 },
            { x: '2020-07-29', y: 124 },
            { x: '2020-07-30', y: 130 },
            { x: '2020-07-31', y: 158 },
            { x: '2020-08-01', y: 90 },
            { x: '2020-08-03', y: 134 },
            { x: '2020-08-04', y: 117 },
            { x: '2020-08-05', y: 127 },
            { x: '2020-08-06', y: 125 },
            { x: '2020-08-07', y: 128 },
            { x: '2020-08-08', y: 92 },
            { x: '2020-08-10', y: 106 },
            { x: '2020-08-11', y: 127 },
            { x: '2020-08-12', y: 146 },
            { x: '2020-08-13', y: 144 },
            { x: '2020-08-14', y: 152 },
            { x: '2020-08-15', y: 53 },
            { x: '2020-08-17', y: 140 },
            { x: '2020-08-18', y: 114 },
            { x: '2020-08-19', y: 153 },
            { x: '2020-08-20', y: 116 },
            { x: '2020-08-21', y: 126 },
            { x: '2020-08-22', y: 97 },
            { x: '2020-08-24', y: 138 },
            { x: '2020-08-25', y: 126 },
            { x: '2020-08-26', y: 122 },
            { x: '2020-08-27', y: 141 },
            { x: '2020-08-28', y: 152 },
            { x: '2020-08-29', y: 125 },
            { x: '2020-08-31', y: 134 },
            { x: '2020-09-01', y: 132 },
            { x: '2020-09-02', y: 135 },
            { x: '2020-09-03', y: 152 },
            { x: '2020-09-04', y: 125 },
            { x: '2020-09-05', y: 134 },
            { x: '2020-09-07', y: 86 },
            { x: '2020-09-08', y: 133 },
            { x: '2020-09-09', y: 124 },
            { x: '2020-09-10', y: 136 },
            { x: '2020-09-11', y: 165 },
            { x: '2020-09-12', y: 131 },
            { x: '2020-09-14', y: 147 },
            { x: '2020-09-15', y: 134 },
            { x: '2020-09-16', y: 130 },
            { x: '2020-09-17', y: 160 },
            { x: '2020-09-18', y: 121 },
            { x: '2020-09-19', y: 130 },
            { x: '2020-09-21', y: 132 },
            { x: '2020-09-22', y: 154 },
            { x: '2020-09-23', y: 136 },
            { x: '2020-09-24', y: 148 },
            { x: '2020-09-25', y: 147 },
            { x: '2020-09-26', y: 102 },
            { x: '2020-09-28', y: 123 },
            { x: '2020-09-29', y: 129 },
            { x: '2020-09-30', y: 106 },
            { x: '2020-10-01', y: 134 },
            { x: '2020-10-02', y: 121 },
            { x: '2020-10-03', y: 86 },
            { x: '2020-10-05', y: 116 },
            { x: '2020-10-06', y: 131 },
            { x: '2020-10-07', y: 138 },
            { x: '2020-10-08', y: 128 },
            { x: '2020-10-09', y: 116 },
            { x: '2020-10-10', y: 104 },
            { x: '2020-10-12', y: 125 },
            { x: '2020-10-13', y: 129 },
            { x: '2020-10-14', y: 118 },
            { x: '2020-10-15', y: 131 },
            { x: '2020-10-16', y: 138 },
            { x: '2020-10-17', y: 109 },
            { x: '2020-10-19', y: 114 },
            { x: '2020-10-20', y: 124 },
            { x: '2020-10-21', y: 118 },
            { x: '2020-10-22', y: 109 },
            { x: '2020-10-23', y: 161 },
            { x: '2020-10-24', y: 130 },
            { x: '2020-10-27', y: 114 },
            { x: '2020-10-28', y: 126 },
            { x: '2020-10-29', y: 137 },
            { x: '2020-10-30', y: 154 },
            { x: '2020-10-31', y: 83 },
            { x: '2020-11-02', y: 107 },
            { x: '2020-11-03', y: 161 },
            { x: '2020-11-04', y: 117 },
            { x: '2020-11-05', y: 109 },
            { x: '2020-11-06', y: 129 },
            { x: '2020-11-07', y: 93 },
            { x: '2020-11-09', y: 107 },
            { x: '2020-11-10', y: 115 },
            { x: '2020-11-11', y: 110 },
            { x: '2020-11-12', y: 90 },
            { x: '2020-11-13', y: 111 },
            { x: '2020-11-14', y: 74 },
            { x: '2020-11-16', y: 117 },
            { x: '2020-11-17', y: 96 },
            { x: '2020-11-18', y: 139 },
            { x: '2020-11-19', y: 127 },
            { x: '2020-11-20', y: 106 },
            { x: '2020-11-21', y: 80 },
            { x: '2020-11-23', y: 122 },
            { x: '2020-11-24', y: 129 },
            { x: '2020-11-25', y: 112 },
            { x: '2020-11-27', y: 92 },
            { x: '2020-11-28', y: 82 },
            { x: '2020-11-30', y: 131 },
            { x: '2020-12-01', y: 116 },
            { x: '2020-12-02', y: 104 },
            { x: '2020-12-03', y: 125 },
            { x: '2020-12-04', y: 114 },
            { x: '2020-12-05', y: 99 },
            { x: '2020-12-07', y: 94 },
            { x: '2020-12-08', y: 100 },
            { x: '2020-12-09', y: 125 },
            { x: '2020-12-10', y: 125 },
            { x: '2020-12-11', y: 94 },
            { x: '2020-12-12', y: 95 },
            { x: '2020-12-14', y: 124 },
            { x: '2020-12-15', y: 120 },
            { x: '2020-12-16', y: 116 },
            { x: '2020-12-17', y: 101 },
            { x: '2020-12-18', y: 108 },
            { x: '2020-12-19', y: 93 },
            { x: '2020-12-21', y: 98 },
            { x: '2020-12-22', y: 104 },
            { x: '2020-12-23', y: 104 },
            { x: '2020-12-24', y: 55 },
            { x: '2020-12-26', y: 67 },
            { x: '2020-12-28', y: 94 },
            { x: '2020-12-29', y: 127 },
            { x: '2020-12-30', y: 108 },
            { x: '2020-12-31', y: 89 },
            { x: '2021-01-01', y: 52 },
            { x: '2021-01-02', y: 81 },
            { x: '2021-01-04', y: 106 },
            { x: '2021-01-05', y: 115 },
            { x: '2021-01-06', y: 156 },
            { x: '2021-01-07', y: 92 },
            { x: '2021-01-08', y: 130 },
            { x: '2021-01-09', y: 95 },
            { x: '2021-01-11', y: 130 },
            { x: '2021-01-12', y: 99 },
            { x: '2021-01-13', y: 117 },
            { x: '2021-01-14', y: 116 },
            { x: '2021-01-15', y: 116 },
            { x: '2021-01-16', y: 108 },
            { x: '2021-01-18', y: 128 },
            { x: '2021-01-19', y: 112 },
            { x: '2021-01-20', y: 136 },
            { x: '2021-01-21', y: 125 },
            { x: '2021-01-22', y: 133 },
            { x: '2021-01-23', y: 75 },
            { x: '2021-01-25', y: 93 },
            { x: '2021-01-26', y: 122 },
            { x: '2021-01-27', y: 115 },
            { x: '2021-01-28', y: 97 },
            { x: '2021-01-29', y: 106 },
            { x: '2021-01-30', y: 116 },
            { x: '2021-02-01', y: 84 },
            { x: '2021-02-02', y: 97 },
            { x: '2021-02-04', y: 123 },
            { x: '2021-02-05', y: 112 },
            { x: '2021-02-06', y: 83 },
            { x: '2021-02-08', y: 87 },
            { x: '2021-02-09', y: 99 },
            { x: '2021-02-10', y: 104 },
            { x: '2021-02-11', y: 85 },
            { x: '2021-02-12', y: 126 },
            { x: '2021-02-13', y: 107 },
            { x: '2021-02-15', y: 100 },
            { x: '2021-02-16', y: 80 },
            { x: '2021-02-17', y: 106 },
            { x: '2021-02-18', y: 93 },
            { x: '2021-02-19', y: 126 },
            { x: '2021-02-20', y: 95 },
            { x: '2021-02-22', y: 90 },
            { x: '2021-02-23', y: 88 },
            { x: '2021-02-24', y: 107 },
            { x: '2021-02-25', y: 118 },
            { x: '2021-02-26', y: 86 },
            { x: '2021-02-27', y: 88 },
            { x: '2021-03-01', y: 99 },
            { x: '2021-03-02', y: 112 },
            { x: '2021-03-03', y: 109 },
            { x: '2021-03-04', y: 109 },
            { x: '2021-03-05', y: 93 },
            { x: '2021-03-06', y: 80 },
            { x: '2021-03-08', y: 105 },
            { x: '2021-03-09', y: 94 },
            { x: '2021-03-10', y: 69 },
            { x: '2021-03-11', y: 107 },
            { x: '2021-03-12', y: 95 },
            { x: '2021-03-13', y: 45 },
            { x: '2021-03-15', y: 109 },
            { x: '2021-03-16', y: 76 },
            { x: '2021-03-17', y: 84 },
            { x: '2021-03-18', y: 116 },
            { x: '2021-03-19', y: 157 },
            { x: '2021-03-20', y: 143 },
            { x: '2021-03-22', y: 117 },
            { x: '2021-03-23', y: 103 },
            { x: '2021-03-24', y: 116 },
            { x: '2021-03-25', y: 119 },
            { x: '2021-03-26', y: 116 },
            { x: '2021-03-27', y: 155 },
            { x: '2021-03-29', y: 109 },
            { x: '2021-03-30', y: 97 },
            { x: '2021-03-31', y: 128 },
            { x: '2021-04-01', y: 151 },
            { x: '2021-04-02', y: 138 },
            { x: '2021-04-03', y: 124 },
            { x: '2021-04-05', y: 132 },
            { x: '2021-04-06', y: 123 },
            { x: '2021-04-07', y: 132 },
            { x: '2021-04-08', y: 132 },
            { x: '2021-04-09', y: 141 },
            { x: '2021-04-10', y: 119 },
            { x: '2021-04-12', y: 106 },
            { x: '2021-04-13', y: 100 },
            { x: '2021-04-14', y: 99 },
            { x: '2021-04-15', y: 111 },
            { x: '2021-04-16', y: 109 },
            { x: '2021-04-17', y: 106 },
            { x: '2021-04-19', y: 101 },
            { x: '2021-04-20', y: 103 },
            { x: '2021-04-21', y: 112 },
            { x: '2021-04-22', y: 108 },
            { x: '2021-04-23', y: 122 },
            { x: '2021-04-24', y: 77 },
            { x: '2021-04-26', y: 87 },
            { x: '2021-04-27', y: 88 },
            { x: '2021-04-28', y: 87 },
            { x: '2021-04-29', y: 118 },
            { x: '2021-04-30', y: 145 },
            { x: '2021-05-01', y: 107 },
            { x: '2021-05-03', y: 116 },
            { x: '2021-05-04', y: 94 },
            { x: '2021-05-05', y: 103 },
            { x: '2021-05-06', y: 87 },
            { x: '2021-05-07', y: 111 },
            { x: '2021-05-08', y: 103 },
            { x: '2021-05-10', y: 96 },
            { x: '2021-05-12', y: 108 },
            { x: '2021-05-13', y: 112 },
            { x: '2021-05-14', y: 106 },
            { x: '2021-05-15', y: 91 },
            { x: '2021-05-17', y: 83 },
            { x: '2021-05-18', y: 96 },
            { x: '2021-05-19', y: 107 },
            { x: '2021-05-20', y: 110 },
            { x: '2021-05-21', y: 108 },
            { x: '2021-05-22', y: 76 },
            { x: '2021-05-24', y: 116 },
            { x: '2021-05-25', y: 92 },
            { x: '2021-05-26', y: 96 },
            { x: '2021-05-27', y: 100 },
            { x: '2021-05-28', y: 101 },
            { x: '2021-05-29', y: 71 },
            { x: '2021-05-31', y: 98 },
            { x: '2021-06-01', y: 99 },
            { x: '2021-06-02', y: 77 },
            { x: '2021-06-03', y: 116 },
            { x: '2021-06-04', y: 88 },
            { x: '2021-06-05', y: 86 },
            { x: '2021-06-07', y: 97 },
            { x: '2021-06-08', y: 103 },
            { x: '2021-06-09', y: 100 },
            { x: '2021-06-10', y: 95 },
            { x: '2021-06-11', y: 111 },
            { x: '2021-06-12', y: 67 },
            { x: '2021-06-14', y: 89 },
            { x: '2021-06-15', y: 112 },
            { x: '2021-06-16', y: 121 },
            { x: '2021-06-17', y: 97 },
            { x: '2021-06-18', y: 88 },
            { x: '2021-06-19', y: 68 },
            { x: '2021-06-21', y: 143 },
            { x: '2021-06-22', y: 95 },
            { x: '2021-06-23', y: 97 },
            { x: '2021-06-24', y: 101 },
            { x: '2021-06-25', y: 105 },
            { x: '2021-06-26', y: 80 },
            { x: '2021-06-28', y: 80 },
            { x: '2021-06-29', y: 102 },
            { x: '2021-06-30', y: 103 },
            { x: '2021-07-01', y: 91 },
            { x: '2021-07-02', y: 83 },
            { x: '2021-07-03', y: 47 },
            { x: '2021-07-05', y: 85 },
            { x: '2021-07-06', y: 115 },
            { x: '2021-07-07', y: 74 },
            { x: '2021-07-08', y: 124 },
            { x: '2021-07-09', y: 80 },
            { x: '2021-07-10', y: 96 },
            { x: '2021-07-12', y: 83 },
            { x: '2021-07-13', y: 91 },
            { x: '2021-07-14', y: 105 },
            { x: '2021-07-15', y: 106 },
            { x: '2021-07-16', y: 81 },
            { x: '2021-07-17', y: 64 },
            { x: '2021-07-19', y: 106 },
            { x: '2021-07-20', y: 90 },
            { x: '2021-07-21', y: 105 },
            { x: '2021-07-22', y: 82 },
            { x: '2021-07-24', y: 51 },
            { x: '2021-07-26', y: 97 },
            { x: '2021-07-27', y: 94 },
            { x: '2021-07-28', y: 98 },
            { x: '2021-07-29', y: 92 },
            { x: '2021-07-30', y: 83 },
            { x: '2021-07-31', y: 67 },
            { x: '2021-08-02', y: 81 },
            { x: '2021-08-03', y: 98 },
            { x: '2021-08-04', y: 100 },
            { x: '2021-08-05', y: 101 },
            { x: '2021-08-06', y: 71 },
            { x: '2021-08-07', y: 51 },
            { x: '2021-08-09', y: 109 },
            { x: '2021-08-10', y: 96 },
            { x: '2021-08-11', y: 108 },
            { x: '2021-08-12', y: 82 },
            { x: '2021-08-13', y: 92 },
            { x: '2021-08-14', y: 63 },
            { x: '2021-08-16', y: 117 },
            { x: '2021-08-17', y: 74 },
            { x: '2021-08-18', y: 105 },
            { x: '2021-08-19', y: 93 },
            { x: '2021-08-20', y: 89 },
            { x: '2021-08-21', y: 62 },
            { x: '2021-08-23', y: 87 },
            { x: '2021-08-24', y: 94 },
            { x: '2021-08-25', y: 85 },
            { x: '2021-08-26', y: 93 },
            { x: '2021-08-27', y: 100 },
            { x: '2021-08-28', y: 85 },
            { x: '2021-08-30', y: 108 },
            { x: '2021-08-31', y: 91 },
            { x: '2021-09-01', y: 89 },
            { x: '2021-09-02', y: 112 },
            { x: '2021-09-03', y: 69 },
            { x: '2021-09-04', y: 73 },
            { x: '2021-09-06', y: 70 },
            { x: '2021-09-07', y: 101 },
            { x: '2021-09-08', y: 101 },
            { x: '2021-09-09', y: 97 },
            { x: '2021-09-10', y: 97 },
            { x: '2021-09-11', y: 72 },
            { x: '2021-09-13', y: 106 },
            { x: '2021-09-14', y: 124 },
            { x: '2021-09-15', y: 87 },
            { x: '2021-09-16', y: 104 },
            { x: '2021-09-17', y: 125 },
            { x: '2021-09-18', y: 51 },
            { x: '2021-09-20', y: 93 },
            { x: '2021-09-21', y: 110 },
            { x: '2021-09-22', y: 96 },
            { x: '2021-09-23', y: 38 },
        ],
    },
    {
        id: 'lettuce',
        color: '#efdf48',
        data: [
            { x: '2019-01-01', y: 152 },
            { x: '2019-01-02', y: 256 },
            { x: '2019-01-03', y: 314 },
            { x: '2019-01-04', y: 304 },
            { x: '2019-01-05', y: 182 },
            { x: '2019-01-07', y: 237 },
            { x: '2019-01-08', y: 290 },
            { x: '2019-01-09', y: 338 },
            { x: '2019-01-10', y: 308 },
            { x: '2019-01-11', y: 307 },
            { x: '2019-01-12', y: 246 },
            { x: '2019-01-14', y: 291 },
            { x: '2019-01-15', y: 264 },
            { x: '2019-01-16', y: 280 },
            { x: '2019-01-17', y: 272 },
            { x: '2019-01-18', y: 317 },
            { x: '2019-01-19', y: 227 },
            { x: '2019-01-21', y: 278 },
            { x: '2019-01-22', y: 299 },
            { x: '2019-01-23', y: 271 },
            { x: '2019-01-24', y: 337 },
            { x: '2019-01-25', y: 387 },
            { x: '2019-01-26', y: 316 },
            { x: '2019-01-28', y: 287 },
            { x: '2019-01-29', y: 276 },
            { x: '2019-01-30', y: 328 },
            { x: '2019-01-31', y: 338 },
            { x: '2019-02-01', y: 388 },
            { x: '2019-02-02', y: 203 },
            { x: '2019-02-04', y: 294 },
            { x: '2019-02-05', y: 261 },
            { x: '2019-02-06', y: 270 },
            { x: '2019-02-07', y: 322 },
            { x: '2019-02-08', y: 360 },
            { x: '2019-02-09', y: 233 },
            { x: '2019-02-11', y: 288 },
            { x: '2019-02-12', y: 303 },
            { x: '2019-02-13', y: 309 },
            { x: '2019-02-14', y: 306 },
            { x: '2019-02-15', y: 381 },
            { x: '2019-02-16', y: 450 },
            { x: '2019-02-18', y: 349 },
            { x: '2019-02-19', y: 285 },
            { x: '2019-02-20', y: 274 },
            { x: '2019-02-21', y: 257 },
            { x: '2019-02-22', y: 323 },
            { x: '2019-02-23', y: 264 },
            { x: '2019-02-25', y: 295 },
            { x: '2019-02-26', y: 316 },
            { x: '2019-02-27', y: 391 },
            { x: '2019-02-28', y: 371 },
            { x: '2019-03-01', y: 394 },
            { x: '2019-03-02', y: 215 },
            { x: '2019-03-04', y: 344 },
            { x: '2019-03-05', y: 325 },
            { x: '2019-03-07', y: 398 },
            { x: '2019-03-08', y: 479 },
            { x: '2019-03-09', y: 353 },
            { x: '2019-03-11', y: 354 },
            { x: '2019-03-12', y: 242 },
            { x: '2019-03-13', y: 280 },
            { x: '2019-03-14', y: 351 },
            { x: '2019-03-15', y: 392 },
            { x: '2019-03-16', y: 335 },
            { x: '2019-03-18', y: 387 },
            { x: '2019-03-19', y: 362 },
            { x: '2019-03-20', y: 351 },
            { x: '2019-03-21', y: 308 },
            { x: '2019-03-22', y: 432 },
            { x: '2019-03-23', y: 478 },
            { x: '2019-03-25', y: 395 },
            { x: '2019-03-26', y: 391 },
            { x: '2019-03-27', y: 341 },
            { x: '2019-03-28', y: 359 },
            { x: '2019-03-29', y: 450 },
            { x: '2019-03-30', y: 369 },
            { x: '2019-04-01', y: 388 },
            { x: '2019-04-02', y: 382 },
            { x: '2019-04-03', y: 425 },
            { x: '2019-04-04', y: 434 },
            { x: '2019-04-05', y: 470 },
            { x: '2019-04-06', y: 322 },
            { x: '2019-04-08', y: 350 },
            { x: '2019-04-09', y: 355 },
            { x: '2019-04-10', y: 384 },
            { x: '2019-04-11', y: 348 },
            { x: '2019-04-12', y: 383 },
            { x: '2019-04-13', y: 361 },
            { x: '2019-04-15', y: 387 },
            { x: '2019-04-16', y: 259 },
            { x: '2019-04-17', y: 387 },
            { x: '2019-04-18', y: 478 },
            { x: '2019-04-19', y: 353 },
            { x: '2019-04-20', y: 291 },
            { x: '2019-04-22', y: 369 },
            { x: '2019-04-23', y: 362 },
            { x: '2019-04-24', y: 370 },
            { x: '2019-04-25', y: 369 },
            { x: '2019-04-26', y: 382 },
            { x: '2019-04-27', y: 271 },
            { x: '2019-04-29', y: 292 },
            { x: '2019-04-30', y: 296 },
            { x: '2019-05-01', y: 333 },
            { x: '2019-05-02', y: 380 },
            { x: '2019-05-03', y: 488 },
            { x: '2019-05-04', y: 386 },
            { x: '2019-05-06', y: 333 },
            { x: '2019-05-07', y: 289 },
            { x: '2019-05-08', y: 322 },
            { x: '2019-05-09', y: 308 },
            { x: '2019-05-10', y: 327 },
            { x: '2019-05-11', y: 296 },
            { x: '2019-05-13', y: 330 },
            { x: '2019-05-14', y: 350 },
            { x: '2019-05-15', y: 378 },
            { x: '2019-05-16', y: 316 },
            { x: '2019-05-17', y: 352 },
            { x: '2019-05-18', y: 237 },
            { x: '2019-05-20', y: 303 },
            { x: '2019-05-21', y: 357 },
            { x: '2019-05-22', y: 338 },
            { x: '2019-05-23', y: 278 },
            { x: '2019-05-24', y: 346 },
            { x: '2019-05-25', y: 368 },
            { x: '2019-05-27', y: 187 },
            { x: '2019-05-28', y: 305 },
            { x: '2019-05-29', y: 327 },
            { x: '2019-05-30', y: 330 },
            { x: '2019-05-31', y: 314 },
            { x: '2019-06-01', y: 255 },
            { x: '2019-06-03', y: 338 },
            { x: '2019-06-04', y: 387 },
            { x: '2019-06-05', y: 363 },
            { x: '2019-06-06', y: 379 },
            { x: '2019-06-07', y: 329 },
            { x: '2019-06-08', y: 247 },
            { x: '2019-06-10', y: 363 },
            { x: '2019-06-11', y: 312 },
            { x: '2019-06-12', y: 324 },
            { x: '2019-06-13', y: 353 },
            { x: '2019-06-15', y: 242 },
            { x: '2019-06-17', y: 349 },
            { x: '2019-06-18', y: 305 },
            { x: '2019-06-19', y: 325 },
            { x: '2019-06-20', y: 350 },
            { x: '2019-06-21', y: 323 },
            { x: '2019-06-22', y: 252 },
            { x: '2019-06-24', y: 349 },
            { x: '2019-06-25', y: 316 },
            { x: '2019-06-26', y: 335 },
            { x: '2019-06-27', y: 320 },
            { x: '2019-06-28', y: 299 },
            { x: '2019-06-29', y: 193 },
            { x: '2019-07-01', y: 268 },
            { x: '2019-07-02', y: 306 },
            { x: '2019-07-03', y: 352 },
            { x: '2019-07-04', y: 175 },
            { x: '2019-07-05', y: 241 },
            { x: '2019-07-06', y: 227 },
            { x: '2019-07-08', y: 314 },
            { x: '2019-07-09', y: 320 },
            { x: '2019-07-10', y: 315 },
            { x: '2019-07-11', y: 272 },
            { x: '2019-07-12', y: 312 },
            { x: '2019-07-13', y: 211 },
            { x: '2019-07-15', y: 313 },
            { x: '2019-07-16', y: 287 },
            { x: '2019-07-17', y: 314 },
            { x: '2019-07-18', y: 320 },
            { x: '2019-07-19', y: 344 },
            { x: '2019-07-20', y: 194 },
            { x: '2019-07-22', y: 298 },
            { x: '2019-07-23', y: 321 },
            { x: '2019-07-24', y: 210 },
            { x: '2019-07-25', y: 301 },
            { x: '2019-07-26', y: 262 },
            { x: '2019-07-27', y: 213 },
            { x: '2019-07-29', y: 338 },
            { x: '2019-07-30', y: 295 },
            { x: '2019-07-31', y: 283 },
            { x: '2019-08-01', y: 300 },
            { x: '2019-08-02', y: 353 },
            { x: '2019-08-03', y: 215 },
            { x: '2019-08-05', y: 319 },
            { x: '2019-08-06', y: 310 },
            { x: '2019-08-07', y: 312 },
            { x: '2019-08-08', y: 313 },
            { x: '2019-08-09', y: 304 },
            { x: '2019-08-10', y: 256 },
            { x: '2019-08-12', y: 342 },
            { x: '2019-08-13', y: 298 },
            { x: '2019-08-14', y: 346 },
            { x: '2019-08-15', y: 305 },
            { x: '2019-08-16', y: 359 },
            { x: '2019-08-17', y: 230 },
            { x: '2019-08-19', y: 292 },
            { x: '2019-08-20', y: 315 },
            { x: '2019-08-21', y: 327 },
            { x: '2019-08-22', y: 369 },
            { x: '2019-08-23', y: 371 },
            { x: '2019-08-24', y: 248 },
            { x: '2019-08-26', y: 332 },
            { x: '2019-08-27', y: 338 },
            { x: '2019-08-28', y: 325 },
            { x: '2019-08-29', y: 336 },
            { x: '2019-08-30', y: 329 },
            { x: '2019-08-31', y: 260 },
            { x: '2019-09-02', y: 210 },
            { x: '2019-09-03', y: 284 },
            { x: '2019-09-04', y: 297 },
            { x: '2019-09-05', y: 327 },
            { x: '2019-09-06', y: 362 },
            { x: '2019-09-07', y: 298 },
            { x: '2019-09-09', y: 306 },
            { x: '2019-09-10', y: 323 },
            { x: '2019-09-11', y: 330 },
            { x: '2019-09-12', y: 298 },
            { x: '2019-09-13', y: 345 },
            { x: '2019-09-14', y: 258 },
            { x: '2019-09-16', y: 289 },
            { x: '2019-09-17', y: 347 },
            { x: '2019-09-18', y: 335 },
            { x: '2019-09-19', y: 349 },
            { x: '2019-09-20', y: 320 },
            { x: '2019-09-21', y: 276 },
            { x: '2019-09-23', y: 315 },
            { x: '2019-09-24', y: 309 },
            { x: '2019-09-25', y: 296 },
            { x: '2019-09-26', y: 322 },
            { x: '2019-09-27', y: 335 },
            { x: '2019-09-28', y: 240 },
            { x: '2019-09-30', y: 314 },
            { x: '2019-10-01', y: 324 },
            { x: '2019-10-02', y: 317 },
            { x: '2019-10-03', y: 365 },
            { x: '2019-10-04', y: 380 },
            { x: '2019-10-05', y: 293 },
            { x: '2019-10-07', y: 372 },
            { x: '2019-10-08', y: 287 },
            { x: '2019-10-09', y: 357 },
            { x: '2019-10-10', y: 315 },
            { x: '2019-10-11', y: 307 },
            { x: '2019-10-12', y: 260 },
            { x: '2019-10-14', y: 334 },
            { x: '2019-10-15', y: 342 },
            { x: '2019-10-16', y: 348 },
            { x: '2019-10-17', y: 345 },
            { x: '2019-10-18', y: 370 },
            { x: '2019-10-19', y: 252 },
            { x: '2019-10-21', y: 317 },
            { x: '2019-10-22', y: 271 },
            { x: '2019-10-23', y: 336 },
            { x: '2019-10-24', y: 336 },
            { x: '2019-10-25', y: 297 },
            { x: '2019-10-26', y: 279 },
            { x: '2019-10-28', y: 267 },
            { x: '2019-10-29', y: 268 },
            { x: '2019-10-30', y: 307 },
            { x: '2019-10-31', y: 245 },
            { x: '2019-11-01', y: 339 },
            { x: '2019-11-02', y: 300 },
            { x: '2019-11-04', y: 278 },
            { x: '2019-11-05', y: 271 },
            { x: '2019-11-06', y: 284 },
            { x: '2019-11-07', y: 334 },
            { x: '2019-11-08', y: 381 },
            { x: '2019-11-09', y: 371 },
            { x: '2019-11-11', y: 289 },
            { x: '2019-11-12', y: 272 },
            { x: '2019-11-13', y: 319 },
            { x: '2019-11-14', y: 290 },
            { x: '2019-11-15', y: 316 },
            { x: '2019-11-16', y: 238 },
            { x: '2019-11-18', y: 307 },
            { x: '2019-11-19', y: 295 },
            { x: '2019-11-20', y: 257 },
            { x: '2019-11-21', y: 271 },
            { x: '2019-11-22', y: 324 },
            { x: '2019-11-23', y: 253 },
            { x: '2019-11-25', y: 279 },
            { x: '2019-11-26', y: 351 },
            { x: '2019-11-27', y: 254 },
            { x: '2019-11-29', y: 201 },
            { x: '2019-11-30', y: 180 },
            { x: '2019-12-02', y: 281 },
            { x: '2019-12-03', y: 257 },
            { x: '2019-12-04', y: 308 },
            { x: '2019-12-05', y: 307 },
            { x: '2019-12-06', y: 343 },
            { x: '2019-12-07', y: 219 },
            { x: '2019-12-09', y: 285 },
            { x: '2019-12-10', y: 254 },
            { x: '2019-12-11', y: 313 },
            { x: '2019-12-12', y: 296 },
            { x: '2019-12-13', y: 266 },
            { x: '2019-12-14', y: 195 },
            { x: '2019-12-16', y: 249 },
            { x: '2019-12-17', y: 258 },
            { x: '2019-12-18', y: 284 },
            { x: '2019-12-19', y: 286 },
            { x: '2019-12-20', y: 286 },
            { x: '2019-12-21', y: 224 },
            { x: '2019-12-23', y: 294 },
            { x: '2019-12-24', y: 120 },
            { x: '2019-12-26', y: 186 },
            { x: '2019-12-27', y: 273 },
            { x: '2019-12-28', y: 208 },
            { x: '2019-12-30', y: 275 },
            { x: '2020-01-01', y: 157 },
            { x: '2020-01-02', y: 292 },
            { x: '2020-01-03', y: 301 },
            { x: '2020-01-04', y: 217 },
            { x: '2020-01-06', y: 284 },
            { x: '2020-01-07', y: 319 },
            { x: '2020-01-08', y: 316 },
            { x: '2020-01-09', y: 290 },
            { x: '2020-01-10', y: 309 },
            { x: '2020-01-11', y: 216 },
            { x: '2020-01-13', y: 293 },
            { x: '2020-01-14', y: 289 },
            { x: '2020-01-15', y: 324 },
            { x: '2020-01-16', y: 307 },
            { x: '2020-01-17', y: 323 },
            { x: '2020-01-18', y: 282 },
            { x: '2020-01-20', y: 275 },
            { x: '2020-01-21', y: 287 },
            { x: '2020-01-22', y: 286 },
            { x: '2020-01-23', y: 309 },
            { x: '2020-01-24', y: 329 },
            { x: '2020-01-25', y: 282 },
            { x: '2020-01-27', y: 319 },
            { x: '2020-01-28', y: 295 },
            { x: '2020-01-29', y: 296 },
            { x: '2020-01-30', y: 290 },
            { x: '2020-01-31', y: 350 },
            { x: '2020-02-01', y: 268 },
            { x: '2020-02-03', y: 238 },
            { x: '2020-02-04', y: 291 },
            { x: '2020-02-05', y: 294 },
            { x: '2020-02-06', y: 315 },
            { x: '2020-02-07', y: 318 },
            { x: '2020-02-08', y: 259 },
            { x: '2020-02-10', y: 333 },
            { x: '2020-02-11', y: 300 },
            { x: '2020-02-12', y: 298 },
            { x: '2020-02-13', y: 351 },
            { x: '2020-02-14', y: 428 },
            { x: '2020-02-15', y: 458 },
            { x: '2020-02-17', y: 401 },
            { x: '2020-02-18', y: 306 },
            { x: '2020-02-19', y: 332 },
            { x: '2020-02-20', y: 383 },
            { x: '2020-02-21', y: 402 },
            { x: '2020-02-22', y: 265 },
            { x: '2020-02-24', y: 317 },
            { x: '2020-02-25', y: 304 },
            { x: '2020-02-26', y: 307 },
            { x: '2020-02-27', y: 263 },
            { x: '2020-02-28', y: 257 },
            { x: '2020-02-29', y: 243 },
            { x: '2020-03-02', y: 230 },
            { x: '2020-03-03', y: 260 },
            { x: '2020-03-04', y: 270 },
            { x: '2020-03-05', y: 321 },
            { x: '2020-03-06', y: 438 },
            { x: '2020-03-07', y: 287 },
            { x: '2020-03-09', y: 272 },
            { x: '2020-03-10', y: 258 },
            { x: '2020-03-11', y: 305 },
            { x: '2020-03-12', y: 328 },
            { x: '2020-03-13', y: 244 },
            { x: '2020-03-14', y: 271 },
            { x: '2020-03-16', y: 238 },
            { x: '2020-03-17', y: 235 },
            { x: '2020-03-18', y: 226 },
            { x: '2020-03-19', y: 224 },
            { x: '2020-03-20', y: 200 },
            { x: '2020-03-21', y: 160 },
            { x: '2020-03-23', y: 215 },
            { x: '2020-03-24', y: 300 },
            { x: '2020-03-25', y: 258 },
            { x: '2020-03-26', y: 281 },
            { x: '2020-03-27', y: 211 },
            { x: '2020-03-28', y: 207 },
            { x: '2020-03-30', y: 240 },
            { x: '2020-03-31', y: 225 },
            { x: '2020-04-01', y: 238 },
            { x: '2020-04-02', y: 289 },
            { x: '2020-04-03', y: 257 },
            { x: '2020-04-04', y: 199 },
            { x: '2020-04-06', y: 299 },
            { x: '2020-04-07', y: 266 },
            { x: '2020-04-08', y: 243 },
            { x: '2020-04-10', y: 215 },
            { x: '2020-04-11', y: 248 },
            { x: '2020-04-13', y: 313 },
            { x: '2020-04-14', y: 250 },
            { x: '2020-04-15', y: 304 },
            { x: '2020-04-16', y: 298 },
            { x: '2020-04-17', y: 361 },
            { x: '2020-04-18', y: 286 },
            { x: '2020-04-20', y: 390 },
            { x: '2020-04-21', y: 249 },
            { x: '2020-04-22', y: 365 },
            { x: '2020-04-23', y: 308 },
            { x: '2020-04-24', y: 423 },
            { x: '2020-04-25', y: 364 },
            { x: '2020-04-27', y: 420 },
            { x: '2020-04-28', y: 328 },
            { x: '2020-04-29', y: 339 },
            { x: '2020-04-30', y: 359 },
            { x: '2020-05-01', y: 398 },
            { x: '2020-05-02', y: 371 },
            { x: '2020-05-04', y: 374 },
            { x: '2020-05-05', y: 296 },
            { x: '2020-05-06', y: 367 },
            { x: '2020-05-07', y: 318 },
            { x: '2020-05-08', y: 380 },
            { x: '2020-05-09', y: 335 },
            { x: '2020-05-11', y: 340 },
            { x: '2020-05-12', y: 342 },
            { x: '2020-05-13', y: 353 },
            { x: '2020-05-14', y: 384 },
            { x: '2020-05-15', y: 390 },
            { x: '2020-05-16', y: 395 },
            { x: '2020-05-18', y: 363 },
            { x: '2020-05-19', y: 321 },
            { x: '2020-05-20', y: 392 },
            { x: '2020-05-21', y: 379 },
            { x: '2020-05-22', y: 403 },
            { x: '2020-05-23', y: 379 },
            { x: '2020-05-25', y: 311 },
            { x: '2020-05-26', y: 345 },
            { x: '2020-05-27', y: 355 },
            { x: '2020-05-28', y: 368 },
            { x: '2020-05-29', y: 421 },
            { x: '2020-05-30', y: 325 },
            { x: '2020-06-01', y: 361 },
            { x: '2020-06-02', y: 338 },
            { x: '2020-06-03', y: 357 },
            { x: '2020-06-04', y: 367 },
            { x: '2020-06-05', y: 424 },
            { x: '2020-06-06', y: 275 },
            { x: '2020-06-08', y: 367 },
            { x: '2020-06-09', y: 353 },
            { x: '2020-06-10', y: 350 },
            { x: '2020-06-11', y: 394 },
            { x: '2020-06-12', y: 377 },
            { x: '2020-06-13', y: 298 },
            { x: '2020-06-15', y: 410 },
            { x: '2020-06-16', y: 350 },
            { x: '2020-06-17', y: 364 },
            { x: '2020-06-18', y: 381 },
            { x: '2020-06-19', y: 420 },
            { x: '2020-06-20', y: 289 },
            { x: '2020-06-22', y: 347 },
            { x: '2020-06-23', y: 356 },
            { x: '2020-06-24', y: 367 },
            { x: '2020-06-25', y: 355 },
            { x: '2020-06-26', y: 427 },
            { x: '2020-06-27', y: 287 },
            { x: '2020-06-29', y: 432 },
            { x: '2020-06-30', y: 350 },
            { x: '2020-07-01', y: 400 },
            { x: '2020-07-02', y: 351 },
            { x: '2020-07-03', y: 323 },
            { x: '2020-07-04', y: 218 },
            { x: '2020-07-06', y: 336 },
            { x: '2020-07-07', y: 295 },
            { x: '2020-07-08', y: 355 },
            { x: '2020-07-09', y: 304 },
            { x: '2020-07-10', y: 295 },
            { x: '2020-07-11', y: 297 },
            { x: '2020-07-13', y: 397 },
            { x: '2020-07-14', y: 362 },
            { x: '2020-07-15', y: 349 },
            { x: '2020-07-16', y: 373 },
            { x: '2020-07-17', y: 374 },
            { x: '2020-07-20', y: 297 },
            { x: '2020-07-21', y: 329 },
            { x: '2020-07-22', y: 333 },
            { x: '2020-07-23', y: 335 },
            { x: '2020-07-24', y: 278 },
            { x: '2020-07-25', y: 278 },
            { x: '2020-07-27', y: 323 },
            { x: '2020-07-28', y: 361 },
            { x: '2020-07-29', y: 332 },
            { x: '2020-07-30', y: 378 },
            { x: '2020-07-31', y: 389 },
            { x: '2020-08-01', y: 257 },
            { x: '2020-08-03', y: 348 },
            { x: '2020-08-04', y: 309 },
            { x: '2020-08-05', y: 359 },
            { x: '2020-08-06', y: 341 },
            { x: '2020-08-07', y: 338 },
            { x: '2020-08-08', y: 272 },
            { x: '2020-08-10', y: 321 },
            { x: '2020-08-11', y: 338 },
            { x: '2020-08-12', y: 383 },
            { x: '2020-08-13', y: 359 },
            { x: '2020-08-14', y: 373 },
            { x: '2020-08-15', y: 140 },
            { x: '2020-08-17', y: 342 },
            { x: '2020-08-18', y: 314 },
            { x: '2020-08-19', y: 360 },
            { x: '2020-08-20', y: 321 },
            { x: '2020-08-21', y: 349 },
            { x: '2020-08-22', y: 247 },
            { x: '2020-08-24', y: 396 },
            { x: '2020-08-25', y: 347 },
            { x: '2020-08-26', y: 356 },
            { x: '2020-08-27', y: 327 },
            { x: '2020-08-28', y: 410 },
            { x: '2020-08-29', y: 330 },
            { x: '2020-08-31', y: 360 },
            { x: '2020-09-01', y: 340 },
            { x: '2020-09-02', y: 340 },
            { x: '2020-09-03', y: 361 },
            { x: '2020-09-04', y: 389 },
            { x: '2020-09-05', y: 310 },
            { x: '2020-09-07', y: 222 },
            { x: '2020-09-08', y: 352 },
            { x: '2020-09-09', y: 321 },
            { x: '2020-09-10', y: 387 },
            { x: '2020-09-11', y: 357 },
            { x: '2020-09-12', y: 336 },
            { x: '2020-09-14', y: 370 },
            { x: '2020-09-15', y: 350 },
            { x: '2020-09-16', y: 339 },
            { x: '2020-09-17', y: 398 },
            { x: '2020-09-18', y: 337 },
            { x: '2020-09-19', y: 344 },
            { x: '2020-09-21', y: 381 },
            { x: '2020-09-22', y: 350 },
            { x: '2020-09-23', y: 339 },
            { x: '2020-09-24', y: 379 },
            { x: '2020-09-25', y: 378 },
            { x: '2020-09-26', y: 281 },
            { x: '2020-09-28', y: 306 },
            { x: '2020-09-29', y: 354 },
            { x: '2020-09-30', y: 297 },
            { x: '2020-10-01', y: 351 },
            { x: '2020-10-02', y: 325 },
            { x: '2020-10-03', y: 239 },
            { x: '2020-10-05', y: 331 },
            { x: '2020-10-06', y: 313 },
            { x: '2020-10-07', y: 346 },
            { x: '2020-10-08', y: 359 },
            { x: '2020-10-09', y: 338 },
            { x: '2020-10-10', y: 313 },
            { x: '2020-10-12', y: 322 },
            { x: '2020-10-13', y: 321 },
            { x: '2020-10-14', y: 313 },
            { x: '2020-10-15', y: 343 },
            { x: '2020-10-16', y: 340 },
            { x: '2020-10-17', y: 289 },
            { x: '2020-10-19', y: 321 },
            { x: '2020-10-20', y: 321 },
            { x: '2020-10-21', y: 331 },
            { x: '2020-10-22', y: 342 },
            { x: '2020-10-23', y: 386 },
            { x: '2020-10-24', y: 343 },
            { x: '2020-10-27', y: 284 },
            { x: '2020-10-28', y: 309 },
            { x: '2020-10-29', y: 363 },
            { x: '2020-10-30', y: 395 },
            { x: '2020-10-31', y: 231 },
            { x: '2020-11-02', y: 286 },
            { x: '2020-11-03', y: 371 },
            { x: '2020-11-04', y: 320 },
            { x: '2020-11-05', y: 297 },
            { x: '2020-11-06', y: 314 },
            { x: '2020-11-07', y: 240 },
            { x: '2020-11-09', y: 290 },
            { x: '2020-11-10', y: 298 },
            { x: '2020-11-11', y: 260 },
            { x: '2020-11-12', y: 243 },
            { x: '2020-11-13', y: 307 },
            { x: '2020-11-14', y: 201 },
            { x: '2020-11-16', y: 282 },
            { x: '2020-11-17', y: 285 },
            { x: '2020-11-18', y: 335 },
            { x: '2020-11-19', y: 321 },
            { x: '2020-11-20', y: 268 },
            { x: '2020-11-21', y: 214 },
            { x: '2020-11-23', y: 311 },
            { x: '2020-11-24', y: 309 },
            { x: '2020-11-25', y: 314 },
            { x: '2020-11-27', y: 204 },
            { x: '2020-11-28', y: 226 },
            { x: '2020-11-30', y: 302 },
            { x: '2020-12-01', y: 286 },
            { x: '2020-12-02', y: 262 },
            { x: '2020-12-03', y: 300 },
            { x: '2020-12-04', y: 296 },
            { x: '2020-12-05', y: 222 },
            { x: '2020-12-07', y: 258 },
            { x: '2020-12-08', y: 283 },
            { x: '2020-12-09', y: 283 },
            { x: '2020-12-10', y: 311 },
            { x: '2020-12-11', y: 274 },
            { x: '2020-12-12', y: 184 },
            { x: '2020-12-14', y: 289 },
            { x: '2020-12-15', y: 314 },
            { x: '2020-12-16', y: 289 },
            { x: '2020-12-17', y: 273 },
            { x: '2020-12-18', y: 285 },
            { x: '2020-12-19', y: 233 },
            { x: '2020-12-21', y: 264 },
            { x: '2020-12-22', y: 269 },
            { x: '2020-12-23', y: 287 },
            { x: '2020-12-24', y: 122 },
            { x: '2020-12-26', y: 153 },
            { x: '2020-12-28', y: 251 },
            { x: '2020-12-29', y: 301 },
            { x: '2020-12-30', y: 310 },
            { x: '2020-12-31', y: 262 },
            { x: '2021-01-01', y: 164 },
            { x: '2021-01-02', y: 240 },
            { x: '2021-01-04', y: 294 },
            { x: '2021-01-05', y: 336 },
            { x: '2021-01-06', y: 379 },
            { x: '2021-01-07', y: 274 },
            { x: '2021-01-08', y: 321 },
            { x: '2021-01-09', y: 244 },
            { x: '2021-01-11', y: 324 },
            { x: '2021-01-12', y: 262 },
            { x: '2021-01-13', y: 340 },
            { x: '2021-01-14', y: 308 },
            { x: '2021-01-15', y: 301 },
            { x: '2021-01-16', y: 299 },
            { x: '2021-01-18', y: 357 },
            { x: '2021-01-19', y: 285 },
            { x: '2021-01-20', y: 353 },
            { x: '2021-01-21', y: 339 },
            { x: '2021-01-22', y: 345 },
            { x: '2021-01-23', y: 216 },
            { x: '2021-01-25', y: 257 },
            { x: '2021-01-26', y: 306 },
            { x: '2021-01-27', y: 291 },
            { x: '2021-01-28', y: 310 },
            { x: '2021-01-29', y: 288 },
            { x: '2021-01-30', y: 295 },
            { x: '2021-02-01', y: 196 },
            { x: '2021-02-02', y: 265 },
            { x: '2021-02-04', y: 301 },
            { x: '2021-02-05', y: 318 },
            { x: '2021-02-06', y: 255 },
            { x: '2021-02-08', y: 228 },
            { x: '2021-02-09', y: 231 },
            { x: '2021-02-10', y: 265 },
            { x: '2021-02-11', y: 233 },
            { x: '2021-02-12', y: 395 },
            { x: '2021-02-13', y: 326 },
            { x: '2021-02-15', y: 289 },
            { x: '2021-02-16', y: 206 },
            { x: '2021-02-17', y: 292 },
            { x: '2021-02-18', y: 267 },
            { x: '2021-02-19', y: 304 },
            { x: '2021-02-20', y: 274 },
            { x: '2021-02-22', y: 214 },
            { x: '2021-02-23', y: 258 },
            { x: '2021-02-24', y: 269 },
            { x: '2021-02-25', y: 303 },
            { x: '2021-02-26', y: 270 },
            { x: '2021-02-27', y: 241 },
            { x: '2021-03-01', y: 253 },
            { x: '2021-03-02', y: 281 },
            { x: '2021-03-03', y: 240 },
            { x: '2021-03-04', y: 266 },
            { x: '2021-03-05', y: 261 },
            { x: '2021-03-06', y: 237 },
            { x: '2021-03-08', y: 265 },
            { x: '2021-03-09', y: 232 },
            { x: '2021-03-10', y: 230 },
            { x: '2021-03-11', y: 279 },
            { x: '2021-03-12', y: 275 },
            { x: '2021-03-13', y: 140 },
            { x: '2021-03-15', y: 281 },
            { x: '2021-03-16', y: 221 },
            { x: '2021-03-17', y: 247 },
            { x: '2021-03-18', y: 308 },
            { x: '2021-03-19', y: 484 },
            { x: '2021-03-20', y: 429 },
            { x: '2021-03-22', y: 302 },
            { x: '2021-03-23', y: 272 },
            { x: '2021-03-24', y: 314 },
            { x: '2021-03-25', y: 301 },
            { x: '2021-03-26', y: 368 },
            { x: '2021-03-27', y: 402 },
            { x: '2021-03-29', y: 334 },
            { x: '2021-03-30', y: 288 },
            { x: '2021-03-31', y: 328 },
            { x: '2021-04-01', y: 395 },
            { x: '2021-04-02', y: 413 },
            { x: '2021-04-03', y: 349 },
            { x: '2021-04-05', y: 323 },
            { x: '2021-04-06', y: 315 },
            { x: '2021-04-07', y: 332 },
            { x: '2021-04-08', y: 360 },
            { x: '2021-04-09', y: 355 },
            { x: '2021-04-10', y: 356 },
            { x: '2021-04-12', y: 264 },
            { x: '2021-04-13', y: 292 },
            { x: '2021-04-14', y: 285 },
            { x: '2021-04-15', y: 308 },
            { x: '2021-04-16', y: 320 },
            { x: '2021-04-17', y: 294 },
            { x: '2021-04-19', y: 285 },
            { x: '2021-04-20', y: 314 },
            { x: '2021-04-21', y: 348 },
            { x: '2021-04-22', y: 278 },
            { x: '2021-04-23', y: 313 },
            { x: '2021-04-24', y: 210 },
            { x: '2021-04-26', y: 230 },
            { x: '2021-04-27', y: 235 },
            { x: '2021-04-28', y: 255 },
            { x: '2021-04-29', y: 343 },
            { x: '2021-04-30', y: 369 },
            { x: '2021-05-01', y: 261 },
            { x: '2021-05-03', y: 285 },
            { x: '2021-05-04', y: 271 },
            { x: '2021-05-05', y: 252 },
            { x: '2021-05-06', y: 262 },
            { x: '2021-05-07', y: 284 },
            { x: '2021-05-08', y: 278 },
            { x: '2021-05-10', y: 250 },
            { x: '2021-05-12', y: 265 },
            { x: '2021-05-13', y: 292 },
            { x: '2021-05-14', y: 334 },
            { x: '2021-05-15', y: 247 },
            { x: '2021-05-17', y: 223 },
            { x: '2021-05-18', y: 262 },
            { x: '2021-05-19', y: 292 },
            { x: '2021-05-20', y: 280 },
            { x: '2021-05-21', y: 300 },
            { x: '2021-05-22', y: 199 },
            { x: '2021-05-24', y: 275 },
            { x: '2021-05-25', y: 255 },
            { x: '2021-05-26', y: 273 },
            { x: '2021-05-27', y: 275 },
            { x: '2021-05-28', y: 282 },
            { x: '2021-05-29', y: 242 },
            { x: '2021-05-31', y: 244 },
            { x: '2021-06-01', y: 281 },
            { x: '2021-06-02', y: 257 },
            { x: '2021-06-03', y: 296 },
            { x: '2021-06-04', y: 286 },
            { x: '2021-06-05', y: 231 },
            { x: '2021-06-07', y: 269 },
            { x: '2021-06-08', y: 267 },
            { x: '2021-06-09', y: 269 },
            { x: '2021-06-10', y: 270 },
            { x: '2021-06-11', y: 278 },
            { x: '2021-06-12', y: 178 },
            { x: '2021-06-14', y: 255 },
            { x: '2021-06-15', y: 301 },
            { x: '2021-06-16', y: 289 },
            { x: '2021-06-17', y: 278 },
            { x: '2021-06-18', y: 283 },
            { x: '2021-06-19', y: 168 },
            { x: '2021-06-21', y: 329 },
            { x: '2021-06-22', y: 265 },
            { x: '2021-06-23', y: 261 },
            { x: '2021-06-24', y: 264 },
            { x: '2021-06-25', y: 305 },
            { x: '2021-06-26', y: 204 },
            { x: '2021-06-28', y: 243 },
            { x: '2021-06-29', y: 270 },
            { x: '2021-06-30', y: 276 },
            { x: '2021-07-01', y: 292 },
            { x: '2021-07-02', y: 251 },
            { x: '2021-07-03', y: 138 },
            { x: '2021-07-05', y: 208 },
            { x: '2021-07-06', y: 292 },
            { x: '2021-07-07', y: 238 },
            { x: '2021-07-08', y: 306 },
            { x: '2021-07-09', y: 235 },
            { x: '2021-07-10', y: 241 },
            { x: '2021-07-12', y: 232 },
            { x: '2021-07-13', y: 244 },
            { x: '2021-07-14', y: 247 },
            { x: '2021-07-15', y: 265 },
            { x: '2021-07-16', y: 237 },
            { x: '2021-07-17', y: 174 },
            { x: '2021-07-19', y: 272 },
            { x: '2021-07-20', y: 216 },
            { x: '2021-07-21', y: 248 },
            { x: '2021-07-22', y: 242 },
            { x: '2021-07-24', y: 140 },
            { x: '2021-07-26', y: 278 },
            { x: '2021-07-27', y: 218 },
            { x: '2021-07-28', y: 260 },
            { x: '2021-07-29', y: 228 },
            { x: '2021-07-30', y: 235 },
            { x: '2021-07-31', y: 178 },
            { x: '2021-08-02', y: 231 },
            { x: '2021-08-03', y: 251 },
            { x: '2021-08-04', y: 270 },
            { x: '2021-08-05', y: 257 },
            { x: '2021-08-06', y: 242 },
            { x: '2021-08-07', y: 161 },
            { x: '2021-08-09', y: 245 },
            { x: '2021-08-10', y: 241 },
            { x: '2021-08-11', y: 286 },
            { x: '2021-08-12', y: 230 },
            { x: '2021-08-13', y: 213 },
            { x: '2021-08-14', y: 173 },
            { x: '2021-08-16', y: 281 },
            { x: '2021-08-17', y: 241 },
            { x: '2021-08-18', y: 234 },
            { x: '2021-08-19', y: 253 },
            { x: '2021-08-20', y: 254 },
            { x: '2021-08-21', y: 186 },
            { x: '2021-08-23', y: 246 },
            { x: '2021-08-24', y: 241 },
            { x: '2021-08-25', y: 230 },
            { x: '2021-08-26', y: 248 },
            { x: '2021-08-27', y: 297 },
            { x: '2021-08-28', y: 240 },
            { x: '2021-08-30', y: 262 },
            { x: '2021-08-31', y: 239 },
            { x: '2021-09-01', y: 218 },
            { x: '2021-09-02', y: 289 },
            { x: '2021-09-03', y: 228 },
            { x: '2021-09-04', y: 216 },
            { x: '2021-09-06', y: 204 },
            { x: '2021-09-07', y: 269 },
            { x: '2021-09-08', y: 265 },
            { x: '2021-09-09', y: 255 },
            { x: '2021-09-10', y: 280 },
            { x: '2021-09-11', y: 219 },
            { x: '2021-09-13', y: 236 },
            { x: '2021-09-14', y: 291 },
            { x: '2021-09-15', y: 216 },
            { x: '2021-09-16', y: 284 },
            { x: '2021-09-17', y: 322 },
            { x: '2021-09-18', y: 181 },
            { x: '2021-09-20', y: 260 },
            { x: '2021-09-21', y: 268 },
            { x: '2021-09-22', y: 261 },
            { x: '2021-09-23', y: 106 },
        ],
    },
    {
        id: 'turkey',
        color: '#f9a52c',
        data: [
            { x: '2019-01-01', y: 106 },
            { x: '2019-01-02', y: 187 },
            { x: '2019-01-03', y: 243 },
            { x: '2019-01-04', y: 230 },
            { x: '2019-01-05', y: 135 },
            { x: '2019-01-07', y: 179 },
            { x: '2019-01-08', y: 212 },
            { x: '2019-01-09', y: 259 },
            { x: '2019-01-10', y: 235 },
            { x: '2019-01-11', y: 238 },
            { x: '2019-01-12', y: 162 },
            { x: '2019-01-14', y: 208 },
            { x: '2019-01-15', y: 197 },
            { x: '2019-01-16', y: 213 },
            { x: '2019-01-17', y: 202 },
            { x: '2019-01-18', y: 242 },
            { x: '2019-01-19', y: 177 },
            { x: '2019-01-21', y: 198 },
            { x: '2019-01-22', y: 229 },
            { x: '2019-01-23', y: 205 },
            { x: '2019-01-24', y: 234 },
            { x: '2019-01-25', y: 308 },
            { x: '2019-01-26', y: 239 },
            { x: '2019-01-28', y: 208 },
            { x: '2019-01-29', y: 211 },
            { x: '2019-01-30', y: 244 },
            { x: '2019-01-31', y: 257 },
            { x: '2019-02-01', y: 291 },
            { x: '2019-02-02', y: 150 },
            { x: '2019-02-04', y: 217 },
            { x: '2019-02-05', y: 194 },
            { x: '2019-02-06', y: 204 },
            { x: '2019-02-07', y: 253 },
            { x: '2019-02-08', y: 259 },
            { x: '2019-02-09', y: 165 },
            { x: '2019-02-11', y: 223 },
            { x: '2019-02-12', y: 223 },
            { x: '2019-02-13', y: 219 },
            { x: '2019-02-14', y: 229 },
            { x: '2019-02-15', y: 286 },
            { x: '2019-02-16', y: 333 },
            { x: '2019-02-18', y: 262 },
            { x: '2019-02-19', y: 216 },
            { x: '2019-02-20', y: 200 },
            { x: '2019-02-21', y: 192 },
            { x: '2019-02-22', y: 225 },
            { x: '2019-02-23', y: 176 },
            { x: '2019-02-25', y: 229 },
            { x: '2019-02-26', y: 234 },
            { x: '2019-02-27', y: 291 },
            { x: '2019-02-28', y: 276 },
            { x: '2019-03-01', y: 304 },
            { x: '2019-03-02', y: 148 },
            { x: '2019-03-04', y: 268 },
            { x: '2019-03-05', y: 248 },
            { x: '2019-03-07', y: 292 },
            { x: '2019-03-08', y: 366 },
            { x: '2019-03-09', y: 257 },
            { x: '2019-03-11', y: 251 },
            { x: '2019-03-12', y: 166 },
            { x: '2019-03-13', y: 209 },
            { x: '2019-03-14', y: 264 },
            { x: '2019-03-15', y: 280 },
            { x: '2019-03-16', y: 253 },
            { x: '2019-03-18', y: 295 },
            { x: '2019-03-19', y: 271 },
            { x: '2019-03-20', y: 259 },
            { x: '2019-03-21', y: 230 },
            { x: '2019-03-22', y: 300 },
            { x: '2019-03-23', y: 366 },
            { x: '2019-03-25', y: 289 },
            { x: '2019-03-26', y: 298 },
            { x: '2019-03-27', y: 260 },
            { x: '2019-03-28', y: 263 },
            { x: '2019-03-29', y: 347 },
            { x: '2019-03-30', y: 275 },
            { x: '2019-04-01', y: 281 },
            { x: '2019-04-02', y: 277 },
            { x: '2019-04-03', y: 319 },
            { x: '2019-04-04', y: 329 },
            { x: '2019-04-05', y: 344 },
            { x: '2019-04-06', y: 250 },
            { x: '2019-04-08', y: 261 },
            { x: '2019-04-09', y: 250 },
            { x: '2019-04-10', y: 282 },
            { x: '2019-04-11', y: 253 },
            { x: '2019-04-12', y: 282 },
            { x: '2019-04-13', y: 280 },
            { x: '2019-04-15', y: 287 },
            { x: '2019-04-16', y: 192 },
            { x: '2019-04-17', y: 289 },
            { x: '2019-04-18', y: 362 },
            { x: '2019-04-19', y: 253 },
            { x: '2019-04-20', y: 221 },
            { x: '2019-04-22', y: 275 },
            { x: '2019-04-23', y: 283 },
            { x: '2019-04-24', y: 271 },
            { x: '2019-04-25', y: 265 },
            { x: '2019-04-26', y: 285 },
            { x: '2019-04-27', y: 202 },
            { x: '2019-04-29', y: 234 },
            { x: '2019-04-30', y: 222 },
            { x: '2019-05-01', y: 246 },
            { x: '2019-05-02', y: 289 },
            { x: '2019-05-03', y: 377 },
            { x: '2019-05-04', y: 298 },
            { x: '2019-05-06', y: 247 },
            { x: '2019-05-07', y: 207 },
            { x: '2019-05-08', y: 237 },
            { x: '2019-05-09', y: 239 },
            { x: '2019-05-10', y: 237 },
            { x: '2019-05-11', y: 210 },
            { x: '2019-05-13', y: 255 },
            { x: '2019-05-14', y: 274 },
            { x: '2019-05-15', y: 267 },
            { x: '2019-05-16', y: 240 },
            { x: '2019-05-17', y: 253 },
            { x: '2019-05-18', y: 162 },
            { x: '2019-05-20', y: 228 },
            { x: '2019-05-21', y: 276 },
            { x: '2019-05-22', y: 246 },
            { x: '2019-05-23', y: 218 },
            { x: '2019-05-24', y: 268 },
            { x: '2019-05-25', y: 279 },
            { x: '2019-05-27', y: 149 },
            { x: '2019-05-28', y: 225 },
            { x: '2019-05-29', y: 235 },
            { x: '2019-05-30', y: 247 },
            { x: '2019-05-31', y: 227 },
            { x: '2019-06-01', y: 187 },
            { x: '2019-06-03', y: 250 },
            { x: '2019-06-04', y: 295 },
            { x: '2019-06-05', y: 266 },
            { x: '2019-06-06', y: 293 },
            { x: '2019-06-07', y: 259 },
            { x: '2019-06-08', y: 179 },
            { x: '2019-06-10', y: 273 },
            { x: '2019-06-11', y: 240 },
            { x: '2019-06-12', y: 241 },
            { x: '2019-06-13', y: 264 },
            { x: '2019-06-15', y: 170 },
            { x: '2019-06-17', y: 248 },
            { x: '2019-06-18', y: 232 },
            { x: '2019-06-19', y: 247 },
            { x: '2019-06-20', y: 273 },
            { x: '2019-06-21', y: 230 },
            { x: '2019-06-22', y: 171 },
            { x: '2019-06-24', y: 261 },
            { x: '2019-06-25', y: 234 },
            { x: '2019-06-26', y: 249 },
            { x: '2019-06-27', y: 237 },
            { x: '2019-06-28', y: 222 },
            { x: '2019-06-29', y: 152 },
            { x: '2019-07-01', y: 198 },
            { x: '2019-07-02', y: 220 },
            { x: '2019-07-03', y: 274 },
            { x: '2019-07-04', y: 129 },
            { x: '2019-07-05', y: 179 },
            { x: '2019-07-06', y: 164 },
            { x: '2019-07-08', y: 233 },
            { x: '2019-07-09', y: 230 },
            { x: '2019-07-10', y: 219 },
            { x: '2019-07-11', y: 201 },
            { x: '2019-07-12', y: 222 },
            { x: '2019-07-13', y: 157 },
            { x: '2019-07-15', y: 245 },
            { x: '2019-07-16', y: 211 },
            { x: '2019-07-17', y: 231 },
            { x: '2019-07-18', y: 238 },
            { x: '2019-07-19', y: 264 },
            { x: '2019-07-20', y: 133 },
            { x: '2019-07-22', y: 202 },
            { x: '2019-07-23', y: 237 },
            { x: '2019-07-24', y: 145 },
            { x: '2019-07-25', y: 218 },
            { x: '2019-07-26', y: 201 },
            { x: '2019-07-27', y: 162 },
            { x: '2019-07-29', y: 270 },
            { x: '2019-07-30', y: 219 },
            { x: '2019-07-31', y: 213 },
            { x: '2019-08-01', y: 221 },
            { x: '2019-08-02', y: 257 },
            { x: '2019-08-03', y: 154 },
            { x: '2019-08-05', y: 213 },
            { x: '2019-08-06', y: 220 },
            { x: '2019-08-07', y: 217 },
            { x: '2019-08-08', y: 222 },
            { x: '2019-08-09', y: 214 },
            { x: '2019-08-10', y: 206 },
            { x: '2019-08-12', y: 262 },
            { x: '2019-08-13', y: 216 },
            { x: '2019-08-14', y: 249 },
            { x: '2019-08-15', y: 225 },
            { x: '2019-08-16', y: 273 },
            { x: '2019-08-17', y: 171 },
            { x: '2019-08-19', y: 209 },
            { x: '2019-08-20', y: 229 },
            { x: '2019-08-21', y: 248 },
            { x: '2019-08-22', y: 276 },
            { x: '2019-08-23', y: 272 },
            { x: '2019-08-24', y: 182 },
            { x: '2019-08-26', y: 243 },
            { x: '2019-08-27', y: 256 },
            { x: '2019-08-28', y: 242 },
            { x: '2019-08-29', y: 239 },
            { x: '2019-08-30', y: 255 },
            { x: '2019-08-31', y: 184 },
            { x: '2019-09-02', y: 160 },
            { x: '2019-09-03', y: 205 },
            { x: '2019-09-04', y: 217 },
            { x: '2019-09-05', y: 239 },
            { x: '2019-09-06', y: 278 },
            { x: '2019-09-07', y: 212 },
            { x: '2019-09-09', y: 225 },
            { x: '2019-09-10', y: 238 },
            { x: '2019-09-11', y: 253 },
            { x: '2019-09-12', y: 214 },
            { x: '2019-09-13', y: 263 },
            { x: '2019-09-14', y: 196 },
            { x: '2019-09-16', y: 206 },
            { x: '2019-09-17', y: 269 },
            { x: '2019-09-18', y: 250 },
            { x: '2019-09-19', y: 262 },
            { x: '2019-09-20', y: 238 },
            { x: '2019-09-21', y: 208 },
            { x: '2019-09-23', y: 241 },
            { x: '2019-09-24', y: 240 },
            { x: '2019-09-25', y: 187 },
            { x: '2019-09-26', y: 237 },
            { x: '2019-09-27', y: 245 },
            { x: '2019-09-28', y: 183 },
            { x: '2019-09-30', y: 227 },
            { x: '2019-10-01', y: 243 },
            { x: '2019-10-02', y: 229 },
            { x: '2019-10-03', y: 279 },
            { x: '2019-10-04', y: 277 },
            { x: '2019-10-05', y: 224 },
            { x: '2019-10-07', y: 248 },
            { x: '2019-10-08', y: 209 },
            { x: '2019-10-09', y: 273 },
            { x: '2019-10-10', y: 246 },
            { x: '2019-10-11', y: 224 },
            { x: '2019-10-12', y: 201 },
            { x: '2019-10-14', y: 241 },
            { x: '2019-10-15', y: 247 },
            { x: '2019-10-16', y: 251 },
            { x: '2019-10-17', y: 252 },
            { x: '2019-10-18', y: 284 },
            { x: '2019-10-19', y: 193 },
            { x: '2019-10-21', y: 228 },
            { x: '2019-10-22', y: 199 },
            { x: '2019-10-23', y: 236 },
            { x: '2019-10-24', y: 240 },
            { x: '2019-10-25', y: 210 },
            { x: '2019-10-26', y: 211 },
            { x: '2019-10-28', y: 190 },
            { x: '2019-10-29', y: 203 },
            { x: '2019-10-30', y: 221 },
            { x: '2019-10-31', y: 177 },
            { x: '2019-11-01', y: 249 },
            { x: '2019-11-02', y: 227 },
            { x: '2019-11-04', y: 206 },
            { x: '2019-11-05', y: 198 },
            { x: '2019-11-06', y: 216 },
            { x: '2019-11-07', y: 249 },
            { x: '2019-11-08', y: 274 },
            { x: '2019-11-09', y: 275 },
            { x: '2019-11-11', y: 226 },
            { x: '2019-11-12', y: 199 },
            { x: '2019-11-13', y: 237 },
            { x: '2019-11-14', y: 214 },
            { x: '2019-11-15', y: 224 },
            { x: '2019-11-16', y: 174 },
            { x: '2019-11-18', y: 229 },
            { x: '2019-11-19', y: 210 },
            { x: '2019-11-20', y: 185 },
            { x: '2019-11-21', y: 200 },
            { x: '2019-11-22', y: 241 },
            { x: '2019-11-23', y: 181 },
            { x: '2019-11-25', y: 202 },
            { x: '2019-11-26', y: 262 },
            { x: '2019-11-27', y: 192 },
            { x: '2019-11-29', y: 149 },
            { x: '2019-11-30', y: 128 },
            { x: '2019-12-02', y: 205 },
            { x: '2019-12-03', y: 195 },
            { x: '2019-12-04', y: 223 },
            { x: '2019-12-05', y: 232 },
            { x: '2019-12-06', y: 246 },
            { x: '2019-12-07', y: 163 },
            { x: '2019-12-09', y: 215 },
            { x: '2019-12-10', y: 188 },
            { x: '2019-12-11', y: 245 },
            { x: '2019-12-12', y: 227 },
            { x: '2019-12-13', y: 208 },
            { x: '2019-12-14', y: 141 },
            { x: '2019-12-16', y: 173 },
            { x: '2019-12-17', y: 194 },
            { x: '2019-12-18', y: 196 },
            { x: '2019-12-19', y: 200 },
            { x: '2019-12-20', y: 201 },
            { x: '2019-12-21', y: 169 },
            { x: '2019-12-23', y: 229 },
            { x: '2019-12-24', y: 84 },
            { x: '2019-12-26', y: 136 },
            { x: '2019-12-27', y: 194 },
            { x: '2019-12-28', y: 148 },
            { x: '2019-12-30', y: 204 },
            { x: '2020-01-01', y: 111 },
            { x: '2020-01-02', y: 224 },
            { x: '2020-01-03', y: 223 },
            { x: '2020-01-04', y: 161 },
            { x: '2020-01-06', y: 210 },
            { x: '2020-01-07', y: 214 },
            { x: '2020-01-08', y: 225 },
            { x: '2020-01-09', y: 221 },
            { x: '2020-01-10', y: 220 },
            { x: '2020-01-11', y: 159 },
            { x: '2020-01-13', y: 223 },
            { x: '2020-01-14', y: 211 },
            { x: '2020-01-15', y: 250 },
            { x: '2020-01-16', y: 234 },
            { x: '2020-01-17', y: 234 },
            { x: '2020-01-18', y: 215 },
            { x: '2020-01-20', y: 209 },
            { x: '2020-01-21', y: 207 },
            { x: '2020-01-22', y: 215 },
            { x: '2020-01-23', y: 236 },
            { x: '2020-01-24', y: 247 },
            { x: '2020-01-25', y: 204 },
            { x: '2020-01-27', y: 240 },
            { x: '2020-01-28', y: 219 },
            { x: '2020-01-29', y: 200 },
            { x: '2020-01-30', y: 209 },
            { x: '2020-01-31', y: 262 },
            { x: '2020-02-01', y: 193 },
            { x: '2020-02-03', y: 172 },
            { x: '2020-02-04', y: 221 },
            { x: '2020-02-05', y: 221 },
            { x: '2020-02-06', y: 234 },
            { x: '2020-02-07', y: 229 },
            { x: '2020-02-08', y: 188 },
            { x: '2020-02-10', y: 253 },
            { x: '2020-02-11', y: 220 },
            { x: '2020-02-12', y: 224 },
            { x: '2020-02-13', y: 275 },
            { x: '2020-02-14', y: 311 },
            { x: '2020-02-15', y: 359 },
            { x: '2020-02-17', y: 300 },
            { x: '2020-02-18', y: 232 },
            { x: '2020-02-19', y: 260 },
            { x: '2020-02-20', y: 287 },
            { x: '2020-02-21', y: 301 },
            { x: '2020-02-22', y: 199 },
            { x: '2020-02-24', y: 227 },
            { x: '2020-02-25', y: 232 },
            { x: '2020-02-26', y: 224 },
            { x: '2020-02-27', y: 187 },
            { x: '2020-02-28', y: 185 },
            { x: '2020-02-29', y: 175 },
            { x: '2020-03-02', y: 169 },
            { x: '2020-03-03', y: 190 },
            { x: '2020-03-04', y: 205 },
            { x: '2020-03-05', y: 241 },
            { x: '2020-03-06', y: 347 },
            { x: '2020-03-07', y: 213 },
            { x: '2020-03-09', y: 204 },
            { x: '2020-03-10', y: 193 },
            { x: '2020-03-11', y: 227 },
            { x: '2020-03-12', y: 233 },
            { x: '2020-03-13', y: 179 },
            { x: '2020-03-14', y: 195 },
            { x: '2020-03-16', y: 180 },
            { x: '2020-03-17', y: 163 },
            { x: '2020-03-18', y: 162 },
            { x: '2020-03-19', y: 159 },
            { x: '2020-03-20', y: 137 },
            { x: '2020-03-21', y: 113 },
            { x: '2020-03-23', y: 155 },
            { x: '2020-03-24', y: 207 },
            { x: '2020-03-25', y: 183 },
            { x: '2020-03-26', y: 205 },
            { x: '2020-03-27', y: 142 },
            { x: '2020-03-28', y: 139 },
            { x: '2020-03-30', y: 179 },
            { x: '2020-03-31', y: 154 },
            { x: '2020-04-01', y: 176 },
            { x: '2020-04-02', y: 216 },
            { x: '2020-04-03', y: 179 },
            { x: '2020-04-04', y: 136 },
            { x: '2020-04-06', y: 217 },
            { x: '2020-04-07', y: 184 },
            { x: '2020-04-08', y: 168 },
            { x: '2020-04-10', y: 167 },
            { x: '2020-04-11', y: 164 },
            { x: '2020-04-13', y: 237 },
            { x: '2020-04-14', y: 178 },
            { x: '2020-04-15', y: 214 },
            { x: '2020-04-16', y: 206 },
            { x: '2020-04-17', y: 265 },
            { x: '2020-04-18', y: 208 },
            { x: '2020-04-20', y: 294 },
            { x: '2020-04-21', y: 177 },
            { x: '2020-04-22', y: 272 },
            { x: '2020-04-23', y: 220 },
            { x: '2020-04-24', y: 315 },
            { x: '2020-04-25', y: 270 },
            { x: '2020-04-27', y: 321 },
            { x: '2020-04-28', y: 251 },
            { x: '2020-04-29', y: 245 },
            { x: '2020-04-30', y: 277 },
            { x: '2020-05-01', y: 281 },
            { x: '2020-05-02', y: 268 },
            { x: '2020-05-04', y: 285 },
            { x: '2020-05-05', y: 221 },
            { x: '2020-05-06', y: 282 },
            { x: '2020-05-07', y: 237 },
            { x: '2020-05-08', y: 282 },
            { x: '2020-05-09', y: 245 },
            { x: '2020-05-11', y: 248 },
            { x: '2020-05-12', y: 254 },
            { x: '2020-05-13', y: 258 },
            { x: '2020-05-14', y: 289 },
            { x: '2020-05-15', y: 278 },
            { x: '2020-05-16', y: 298 },
            { x: '2020-05-18', y: 262 },
            { x: '2020-05-19', y: 231 },
            { x: '2020-05-20', y: 281 },
            { x: '2020-05-21', y: 272 },
            { x: '2020-05-22', y: 307 },
            { x: '2020-05-23', y: 282 },
            { x: '2020-05-25', y: 224 },
            { x: '2020-05-26', y: 246 },
            { x: '2020-05-27', y: 271 },
            { x: '2020-05-28', y: 279 },
            { x: '2020-05-29', y: 308 },
            { x: '2020-05-30', y: 249 },
            { x: '2020-06-01', y: 271 },
            { x: '2020-06-02', y: 258 },
            { x: '2020-06-03', y: 262 },
            { x: '2020-06-04', y: 272 },
            { x: '2020-06-05', y: 306 },
            { x: '2020-06-06', y: 209 },
            { x: '2020-06-08', y: 295 },
            { x: '2020-06-09', y: 260 },
            { x: '2020-06-10', y: 265 },
            { x: '2020-06-11', y: 296 },
            { x: '2020-06-12', y: 283 },
            { x: '2020-06-13', y: 222 },
            { x: '2020-06-15', y: 310 },
            { x: '2020-06-16', y: 255 },
            { x: '2020-06-17', y: 262 },
            { x: '2020-06-18', y: 278 },
            { x: '2020-06-19', y: 301 },
            { x: '2020-06-20', y: 225 },
            { x: '2020-06-22', y: 246 },
            { x: '2020-06-23', y: 271 },
            { x: '2020-06-24', y: 273 },
            { x: '2020-06-25', y: 261 },
            { x: '2020-06-26', y: 333 },
            { x: '2020-06-27', y: 201 },
            { x: '2020-06-29', y: 309 },
            { x: '2020-06-30', y: 258 },
            { x: '2020-07-01', y: 291 },
            { x: '2020-07-02', y: 269 },
            { x: '2020-07-03', y: 234 },
            { x: '2020-07-04', y: 176 },
            { x: '2020-07-06', y: 262 },
            { x: '2020-07-07', y: 220 },
            { x: '2020-07-08', y: 261 },
            { x: '2020-07-09', y: 216 },
            { x: '2020-07-10', y: 191 },
            { x: '2020-07-11', y: 233 },
            { x: '2020-07-13', y: 295 },
            { x: '2020-07-14', y: 281 },
            { x: '2020-07-15', y: 261 },
            { x: '2020-07-16', y: 276 },
            { x: '2020-07-17', y: 266 },
            { x: '2020-07-20', y: 218 },
            { x: '2020-07-21', y: 226 },
            { x: '2020-07-22', y: 234 },
            { x: '2020-07-23', y: 253 },
            { x: '2020-07-24', y: 216 },
            { x: '2020-07-25', y: 213 },
            { x: '2020-07-27', y: 243 },
            { x: '2020-07-28', y: 259 },
            { x: '2020-07-29', y: 249 },
            { x: '2020-07-30', y: 280 },
            { x: '2020-07-31', y: 282 },
            { x: '2020-08-01', y: 192 },
            { x: '2020-08-03', y: 264 },
            { x: '2020-08-04', y: 230 },
            { x: '2020-08-05', y: 274 },
            { x: '2020-08-06', y: 258 },
            { x: '2020-08-07', y: 252 },
            { x: '2020-08-08', y: 213 },
            { x: '2020-08-10', y: 243 },
            { x: '2020-08-11', y: 243 },
            { x: '2020-08-12', y: 279 },
            { x: '2020-08-13', y: 255 },
            { x: '2020-08-14', y: 266 },
            { x: '2020-08-15', y: 103 },
            { x: '2020-08-17', y: 240 },
            { x: '2020-08-18', y: 233 },
            { x: '2020-08-19', y: 250 },
            { x: '2020-08-20', y: 243 },
            { x: '2020-08-21', y: 261 },
            { x: '2020-08-22', y: 188 },
            { x: '2020-08-24', y: 304 },
            { x: '2020-08-25', y: 259 },
            { x: '2020-08-26', y: 276 },
            { x: '2020-08-27', y: 224 },
            { x: '2020-08-28', y: 299 },
            { x: '2020-08-29', y: 252 },
            { x: '2020-08-31', y: 273 },
            { x: '2020-09-01', y: 246 },
            { x: '2020-09-02', y: 245 },
            { x: '2020-09-03', y: 263 },
            { x: '2020-09-04', y: 310 },
            { x: '2020-09-05', y: 213 },
            { x: '2020-09-07', y: 161 },
            { x: '2020-09-08', y: 255 },
            { x: '2020-09-09', y: 234 },
            { x: '2020-09-10', y: 281 },
            { x: '2020-09-11', y: 246 },
            { x: '2020-09-12', y: 247 },
            { x: '2020-09-14', y: 265 },
            { x: '2020-09-15', y: 249 },
            { x: '2020-09-16', y: 247 },
            { x: '2020-09-17', y: 284 },
            { x: '2020-09-18', y: 256 },
            { x: '2020-09-19', y: 253 },
            { x: '2020-09-21', y: 285 },
            { x: '2020-09-22', y: 235 },
            { x: '2020-09-23', y: 236 },
            { x: '2020-09-24', y: 270 },
            { x: '2020-09-25', y: 272 },
            { x: '2020-09-26', y: 199 },
            { x: '2020-09-28', y: 222 },
            { x: '2020-09-29', y: 262 },
            { x: '2020-09-30', y: 231 },
            { x: '2020-10-01', y: 254 },
            { x: '2020-10-02', y: 243 },
            { x: '2020-10-03', y: 169 },
            { x: '2020-10-05', y: 242 },
            { x: '2020-10-06', y: 218 },
            { x: '2020-10-07', y: 252 },
            { x: '2020-10-08', y: 273 },
            { x: '2020-10-09', y: 253 },
            { x: '2020-10-10', y: 240 },
            { x: '2020-10-12', y: 229 },
            { x: '2020-10-13', y: 230 },
            { x: '2020-10-14', y: 234 },
            { x: '2020-10-15', y: 247 },
            { x: '2020-10-16', y: 239 },
            { x: '2020-10-17', y: 207 },
            { x: '2020-10-19', y: 244 },
            { x: '2020-10-20', y: 234 },
            { x: '2020-10-21', y: 249 },
            { x: '2020-10-22', y: 270 },
            { x: '2020-10-23', y: 278 },
            { x: '2020-10-24', y: 252 },
            { x: '2020-10-27', y: 205 },
            { x: '2020-10-28', y: 220 },
            { x: '2020-10-29', y: 267 },
            { x: '2020-10-30', y: 289 },
            { x: '2020-10-31', y: 163 },
            { x: '2020-11-02', y: 206 },
            { x: '2020-11-03', y: 259 },
            { x: '2020-11-04', y: 228 },
            { x: '2020-11-05', y: 215 },
            { x: '2020-11-06', y: 226 },
            { x: '2020-11-07', y: 173 },
            { x: '2020-11-09', y: 206 },
            { x: '2020-11-10', y: 226 },
            { x: '2020-11-11', y: 176 },
            { x: '2020-11-12', y: 183 },
            { x: '2020-11-13', y: 224 },
            { x: '2020-11-14', y: 159 },
            { x: '2020-11-16', y: 208 },
            { x: '2020-11-17', y: 217 },
            { x: '2020-11-18', y: 240 },
            { x: '2020-11-19', y: 228 },
            { x: '2020-11-20', y: 195 },
            { x: '2020-11-21', y: 156 },
            { x: '2020-11-23', y: 221 },
            { x: '2020-11-24', y: 208 },
            { x: '2020-11-25', y: 231 },
            { x: '2020-11-27', y: 132 },
            { x: '2020-11-28', y: 170 },
            { x: '2020-11-30', y: 206 },
            { x: '2020-12-01', y: 212 },
            { x: '2020-12-02', y: 184 },
            { x: '2020-12-03', y: 214 },
            { x: '2020-12-04', y: 217 },
            { x: '2020-12-05', y: 145 },
            { x: '2020-12-07', y: 195 },
            { x: '2020-12-08', y: 211 },
            { x: '2020-12-09', y: 199 },
            { x: '2020-12-10', y: 223 },
            { x: '2020-12-11', y: 198 },
            { x: '2020-12-12', y: 123 },
            { x: '2020-12-14', y: 200 },
            { x: '2020-12-15', y: 228 },
            { x: '2020-12-16', y: 212 },
            { x: '2020-12-17', y: 207 },
            { x: '2020-12-18', y: 218 },
            { x: '2020-12-19', y: 177 },
            { x: '2020-12-21', y: 201 },
            { x: '2020-12-22', y: 195 },
            { x: '2020-12-23', y: 217 },
            { x: '2020-12-24', y: 77 },
            { x: '2020-12-26', y: 100 },
            { x: '2020-12-28', y: 191 },
            { x: '2020-12-29', y: 212 },
            { x: '2020-12-30', y: 231 },
            { x: '2020-12-31', y: 200 },
            { x: '2021-01-01', y: 129 },
            { x: '2021-01-02', y: 181 },
            { x: '2021-01-04', y: 218 },
            { x: '2021-01-05', y: 258 },
            { x: '2021-01-06', y: 266 },
            { x: '2021-01-07', y: 210 },
            { x: '2021-01-08', y: 230 },
            { x: '2021-01-09', y: 185 },
            { x: '2021-01-11', y: 232 },
            { x: '2021-01-12', y: 190 },
            { x: '2021-01-13', y: 255 },
            { x: '2021-01-14', y: 223 },
            { x: '2021-01-15', y: 221 },
            { x: '2021-01-16', y: 226 },
            { x: '2021-01-18', y: 268 },
            { x: '2021-01-19', y: 201 },
            { x: '2021-01-20', y: 258 },
            { x: '2021-01-21', y: 250 },
            { x: '2021-01-22', y: 257 },
            { x: '2021-01-23', y: 165 },
            { x: '2021-01-25', y: 188 },
            { x: '2021-01-26', y: 218 },
            { x: '2021-01-27', y: 208 },
            { x: '2021-01-28', y: 244 },
            { x: '2021-01-29', y: 207 },
            { x: '2021-01-30', y: 212 },
            { x: '2021-02-01', y: 136 },
            { x: '2021-02-02', y: 198 },
            { x: '2021-02-04', y: 216 },
            { x: '2021-02-05', y: 233 },
            { x: '2021-02-06', y: 193 },
            { x: '2021-02-08', y: 171 },
            { x: '2021-02-09', y: 161 },
            { x: '2021-02-10', y: 197 },
            { x: '2021-02-11', y: 176 },
            { x: '2021-02-12', y: 302 },
            { x: '2021-02-13', y: 244 },
            { x: '2021-02-15', y: 221 },
            { x: '2021-02-16', y: 148 },
            { x: '2021-02-17', y: 223 },
            { x: '2021-02-18', y: 197 },
            { x: '2021-02-19', y: 214 },
            { x: '2021-02-20', y: 201 },
            { x: '2021-02-22', y: 148 },
            { x: '2021-02-23', y: 195 },
            { x: '2021-02-24', y: 190 },
            { x: '2021-02-25', y: 213 },
            { x: '2021-02-26', y: 204 },
            { x: '2021-02-27', y: 170 },
            { x: '2021-03-01', y: 185 },
            { x: '2021-03-02', y: 204 },
            { x: '2021-03-03', y: 163 },
            { x: '2021-03-04', y: 185 },
            { x: '2021-03-05', y: 196 },
            { x: '2021-03-06', y: 170 },
            { x: '2021-03-08', y: 194 },
            { x: '2021-03-09', y: 162 },
            { x: '2021-03-10', y: 182 },
            { x: '2021-03-11', y: 197 },
            { x: '2021-03-12', y: 206 },
            { x: '2021-03-13', y: 110 },
            { x: '2021-03-15', y: 205 },
            { x: '2021-03-16', y: 171 },
            { x: '2021-03-17', y: 189 },
            { x: '2021-03-18', y: 226 },
            { x: '2021-03-19', y: 383 },
            { x: '2021-03-20', y: 329 },
            { x: '2021-03-22', y: 220 },
            { x: '2021-03-23', y: 202 },
            { x: '2021-03-24', y: 236 },
            { x: '2021-03-25', y: 230 },
            { x: '2021-03-26', y: 297 },
            { x: '2021-03-27', y: 286 },
            { x: '2021-03-29', y: 257 },
            { x: '2021-03-30', y: 227 },
            { x: '2021-03-31', y: 232 },
            { x: '2021-04-01', y: 288 },
            { x: '2021-04-02', y: 307 },
            { x: '2021-04-03', y: 258 },
            { x: '2021-04-05', y: 224 },
            { x: '2021-04-06', y: 233 },
            { x: '2021-04-07', y: 244 },
            { x: '2021-04-08', y: 260 },
            { x: '2021-04-09', y: 257 },
            { x: '2021-04-10', y: 269 },
            { x: '2021-04-12', y: 190 },
            { x: '2021-04-13', y: 222 },
            { x: '2021-04-14', y: 219 },
            { x: '2021-04-15', y: 229 },
            { x: '2021-04-16', y: 246 },
            { x: '2021-04-17', y: 219 },
            { x: '2021-04-19', y: 215 },
            { x: '2021-04-20', y: 242 },
            { x: '2021-04-21', y: 274 },
            { x: '2021-04-22', y: 205 },
            { x: '2021-04-23', y: 228 },
            { x: '2021-04-24', y: 156 },
            { x: '2021-04-26', y: 165 },
            { x: '2021-04-27', y: 174 },
            { x: '2021-04-28', y: 196 },
            { x: '2021-04-29', y: 254 },
            { x: '2021-04-30', y: 277 },
            { x: '2021-05-01', y: 189 },
            { x: '2021-05-03', y: 208 },
            { x: '2021-05-04', y: 194 },
            { x: '2021-05-05', y: 186 },
            { x: '2021-05-06', y: 200 },
            { x: '2021-05-07', y: 210 },
            { x: '2021-05-08', y: 206 },
            { x: '2021-05-10', y: 174 },
            { x: '2021-05-12', y: 183 },
            { x: '2021-05-13', y: 213 },
            { x: '2021-05-14', y: 265 },
            { x: '2021-05-15', y: 194 },
            { x: '2021-05-17', y: 164 },
            { x: '2021-05-18', y: 201 },
            { x: '2021-05-19', y: 213 },
            { x: '2021-05-20', y: 206 },
            { x: '2021-05-21', y: 232 },
            { x: '2021-05-22', y: 151 },
            { x: '2021-05-24', y: 199 },
            { x: '2021-05-25', y: 186 },
            { x: '2021-05-26', y: 200 },
            { x: '2021-05-27', y: 197 },
            { x: '2021-05-28', y: 217 },
            { x: '2021-05-29', y: 192 },
            { x: '2021-05-31', y: 179 },
            { x: '2021-06-01', y: 213 },
            { x: '2021-06-02', y: 201 },
            { x: '2021-06-03', y: 214 },
            { x: '2021-06-04', y: 227 },
            { x: '2021-06-05', y: 174 },
            { x: '2021-06-07', y: 205 },
            { x: '2021-06-08', y: 201 },
            { x: '2021-06-09', y: 198 },
            { x: '2021-06-10', y: 203 },
            { x: '2021-06-11', y: 200 },
            { x: '2021-06-12', y: 131 },
            { x: '2021-06-14', y: 188 },
            { x: '2021-06-15', y: 222 },
            { x: '2021-06-16', y: 202 },
            { x: '2021-06-17', y: 214 },
            { x: '2021-06-18', y: 226 },
            { x: '2021-06-19', y: 122 },
            { x: '2021-06-21', y: 244 },
            { x: '2021-06-22', y: 197 },
            { x: '2021-06-23', y: 192 },
            { x: '2021-06-24', y: 191 },
            { x: '2021-06-25', y: 228 },
            { x: '2021-06-26', y: 140 },
            { x: '2021-06-28', y: 188 },
            { x: '2021-06-29', y: 194 },
            { x: '2021-06-30', y: 206 },
            { x: '2021-07-01', y: 231 },
            { x: '2021-07-02', y: 186 },
            { x: '2021-07-03', y: 107 },
            { x: '2021-07-05', y: 146 },
            { x: '2021-07-06', y: 210 },
            { x: '2021-07-07', y: 187 },
            { x: '2021-07-08', y: 220 },
            { x: '2021-07-09', y: 178 },
            { x: '2021-07-10', y: 175 },
            { x: '2021-07-12', y: 172 },
            { x: '2021-07-13', y: 174 },
            { x: '2021-07-14', y: 173 },
            { x: '2021-07-15', y: 199 },
            { x: '2021-07-16', y: 183 },
            { x: '2021-07-17', y: 127 },
            { x: '2021-07-19', y: 203 },
            { x: '2021-07-20', y: 159 },
            { x: '2021-07-21', y: 172 },
            { x: '2021-07-22', y: 178 },
            { x: '2021-07-24', y: 97 },
            { x: '2021-07-26', y: 206 },
            { x: '2021-07-27', y: 151 },
            { x: '2021-07-28', y: 193 },
            { x: '2021-07-29', y: 165 },
            { x: '2021-07-30', y: 179 },
            { x: '2021-07-31', y: 127 },
            { x: '2021-08-02', y: 171 },
            { x: '2021-08-03', y: 177 },
            { x: '2021-08-04', y: 200 },
            { x: '2021-08-05', y: 197 },
            { x: '2021-08-06', y: 202 },
            { x: '2021-08-07', y: 120 },
            { x: '2021-08-09', y: 163 },
            { x: '2021-08-10', y: 163 },
            { x: '2021-08-11', y: 218 },
            { x: '2021-08-12', y: 171 },
            { x: '2021-08-13', y: 144 },
            { x: '2021-08-14', y: 126 },
            { x: '2021-08-16', y: 205 },
            { x: '2021-08-17', y: 184 },
            { x: '2021-08-18', y: 165 },
            { x: '2021-08-19', y: 183 },
            { x: '2021-08-20', y: 190 },
            { x: '2021-08-21', y: 146 },
            { x: '2021-08-23', y: 181 },
            { x: '2021-08-24', y: 178 },
            { x: '2021-08-25', y: 166 },
            { x: '2021-08-26', y: 181 },
            { x: '2021-08-27', y: 232 },
            { x: '2021-08-28', y: 187 },
            { x: '2021-08-30', y: 179 },
            { x: '2021-08-31', y: 174 },
            { x: '2021-09-01', y: 158 },
            { x: '2021-09-02', y: 206 },
            { x: '2021-09-03', y: 178 },
            { x: '2021-09-04', y: 165 },
            { x: '2021-09-06', y: 155 },
            { x: '2021-09-07', y: 199 },
            { x: '2021-09-08', y: 198 },
            { x: '2021-09-09', y: 190 },
            { x: '2021-09-10', y: 212 },
            { x: '2021-09-11', y: 163 },
            { x: '2021-09-13', y: 166 },
            { x: '2021-09-14', y: 199 },
            { x: '2021-09-15', y: 154 },
            { x: '2021-09-16', y: 222 },
            { x: '2021-09-17', y: 246 },
            { x: '2021-09-18', y: 145 },
            { x: '2021-09-20', y: 197 },
            { x: '2021-09-21', y: 190 },
            { x: '2021-09-22', y: 183 },
            { x: '2021-09-23', y: 80 },
        ],
    },
    {
        id: 'mayo',
        color: '#d64e12',
        data: [
            { x: '2019-01-01', y: 152 },
            { x: '2019-01-02', y: 256 },
            { x: '2019-01-03', y: 314 },
            { x: '2019-01-04', y: 304 },
            { x: '2019-01-05', y: 182 },
            { x: '2019-01-07', y: 237 },
            { x: '2019-01-08', y: 290 },
            { x: '2019-01-09', y: 338 },
            { x: '2019-01-10', y: 308 },
            { x: '2019-01-11', y: 307 },
            { x: '2019-01-12', y: 246 },
            { x: '2019-01-14', y: 291 },
            { x: '2019-01-15', y: 264 },
            { x: '2019-01-16', y: 280 },
            { x: '2019-01-17', y: 272 },
            { x: '2019-01-18', y: 317 },
            { x: '2019-01-19', y: 227 },
            { x: '2019-01-21', y: 278 },
            { x: '2019-01-22', y: 299 },
            { x: '2019-01-23', y: 271 },
            { x: '2019-01-24', y: 337 },
            { x: '2019-01-25', y: 387 },
            { x: '2019-01-26', y: 316 },
            { x: '2019-01-28', y: 287 },
            { x: '2019-01-29', y: 276 },
            { x: '2019-01-30', y: 328 },
            { x: '2019-01-31', y: 338 },
            { x: '2019-02-01', y: 388 },
            { x: '2019-02-02', y: 203 },
            { x: '2019-02-04', y: 294 },
            { x: '2019-02-05', y: 261 },
            { x: '2019-02-06', y: 270 },
            { x: '2019-02-07', y: 322 },
            { x: '2019-02-08', y: 360 },
            { x: '2019-02-09', y: 233 },
            { x: '2019-02-11', y: 288 },
            { x: '2019-02-12', y: 303 },
            { x: '2019-02-13', y: 309 },
            { x: '2019-02-14', y: 306 },
            { x: '2019-02-15', y: 381 },
            { x: '2019-02-16', y: 450 },
            { x: '2019-02-18', y: 349 },
            { x: '2019-02-19', y: 285 },
            { x: '2019-02-20', y: 274 },
            { x: '2019-02-21', y: 257 },
            { x: '2019-02-22', y: 323 },
            { x: '2019-02-23', y: 264 },
            { x: '2019-02-25', y: 295 },
            { x: '2019-02-26', y: 316 },
            { x: '2019-02-27', y: 391 },
            { x: '2019-02-28', y: 371 },
            { x: '2019-03-01', y: 394 },
            { x: '2019-03-02', y: 215 },
            { x: '2019-03-04', y: 344 },
            { x: '2019-03-05', y: 325 },
            { x: '2019-03-07', y: 398 },
            { x: '2019-03-08', y: 479 },
            { x: '2019-03-09', y: 353 },
            { x: '2019-03-11', y: 354 },
            { x: '2019-03-12', y: 242 },
            { x: '2019-03-13', y: 280 },
            { x: '2019-03-14', y: 351 },
            { x: '2019-03-15', y: 392 },
            { x: '2019-03-16', y: 335 },
            { x: '2019-03-18', y: 387 },
            { x: '2019-03-19', y: 362 },
            { x: '2019-03-20', y: 351 },
            { x: '2019-03-21', y: 308 },
            { x: '2019-03-22', y: 432 },
            { x: '2019-03-23', y: 478 },
            { x: '2019-03-25', y: 395 },
            { x: '2019-03-26', y: 391 },
            { x: '2019-03-27', y: 341 },
            { x: '2019-03-28', y: 359 },
            { x: '2019-03-29', y: 450 },
            { x: '2019-03-30', y: 369 },
            { x: '2019-04-01', y: 388 },
            { x: '2019-04-02', y: 382 },
            { x: '2019-04-03', y: 425 },
            { x: '2019-04-04', y: 434 },
            { x: '2019-04-05', y: 470 },
            { x: '2019-04-06', y: 322 },
            { x: '2019-04-08', y: 350 },
            { x: '2019-04-09', y: 355 },
            { x: '2019-04-10', y: 384 },
            { x: '2019-04-11', y: 348 },
            { x: '2019-04-12', y: 383 },
            { x: '2019-04-13', y: 361 },
            { x: '2019-04-15', y: 387 },
            { x: '2019-04-16', y: 259 },
            { x: '2019-04-17', y: 387 },
            { x: '2019-04-18', y: 478 },
            { x: '2019-04-19', y: 353 },
            { x: '2019-04-20', y: 291 },
            { x: '2019-04-22', y: 369 },
            { x: '2019-04-23', y: 362 },
            { x: '2019-04-24', y: 370 },
            { x: '2019-04-25', y: 369 },
            { x: '2019-04-26', y: 382 },
            { x: '2019-04-27', y: 271 },
            { x: '2019-04-29', y: 292 },
            { x: '2019-04-30', y: 296 },
            { x: '2019-05-01', y: 333 },
            { x: '2019-05-02', y: 380 },
            { x: '2019-05-03', y: 488 },
            { x: '2019-05-04', y: 386 },
            { x: '2019-05-06', y: 333 },
            { x: '2019-05-07', y: 289 },
            { x: '2019-05-08', y: 322 },
            { x: '2019-05-09', y: 308 },
            { x: '2019-05-10', y: 327 },
            { x: '2019-05-11', y: 296 },
            { x: '2019-05-13', y: 330 },
            { x: '2019-05-14', y: 350 },
            { x: '2019-05-15', y: 378 },
            { x: '2019-05-16', y: 316 },
            { x: '2019-05-17', y: 352 },
            { x: '2019-05-18', y: 237 },
            { x: '2019-05-20', y: 303 },
            { x: '2019-05-21', y: 357 },
            { x: '2019-05-22', y: 338 },
            { x: '2019-05-23', y: 278 },
            { x: '2019-05-24', y: 346 },
            { x: '2019-05-25', y: 368 },
            { x: '2019-05-27', y: 187 },
            { x: '2019-05-28', y: 305 },
            { x: '2019-05-29', y: 327 },
            { x: '2019-05-30', y: 330 },
            { x: '2019-05-31', y: 314 },
            { x: '2019-06-01', y: 255 },
            { x: '2019-06-03', y: 338 },
            { x: '2019-06-04', y: 387 },
            { x: '2019-06-05', y: 363 },
            { x: '2019-06-06', y: 379 },
            { x: '2019-06-07', y: 329 },
            { x: '2019-06-08', y: 247 },
            { x: '2019-06-10', y: 363 },
            { x: '2019-06-11', y: 312 },
            { x: '2019-06-12', y: 324 },
            { x: '2019-06-13', y: 353 },
            { x: '2019-06-15', y: 242 },
            { x: '2019-06-17', y: 349 },
            { x: '2019-06-18', y: 305 },
            { x: '2019-06-19', y: 325 },
            { x: '2019-06-20', y: 350 },
            { x: '2019-06-21', y: 323 },
            { x: '2019-06-22', y: 252 },
            { x: '2019-06-24', y: 349 },
            { x: '2019-06-25', y: 316 },
            { x: '2019-06-26', y: 335 },
            { x: '2019-06-27', y: 320 },
            { x: '2019-06-28', y: 299 },
            { x: '2019-06-29', y: 193 },
            { x: '2019-07-01', y: 268 },
            { x: '2019-07-02', y: 306 },
            { x: '2019-07-03', y: 352 },
            { x: '2019-07-04', y: 175 },
            { x: '2019-07-05', y: 241 },
            { x: '2019-07-06', y: 227 },
            { x: '2019-07-08', y: 314 },
            { x: '2019-07-09', y: 320 },
            { x: '2019-07-10', y: 315 },
            { x: '2019-07-11', y: 272 },
            { x: '2019-07-12', y: 312 },
            { x: '2019-07-13', y: 211 },
            { x: '2019-07-15', y: 313 },
            { x: '2019-07-16', y: 287 },
            { x: '2019-07-17', y: 314 },
            { x: '2019-07-18', y: 320 },
            { x: '2019-07-19', y: 344 },
            { x: '2019-07-20', y: 194 },
            { x: '2019-07-22', y: 298 },
            { x: '2019-07-23', y: 321 },
            { x: '2019-07-24', y: 210 },
            { x: '2019-07-25', y: 301 },
            { x: '2019-07-26', y: 262 },
            { x: '2019-07-27', y: 213 },
            { x: '2019-07-29', y: 338 },
            { x: '2019-07-30', y: 295 },
            { x: '2019-07-31', y: 283 },
            { x: '2019-08-01', y: 300 },
            { x: '2019-08-02', y: 353 },
            { x: '2019-08-03', y: 215 },
            { x: '2019-08-05', y: 319 },
            { x: '2019-08-06', y: 310 },
            { x: '2019-08-07', y: 312 },
            { x: '2019-08-08', y: 313 },
            { x: '2019-08-09', y: 304 },
            { x: '2019-08-10', y: 256 },
            { x: '2019-08-12', y: 342 },
            { x: '2019-08-13', y: 298 },
            { x: '2019-08-14', y: 346 },
            { x: '2019-08-15', y: 305 },
            { x: '2019-08-16', y: 359 },
            { x: '2019-08-17', y: 230 },
            { x: '2019-08-19', y: 292 },
            { x: '2019-08-20', y: 315 },
            { x: '2019-08-21', y: 327 },
            { x: '2019-08-22', y: 369 },
            { x: '2019-08-23', y: 371 },
            { x: '2019-08-24', y: 248 },
            { x: '2019-08-26', y: 332 },
            { x: '2019-08-27', y: 338 },
            { x: '2019-08-28', y: 325 },
            { x: '2019-08-29', y: 336 },
            { x: '2019-08-30', y: 329 },
            { x: '2019-08-31', y: 260 },
            { x: '2019-09-02', y: 210 },
            { x: '2019-09-03', y: 284 },
            { x: '2019-09-04', y: 297 },
            { x: '2019-09-05', y: 327 },
            { x: '2019-09-06', y: 362 },
            { x: '2019-09-07', y: 298 },
            { x: '2019-09-09', y: 306 },
            { x: '2019-09-10', y: 323 },
            { x: '2019-09-11', y: 330 },
            { x: '2019-09-12', y: 298 },
            { x: '2019-09-13', y: 345 },
            { x: '2019-09-14', y: 258 },
            { x: '2019-09-16', y: 289 },
            { x: '2019-09-17', y: 347 },
            { x: '2019-09-18', y: 335 },
            { x: '2019-09-19', y: 349 },
            { x: '2019-09-20', y: 320 },
            { x: '2019-09-21', y: 276 },
            { x: '2019-09-23', y: 315 },
            { x: '2019-09-24', y: 309 },
            { x: '2019-09-25', y: 296 },
            { x: '2019-09-26', y: 322 },
            { x: '2019-09-27', y: 335 },
            { x: '2019-09-28', y: 240 },
            { x: '2019-09-30', y: 314 },
            { x: '2019-10-01', y: 324 },
            { x: '2019-10-02', y: 317 },
            { x: '2019-10-03', y: 365 },
            { x: '2019-10-04', y: 380 },
            { x: '2019-10-05', y: 293 },
            { x: '2019-10-07', y: 372 },
            { x: '2019-10-08', y: 287 },
            { x: '2019-10-09', y: 357 },
            { x: '2019-10-10', y: 315 },
            { x: '2019-10-11', y: 307 },
            { x: '2019-10-12', y: 260 },
            { x: '2019-10-14', y: 334 },
            { x: '2019-10-15', y: 342 },
            { x: '2019-10-16', y: 348 },
            { x: '2019-10-17', y: 345 },
            { x: '2019-10-18', y: 370 },
            { x: '2019-10-19', y: 252 },
            { x: '2019-10-21', y: 317 },
            { x: '2019-10-22', y: 271 },
            { x: '2019-10-23', y: 336 },
            { x: '2019-10-24', y: 336 },
            { x: '2019-10-25', y: 297 },
            { x: '2019-10-26', y: 279 },
            { x: '2019-10-28', y: 267 },
            { x: '2019-10-29', y: 268 },
            { x: '2019-10-30', y: 307 },
            { x: '2019-10-31', y: 245 },
            { x: '2019-11-01', y: 339 },
            { x: '2019-11-02', y: 300 },
            { x: '2019-11-04', y: 278 },
            { x: '2019-11-05', y: 271 },
            { x: '2019-11-06', y: 284 },
            { x: '2019-11-07', y: 334 },
            { x: '2019-11-08', y: 381 },
            { x: '2019-11-09', y: 371 },
            { x: '2019-11-11', y: 289 },
            { x: '2019-11-12', y: 272 },
            { x: '2019-11-13', y: 319 },
            { x: '2019-11-14', y: 290 },
            { x: '2019-11-15', y: 316 },
            { x: '2019-11-16', y: 238 },
            { x: '2019-11-18', y: 307 },
            { x: '2019-11-19', y: 295 },
            { x: '2019-11-20', y: 257 },
            { x: '2019-11-21', y: 271 },
            { x: '2019-11-22', y: 324 },
            { x: '2019-11-23', y: 253 },
            { x: '2019-11-25', y: 279 },
            { x: '2019-11-26', y: 351 },
            { x: '2019-11-27', y: 254 },
            { x: '2019-11-29', y: 201 },
            { x: '2019-11-30', y: 180 },
            { x: '2019-12-02', y: 281 },
            { x: '2019-12-03', y: 257 },
            { x: '2019-12-04', y: 308 },
            { x: '2019-12-05', y: 307 },
            { x: '2019-12-06', y: 343 },
            { x: '2019-12-07', y: 219 },
            { x: '2019-12-09', y: 285 },
            { x: '2019-12-10', y: 254 },
            { x: '2019-12-11', y: 313 },
            { x: '2019-12-12', y: 296 },
            { x: '2019-12-13', y: 266 },
            { x: '2019-12-14', y: 195 },
            { x: '2019-12-16', y: 249 },
            { x: '2019-12-17', y: 258 },
            { x: '2019-12-18', y: 284 },
            { x: '2019-12-19', y: 286 },
            { x: '2019-12-20', y: 286 },
            { x: '2019-12-21', y: 224 },
            { x: '2019-12-23', y: 294 },
            { x: '2019-12-24', y: 120 },
            { x: '2019-12-26', y: 186 },
            { x: '2019-12-27', y: 273 },
            { x: '2019-12-28', y: 208 },
            { x: '2019-12-30', y: 275 },
            { x: '2020-01-01', y: 157 },
            { x: '2020-01-02', y: 292 },
            { x: '2020-01-03', y: 301 },
            { x: '2020-01-04', y: 217 },
            { x: '2020-01-06', y: 284 },
            { x: '2020-01-07', y: 319 },
            { x: '2020-01-08', y: 316 },
            { x: '2020-01-09', y: 290 },
            { x: '2020-01-10', y: 309 },
            { x: '2020-01-11', y: 216 },
            { x: '2020-01-13', y: 293 },
            { x: '2020-01-14', y: 289 },
            { x: '2020-01-15', y: 324 },
            { x: '2020-01-16', y: 307 },
            { x: '2020-01-17', y: 323 },
            { x: '2020-01-18', y: 282 },
            { x: '2020-01-20', y: 275 },
            { x: '2020-01-21', y: 287 },
            { x: '2020-01-22', y: 286 },
            { x: '2020-01-23', y: 309 },
            { x: '2020-01-24', y: 329 },
            { x: '2020-01-25', y: 282 },
            { x: '2020-01-27', y: 319 },
            { x: '2020-01-28', y: 295 },
            { x: '2020-01-29', y: 296 },
            { x: '2020-01-30', y: 290 },
            { x: '2020-01-31', y: 350 },
            { x: '2020-02-01', y: 268 },
            { x: '2020-02-03', y: 238 },
            { x: '2020-02-04', y: 291 },
            { x: '2020-02-05', y: 294 },
            { x: '2020-02-06', y: 315 },
            { x: '2020-02-07', y: 318 },
            { x: '2020-02-08', y: 259 },
            { x: '2020-02-10', y: 333 },
            { x: '2020-02-11', y: 300 },
            { x: '2020-02-12', y: 298 },
            { x: '2020-02-13', y: 351 },
            { x: '2020-02-14', y: 428 },
            { x: '2020-02-15', y: 458 },
            { x: '2020-02-17', y: 401 },
            { x: '2020-02-18', y: 306 },
            { x: '2020-02-19', y: 332 },
            { x: '2020-02-20', y: 383 },
            { x: '2020-02-21', y: 402 },
            { x: '2020-02-22', y: 265 },
            { x: '2020-02-24', y: 317 },
            { x: '2020-02-25', y: 304 },
            { x: '2020-02-26', y: 307 },
            { x: '2020-02-27', y: 263 },
            { x: '2020-02-28', y: 257 },
            { x: '2020-02-29', y: 243 },
            { x: '2020-03-02', y: 230 },
            { x: '2020-03-03', y: 260 },
            { x: '2020-03-04', y: 270 },
            { x: '2020-03-05', y: 321 },
            { x: '2020-03-06', y: 438 },
            { x: '2020-03-07', y: 287 },
            { x: '2020-03-09', y: 272 },
            { x: '2020-03-10', y: 258 },
            { x: '2020-03-11', y: 305 },
            { x: '2020-03-12', y: 328 },
            { x: '2020-03-13', y: 244 },
            { x: '2020-03-14', y: 271 },
            { x: '2020-03-16', y: 238 },
            { x: '2020-03-17', y: 235 },
            { x: '2020-03-18', y: 226 },
            { x: '2020-03-19', y: 224 },
            { x: '2020-03-20', y: 200 },
            { x: '2020-03-21', y: 160 },
            { x: '2020-03-23', y: 215 },
            { x: '2020-03-24', y: 300 },
            { x: '2020-03-25', y: 258 },
            { x: '2020-03-26', y: 281 },
            { x: '2020-03-27', y: 211 },
            { x: '2020-03-28', y: 207 },
            { x: '2020-03-30', y: 240 },
            { x: '2020-03-31', y: 225 },
            { x: '2020-04-01', y: 238 },
            { x: '2020-04-02', y: 289 },
            { x: '2020-04-03', y: 257 },
            { x: '2020-04-04', y: 199 },
            { x: '2020-04-06', y: 299 },
            { x: '2020-04-07', y: 266 },
            { x: '2020-04-08', y: 243 },
            { x: '2020-04-10', y: 215 },
            { x: '2020-04-11', y: 248 },
            { x: '2020-04-13', y: 313 },
            { x: '2020-04-14', y: 250 },
            { x: '2020-04-15', y: 304 },
            { x: '2020-04-16', y: 298 },
            { x: '2020-04-17', y: 361 },
            { x: '2020-04-18', y: 286 },
            { x: '2020-04-20', y: 390 },
            { x: '2020-04-21', y: 249 },
            { x: '2020-04-22', y: 365 },
            { x: '2020-04-23', y: 308 },
            { x: '2020-04-24', y: 423 },
            { x: '2020-04-25', y: 364 },
            { x: '2020-04-27', y: 420 },
            { x: '2020-04-28', y: 328 },
            { x: '2020-04-29', y: 339 },
            { x: '2020-04-30', y: 359 },
            { x: '2020-05-01', y: 398 },
            { x: '2020-05-02', y: 371 },
            { x: '2020-05-04', y: 374 },
            { x: '2020-05-05', y: 296 },
            { x: '2020-05-06', y: 367 },
            { x: '2020-05-07', y: 318 },
            { x: '2020-05-08', y: 380 },
            { x: '2020-05-09', y: 335 },
            { x: '2020-05-11', y: 340 },
            { x: '2020-05-12', y: 342 },
            { x: '2020-05-13', y: 353 },
            { x: '2020-05-14', y: 384 },
            { x: '2020-05-15', y: 390 },
            { x: '2020-05-16', y: 395 },
            { x: '2020-05-18', y: 363 },
            { x: '2020-05-19', y: 321 },
            { x: '2020-05-20', y: 392 },
            { x: '2020-05-21', y: 379 },
            { x: '2020-05-22', y: 403 },
            { x: '2020-05-23', y: 379 },
            { x: '2020-05-25', y: 311 },
            { x: '2020-05-26', y: 345 },
            { x: '2020-05-27', y: 355 },
            { x: '2020-05-28', y: 368 },
            { x: '2020-05-29', y: 421 },
            { x: '2020-05-30', y: 325 },
            { x: '2020-06-01', y: 361 },
            { x: '2020-06-02', y: 338 },
            { x: '2020-06-03', y: 357 },
            { x: '2020-06-04', y: 367 },
            { x: '2020-06-05', y: 424 },
            { x: '2020-06-06', y: 275 },
            { x: '2020-06-08', y: 367 },
            { x: '2020-06-09', y: 353 },
            { x: '2020-06-10', y: 350 },
            { x: '2020-06-11', y: 394 },
            { x: '2020-06-12', y: 377 },
            { x: '2020-06-13', y: 298 },
            { x: '2020-06-15', y: 410 },
            { x: '2020-06-16', y: 350 },
            { x: '2020-06-17', y: 364 },
            { x: '2020-06-18', y: 381 },
            { x: '2020-06-19', y: 420 },
            { x: '2020-06-20', y: 289 },
            { x: '2020-06-22', y: 347 },
            { x: '2020-06-23', y: 356 },
            { x: '2020-06-24', y: 367 },
            { x: '2020-06-25', y: 355 },
            { x: '2020-06-26', y: 427 },
            { x: '2020-06-27', y: 287 },
            { x: '2020-06-29', y: 432 },
            { x: '2020-06-30', y: 350 },
            { x: '2020-07-01', y: 400 },
            { x: '2020-07-02', y: 351 },
            { x: '2020-07-03', y: 323 },
            { x: '2020-07-04', y: 218 },
            { x: '2020-07-06', y: 336 },
            { x: '2020-07-07', y: 295 },
            { x: '2020-07-08', y: 355 },
            { x: '2020-07-09', y: 304 },
            { x: '2020-07-10', y: 295 },
            { x: '2020-07-11', y: 297 },
            { x: '2020-07-13', y: 397 },
            { x: '2020-07-14', y: 362 },
            { x: '2020-07-15', y: 349 },
            { x: '2020-07-16', y: 373 },
            { x: '2020-07-17', y: 374 },
            { x: '2020-07-20', y: 297 },
            { x: '2020-07-21', y: 329 },
            { x: '2020-07-22', y: 333 },
            { x: '2020-07-23', y: 335 },
            { x: '2020-07-24', y: 278 },
            { x: '2020-07-25', y: 278 },
            { x: '2020-07-27', y: 323 },
            { x: '2020-07-28', y: 361 },
            { x: '2020-07-29', y: 332 },
            { x: '2020-07-30', y: 378 },
            { x: '2020-07-31', y: 389 },
            { x: '2020-08-01', y: 257 },
            { x: '2020-08-03', y: 348 },
            { x: '2020-08-04', y: 309 },
            { x: '2020-08-05', y: 359 },
            { x: '2020-08-06', y: 341 },
            { x: '2020-08-07', y: 338 },
            { x: '2020-08-08', y: 272 },
            { x: '2020-08-10', y: 321 },
            { x: '2020-08-11', y: 338 },
            { x: '2020-08-12', y: 383 },
            { x: '2020-08-13', y: 359 },
            { x: '2020-08-14', y: 373 },
            { x: '2020-08-15', y: 140 },
            { x: '2020-08-17', y: 342 },
            { x: '2020-08-18', y: 314 },
            { x: '2020-08-19', y: 360 },
            { x: '2020-08-20', y: 321 },
            { x: '2020-08-21', y: 349 },
            { x: '2020-08-22', y: 247 },
            { x: '2020-08-24', y: 396 },
            { x: '2020-08-25', y: 347 },
            { x: '2020-08-26', y: 356 },
            { x: '2020-08-27', y: 327 },
            { x: '2020-08-28', y: 410 },
            { x: '2020-08-29', y: 330 },
            { x: '2020-08-31', y: 360 },
            { x: '2020-09-01', y: 340 },
            { x: '2020-09-02', y: 340 },
            { x: '2020-09-03', y: 361 },
            { x: '2020-09-04', y: 389 },
            { x: '2020-09-05', y: 310 },
            { x: '2020-09-07', y: 222 },
            { x: '2020-09-08', y: 352 },
            { x: '2020-09-09', y: 321 },
            { x: '2020-09-10', y: 387 },
            { x: '2020-09-11', y: 357 },
            { x: '2020-09-12', y: 336 },
            { x: '2020-09-14', y: 370 },
            { x: '2020-09-15', y: 350 },
            { x: '2020-09-16', y: 339 },
            { x: '2020-09-17', y: 398 },
            { x: '2020-09-18', y: 337 },
            { x: '2020-09-19', y: 344 },
            { x: '2020-09-21', y: 381 },
            { x: '2020-09-22', y: 350 },
            { x: '2020-09-23', y: 339 },
            { x: '2020-09-24', y: 379 },
            { x: '2020-09-25', y: 378 },
            { x: '2020-09-26', y: 281 },
            { x: '2020-09-28', y: 306 },
            { x: '2020-09-29', y: 354 },
            { x: '2020-09-30', y: 297 },
            { x: '2020-10-01', y: 351 },
            { x: '2020-10-02', y: 325 },
            { x: '2020-10-03', y: 239 },
            { x: '2020-10-05', y: 331 },
            { x: '2020-10-06', y: 313 },
            { x: '2020-10-07', y: 346 },
            { x: '2020-10-08', y: 359 },
            { x: '2020-10-09', y: 338 },
            { x: '2020-10-10', y: 313 },
            { x: '2020-10-12', y: 322 },
            { x: '2020-10-13', y: 321 },
            { x: '2020-10-14', y: 313 },
            { x: '2020-10-15', y: 343 },
            { x: '2020-10-16', y: 340 },
            { x: '2020-10-17', y: 289 },
            { x: '2020-10-19', y: 321 },
            { x: '2020-10-20', y: 321 },
            { x: '2020-10-21', y: 331 },
            { x: '2020-10-22', y: 342 },
            { x: '2020-10-23', y: 386 },
            { x: '2020-10-24', y: 343 },
            { x: '2020-10-27', y: 284 },
            { x: '2020-10-28', y: 309 },
            { x: '2020-10-29', y: 363 },
            { x: '2020-10-30', y: 395 },
            { x: '2020-10-31', y: 231 },
            { x: '2020-11-02', y: 286 },
            { x: '2020-11-03', y: 371 },
            { x: '2020-11-04', y: 320 },
            { x: '2020-11-05', y: 297 },
            { x: '2020-11-06', y: 314 },
            { x: '2020-11-07', y: 240 },
            { x: '2020-11-09', y: 290 },
            { x: '2020-11-10', y: 298 },
            { x: '2020-11-11', y: 260 },
            { x: '2020-11-12', y: 243 },
            { x: '2020-11-13', y: 307 },
            { x: '2020-11-14', y: 201 },
            { x: '2020-11-16', y: 282 },
            { x: '2020-11-17', y: 285 },
            { x: '2020-11-18', y: 335 },
            { x: '2020-11-19', y: 321 },
            { x: '2020-11-20', y: 268 },
            { x: '2020-11-21', y: 214 },
            { x: '2020-11-23', y: 311 },
            { x: '2020-11-24', y: 309 },
            { x: '2020-11-25', y: 314 },
            { x: '2020-11-27', y: 204 },
            { x: '2020-11-28', y: 226 },
            { x: '2020-11-30', y: 302 },
            { x: '2020-12-01', y: 286 },
            { x: '2020-12-02', y: 262 },
            { x: '2020-12-03', y: 300 },
            { x: '2020-12-04', y: 296 },
            { x: '2020-12-05', y: 222 },
            { x: '2020-12-07', y: 258 },
            { x: '2020-12-08', y: 283 },
            { x: '2020-12-09', y: 283 },
            { x: '2020-12-10', y: 311 },
            { x: '2020-12-11', y: 274 },
            { x: '2020-12-12', y: 184 },
            { x: '2020-12-14', y: 289 },
            { x: '2020-12-15', y: 314 },
            { x: '2020-12-16', y: 289 },
            { x: '2020-12-17', y: 273 },
            { x: '2020-12-18', y: 285 },
            { x: '2020-12-19', y: 233 },
            { x: '2020-12-21', y: 264 },
            { x: '2020-12-22', y: 269 },
            { x: '2020-12-23', y: 287 },
            { x: '2020-12-24', y: 122 },
            { x: '2020-12-26', y: 153 },
            { x: '2020-12-28', y: 251 },
            { x: '2020-12-29', y: 301 },
            { x: '2020-12-30', y: 310 },
            { x: '2020-12-31', y: 262 },
            { x: '2021-01-01', y: 164 },
            { x: '2021-01-02', y: 240 },
            { x: '2021-01-04', y: 294 },
            { x: '2021-01-05', y: 336 },
            { x: '2021-01-06', y: 379 },
            { x: '2021-01-07', y: 274 },
            { x: '2021-01-08', y: 321 },
            { x: '2021-01-09', y: 244 },
            { x: '2021-01-11', y: 324 },
            { x: '2021-01-12', y: 262 },
            { x: '2021-01-13', y: 340 },
            { x: '2021-01-14', y: 308 },
            { x: '2021-01-15', y: 301 },
            { x: '2021-01-16', y: 299 },
            { x: '2021-01-18', y: 357 },
            { x: '2021-01-19', y: 285 },
            { x: '2021-01-20', y: 353 },
            { x: '2021-01-21', y: 339 },
            { x: '2021-01-22', y: 345 },
            { x: '2021-01-23', y: 216 },
            { x: '2021-01-25', y: 257 },
            { x: '2021-01-26', y: 306 },
            { x: '2021-01-27', y: 291 },
            { x: '2021-01-28', y: 310 },
            { x: '2021-01-29', y: 288 },
            { x: '2021-01-30', y: 295 },
            { x: '2021-02-01', y: 196 },
            { x: '2021-02-02', y: 265 },
            { x: '2021-02-04', y: 301 },
            { x: '2021-02-05', y: 318 },
            { x: '2021-02-06', y: 255 },
            { x: '2021-02-08', y: 228 },
            { x: '2021-02-09', y: 231 },
            { x: '2021-02-10', y: 265 },
            { x: '2021-02-11', y: 233 },
            { x: '2021-02-12', y: 395 },
            { x: '2021-02-13', y: 326 },
            { x: '2021-02-15', y: 289 },
            { x: '2021-02-16', y: 206 },
            { x: '2021-02-17', y: 292 },
            { x: '2021-02-18', y: 267 },
            { x: '2021-02-19', y: 304 },
            { x: '2021-02-20', y: 274 },
            { x: '2021-02-22', y: 214 },
            { x: '2021-02-23', y: 258 },
            { x: '2021-02-24', y: 269 },
            { x: '2021-02-25', y: 303 },
            { x: '2021-02-26', y: 270 },
            { x: '2021-02-27', y: 241 },
            { x: '2021-03-01', y: 253 },
            { x: '2021-03-02', y: 281 },
            { x: '2021-03-03', y: 240 },
            { x: '2021-03-04', y: 266 },
            { x: '2021-03-05', y: 261 },
            { x: '2021-03-06', y: 237 },
            { x: '2021-03-08', y: 265 },
            { x: '2021-03-09', y: 232 },
            { x: '2021-03-10', y: 230 },
            { x: '2021-03-11', y: 279 },
            { x: '2021-03-12', y: 275 },
            { x: '2021-03-13', y: 140 },
            { x: '2021-03-15', y: 281 },
            { x: '2021-03-16', y: 221 },
            { x: '2021-03-17', y: 247 },
            { x: '2021-03-18', y: 308 },
            { x: '2021-03-19', y: 484 },
            { x: '2021-03-20', y: 429 },
            { x: '2021-03-22', y: 302 },
            { x: '2021-03-23', y: 272 },
            { x: '2021-03-24', y: 314 },
            { x: '2021-03-25', y: 301 },
            { x: '2021-03-26', y: 368 },
            { x: '2021-03-27', y: 402 },
            { x: '2021-03-29', y: 334 },
            { x: '2021-03-30', y: 288 },
            { x: '2021-03-31', y: 328 },
            { x: '2021-04-01', y: 395 },
            { x: '2021-04-02', y: 413 },
            { x: '2021-04-03', y: 349 },
            { x: '2021-04-05', y: 323 },
            { x: '2021-04-06', y: 315 },
            { x: '2021-04-07', y: 332 },
            { x: '2021-04-08', y: 360 },
            { x: '2021-04-09', y: 355 },
            { x: '2021-04-10', y: 356 },
            { x: '2021-04-12', y: 264 },
            { x: '2021-04-13', y: 292 },
            { x: '2021-04-14', y: 285 },
            { x: '2021-04-15', y: 308 },
            { x: '2021-04-16', y: 320 },
            { x: '2021-04-17', y: 294 },
            { x: '2021-04-19', y: 285 },
            { x: '2021-04-20', y: 314 },
            { x: '2021-04-21', y: 348 },
            { x: '2021-04-22', y: 278 },
            { x: '2021-04-23', y: 313 },
            { x: '2021-04-24', y: 210 },
            { x: '2021-04-26', y: 230 },
            { x: '2021-04-27', y: 235 },
            { x: '2021-04-28', y: 255 },
            { x: '2021-04-29', y: 343 },
            { x: '2021-04-30', y: 369 },
            { x: '2021-05-01', y: 261 },
            { x: '2021-05-03', y: 285 },
            { x: '2021-05-04', y: 271 },
            { x: '2021-05-05', y: 252 },
            { x: '2021-05-06', y: 262 },
            { x: '2021-05-07', y: 284 },
            { x: '2021-05-08', y: 278 },
            { x: '2021-05-10', y: 250 },
            { x: '2021-05-12', y: 265 },
            { x: '2021-05-13', y: 292 },
            { x: '2021-05-14', y: 334 },
            { x: '2021-05-15', y: 247 },
            { x: '2021-05-17', y: 223 },
            { x: '2021-05-18', y: 262 },
            { x: '2021-05-19', y: 292 },
            { x: '2021-05-20', y: 280 },
            { x: '2021-05-21', y: 300 },
            { x: '2021-05-22', y: 199 },
            { x: '2021-05-24', y: 275 },
            { x: '2021-05-25', y: 255 },
            { x: '2021-05-26', y: 273 },
            { x: '2021-05-27', y: 275 },
            { x: '2021-05-28', y: 282 },
            { x: '2021-05-29', y: 242 },
            { x: '2021-05-31', y: 244 },
            { x: '2021-06-01', y: 281 },
            { x: '2021-06-02', y: 257 },
            { x: '2021-06-03', y: 296 },
            { x: '2021-06-04', y: 286 },
            { x: '2021-06-05', y: 231 },
            { x: '2021-06-07', y: 269 },
            { x: '2021-06-08', y: 267 },
            { x: '2021-06-09', y: 269 },
            { x: '2021-06-10', y: 270 },
            { x: '2021-06-11', y: 278 },
            { x: '2021-06-12', y: 178 },
            { x: '2021-06-14', y: 255 },
            { x: '2021-06-15', y: 301 },
            { x: '2021-06-16', y: 289 },
            { x: '2021-06-17', y: 278 },
            { x: '2021-06-18', y: 283 },
            { x: '2021-06-19', y: 168 },
            { x: '2021-06-21', y: 329 },
            { x: '2021-06-22', y: 265 },
            { x: '2021-06-23', y: 261 },
            { x: '2021-06-24', y: 264 },
            { x: '2021-06-25', y: 305 },
            { x: '2021-06-26', y: 204 },
            { x: '2021-06-28', y: 243 },
            { x: '2021-06-29', y: 270 },
            { x: '2021-06-30', y: 276 },
            { x: '2021-07-01', y: 292 },
            { x: '2021-07-02', y: 251 },
            { x: '2021-07-03', y: 138 },
            { x: '2021-07-05', y: 208 },
            { x: '2021-07-06', y: 292 },
            { x: '2021-07-07', y: 238 },
            { x: '2021-07-08', y: 306 },
            { x: '2021-07-09', y: 235 },
            { x: '2021-07-10', y: 241 },
            { x: '2021-07-12', y: 232 },
            { x: '2021-07-13', y: 244 },
            { x: '2021-07-14', y: 247 },
            { x: '2021-07-15', y: 265 },
            { x: '2021-07-16', y: 237 },
            { x: '2021-07-17', y: 174 },
            { x: '2021-07-19', y: 272 },
            { x: '2021-07-20', y: 216 },
            { x: '2021-07-21', y: 248 },
            { x: '2021-07-22', y: 242 },
            { x: '2021-07-24', y: 140 },
            { x: '2021-07-26', y: 278 },
            { x: '2021-07-27', y: 218 },
            { x: '2021-07-28', y: 260 },
            { x: '2021-07-29', y: 228 },
            { x: '2021-07-30', y: 235 },
            { x: '2021-07-31', y: 178 },
            { x: '2021-08-02', y: 231 },
            { x: '2021-08-03', y: 251 },
            { x: '2021-08-04', y: 270 },
            { x: '2021-08-05', y: 257 },
            { x: '2021-08-06', y: 242 },
            { x: '2021-08-07', y: 161 },
            { x: '2021-08-09', y: 245 },
            { x: '2021-08-10', y: 241 },
            { x: '2021-08-11', y: 286 },
            { x: '2021-08-12', y: 230 },
            { x: '2021-08-13', y: 213 },
            { x: '2021-08-14', y: 173 },
            { x: '2021-08-16', y: 281 },
            { x: '2021-08-17', y: 241 },
            { x: '2021-08-18', y: 234 },
            { x: '2021-08-19', y: 253 },
            { x: '2021-08-20', y: 254 },
            { x: '2021-08-21', y: 186 },
            { x: '2021-08-23', y: 246 },
            { x: '2021-08-24', y: 241 },
            { x: '2021-08-25', y: 230 },
            { x: '2021-08-26', y: 248 },
            { x: '2021-08-27', y: 297 },
            { x: '2021-08-28', y: 240 },
            { x: '2021-08-30', y: 262 },
            { x: '2021-08-31', y: 239 },
            { x: '2021-09-01', y: 218 },
            { x: '2021-09-02', y: 289 },
            { x: '2021-09-03', y: 228 },
            { x: '2021-09-04', y: 216 },
            { x: '2021-09-06', y: 204 },
            { x: '2021-09-07', y: 269 },
            { x: '2021-09-08', y: 265 },
            { x: '2021-09-09', y: 255 },
            { x: '2021-09-10', y: 280 },
            { x: '2021-09-11', y: 219 },
            { x: '2021-09-13', y: 236 },
            { x: '2021-09-14', y: 291 },
            { x: '2021-09-15', y: 216 },
            { x: '2021-09-16', y: 284 },
            { x: '2021-09-17', y: 322 },
            { x: '2021-09-18', y: 181 },
            { x: '2021-09-20', y: 260 },
            { x: '2021-09-21', y: 268 },
            { x: '2021-09-22', y: 261 },
            { x: '2021-09-23', y: 106 },
        ],
    },
];
