import { RootState } from '../../store';
import { RecipesInterface, prepItem } from './recipesState';

export const selectRecipesState = (
    state: RootState
): Partial<RecipesInterface> => {
    return state.recipes;
};

export const selectPrepLists = (state: RootState): any => {
    return state.recipes.prepLists;
};

export const selectFocusedPrepItem = (state: RootState): prepItem => {
    return state.recipes.focusedPrepItem;
};

export const selectFocusedPrepSection = (state: RootState): string => {
    return state.recipes.focusedPrepSection ?? '';
};

export const selectSelectedPrepSection = (state: RootState): string => {
    return state.recipes.selectedPrepSection ?? '';
};

export const selectDialogState = (state: RootState): string => {
    return state.recipes.dialogState;
};

export const selectOpenDialog = (state: RootState): boolean => {
    return state.recipes.openDialog;
};

export const selectActiveItem = (state: RootState): string => {
    return state.recipes.activeItem;
};

export const selectSelectedPrepList = (state: RootState): string => {
    return state.recipes.selectedPrepList;
};

export const selectPrepLicenses = (state: RootState): number | null => {
    return state.recipes.prepLicense ?? null;
};
