import { Typography } from '@mui/material';

import { BoxCenteredHeaderRow } from '../../../../components/StyledMUI/StyledBox';
import { prepListColumns } from '../../../../constants/recipe_constants';
import { PrepListLabelRowProps } from './IPrepListTab';

/**
 * Renders a row of labels for the prep list table.
 * @returns The JSX element representing the prep list label row.
 */
export const PrepListLabelRow: React.FC<PrepListLabelRowProps> = () => {
    return (
        <BoxCenteredHeaderRow>
            {prepListColumns.map((column: any) => {
                return (
                    <Typography
                        variant="columnHeader"
                        width={column.width}
                        key={column.key()}
                    >
                        {column.header}
                    </Typography>
                );
            })}
        </BoxCenteredHeaderRow>
    );
};
