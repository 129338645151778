import { useMediaQuery } from '@mui/material';

import { ResponsiveInterface } from '../redux/reducers/responsive/responsiveState';

const widescreenMinWidth = 1824;
const desktopMinWidth = 1024;
const mobileMaxWidth = 768;

export const useResponsiveState: () => ResponsiveInterface = () => {
    const isWideScreenQuery = useMediaQuery(
        `(min-width: ${widescreenMinWidth}px)`
    );
    const isDesktopQuery = useMediaQuery(`(min-width: ${desktopMinWidth}px)`);
    const isMobileQuery = useMediaQuery(`(max-width: ${mobileMaxWidth}px)`);
    const isPortraitQuery = useMediaQuery('(orientation: portrait)');
    const isRetinaQuery = useMediaQuery('(min-resolution: 2dppx)');

    return {
        isDesktop: isDesktopQuery,
        isWideScreen: isWideScreenQuery,
        isMobile: isMobileQuery,
        isTablet: !isDesktopQuery && !isMobileQuery,
        isPortrait: isPortraitQuery,
        isRetina: isRetinaQuery,
    };
};

export const hooklessUseResponsiveState: () => ResponsiveInterface = () => {
    return {
        isDesktop: window.matchMedia(`(min-width: ${desktopMinWidth}px)`)
            .matches,
        isWideScreen: window.matchMedia(`(min-width: ${widescreenMinWidth}px)`)
            .matches,
        isMobile: window.matchMedia(`(max-width: ${mobileMaxWidth}px)`).matches,
        isTablet: window.matchMedia(
            `(max-width: ${desktopMinWidth}px) and (min-width: ${mobileMaxWidth}px)`
        ).matches,
        isPortrait: window.matchMedia('(orientation: portrait)').matches,
        isRetina: window.matchMedia('(min-resolution: 2dppx)').matches,
    };
};
