import { useState } from 'react';

import { useAppSelector } from '../../../redux/hooks';
import { selectIsMobile } from '../../../redux/reducers/responsive/responsiveSelectors';
import { CollapsibleWrapper } from './CollapsibleWrapper';
import { NavigationBar } from './NavigationBar';

interface NavProps {
    path: string;
    signOut: any;
    userDetails: any;
    setConceptStorePair: any;
    concept: string | null;
    store: string | null;
}

export function Nav({
    userDetails,
    setConceptStorePair,
    path,
    signOut,
    concept,
    store,
}: NavProps) {
    const [navStatus, setNavStatus] = useState(false);

    const isMobile: boolean = useAppSelector(selectIsMobile);

    return isMobile ? (
        <NavigationBar
            navStatus={navStatus}
            setNavStatus={setNavStatus}
            path={path}
            signOut={signOut}
            setConceptStorePair={setConceptStorePair}
            concept={concept}
            store={store}
        />
    ) : (
        <CollapsibleWrapper navStatus={navStatus}>
            <NavigationBar
                navStatus={navStatus}
                setNavStatus={setNavStatus}
                path={path}
                signOut={signOut}
                setConceptStorePair={setConceptStorePair}
                concept={concept}
                store={store}
            />
        </CollapsibleWrapper>
    );
}
