declare module '@mui/material/Divider' {
    interface DividerPropsVariantOverrides {
        black: true;
    }
}

const dividerThemeOptions = {
    components: {
        MuiDivider: {
            variants: [
                {
                    props: { variant: 'black' },
                    style: {
                        width: '100%',
                        borderColor: 'black',
                    },
                },
            ],
        },
    },
};

export default dividerThemeOptions;
