/**
 * * Async JSON request
 * @param {user_id} string containing user's UUID
 * @returns {object} object containing all user and store data
 */
export async function getUserDetails(user_id: string) {
    // API URL Endpoint Construction
    const url = `${process.env.REACT_APP_API_URL}/user_details?action=READ&id=${user_id}`;

    // Perform API call
    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(JSON.stringify(response.json()));
            }
            return response.json();
        })
        .catch((err) => {
            console.error(err);
            throw err;
        });
}
