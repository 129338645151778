/**
 * * Async JSON request
 * @param {concept} string containing concept's UUID
 * @returns {object} object containing all user and store data
 */
export async function getIngredients(concept: string) {
    const url = `${process.env.REACT_APP_API_URL}/menu_mapper?action=READ_INGREDIENT&id=${concept}`;

    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(JSON.stringify(response.json()));
            }
            return response.json();
        })
        .catch((err) => {
            console.error(err);
            throw err;
        });
}
