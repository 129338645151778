import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Typography } from '@mui/material';

import { BoxCenteredRow } from '../../../../components/StyledMUI/StyledBox';
import { useAppDispatch } from '../../../../redux/hooks';
import { newFocusedPrepItem } from '../../../../redux/reducers/recipes/recipesActions';
import { PrepListAddItemProps } from './IPrepListTab';

/**
 * Component for adding an item to the prep list section.
 *
 * @component
 * @param {string} section - The section to add the item to.
 * @returns {JSX.Element} The rendered component.
 */
export const PrepListAddItem: React.FC<PrepListAddItemProps> = ({
    section,
}) => {
    const dispatch = useAppDispatch();

    return (
        <BoxCenteredRow
            onClick={() => {
                dispatch(newFocusedPrepItem(section));
            }}
        >
            <AddRoundedIcon /> <Typography>Add Item To Section</Typography>
        </BoxCenteredRow>
    );
};
