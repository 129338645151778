/**
 * * Async JSON request
 * @param {id} string containing concept's UUID
 * @param {item_id} string containing item's UUID
 * @param {item} object containing copy of the item
 * @returns {object} object containing all user and store data
 */
export async function updateMenuItems({ id, menuItems }: any) {
    const url = `${process.env.REACT_APP_API_URL}/menu_mapper`;

    let body = JSON.stringify({
        action: 'DIFF_MENU',
        id: id,
        data: menuItems,
    });

    return fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: body,
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(JSON.stringify(response.json()));
            }
            return response.json();
        })
        .catch((err) => {
            console.error(err);
            throw err;
        });
}
