import { useEffect, useState } from 'react';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { postOrganization } from '../../../api';
import { ActionMenu } from './ActionMenu';

const debugMode = process.env.REACT_APP_DEBUG === 'true';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion
        disableGutters
        elevation={0}
        square
        {...props}
    />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface emailInterface {
    user: any;
    organization: any[];
    setOrgnization: any;
    organizationError: boolean;
}

const widgetHeight = 52;
const widgetLimit = Math.floor(window.innerHeight / widgetHeight) - 3;

export function Email({
    user,
    organization = [],
    setOrgnization,
    organizationError,
}: emailInterface) {
    const [expandedStore, setExpandedStore] = useState<number | null>(null);
    const [expandedList, setExpandedList] = useState<number | null>(null);
    const [page, setPage] = useState<number>(1);
    const [pages, setPages] = useState<number>(0);
    const [addEmail, setAddEmail] = useState<boolean>(false);
    const [actionMenuStatus, setActionMenuStatus] = useState<number>(-1);

    useEffect(() => {
        if (organization) {
            setPages(Math.ceil(organization.length / widgetLimit));
        }
        debugMode && console.log(organization);
    }, [organization]);

    const handleStoreChange =
        (panel: number) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setAddEmail(false);
            setExpandedList(null);
            setExpandedStore(isExpanded ? panel : null);
            setActionMenuStatus(-1);
        };

    const handleListChange =
        (panel: number) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setAddEmail(false);
            setExpandedList(isExpanded ? panel : null);
            setActionMenuStatus(-1);
        };

    const handlePageChange = (
        event: React.ChangeEvent<unknown>,
        value: number
    ) => {
        setPage(value);
    };

    const setTextField = (
        store: string,
        list: string,
        index: number,
        newText: string
    ) => {
        var orgCopy = [...organization];
        if (newText === '') {
            orgCopy[
                orgCopy.findIndex((object) => {
                    return object.id === store;
                })
            ].emails[list].splice(index, 1);
        } else {
            orgCopy[
                orgCopy.findIndex((object) => {
                    return object.id === store;
                })
            ].emails[list][index] = newText;
        }
        if (orgCopy !== organization) {
            setOrgnization(orgCopy);
            postOrganization(
                user,
                orgCopy.filter((object) => object.id === store)
            );
        }
        setAddEmail(false);
    };

    return (
        <Box
            width="fill-available"
            height="auto"
            paddingTop={4}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'start',
            }}
        >
            <Typography variant="h3">Email Subscriptions</Typography>
            <Typography variant="subtitle1">
                Select a store below to modify and control who recieves your
                reports.
            </Typography>
            {organization.length !== 0 ? (
                <Box paddingTop={1}>
                    {organization
                        .slice((page - 1) * widgetLimit, page * widgetLimit)
                        .map((store, index) => {
                            return (
                                <Box key={index}>
                                    <Collapse
                                        in={
                                            expandedStore === index ||
                                            expandedStore == null
                                        }
                                    >
                                        <Accordion
                                            expanded={expandedStore === index}
                                            onChange={handleStoreChange(index)}
                                            sx={{ width: '60vw' }}
                                        >
                                            <AccordionSummary
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <Typography>
                                                    {store.name}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography variant="subtitle2">
                                                    Select a subscription list
                                                    to view or modify who
                                                    recieves your reports.
                                                </Typography>
                                                {Object.keys(store.emails).map(
                                                    (listKey, index) => {
                                                        return (
                                                            <Collapse
                                                                key={index}
                                                                in={
                                                                    expandedList ===
                                                                        index ||
                                                                    expandedList ==
                                                                        null
                                                                }
                                                            >
                                                                <Accordion
                                                                    expanded={
                                                                        expandedList ===
                                                                        index
                                                                    }
                                                                    onChange={handleListChange(
                                                                        index
                                                                    )}
                                                                    sx={{
                                                                        width: 'fill',
                                                                    }}
                                                                >
                                                                    <AccordionSummary
                                                                        aria-controls="panel1a-content"
                                                                        id="panel1a-header"
                                                                    >
                                                                        <Typography>
                                                                            {
                                                                                listKey
                                                                            }
                                                                        </Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        {store.emails[
                                                                            listKey
                                                                        ].map(
                                                                            (
                                                                                email: any,
                                                                                index: any
                                                                            ) => {
                                                                                return (
                                                                                    <ActionMenu
                                                                                        key={
                                                                                            index
                                                                                        }
                                                                                        textField={
                                                                                            email
                                                                                        }
                                                                                        setTextField={(
                                                                                            newText: string
                                                                                        ) =>
                                                                                            setTextField(
                                                                                                store.id,
                                                                                                listKey,
                                                                                                index,
                                                                                                newText
                                                                                            )
                                                                                        }
                                                                                        variant="right"
                                                                                        generalCancel={() => {
                                                                                            setActionMenuStatus(
                                                                                                -1
                                                                                            );
                                                                                        }}
                                                                                        generalOpen={() => {
                                                                                            setActionMenuStatus(
                                                                                                index
                                                                                            );
                                                                                            setAddEmail(
                                                                                                false
                                                                                            );
                                                                                        }}
                                                                                        controlledActive={
                                                                                            actionMenuStatus ===
                                                                                            index
                                                                                        }
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                        {addEmail ? (
                                                                            <ActionMenu
                                                                                key={
                                                                                    store
                                                                                        .emails[
                                                                                        listKey
                                                                                    ]
                                                                                        .length
                                                                                }
                                                                                textField={
                                                                                    ''
                                                                                }
                                                                                setTextField={(
                                                                                    newText: string
                                                                                ) =>
                                                                                    setTextField(
                                                                                        store.id,
                                                                                        listKey,
                                                                                        store
                                                                                            .emails[
                                                                                            listKey
                                                                                        ]
                                                                                            .length,
                                                                                        newText
                                                                                    )
                                                                                }
                                                                                variant="right"
                                                                                newField={
                                                                                    true
                                                                                }
                                                                                newCancel={() =>
                                                                                    setAddEmail(
                                                                                        false
                                                                                    )
                                                                                }
                                                                                generalCancel={() => {
                                                                                    setActionMenuStatus(
                                                                                        -1
                                                                                    );
                                                                                }}
                                                                                generalOpen={() => {
                                                                                    setActionMenuStatus(
                                                                                        store
                                                                                            .emails[
                                                                                            listKey
                                                                                        ]
                                                                                            .length
                                                                                    );
                                                                                }}
                                                                                controlledActive={
                                                                                    actionMenuStatus ===
                                                                                    store
                                                                                        .emails[
                                                                                        listKey
                                                                                    ]
                                                                                        .length
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            <Box
                                                                                height="40px"
                                                                                sx={{
                                                                                    display:
                                                                                        'flex',
                                                                                    alignItems:
                                                                                        'center',
                                                                                }}
                                                                            >
                                                                                <Box
                                                                                    sx={{
                                                                                        opacity: 1,
                                                                                        display:
                                                                                            'flex',
                                                                                        justifyContent:
                                                                                            'center',
                                                                                        alignItems:
                                                                                            'center',
                                                                                        cursor: 'pointer',
                                                                                        background:
                                                                                            '#222',
                                                                                        borderRadius:
                                                                                            '50%',
                                                                                        width: '30px',
                                                                                        height: '30px',
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setActionMenuStatus(
                                                                                            store
                                                                                                .emails[
                                                                                                listKey
                                                                                            ]
                                                                                                .length
                                                                                        );
                                                                                        setAddEmail(
                                                                                            true
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <AddRoundedIcon
                                                                                        sx={{
                                                                                            color: '#fff',
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                        )}
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </Collapse>
                                                        );
                                                    }
                                                )}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Collapse>
                                </Box>
                            );
                        })}
                </Box>
            ) : !organizationError ? (
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Typography>
                    An Error Occured, Please Refresh The Page.
                </Typography>
            )}
            <Box>
                {pages > 1 && expandedStore == null && (
                    <Pagination
                        count={pages}
                        page={page}
                        onChange={handlePageChange}
                    />
                )}
            </Box>
        </Box>
    );
}
