import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import { SelectChangeEvent } from '@mui/material/Select';

import { ingredientsInterface } from '../../components/Recipes';

export function DialogNewMenuItem(
    openDialog: boolean,
    dialogState: number,
    setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>,
    editName: string,
    handleNameChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void,
    ingredients: ingredientsInterface,
    ingredientsEgress: ingredientsInterface,
    activeItem: string,
    handleUnitChange: (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        ingredient: string
    ) => void,
    handleSelectCategoryChange: (
        event: SelectChangeEvent,
        ingredient: string
    ) => void,
    category: any,
    categories: string[],
    handleIngredientDeletion: () => void,
    handleEgressDemotion: () => void,
    handleEgressPromotion: () => void,
    duplicateError: boolean,
    setDuplicateError: (duplicateError: boolean) => void
): React.ReactNode {
    return (
        <Dialog
            open={openDialog && dialogState === 1}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => {
                setDuplicateError(false);
                setOpenDialog(false);
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {'Edit Forecastable Item'}
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    marginBottom={2}
                >
                    Give your forecastable item a name and/or a unit of
                    measurement.
                </DialogContentText>
                <Box
                    display="flex"
                    sx={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <FormControl
                        variant="standard"
                        sx={{
                            marginTop: 2,
                            marginBottom: 2,
                            width: '50%',
                        }}
                        error={duplicateError}
                    >
                        <InputLabel>Name</InputLabel>
                        <Input
                            value={editName}
                            onChange={(event) => {
                                handleNameChange(event);
                                if (duplicateError) {
                                    setDuplicateError(false);
                                }
                            }}
                        />
                        {duplicateError && (
                            <FormHelperText id="component-error-text">
                                There is already an item with this name.
                            </FormHelperText>
                        )}
                    </FormControl>

                    <FormControl
                        variant="standard"
                        disabled={category !== 'Forecastable Items'}
                        sx={{
                            marginTop: 2,
                            marginBottom: 2,
                            width: '40%',
                        }}
                    >
                        <InputLabel>Units</InputLabel>
                        <Input
                            value={ingredientsEgress[activeItem].units}
                            onChange={(event) => {
                                handleUnitChange(event, activeItem);
                            }}
                        />
                        {category !== 'Forecastable Items' && (
                            <FormHelperText id="component-error-text">
                                Editing units is unavailable for Menu Items.
                            </FormHelperText>
                        )}
                    </FormControl>
                </Box>
                {/* <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        Category
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ingredientsEgress[activeItem].category}
                        label="Category"
                        onChange={(event) =>
                            handleSelectCategoryChange(event, activeItem)
                        }
                    >
                        {categories.map((option, idx) => {
                            return (
                                <MenuItem value={option} key={idx}>
                                    {option}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl> */}
            </DialogContent>
            <DialogActions>
                {category === 'Forecastable Items' && (
                    <Button
                        color="error"
                        onClick={() => {
                            handleIngredientDeletion();
                            handleEgressDemotion();
                        }}
                    >
                        DELETE
                    </Button>
                )}
                <Button
                    onClick={() => {
                        handleEgressDemotion();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => {
                        if (
                            editName !== activeItem &&
                            Object.keys(ingredientsEgress).includes(
                                editName.trim()
                            )
                        ) {
                            setDuplicateError(true);
                        } else {
                            handleEgressPromotion();
                        }
                    }}
                    color={duplicateError ? 'error' : 'primary'}
                    disabled={
                        (editName === activeItem &&
                            ingredientsEgress[activeItem].units ===
                                ingredients[activeItem].units) ||
                        !editName ||
                        !ingredientsEgress[activeItem].units ||
                        !ingredientsEgress[activeItem].category
                    }
                    autoFocus
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}
