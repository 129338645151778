import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import { ratio } from '../../../../constants/recipe_constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
    clearFocusedPrepItem,
    updateFocusedPrepItem,
} from '../../../../redux/reducers/recipes/recipesActions';
import {
    selectDialogState,
    selectFocusedPrepItem,
    selectOpenDialog,
    selectSelectedPrepList,
} from '../../../../redux/reducers/recipes/recipesSelectors';
import { ingredientsInterface } from '../../components/Recipes';

interface UnitConversionProps {
    ingredients: ingredientsInterface;
}

export const Yield: React.FC<UnitConversionProps> = ({ ingredients }) => {
    const dispatch = useAppDispatch();

    const selectedPrepList = useAppSelector(selectSelectedPrepList);
    const focusedPrepItem = useAppSelector(selectFocusedPrepItem);
    const dialogState = useAppSelector(selectDialogState);
    const openDialog = useAppSelector(selectOpenDialog);

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            sx={{
                width: `${(1 - ratio) * 100}%`,
                marginLeft: `${ratio * 100}%`,
            }}
            open={openDialog && dialogState === 'Yield'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => {
                dispatch(clearFocusedPrepItem());
            }}
            BackdropProps={{
                style: { backgroundColor: 'transparent' },
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {selectedPrepList}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    1 -{' '}
                    <span style={{ color: 'blue' }}>
                        {focusedPrepItem.newUnit ?? focusedPrepItem.units}
                    </span>{' '}
                    of{' '}
                    <span style={{ color: 'blue' }}>
                        {focusedPrepItem.itemName}
                    </span>{' '}
                    yields
                </DialogContentText>
                <FormControl
                    variant="standard"
                    sx={{
                        marginTop: 1,
                        marginBottom: 1,
                    }}
                >
                    <TextField
                        id="filled-number"
                        label={
                            Object.values(ingredients).find(
                                (ingredient: any) =>
                                    ingredient.uuid ===
                                    focusedPrepItem.ingredientUUID
                            )?.units +
                            ' of ' +
                            focusedPrepItem.ingredientName
                        }
                        type="number"
                        value={focusedPrepItem.newUnitConversion}
                        variant="standard"
                        onChange={(event) => {
                            dispatch(
                                updateFocusedPrepItem({
                                    newUnitConversion: event.target.value,
                                })
                            );
                        }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        dispatch(updateFocusedPrepItem({}, 'UnitConversion'));
                    }}
                >
                    Back
                </Button>
                <Button
                    disabled={focusedPrepItem.newUnitConversion === ''}
                    autoFocus
                    onClick={() => {
                        dispatch(updateFocusedPrepItem({}, 'ShelfLife'));
                    }}
                >
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    );
};
