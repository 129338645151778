import { Box, Divider, Typography } from '@mui/material';

import {
    BoxPrepListSelctor,
    BoxPrepListSideBar,
} from '../../../../components/StyledMUI/StyledBox';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { setSelectedPrepList } from '../../../../redux/reducers/recipes/recipesActions';
import {
    selectPrepLists,
    selectSelectedPrepList,
} from '../../../../redux/reducers/recipes/recipesSelectors';
import { PrepListSideBarProps } from './IPrepListTab';

export const PrepListSideBar: React.FC<PrepListSideBarProps> = () => {
    const dispatch = useAppDispatch();

    const prepLists = useAppSelector(selectPrepLists);
    const selectedPrepList = useAppSelector(selectSelectedPrepList);

    return (
        <BoxPrepListSideBar>
            {Object.keys(prepLists).map((item, idx) => {
                return (
                    <Box key={idx}>
                        <BoxPrepListSelctor
                            idx={idx}
                            selected={selectedPrepList === item}
                            onClick={() => {
                                if (idx === 0) {
                                    dispatch(setSelectedPrepList(item));
                                }
                            }}
                        >
                            <Typography variant="prepListSelector">
                                {item}
                            </Typography>
                        </BoxPrepListSelctor>
                        <Divider />
                    </Box>
                );
            })}
        </BoxPrepListSideBar>
    );
};
