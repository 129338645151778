import React from 'react';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { days } from '../../../../constants/recipe_constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
    clearFocusedPrepItem,
    updateFocusedPrepItem,
} from '../../../../redux/reducers/recipes/recipesActions';
import {
    selectDialogState,
    selectFocusedPrepItem,
    selectOpenDialog,
} from '../../../../redux/reducers/recipes/recipesSelectors';
import { ingredientsInterface } from '../../components/Recipes';

let units_of_time_selector =
    process.env.REACT_APP_FEATURE_UNITS_OF_TIME_SELECTOR === 'true';

interface EditEntryPopupProps {
    categories: string[];
    ingredients: ingredientsInterface;
    addToPrepList: (
        modifiedItem?: any,
        remove?: boolean,
        duplicate?: boolean
    ) => void;
}

export const EditEntryPopup: React.FC<EditEntryPopupProps> = ({
    categories,
    ingredients,
    addToPrepList,
}) => {
    const dispatch = useAppDispatch();

    const focusedPrepItem = useAppSelector(selectFocusedPrepItem);
    const dialogState = useAppSelector(selectDialogState);
    const openDialog = useAppSelector(selectOpenDialog);

    return (
        <Dialog
            fullWidth
            open={openDialog && dialogState === 'EditEntryPopup'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => {
                dispatch(clearFocusedPrepItem());
            }}
        >
            <DialogTitle id="alert-dialog-title">Edit This Item</DialogTitle>
            <DialogContent>
                <FormControl
                    variant="standard"
                    sx={{
                        marginTop: 1,
                        marginBottom: 1,
                    }}
                >
                    <InputLabel>Food Item</InputLabel>
                    <Input
                        value={focusedPrepItem.itemName}
                        onChange={(event) => {
                            dispatch(
                                updateFocusedPrepItem({
                                    itemName: event.target.value,
                                })
                            );
                        }}
                    />
                </FormControl>
                <FormControl
                    fullWidth
                    sx={{ my: 2 }}
                >
                    <InputLabel id="demo-simple-select-label">
                        Item Category
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={focusedPrepItem.ingredientCategory}
                        label="Item Category"
                        onChange={(event) => {
                            dispatch(
                                updateFocusedPrepItem({
                                    ingredientCategory: event.target
                                        .value as string,
                                    ingredientName: '',
                                    ingredientUUID: '',
                                })
                            );
                        }}
                    >
                        {categories.map((option, idx) => {
                            return (
                                <MenuItem
                                    value={option}
                                    key={idx}
                                >
                                    {option}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <FormControl
                    fullWidth
                    disabled={focusedPrepItem.ingredientCategory === ''}
                    sx={{ mb: 2 }}
                >
                    <InputLabel id="demo-simple-select-label">
                        Forecastable Item
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={
                            Object.entries(ingredients).find(
                                ([_, value]) =>
                                    value.uuid ===
                                    focusedPrepItem.ingredientUUID
                            )?.[0] || ''
                        }
                        label="Forecastable Item"
                        onChange={(event) => {
                            dispatch(
                                updateFocusedPrepItem({
                                    ingredientName: event.target
                                        .value as string,
                                    ingredientUUID:
                                        ingredients[
                                            event.target.value as string
                                        ].uuid,
                                })
                            );
                        }}
                    >
                        {Object.keys(ingredients)
                            .filter((item) => {
                                return (
                                    ingredients[item].category ===
                                    focusedPrepItem.ingredientCategory
                                );
                            })
                            .map((option, idx) => {
                                return (
                                    <MenuItem
                                        value={option}
                                        key={idx}
                                    >
                                        {option}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
                <FormControl
                    variant="standard"
                    sx={{
                        marginTop: 1,
                        marginBottom: 1,
                    }}
                >
                    <ButtonGroup size="large">
                        <Button
                            key="As Needed"
                            variant={
                                focusedPrepItem.scheduleType === 'As Needed'
                                    ? 'contained'
                                    : undefined
                            }
                            onClick={() => {
                                dispatch(
                                    updateFocusedPrepItem({
                                        scheduleType: 'As Needed',
                                    })
                                );
                            }}
                        >
                            As Needed
                        </Button>
                        <Button
                            key="Batches"
                            variant={
                                focusedPrepItem.scheduleType === 'Batches'
                                    ? 'contained'
                                    : undefined
                            }
                            onClick={() => {
                                dispatch(
                                    updateFocusedPrepItem({
                                        scheduleType: 'Batches',
                                    })
                                );
                            }}
                        >
                            Batches
                        </Button>
                    </ButtonGroup>
                </FormControl>
                {focusedPrepItem.scheduleType === 'Batches' && (
                    <FormControl
                        variant="standard"
                        sx={{
                            marginTop: 1,
                            marginBottom: 1,
                        }}
                    >
                        <ButtonGroup size="large">
                            <Button
                                key="schedule"
                                variant={
                                    focusedPrepItem.prepSchedule === 'schedule'
                                        ? 'contained'
                                        : undefined
                                }
                                onClick={() => {
                                    dispatch(
                                        updateFocusedPrepItem({
                                            prepSchedule: 'schedule',
                                        })
                                    );
                                }}
                            >
                                This item is prepped on a specific day of the
                                week
                            </Button>
                            <Button
                                key="shelf life"
                                variant={
                                    focusedPrepItem.prepSchedule ===
                                    'shelf life'
                                        ? 'contained'
                                        : undefined
                                }
                                onClick={() => {
                                    dispatch(
                                        updateFocusedPrepItem({
                                            prepSchedule: 'shelf life',
                                        })
                                    );
                                }}
                            >
                                This item is prepped to shelf life
                            </Button>
                        </ButtonGroup>
                    </FormControl>
                )}
                {focusedPrepItem.scheduleType === 'Batches' &&
                    focusedPrepItem.prepSchedule === 'schedule' && (
                        <FormControl
                            variant="standard"
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginTop: 1,
                                marginBottom: 1,
                            }}
                        >
                            {focusedPrepItem.prepDays.map((value, day) => {
                                return (
                                    <FormControlLabel
                                        sx={{ margin: 0 }}
                                        key={day}
                                        labelPlacement="top"
                                        control={
                                            <Checkbox
                                                checked={value}
                                                onChange={(event) => {
                                                    let prepDaysCopy =
                                                        JSON.parse(
                                                            JSON.stringify(
                                                                focusedPrepItem.prepDays
                                                            )
                                                        );
                                                    prepDaysCopy[day] =
                                                        event.target.checked;
                                                    dispatch(
                                                        updateFocusedPrepItem({
                                                            prepDays:
                                                                prepDaysCopy,
                                                        })
                                                    );
                                                }}
                                            />
                                        }
                                        label={days[day]}
                                    />
                                );
                            })}
                        </FormControl>
                    )}
                <FormControl
                    variant="standard"
                    sx={{
                        marginTop: 1,
                        marginBottom: 1,
                    }}
                >
                    <ButtonGroup size="large">
                        <Button
                            key={
                                focusedPrepItem.ingredientUUID
                                    ? Object.values(ingredients).find(
                                          (ingredient: any) =>
                                              ingredient.uuid ===
                                              focusedPrepItem.ingredientUUID
                                      ).units
                                    : 'no unit available'
                            }
                            variant={
                                focusedPrepItem.ingredientUUID
                                    ? focusedPrepItem.newUnit === null
                                        ? 'contained'
                                        : undefined
                                    : undefined
                            }
                            onClick={() => {
                                dispatch(
                                    updateFocusedPrepItem({
                                        units: focusedPrepItem.ingredientUUID
                                            ? Object.values(ingredients).find(
                                                  (ingredient: any) =>
                                                      ingredient.uuid ===
                                                      focusedPrepItem.ingredientUUID
                                              ).units
                                            : null,
                                        newUnit: null,
                                        newUnitConversion: undefined,
                                    })
                                );
                            }}
                            disabled={!!!focusedPrepItem.ingredientUUID}
                        >
                            {focusedPrepItem.ingredientUUID
                                ? `Use "${
                                      Object.values(ingredients).find(
                                          (ingredient: any) =>
                                              ingredient.uuid ===
                                              focusedPrepItem.ingredientUUID
                                      ).units
                                  }"`
                                : 'No unit available'}
                        </Button>
                        <Button
                            key={null}
                            variant={
                                focusedPrepItem.ingredientUUID
                                    ? focusedPrepItem.newUnit !== null
                                        ? 'contained'
                                        : undefined
                                    : undefined
                            }
                            onClick={() => {
                                dispatch(
                                    updateFocusedPrepItem({
                                        // Below is stopgap to return removed unit to original unit
                                        units: focusedPrepItem.ingredientUUID
                                            ? Object.values(ingredients).find(
                                                  (ingredient: any) =>
                                                      ingredient.uuid ===
                                                      focusedPrepItem.ingredientUUID
                                              ).units
                                            : null,
                                        // Stopgap end. Delete above when all original units are restored.
                                        newUnit: '',
                                        newUnitConversion: '',
                                    })
                                );
                            }}
                            disabled={!!!focusedPrepItem.ingredientUUID}
                        >
                            Convert to something else
                        </Button>
                    </ButtonGroup>
                </FormControl>
                {focusedPrepItem.ingredientUUID &&
                    focusedPrepItem.newUnit !== null && (
                        <>
                            <FormControl
                                variant="standard"
                                sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                }}
                            >
                                <InputLabel>
                                    {focusedPrepItem.itemName} Units
                                </InputLabel>
                                <Input
                                    value={focusedPrepItem.newUnit}
                                    onChange={(event) => {
                                        dispatch(
                                            updateFocusedPrepItem({
                                                newUnit: event.target.value,
                                            })
                                        );
                                    }}
                                />
                            </FormControl>
                            <FormControl
                                variant="standard"
                                sx={{
                                    marginTop: 1,
                                    marginBottom: 1,
                                }}
                            >
                                <TextField
                                    id="filled-number"
                                    label={
                                        focusedPrepItem.newUnit +
                                        ' yield of ' +
                                        Object.values(ingredients).find(
                                            (ingredient: any) =>
                                                ingredient.uuid ===
                                                focusedPrepItem.ingredientUUID
                                        ).units
                                    }
                                    type="number"
                                    value={focusedPrepItem.newUnitConversion}
                                    variant="standard"
                                    onChange={(event) => {
                                        dispatch(
                                            updateFocusedPrepItem({
                                                newUnitConversion:
                                                    event.target.value,
                                            })
                                        );
                                    }}
                                />
                            </FormControl>
                        </>
                    )}
                <FormGroup
                    aria-label="position"
                    row
                >
                    <FormControl
                        variant="standard"
                        sx={{
                            marginTop: 1,
                            marginBottom: 1,
                        }}
                    >
                        <TextField
                            id="filled-number"
                            label="Shelf Life"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={focusedPrepItem.shelfLife}
                            variant="standard"
                            error={
                                Number(focusedPrepItem.shelfLife) % 1 !== 0 ||
                                Number(focusedPrepItem.shelfLife) < 0
                            }
                            InputProps={{
                                endAdornment: !units_of_time_selector && (
                                    <InputAdornment position="end">
                                        Days
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) => {
                                dispatch(
                                    updateFocusedPrepItem({
                                        shelfLife: event.target.value,
                                    })
                                );
                            }}
                        />
                        {(Number(focusedPrepItem.shelfLife) % 1 !== 0 ||
                            Number(focusedPrepItem.shelfLife) < 0) && (
                            <FormHelperText id="component-error-text">
                                Only whole, non-negative values are allowed.
                            </FormHelperText>
                        )}
                    </FormControl>
                    {units_of_time_selector && (
                        <FormControl
                            variant="standard"
                            sx={{
                                marginTop: 1,
                                marginBottom: 1,
                            }}
                        >
                            <InputLabel id="select-unit-of-time-label">
                                Unit
                            </InputLabel>
                            <Select
                                labelId="select-unit-of-time-label"
                                id="select-unit-of-time"
                                value={focusedPrepItem.shelfLifeUnits}
                                label="Unit"
                                onChange={(event) =>
                                    dispatch(
                                        updateFocusedPrepItem({
                                            shelfLifeUnits: event.target.value,
                                        })
                                    )
                                }
                            >
                                {['Days'].map((option, idx) => {
                                    return (
                                        <MenuItem
                                            value={option}
                                            key={idx}
                                        >
                                            {option}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}
                </FormGroup>
                <FormGroup
                    aria-label="position"
                    row
                >
                    <FormControl
                        variant="standard"
                        sx={{
                            marginTop: 1,
                            marginBottom: 1,
                        }}
                    >
                        <TextField
                            id="filled-number"
                            label="Lead Time"
                            type="number"
                            inputProps={{ min: 0 }}
                            value={focusedPrepItem.leadTime}
                            variant="standard"
                            error={
                                Number(focusedPrepItem.leadTime) % 1 !== 0 ||
                                Number(focusedPrepItem.leadTime) < 0
                            }
                            InputProps={{
                                endAdornment: !units_of_time_selector && (
                                    <InputAdornment position="end">
                                        Days
                                    </InputAdornment>
                                ),
                            }}
                            onChange={(event) => {
                                dispatch(
                                    updateFocusedPrepItem({
                                        leadTime: event.target.value,
                                    })
                                );
                            }}
                        />
                        {(Number(focusedPrepItem.leadTime) % 1 !== 0 ||
                            Number(focusedPrepItem.leadTime) < 0) && (
                            <FormHelperText id="component-error-text">
                                Only whole, non-negative values are allowed.
                            </FormHelperText>
                        )}
                    </FormControl>
                    {units_of_time_selector && (
                        <FormControl
                            variant="standard"
                            sx={{
                                marginTop: 1,
                                marginBottom: 1,
                            }}
                        >
                            <InputLabel id="select-unit-of-time-label">
                                Unit
                            </InputLabel>
                            <Select
                                labelId="select-unit-of-time-label"
                                id="select-unit-of-time"
                                value={focusedPrepItem.leadTimeUnits}
                                label="Unit"
                                onChange={(event) =>
                                    dispatch(
                                        updateFocusedPrepItem({
                                            leadTimeUnits: event.target.value,
                                        })
                                    )
                                }
                            >
                                {['Days'].map((option, idx) => {
                                    return (
                                        <MenuItem
                                            value={option}
                                            key={idx}
                                        >
                                            {option}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button
                    color="error"
                    onClick={() => {
                        addToPrepList(focusedPrepItem.id, true);
                        dispatch(clearFocusedPrepItem());
                    }}
                >
                    DELETE
                </Button>
                <Button
                    onClick={() => {
                        dispatch(clearFocusedPrepItem());
                    }}
                >
                    Cancel
                </Button>
                <Button
                    disabled={
                        focusedPrepItem.leadTime === '' ||
                        focusedPrepItem.leadTimeUnits === '' ||
                        Number(focusedPrepItem.leadTime) % 1 !== 0 ||
                        Number(focusedPrepItem.leadTime) < 0 ||
                        focusedPrepItem.shelfLife === '' ||
                        focusedPrepItem.shelfLifeUnits === '' ||
                        Number(focusedPrepItem.shelfLife) % 1 !== 0 ||
                        Number(focusedPrepItem.shelfLife) < 0 ||
                        focusedPrepItem.newUnit === '' ||
                        focusedPrepItem.newUnitConversion === ''
                    }
                    onClick={() => {
                        addToPrepList(focusedPrepItem.id, false, true);
                    }}
                    color={
                        Number(focusedPrepItem.shelfLife) % 1 !== 0 ||
                        Number(focusedPrepItem.shelfLife) < 0 ||
                        Number(focusedPrepItem.leadTime) % 1 !== 0 ||
                        Number(focusedPrepItem.leadTime) < 0
                            ? 'error'
                            : 'primary'
                    }
                >
                    Create New
                </Button>
                <Button
                    disabled={
                        focusedPrepItem.leadTime === '' ||
                        focusedPrepItem.leadTimeUnits === '' ||
                        Number(focusedPrepItem.leadTime) % 1 !== 0 ||
                        Number(focusedPrepItem.leadTime) < 0 ||
                        focusedPrepItem.shelfLife === '' ||
                        focusedPrepItem.shelfLifeUnits === '' ||
                        Number(focusedPrepItem.shelfLife) % 1 !== 0 ||
                        Number(focusedPrepItem.shelfLife) < 0 ||
                        focusedPrepItem.newUnit === '' ||
                        focusedPrepItem.newUnitConversion === ''
                    }
                    onClick={() => {
                        addToPrepList(focusedPrepItem.id);
                        dispatch(clearFocusedPrepItem());
                    }}
                    color={
                        Number(focusedPrepItem.shelfLife) % 1 !== 0 ||
                        Number(focusedPrepItem.shelfLife) < 0 ||
                        Number(focusedPrepItem.leadTime) % 1 !== 0 ||
                        Number(focusedPrepItem.leadTime) < 0
                            ? 'error'
                            : 'primary'
                    }
                    autoFocus
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
