import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { ratio } from '../../../../constants/recipe_constants';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import {
    clearFocusedPrepItem,
    newFocusedPrepItem,
} from '../../../../redux/reducers/recipes/recipesActions';
import {
    selectDialogState,
    selectFocusedPrepItem,
    selectOpenDialog,
    selectSelectedPrepList,
} from '../../../../redux/reducers/recipes/recipesSelectors';

interface FinishEntryProps {}

export const FinishEntry: React.FC<FinishEntryProps> = () => {
    const dispatch = useAppDispatch();

    const selectedPrepList = useAppSelector(selectSelectedPrepList);
    const focusedPrepItem = useAppSelector(selectFocusedPrepItem);
    const dialogState = useAppSelector(selectDialogState);
    const openDialog = useAppSelector(selectOpenDialog);

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            sx={{
                width: `${(1 - ratio) * 100}%`,
                marginLeft: `${ratio * 100}%`,
            }}
            open={openDialog && dialogState === 'FinishEntry'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            onClose={() => {
                dispatch(clearFocusedPrepItem());
            }}
            BackdropProps={{
                style: { backgroundColor: 'transparent' },
            }}
        >
            <DialogTitle id="alert-dialog-title">Congrats!</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <span style={{ color: 'blue' }}>
                        {focusedPrepItem.ingredientName}
                    </span>{' '}
                    will now be included in{' '}
                    <span style={{ color: 'blue' }}>{selectedPrepList}</span>{' '}
                    Prep report!
                    <br />
                    <br />
                    Would you like to add another item to this report?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        dispatch(clearFocusedPrepItem());
                    }}
                >
                    Done for Now
                </Button>
                <Button
                    onClick={() => {
                        dispatch(newFocusedPrepItem(focusedPrepItem.section));
                    }}
                    autoFocus
                >
                    Add another item
                </Button>
            </DialogActions>
        </Dialog>
    );
};
