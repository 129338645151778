import React, { Component } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { connect } from 'react-redux';
import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

import { Auth as AWS_Auth, Amplify } from 'aws-amplify';
import Cookies from 'js-cookie';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Paper,
    Select,
} from '@mui/material';

import { AppFooter } from './AppFooter';
import { getEmailLists, getUserDetails, postTelemetryLog } from './api';
import { authenticationChecks } from './authentication/authenticationChecks';
import { AuthStrings } from './constants/auth_strings';
import awsconfig from './constants/aws-exports';
import { AWS_Offline_SignIn, AWS_Offline_User } from './constants/offline_data';
import { RoutesURL } from './constants/routes';
import { logAction, logSeverity, logType } from './constants/telemetry';
import { selectIsMobile } from './redux/reducers/responsive/responsiveSelectors';
import { RootState } from './redux/store';
import {
    Auth,
    Dashboard,
    Email,
    Error,
    Missing,
    Nav,
    Onboarding,
    Recipes,
    Settings,
    Subscription,
    UnderConstruction,
} from './views';

Amplify.configure(awsconfig);

const offlineMode = process.env.REACT_APP_OFFLINE_MODE === 'true';
const debugMode = process.env.REACT_APP_DEBUG === 'true';

function withRouter(Component: any) {
    function ComponentWithRouterProp(props: any) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }
    return ComponentWithRouterProp;
}

interface AppRouterState {
    user: any;
    userDomain: string;
    status: boolean;
    message: string;
    session: any;
    otp: string;
    email: string;
    organization: any[];
    initialCheck: boolean;
    organizationError: boolean;
    user_details: any;
    concept: string;
    store: string;
    storeName: string;
    flattenedStores: any[];
    conceptMap: any;
    tempConcept: string;
    tempStore: string;
    openConceptStorePairMenu: any;
    openDialogWarning: boolean;
    delayedRedirect: boolean;
    delayedRedirectInit: boolean;
}

type RouterState = {
    user: any;
    userDomain: string;
    status: boolean;
    message: string;
    session: any;
    otp: string;
    email: string;
    organization: any[];
    initialCheck: boolean;
    organizationError: boolean;
    user_details: any;
    concept: string;
    store: string;
    flattenedStores: any[];
    conceptMap: any;
    storeName: string;
    tempConcept: string;
    tempStore: string;
    openConceptStorePairMenu: boolean;
    openDialogWarning: boolean;
    delayedRedirect: boolean;
    delayedRedirectInit: boolean;
};
type RouterProps = { router: any; isMobile: boolean };

class AppRouter extends Component<RouterProps, RouterState> {
    state: AppRouterState = {
        user: null,
        userDomain: '',
        status: false,
        message: 'Welcome to ClearCOGS!',
        session: null,
        otp: '',
        email: '',
        organization: [],
        initialCheck: false,
        organizationError: false,
        user_details: {},
        concept: '',
        store: '',
        storeName: '',
        flattenedStores: [],
        conceptMap: {},
        tempConcept: '',
        tempStore: '',
        openConceptStorePairMenu: false,
        openDialogWarning: process.env.REACT_APP_DEPLOYMENT === 'development',
        delayedRedirect: false,
        delayedRedirectInit: false,
    };

    componentDidMount() {
        if (debugMode) {
            console.log(
                `page location: ${window.location.origin.toString()}\n`,
                `page extention: ${window.location.pathname.toString()}\n`,
                `DEBUG FLAG: ${process.env.REACT_APP_DEBUG === 'true'}\n`,
                `OFFLINE FLAG: ${
                    process.env.REACT_APP_OFFLINE_MODE === 'true'
                }\n`,
                `SWITCH UNITS OF TIME SELECTOR: ${
                    process.env.REACT_APP_FEATURE_UNITS_OF_TIME_SELECTOR ===
                    'true'
                }\n`,
                `NEW DATA RELATIONSHIP: ${
                    process.env.REACT_APP_FEATURE_NEW_DATA_RELATIONSHIP ===
                    'true'
                }\n`,
                `NEW DATA FORMAT: ${
                    process.env.REACT_APP_FEATURE_NEW_DATA_FORMAT === 'true'
                }\n`,
                `WASTE STOCKOUT SLIDER: ${
                    process.env.REACT_APP_FEATURE_WASTE_STOCKOUT_SLIDER ===
                    'true'
                }\n`,
                `ENFORCE LICENSE LIMIT: ${
                    process.env.REACT_APP_FEATURE_ENFORCE_LICENSE_LIMIT ===
                    'true'
                }\n`,
                process.env
                    .REACT_APP_PATRICKS_SUPER_SECRET_FUCK_THIS_SHIT_FEATURES ===
                    'true'
                    ? ';)'
                    : 'Standard operations are a go.'
            );
        }
        if (this.state.user == null) {
            this.verifyAuth();
        }
        //this.signOut();
    }

    setOpenConceptStorePairMenu = () => {
        this.setState({
            openConceptStorePairMenu: !this.state.openConceptStorePairMenu,
        });
    };

    setNewUser = (user: any) => {
        this.setState({ user: user });
    };

    setEmail = (email: string) => {
        this.setState({ email: email });
    };

    setOrgnization = (organization: any[]) => {
        this.setState({ organization: organization });
    };

    setOtp = (otp: string) => {
        this.setState({ otp: otp });
    };

    findStoreName = (stores: any, id: string) => {
        let store: any = stores.find((obj: any) => obj.id === id);
        return store ? store.name : id;
    };

    setRefreshToken = () => {
        setTimeout(() => {
            AWS_Auth.currentSession().catch((err) => {
                console.error(err);
                this.signOut();
            });
        }, 3000000);
    };

    verifyAuth = async () => {
        let cookiedConcept = Cookies.get('concept');
        let cookiedStore = Cookies.get('store');
        let initConcept = (results: any) => {
            return cookiedConcept &&
                Object.keys(results.stores).includes(cookiedConcept)
                ? cookiedConcept
                : results.email.split('@').pop().split('.')[0] === 'clearcogs'
                  ? 'demo'
                  : Object.keys(results.stores)[0];
        };
        let initStore = (results: any) => {
            let concept = initConcept(results);
            return cookiedStore ?? results.stores[concept][0]
                ? results.stores[concept][0]
                : '';
        };

        AWS_Auth.currentSession()
            .then(() => {
                AWS_Auth.currentAuthenticatedUser()
                    .then((user) => {
                        debugMode && console.log('user: ', user);
                        Cookies.set(
                            'user',
                            user.signInUserSession.accessToken.payload.username
                        );
                        Cookies.set(
                            'user_uuid',
                            user.signInUserSession.accessToken.payload.username,
                            {
                                domain: '.clearcogs.ai',
                            }
                        );
                        this.setState({
                            user: user.signInUserSession.accessToken.payload
                                .username,
                            message: AuthStrings.SIGNED_IN,
                            status: true,
                            session: null,
                        });
                        getUserDetails(
                            user.signInUserSession.accessToken.payload.username
                        )
                            .then((results) => {
                                if (results.state === 'free') {
                                    window.location.href =
                                        'https://subscriptions.clearcogs.ai/Account';
                                }
                                // if (results.user_type === 'someshit') (return <redirect to someshit>); return; else, continue;
                                let flattenedStores = Object.keys(
                                    results.stores_exp
                                )
                                    .map((concept) => {
                                        return Object.keys(
                                            results.stores_exp[concept]
                                        ).map((store) => {
                                            return results.stores_exp[concept][
                                                store
                                            ];
                                        });
                                    })
                                    .flat();
                                debugMode &&
                                    console.log(
                                        'Flattened Store Exp: ',
                                        flattenedStores
                                    );
                                debugMode &&
                                    console.log('User Details: ', results);
                                this.setState({
                                    user_details: process.env
                                        .REACT_APP_ONBOARDING_STATE
                                        ? {
                                              ...JSON.parse(
                                                  JSON.stringify(results)
                                              ),
                                              state: process.env
                                                  .REACT_APP_ONBOARDING_STATE,
                                          }
                                        : JSON.parse(JSON.stringify(results)),
                                    userDomain: results.email
                                        .split('@')
                                        .pop()
                                        .split('.')[0],
                                    concept: initConcept(results),
                                    tempConcept: initConcept(results),
                                    store: initStore(results),
                                    tempStore: initStore(results),
                                    flattenedStores: flattenedStores,
                                    conceptMap: results.concept_translation,
                                });
                                if (
                                    results.email
                                        .split('@')
                                        .pop()
                                        .split('.')[0]
                                        .toUpperCase() !== 'CLEARCOGS'
                                ) {
                                    if (
                                        !!process.env[
                                            `REACT_APP_DOMAIN_${results.email
                                                .split('@')
                                                .pop()
                                                .split('.')[0]
                                                .toUpperCase()}`
                                        ] &&
                                        process.env[
                                            `REACT_APP_DOMAIN_${results.email
                                                .split('@')
                                                .pop()
                                                .split('.')[0]
                                                .toUpperCase()}`
                                        ] !== window.location.origin.toString()
                                    ) {
                                        window.location.href = `${
                                            process.env[
                                                `REACT_APP_DOMAIN_${results.email
                                                    .split('@')
                                                    .pop()
                                                    .split('.')[0]
                                                    .toUpperCase()}`
                                            ] +
                                            window.location.pathname.toString()
                                        }`;
                                    } else if (
                                        !!!process.env[
                                            `REACT_APP_DOMAIN_${results.email
                                                .split('@')
                                                .pop()
                                                .split('.')[0]
                                                .toUpperCase()}`
                                        ] &&
                                        process.env
                                            .REACT_APP_UNREGISTERED_DOMAIN !==
                                            window.location.origin.toString()
                                    ) {
                                        window.location.href = `${
                                            process.env
                                                .REACT_APP_UNREGISTERED_DOMAIN +
                                            window.location.pathname.toString()
                                        }`;
                                    }
                                }
                                Cookies.set(
                                    'tenant',
                                    results.email.split('@').pop().split('.')[0]
                                );
                                if (debugMode) {
                                    console.log(
                                        'user domain: ',
                                        results.email
                                            .split('@')
                                            .pop()
                                            .split('.')[0]
                                    );
                                    console.log('details: ', results);
                                    console.log(
                                        'Email Input: ',
                                        Object.keys(results.stores)
                                            .map((store) => {
                                                return results.stores[store];
                                            })
                                            .flat()
                                    );
                                }
                                Object.keys(results.stores)
                                    .map((store) => {
                                        return results.stores[store];
                                    })
                                    .flat().length !== 0
                                    ? getEmailLists(
                                          Object.keys(results.stores)
                                              .map((store) => {
                                                  return results.stores[store];
                                              })
                                              .flat()
                                      )
                                          .then((results) => {
                                              debugMode &&
                                                  console.log(
                                                      'Email Lists: ',
                                                      results
                                                  );
                                              this.setState({
                                                  organization: results,
                                                  storeName: this.findStoreName(
                                                      results,
                                                      this.state.store
                                                  ),
                                                  initialCheck: true,
                                              });
                                          })
                                          .catch((err) => {
                                              console.error(err);
                                              this.setState({
                                                  organizationError: true,
                                                  initialCheck: true,
                                              });
                                          })
                                    : this.setState({
                                          initialCheck: true,
                                      });
                            })
                            .catch((err) => {
                                console.error(err);
                                this.signOut();
                                this.setState({
                                    message: AuthStrings.NOT_SIGNED_IN,
                                    initialCheck: true,
                                });
                            });
                    })
                    .catch((err) => {
                        console.error(err);
                        this.signOut();
                        this.setState({
                            message: AuthStrings.NOT_SIGNED_IN,
                            initialCheck: true,
                        });
                    });
                this.setRefreshToken();
            })
            .catch((err) => {
                console.error(err);
                this.signOut();
                this.setState({
                    message: AuthStrings.NOT_SIGNED_IN,
                    initialCheck: true,
                });
            });
    };

    signIn = () => {
        this.setState({ message: AuthStrings.VERIFY_NUMBER });
        (offlineMode
            ? Promise.resolve(AWS_Offline_SignIn)
            : AWS_Auth.signIn(this.state.email)
        )
            .then((result) => {
                debugMode && console.log('Session: ', result);
                this.setState({
                    session: result,
                    message: AuthStrings.WAITING_FOR_OTP,
                });
            })
            .catch((e) => {
                if (e.code === 'AuthStrings.USER_NOT_FOUNDException') {
                    this.setState({ message: AuthStrings.USER_NOT_FOUND });
                    //signUp();
                } else if (e.code === 'UsernameExistsException') {
                    this.setState({ message: AuthStrings.WAITING_FOR_OTP });
                    this.signIn();
                } else {
                    this.setState({ message: AuthStrings.UNEXPECTED_ERROR });
                    console.log(e.code);
                    console.error(e);
                }
            });
    };

    verifyOtp = () => {
        let cookiedConcept = Cookies.get('concept');
        let cookiedStore = Cookies.get('store');
        (offlineMode
            ? Promise.resolve(AWS_Offline_User)
            : AWS_Auth.sendCustomChallengeAnswer(
                  this.state.session,
                  this.state.otp
              )
        )
            .then((user) => {
                debugMode && console.log('user: ', user);
                if (user.challengeParam === undefined) {
                    Cookies.set(
                        'user_uuid',
                        user.signInUserSession.accessToken.payload.username,
                        {
                            domain: '.clearcogs.ai',
                        }
                    );
                    this.setState({
                        user: user.signInUserSession.accessToken.payload
                            .username,
                        message: AuthStrings.SIGNED_IN,
                        status: true,
                        session: null,
                    });
                    getUserDetails(
                        user.signInUserSession.accessToken.payload.username
                    ).then((results) => {
                        if (results.state === 'free') {
                            window.location.href =
                                'https://subscriptions.clearcogs.ai/Account';
                        }
                        let flattenedStores = Object.keys(results.stores_exp)
                            .map((concept) => {
                                return Object.keys(
                                    results.stores_exp[concept]
                                ).map((store) => {
                                    return results.stores_exp[concept][store];
                                });
                            })
                            .flat();
                        debugMode &&
                            console.log(
                                'Flattened Store Exp: ',
                                flattenedStores
                            );
                        this.setState({
                            user_details: process.env.REACT_APP_ONBOARDING_STATE
                                ? {
                                      ...JSON.parse(JSON.stringify(results)),
                                      state: process.env
                                          .REACT_APP_ONBOARDING_STATE,
                                  }
                                : JSON.parse(JSON.stringify(results)),
                            concept:
                                cookiedConcept ??
                                Object.keys(results.stores)[0],
                            tempConcept:
                                cookiedConcept ??
                                Object.keys(results.stores)[0],
                            store:
                                cookiedStore ??
                                results.stores[
                                    Object.keys(results.stores)[0]
                                ][0]
                                    ? results.stores[
                                          Object.keys(results.stores)[0]
                                      ][0]
                                    : '',
                            tempStore:
                                cookiedStore ??
                                results.stores[
                                    Object.keys(results.stores)[0]
                                ][0]
                                    ? results.stores[
                                          Object.keys(results.stores)[0]
                                      ][0]
                                    : '',
                            flattenedStores: flattenedStores,
                            conceptMap: results.concept_translation,
                        });
                        debugMode && console.log('details: ', results);
                        Object.keys(results.stores)
                            .map((store) => {
                                return results.stores[store];
                            })
                            .flat().length !== 0 &&
                            getEmailLists(
                                Object.keys(results.stores)
                                    .map((store) => {
                                        return results.stores[store];
                                    })
                                    .flat()
                            )
                                .then((results) => {
                                    this.setState({
                                        organization: results,
                                        storeName: this.findStoreName(
                                            results,
                                            this.state.store
                                        ),
                                    });
                                })
                                .catch((err) => {
                                    console.error(err);
                                    this.setState({ organizationError: true });
                                });
                    });
                } else {
                    this.setState({
                        message: AuthStrings.WRONG_PASSWORD,
                        otp: '',
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    message: err.message,
                    otp: '',
                });
                debugMode && console.log(err);
            });
    };

    signOut = () => {
        Cookies.remove('user');
        Cookies.remove('tenant');
        if (this.state.user) {
            !offlineMode && AWS_Auth.signOut({ global: true });
            this.setState({
                user: null,
                status: false,
                message: AuthStrings.NOT_SIGNED_IN,
                initialCheck: true,
            });
        } else {
            !offlineMode && AWS_Auth.signOut();
        }
    };

    refreshUserData = () => {
        let cookiedConcept = Cookies.get('concept');
        let cookiedStore = Cookies.get('store');
        return getUserDetails(this.state.user)
            .then((results) => {
                let flattenedStores = Object.keys(results.stores_exp)
                    .map((concept) => {
                        return Object.keys(results.stores_exp[concept]).map(
                            (store) => {
                                return results.stores_exp[concept][store];
                            }
                        );
                    })
                    .flat();
                this.setState({
                    user_details: process.env.REACT_APP_ONBOARDING_STATE
                        ? {
                              ...JSON.parse(JSON.stringify(results)),
                              state: process.env.REACT_APP_ONBOARDING_STATE,
                          }
                        : JSON.parse(JSON.stringify(results)),
                    concept: cookiedConcept ?? Object.keys(results.stores)[0],
                    tempConcept:
                        cookiedConcept ?? Object.keys(results.stores)[0],
                    store:
                        cookiedStore ??
                        results.stores[Object.keys(results.stores)[0]][0]
                            ? results.stores[Object.keys(results.stores)[0]][0]
                            : '',
                    tempStore:
                        cookiedStore ??
                        results.stores[Object.keys(results.stores)[0]][0]
                            ? results.stores[Object.keys(results.stores)[0]][0]
                            : '',
                    flattenedStores: flattenedStores,
                    conceptMap: results.concept_translation,
                });
                return true;
            })
            .catch((err) => {
                console.error(err);
            });
    };

    //TODO: Delayed state change causes a state/render mismatch. Needs attention
    redirectToDashboard = () => {
        if (!this.state.delayedRedirectInit) {
            setTimeout(() => {
                this.setState({
                    delayedRedirect: true,
                });
            }, 10000);
            this.setState({
                delayedRedirectInit: true,
            });
        }
        return this.state.delayedRedirect;
    };

    render() {
        const {
            status,
            user,
            userDomain,
            user_details,
            message,
            session,
            otp,
            organization,
            initialCheck,
            organizationError,
            concept,
            store,
            storeName,
            flattenedStores,
            tempConcept,
            tempStore,
            openConceptStorePairMenu,
            openDialogWarning,
            delayedRedirect,
            conceptMap,
        } = this.state;

        const {
            emailProps,
            authProps,
            dashboardProps,
            recipeProps,
            subscriptionProps,
            onboardingProps,
        } = this.generateComponentProps(
            message,
            session,
            otp,
            concept,
            store,
            user,
            organization,
            organizationError,
            user_details,
            flattenedStores
        );

        const { sendToAuth, sendFromAuth } = authenticationChecks(
            initialCheck,
            user
        );

        return (
            <React.Fragment>
                <Box
                    width="100%"
                    height="100%"
                    minHeight={[
                        '-moz-fill-available',
                        '-webkit-fill-available',
                        'fill-available',
                    ]}
                    position="fixed"
                    sx={{
                        backgroundColor: '#F2F2F2',
                        display: 'flex',
                        flexDirection: this.props.isMobile
                            ? 'column-reverse'
                            : 'row',
                    }}
                >
                    <Dialog
                        fullWidth
                        open={openConceptStorePairMenu}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        onClose={() => {
                            this.setState({
                                openConceptStorePairMenu: false,
                                tempStore: store,
                                tempConcept: concept,
                            });
                        }}
                    >
                        <DialogTitle id="alert-dialog-title">
                            View Another Store
                        </DialogTitle>
                        <DialogContent>
                            <FormControl
                                variant="standard"
                                sx={{
                                    margin: 1,
                                }}
                            >
                                <InputLabel id="first-simple-select-label">
                                    Concept
                                </InputLabel>
                                <Select
                                    labelId="first-simple-select-label"
                                    id="first-simple-select"
                                    value={tempConcept}
                                    label="Concept"
                                    sx={{ minWidth: '150px' }}
                                    onChange={(event) => {
                                        if (
                                            user_details &&
                                            user_details.stores &&
                                            !!user_details.stores[
                                                event.target.value
                                            ].length
                                        ) {
                                            this.setState({
                                                tempConcept: event.target.value,
                                                tempStore: this.findStoreName(
                                                    organization,
                                                    user_details.stores[
                                                        event.target.value
                                                    ][0]
                                                ),
                                            });
                                        } else {
                                            this.setState({
                                                tempConcept: event.target.value,
                                                tempStore: '',
                                            });
                                        }
                                    }}
                                >
                                    {user_details &&
                                        user_details.stores &&
                                        Object.keys(user_details.stores)
                                            .sort()
                                            .map(
                                                (
                                                    concept: string,
                                                    idx: number
                                                ) => {
                                                    return (
                                                        <MenuItem
                                                            value={concept}
                                                            key={idx}
                                                        >
                                                            {conceptMap[
                                                                concept
                                                            ] ?? concept}
                                                        </MenuItem>
                                                    );
                                                }
                                            )}
                                </Select>
                            </FormControl>
                            <FormControl
                                variant="standard"
                                sx={{
                                    margin: 1,
                                }}
                                disabled={
                                    !(
                                        user_details &&
                                        user_details.stores &&
                                        !!user_details.stores[tempConcept]
                                            .length
                                    )
                                }
                            >
                                <InputLabel id="first-simple-select-label">
                                    Store
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={tempStore}
                                    label={'Store'}
                                    sx={{ minWidth: '150px' }}
                                    onChange={(event) => {
                                        this.setState({
                                            tempStore: event.target.value
                                                ? event.target.value
                                                : '',
                                        });
                                    }}
                                >
                                    {user_details &&
                                        user_details.stores &&
                                        user_details.stores[tempConcept].map(
                                            (store: string, idx: number) => {
                                                return (
                                                    <MenuItem
                                                        value={store}
                                                        key={idx}
                                                    >
                                                        {this.findStoreName(
                                                            organization,
                                                            store
                                                        )}
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                </Select>
                                {!(
                                    user_details &&
                                    user_details.stores &&
                                    !!user_details.stores[tempConcept].length
                                ) && (
                                    <FormHelperText>Unavailable</FormHelperText>
                                )}
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        openConceptStorePairMenu: false,
                                        store: tempStore,
                                        storeName: this.findStoreName(
                                            organization,
                                            tempStore
                                        ),
                                        concept: tempConcept,
                                    });
                                    Cookies.set('concept', tempConcept, {
                                        expires: 7,
                                    });
                                    tempStore
                                        ? Cookies.set('store', tempStore, {
                                              expires: 7,
                                          })
                                        : Cookies.get('store') &&
                                          Cookies.remove('store');
                                }}
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        openConceptStorePairMenu: false,
                                        tempStore: store,
                                        tempConcept: concept,
                                    });
                                }}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {status &&
                        (process.env.REACT_APP_DEPLOYMENT === 'development' ||
                            !this.props.isMobile) && (
                            <Paper
                                elevation={16}
                                sx={{
                                    zIndex: 30,
                                }}
                            >
                                <Nav
                                    userDetails={user_details}
                                    setConceptStorePair={
                                        this.setOpenConceptStorePairMenu
                                    }
                                    path={window.location.pathname}
                                    signOut={this.signOut}
                                    concept={conceptMap[concept] ?? concept}
                                    store={storeName ? storeName : store}
                                />
                            </Paper>
                        )}
                    {process.env.REACT_APP_DEPLOYMENT !== 'development' &&
                        this.props.isMobile && (
                            <Navigate
                                to={RoutesURL.mobile}
                                replace={true}
                            />
                        )}
                    {window.location.pathname.length > 1 &&
                        window.location.pathname.slice(-1) === '/' && (
                            <Navigate
                                to={window.location.pathname.slice(0, -1)}
                                replace={true}
                            />
                        )}
                    {sendToAuth && (
                        <Navigate
                            to={RoutesURL.auth}
                            replace={true}
                        />
                    )}
                    {sendFromAuth && (
                        <Navigate
                            to={RoutesURL.email}
                            replace={true}
                        />
                    )}
                    {initialCheck &&
                        user != null &&
                        user_details.state === 'production' &&
                        (delayedRedirect || this.redirectToDashboard()) &&
                        (userDomain.toUpperCase() === 'CLEARCOGS' ? (
                            debugMode ? (
                                window.location.pathname === RoutesURL.debug ? (
                                    <Navigate
                                        to={RoutesURL.dashboard}
                                        replace={true}
                                    />
                                ) : null
                            ) : window.location.pathname ===
                              RoutesURL.verification ? (
                                <Navigate
                                    to={RoutesURL.dashboard}
                                    replace={true}
                                />
                            ) : null
                        ) : window.location.pathname ===
                          RoutesURL.verification ? (
                            <Navigate
                                to={RoutesURL.dashboard}
                                replace={true}
                            />
                        ) : null)}
                    {initialCheck &&
                        user != null &&
                        user_details.state &&
                        user_details.state !== 'production' &&
                        (userDomain.toUpperCase() === 'CLEARCOGS' ? (
                            debugMode ? (
                                window.location.pathname !== RoutesURL.debug ? (
                                    <Navigate
                                        to={RoutesURL.debug}
                                        replace={true}
                                    />
                                ) : null
                            ) : window.location.pathname !==
                              RoutesURL.verification ? (
                                <Navigate
                                    to={RoutesURL.verification}
                                    replace={true}
                                />
                            ) : null
                        ) : window.location.pathname !==
                          RoutesURL.verification ? (
                            <Navigate
                                to={RoutesURL.verification}
                                replace={true}
                            />
                        ) : null)}
                    <Box
                        width="fill-available"
                        height="100%"
                        display="flex"
                        sx={{
                            background: '#F2F2F2',
                            flexDirection: 'column',
                            alignItems: 'stretch',
                        }}
                    >
                        <Routes>
                            <Route
                                index
                                element={
                                    <ErrorBoundary
                                        FallbackComponent={Error}
                                        onError={() =>
                                            postTelemetryLog({
                                                action: logAction.renderPath,
                                                type: logType.Render,
                                                severity: logSeverity.Crash,
                                            })
                                        }
                                    >
                                        <Email {...emailProps} />
                                    </ErrorBoundary>
                                }
                            />
                            <Route
                                path={RoutesURL.auth}
                                element={
                                    <ErrorBoundary
                                        FallbackComponent={Error}
                                        onError={() =>
                                            postTelemetryLog({
                                                action: logAction.renderPath,
                                                type: logType.Render,
                                                severity: logSeverity.Crash,
                                            })
                                        }
                                    >
                                        <Auth {...authProps} />
                                    </ErrorBoundary>
                                }
                            />
                            <Route
                                path={RoutesURL.login}
                                element={
                                    <ErrorBoundary
                                        FallbackComponent={Error}
                                        onError={() =>
                                            postTelemetryLog({
                                                action: logAction.renderPath,
                                                type: logType.Render,
                                                severity: logSeverity.Crash,
                                            })
                                        }
                                    >
                                        <Auth {...authProps} />
                                    </ErrorBoundary>
                                }
                            />
                            <Route
                                path={RoutesURL.dashboard}
                                element={
                                    process.env.REACT_APP_DEPLOYMENT !==
                                    'production' ? (
                                        <ErrorBoundary
                                            FallbackComponent={Error}
                                            onError={() =>
                                                postTelemetryLog({
                                                    action: logAction.renderPath,
                                                    type: logType.Render,
                                                    severity: logSeverity.Crash,
                                                })
                                            }
                                        >
                                            <Dashboard {...dashboardProps} />
                                        </ErrorBoundary>
                                    ) : (
                                        <Missing />
                                    )
                                }
                            />
                            <Route
                                path={RoutesURL.email}
                                element={
                                    <ErrorBoundary
                                        FallbackComponent={Error}
                                        onError={() =>
                                            postTelemetryLog({
                                                action: logAction.renderPath,
                                                type: logType.Render,
                                                severity: logSeverity.Crash,
                                            })
                                        }
                                    >
                                        <Email {...emailProps} />
                                    </ErrorBoundary>
                                }
                            />
                            <Route
                                path={RoutesURL.recipes}
                                element={
                                    <ErrorBoundary
                                        FallbackComponent={Error}
                                        onError={() =>
                                            postTelemetryLog({
                                                action: logAction.renderPath,
                                                type: logType.Render,
                                                severity: logSeverity.Crash,
                                            })
                                        }
                                    >
                                        <Recipes {...recipeProps} />
                                    </ErrorBoundary>
                                }
                            />
                            <Route
                                path={RoutesURL.subsription}
                                element={
                                    process.env.REACT_APP_DEPLOYMENT ===
                                    'development' ? (
                                        <ErrorBoundary
                                            FallbackComponent={Error}
                                            onError={() =>
                                                postTelemetryLog({
                                                    action: logAction.renderPath,
                                                    type: logType.Render,
                                                    severity: logSeverity.Crash,
                                                })
                                            }
                                        >
                                            <Subscription
                                                {...subscriptionProps}
                                            />
                                        </ErrorBoundary>
                                    ) : (
                                        <Missing />
                                    )
                                }
                            />
                            <Route
                                path={RoutesURL.settings}
                                element={
                                    process.env.REACT_APP_DEPLOYMENT ===
                                    'development' ? (
                                        <ErrorBoundary
                                            FallbackComponent={Error}
                                            onError={() =>
                                                postTelemetryLog({
                                                    action: logAction.renderPath,
                                                    type: logType.Render,
                                                    severity: logSeverity.Crash,
                                                })
                                            }
                                        >
                                            <Settings {...subscriptionProps} />
                                        </ErrorBoundary>
                                    ) : (
                                        <Missing />
                                    )
                                }
                            />
                            <Route
                                path={RoutesURL.verification}
                                element={
                                    <ErrorBoundary
                                        FallbackComponent={Error}
                                        onError={() =>
                                            postTelemetryLog({
                                                action: logAction.renderPath,
                                                type: logType.Render,
                                                severity: logSeverity.Crash,
                                            })
                                        }
                                    >
                                        <>
                                            <Onboarding {...onboardingProps} />
                                            <Dashboard {...dashboardProps} />
                                        </>
                                    </ErrorBoundary>
                                }
                            />
                            <Route
                                path={RoutesURL.debug}
                                element={
                                    userDomain &&
                                    (userDomain.toUpperCase() ===
                                    'CLEARCOGS' ? (
                                        <ErrorBoundary
                                            FallbackComponent={Error}
                                            onError={() =>
                                                postTelemetryLog({
                                                    action: logAction.renderPath,
                                                    type: logType.Render,
                                                    severity: logSeverity.Crash,
                                                })
                                            }
                                        >
                                            <>
                                                <Onboarding
                                                    {...onboardingProps}
                                                />
                                                <Dashboard
                                                    {...dashboardProps}
                                                />
                                            </>
                                        </ErrorBoundary>
                                    ) : (
                                        <Missing />
                                    ))
                                }
                            />
                            <Route
                                path={RoutesURL.mobile}
                                element={<UnderConstruction />}
                            />
                            <Route
                                path={RoutesURL.fourofour}
                                element={<Missing />}
                            />
                            <Route
                                path={RoutesURL.notFound}
                                element={
                                    <Navigate
                                        replace
                                        to={RoutesURL.fourofour}
                                    />
                                }
                            />
                        </Routes>
                        {AppFooter()}
                    </Box>
                </Box>
                <Dialog
                    open={openDialogWarning}
                    onClose={() => {}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {'ClearCOGS Beta Agreement'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You are accessing a highly volatile version of our
                            application meant for development and testing. Your
                            actions may have severe effects on underlying user
                            data.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() =>
                                this.setState({ openDialogWarning: false })
                            }
                            autoFocus
                        >
                            Acknowledge
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }

    private generateComponentProps(
        message: string,
        session: any,
        otp: string,
        concept: string,
        store: string,
        user: any,
        organization: any[],
        organizationError: boolean,
        user_details: any,
        flattenedStores: any[]
    ) {
        const authProps = {
            message: message,
            session: session,
            otp: otp,
            setOtp: this.setOtp,
            setEmail: this.setEmail,
            verifyOtp: this.verifyOtp,
            signIn: this.signIn,
        };

        const dashboardProps = {
            concept: concept,
            store: store,
        };

        const emailProps = {
            user: user,
            organization: organization ? organization : [],
            setOrgnization: this.setOrgnization,
            organizationError: organizationError,
        };

        const recipeProps = {
            user_details: user_details,
            concept: concept,
            store: store,
        };

        const subscriptionProps = {
            user: user,
        };

        const onboardingProps = {
            user: user,
            user_details: user_details,
            concept: concept,
            stores: flattenedStores,
            refreshUserData: this.refreshUserData,
        };

        return {
            emailProps,
            authProps,
            dashboardProps,
            recipeProps,
            subscriptionProps,
            onboardingProps,
        };
    }
}

// Connects Redux store to AppRouter state
const mapStateToProps = (state: RootState) => {
    return {
        isMobile: selectIsMobile(state),
    };
};

export default connect(mapStateToProps)(withRouter(AppRouter));
