enum AuthStrings {
    NOT_SIGNED_IN = 'LOG IN',
    SIGNED_IN = 'You have logged in successfully',
    SIGNED_OUT = 'You have logged out successfully',
    WAITING_FOR_OTP = 'ENTER OTP',
    VERIFY_NUMBER = 'VERIFYING',
    USER_NOT_FOUND = 'USER NOT FOUND',
    UNEXPECTED_ERROR = 'UNEXPECTED ERROR, TRY AGAIN',
    WRONG_PASSWORD = 'WRONG PASSWORD',
}

export { AuthStrings };
