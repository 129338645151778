declare module '@mui/material/styles' {
    interface TypographyVariants {
        addSection: React.CSSProperties;
        centerText: React.CSSProperties;
        columnHeader: React.CSSProperties;
        emptyTab: React.CSSProperties;
        prepListHeader: React.CSSProperties;
        prepListSectionHeader: React.CSSProperties;
        prepListSelector: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        addSection?: React.CSSProperties;
        centerText?: React.CSSProperties;
        columnHeader?: React.CSSProperties;
        emptyTab?: React.CSSProperties;
        prepListHeader?: React.CSSProperties;
        prepListSectionHeader?: React.CSSProperties;
        prepListSelector?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        addSection: true;
        centerText: true;
        columnHeader: true;
        emptyTab: true;
        prepListHeader: true;
        prepListSectionHeader: true;
        prepListSelector: true;
    }
}

const defaultTypographyCSS = {
    margin: 0,
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
};

const typographyThemeOptions = {
    typography: {
        addSection: {
            ...defaultTypographyCSS,
            fontSize: '22px',
        },
        centerText: {
            ...defaultTypographyCSS,
            textAlign: 'center',
        },
        columnHeader: {
            ...defaultTypographyCSS,
            fontSize: '13px',
            fontWeight: '500',
            color: '#707070',
            textAlign: 'center',
        },
        emptyTab: {
            ...defaultTypographyCSS,
            textAlign: 'center',
            marginTop: '40px',
        },
        prepListHeader: {
            ...defaultTypographyCSS,
            fontSize: '24px',
            fontWeight: '700',
        },
        prepListSectionHeader: {
            ...defaultTypographyCSS,
            fontSize: '22px',
            fontWeight: '700',
        },
        prepListSelector: {
            ...defaultTypographyCSS,
            marginTop: '16px',
            marginBottom: '16px',
            marginLeft: '24px',
            fontWeight: '500',
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    addSection: 'p',
                    centerText: 'p',
                    columnHeader: 'p',
                    emptyTab: 'p',
                    prepListHeader: 'p',
                    prepListSectionHeader: 'p',
                    prepListSelector: 'p',
                },
            },
        },
    },
};

export default typographyThemeOptions;
