import { createStore } from 'redux';

import rootReducer from './reducers';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const store = createStore(rootReducer);

export default store;
