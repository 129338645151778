import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import AppRouter from './AppRouter';
import ReduxContext from './contexts/ReduxContext';
import ToastContext from './contexts/ToastContext';
import store from './redux/store';
import { muiTheme } from './styles';

// Outermost shell of core application post-authentication
function App() {
    //! Out of date comment below
    // Renders the Layout (1st Layer) -> Admin (2nd Layer)
    return (
        <ThemeProvider theme={muiTheme}>
            <Provider store={store}>
                <ReduxContext>
                    <ToastContext>
                        <BrowserRouter>
                            <AppRouter />
                        </BrowserRouter>
                    </ToastContext>
                </ReduxContext>
            </Provider>
        </ThemeProvider>
    );
}

// Exports for use by `./index.tsx`
export default App;
